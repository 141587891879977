import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentRole, currentShop, me } from 'src/redux/services/common/Common.slice';
import { Controller, useFormContext } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { useTranslation } from 'react-i18next';
import { RightGreen, Url } from 'src/theme/Images';
import CustomButton from 'src/components/CustomButton';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import { OWNER_RIGHTS_ROLES } from 'src/constants/common';
import { FileType } from './BusinessDetails.interface';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import ImageUpload from 'src/components/ImageUpload';
import Switch from 'src/components/Switch/Switch';

const BusinessInfo = ({ errors, cropData, setCropData }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const { watch, control, setValue, setError, clearErrors } = useFormContext();
    const slugValue = watch('slug');
    const role = useAppSelector(currentRole);
    const hasOwnerPermission = OWNER_RIGHTS_ROLES.includes(role?.name);
    const [image, setImage] = useState<any>('');
    const [upload, setUpload] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);
    const [fileType, setFileType] = useState<FileType>(null);

    const imageUpload = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fileType) {
            setValue(fileType, convertedFile.filename);
            setCropData((old: any) => ({ ...old, [fileType]: { ...old[fileType], file: convertedFile.convertedFile, name: convertedFile.filename } }));
        }
    };

    const handleCropData = (data: any) => {
        if (fileType) {
            setCropData((old: any) => ({ ...old, [fileType]: { ...old[fileType], data } }));
        }
    };

    const onChangeCrop = (type: FileType) => (e: any) => {
        e.preventDefault();
        if (!hasOwnerPermission || type === null) {
            return;
        }
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);

        if (!result) {
            setError(type, {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            setFileType(type);
            setUpload(false);

            clearErrors(type);
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        }
    };

    const handlepublished = () => {
        setPublishLoading(true);
        axiosPost(API.THEME.SLUG, { is_slug_active: !shop.is_slug_active }, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error: any) => toast.error(error?.message))
            .finally(() => setPublishLoading(false));
    };

    const onImageDownload = (Id: string) => () => {
        const svg = document.getElementById(Id);

        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                canvas.width = 500;
                canvas.height = 500;
                const img = new Image();
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    const pngFile = canvas.toDataURL('image/png');
                    const downloadLink = document.createElement('a');
                    downloadLink.download = `${Id}.png`;
                    downloadLink.href = pngFile;
                    downloadLink.click();
                };

                img.onerror = () => {
                    toast.error('Failed to load SVG data as an image.');
                };

                img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
            }
        }
    };

    return (
        <>
            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Brand Name')}</p>
                    <p className="subtitle-text">{t('Set the name that represents your brand on all channels.')}</p>
                </div>
                <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4">
                    <Controller
                        name="business_name"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-[512px]">
                                <InputWithLabel name="business_name" placeholder={t('Business name')} value={value} onChange={onChange} error={!!error} disabled={!hasOwnerPermission} />
                            </div>
                        )}
                    />
                </div>
            </div>

            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]  flex flex-col">
                    <p className="title-text">{t('Brand Logo')}</p>
                    <p className="subtitle-text">{t('Upload a logo to display on booking platforms, emails, and more.')}</p>
                </div>
                <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4 items-start">
                    <ImageUpload
                        isIcon
                        id="logo_image_name"
                        disabled={!hasOwnerPermission}
                        cropData={cropData.logo_image_name.data}
                        error={errors?.logo_image_name?.message}
                        onChangeCrop={onChangeCrop('logo_image_name')}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px] flex flex-col">
                    <p className="title-text">{t('Brand Icon')}</p>
                    <p className="subtitle-text">{t('Upload a icon to display on manager platforms, favicon, and more.')}</p>
                </div>
                <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4 items-start">
                    <div className="max-w-[620px] w-full">
                        <Controller
                            name="is_same_as_logo"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <div className="flex mb-3 items-center">
                                        <Switch onChange={onChange} isChecked={value} />
                                        <span className="ml-2 font-medium text-sm text-gray-700">Use my brand logo</span>
                                    </div>
                                    {!value && (
                                        <ImageUpload
                                            isIcon
                                            id="admin_logo_image_name"
                                            disabled={!hasOwnerPermission}
                                            cropData={cropData.admin_logo_image_name.data}
                                            error={errors?.admin_logo_image_name?.message}
                                            onChangeCrop={onChangeCrop('admin_logo_image_name')}
                                        />
                                    )}
                                </>
                            )}
                        />
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px] min-w-[310px]">
                    <p className="title-text">{t('Booking URL')}</p>
                    <p className="subtitle-text">{t('Define the link clients will use to book with your brand.')}</p>
                </div>
                <div className="flex flex-row w-full justify-between gap-4">
                    <div className="max-2xl:w-[400px] w-[512px]">
                        <Controller
                            name={`slug`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="data-wrap-block max-w-[512px] gap-4 flex xxl:max-w-[700px]">
                                    <div className="w-full max-w-[512px]">
                                        <InputWithLabel
                                            labelIcon={<Url />}
                                            inputControlClassName="pl-0"
                                            labelEndIcon={
                                                <div className="flex text-xs leading-[18px] font-semibold text-[#344054] flex-row gap-1 items-center">
                                                    {'.getflair.ca'}
                                                    <RightGreen />
                                                </div>
                                            }
                                            onChange={onChange}
                                            disabled={!hasOwnerPermission}
                                            value={value}
                                            name="slug"
                                            error={!!error}
                                        />
                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    {hasOwnerPermission && (
                        <CustomButton outlinePrimary isLoading={publishLoading} disabled={publishLoading} onClick={handlepublished} className="h-[36px]">
                            {shop.is_slug_active ? 'Unpublished' : 'Published'}
                        </CustomButton>
                    )}
                </div>
            </div>

            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className="w-[310px] min-w-[310px]">
                    <p className="title-text">{t('Booking QR Code')}</p>
                    <p className="subtitle-text">{t('Generate a QR code to easily share your booking link.')}</p>
                </div>
                <div className="flex items-center justify-between gap-5 w-full">
                    {slugValue && (
                        <QRCode className="min-w-[120px]  w-[120px] h-[120px] inline-block object-cover" value={`https://${slugValue}.${process.env.REACT_APP_DOMAIN}`} level="H" id="WebQRCode" />
                    )}
                    <CustomButton outlinePrimary onClick={onImageDownload('WebQRCode')} className="h-[36px]">
                        Download
                    </CustomButton>
                </div>
            </div>
            {upload && fileType && (
                <CropperModal
                    title={cropData[fileType].title}
                    description={cropData[fileType].description}
                    defaultCropType={cropData[fileType].default_crop_type}
                    types={cropData[fileType].cropTypes}
                    imageUrl={image}
                    imageUpload={imageUpload}
                    setCropData={handleCropData}
                    setUpload={setUpload}
                />
            )}
        </>
    );
};

export default BusinessInfo;
