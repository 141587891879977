export const FONT_FAMILY = [
    { value: 'helvetica', label: 'Helvetica' },
    { value: 'plus_jakarta_sans', label: 'Plus jakarta sans' },
    { value: 'roboto', label: 'Roboto' },
    { value: 'inter', label: 'Inter' },
    { value: 'montserrat', label: 'Montserrat' },
    { value: 'poppins', label: 'Poppins' },
    { value: 'mulish', label: 'Mulish' },
    // Add more options as needed
];

export const FONT_WEIGHT = [
    { value: '200', label: 'Thin' },
    { value: '300', label: 'Light' },
    { value: '400', label: 'Regular' },
    { value: '500', label: 'Medium' },
    { value: '600', label: 'Semi Bold' },
    { value: '700', label: 'Bold' },
    { value: '800', label: 'Extra Bold' },
    // Add more options as needed
];
export const LANGUAGES = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
    // Add more options as needed
];
export type LogoType = 'logo' | 'logo_text' | 'text';
export type FileType = 'logo_image_name' | 'favicon' | 'admin_logo_image_name' | null;

export interface IFile {
    logo_image_name: { convertedFile: string; filename: string };
    favicon: { convertedFile: string; filename: string };
}
export interface IFormData {
    logo_type: LogoType;
    name?: string;
    logo_font_family?: string;
    font_weight?: string;
    font_size?: string;
    logo_height?: string;
}
export interface ICropData {
    logo_image_name: string | null;
    favicon: string | null;
}
export interface LogoDisplaySettingsProps {
    setFormDetails: React.Dispatch<any>;
    displayType: 'logo' | 'logo_text' | 'text';
}
