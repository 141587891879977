import React from 'react';
import { IInputWithLabel } from './InputWithLabel.interface';
import Button from '../Button';

const InputWithLabel: React.FC<IInputWithLabel> = ({
    error,
    onChange,
    value,
    name,
    inputWrapClassName,
    fileName,
    placeholder,
    label,
    labelClassName,
    type,
    index,
    readOnly,
    textArea,
    disabled = false,
    textAearClassName,
    isFocus,
    rows,
    max,
    min,
    labelIcon,
    labelEndIcon,
    onBlur,
    onFocus,
    inputControlClassName = '',
    maxLenght,
    labelIconClassName,
    labelEndIconClassName,
    inputTextWrapper,
    className,
    toggle,
    required = false,
    id,
    onKeyPress,
}): JSX.Element => (
    <div className={` flex flex-col  w-full ${inputWrapClassName}`}>
        {type !== 'hidden' && label && (
            <div className={`flex justify-between mb-[6px] ${className}`}>
                <div className="flex items-center gap-[10px]">
                    <label htmlFor={name} className={`fl-field-title-label leading-[18px] mb-0 ${labelClassName}`}>
                        {label}
                        {required && <span className="asterisk">*</span>}
                    </label>
                    {toggle !== undefined && toggle}
                </div>

                {maxLenght && (
                    <span className={`text-xs  text-secondaryTxtColor text-${value?.toString().length <= maxLenght ? '' : 'dangerText'} self-end`}>
                        {value?.toString().length}/{maxLenght}
                    </span>
                )}
            </div>
        )}
        <div>
            {!textArea ? (
                type === 'file' ? (
                    <div className="relative flex">
                        <div className={`form_input_control rounded-none rounded-l-full flex items-center ' ${error && 'is-invalid'}`}>
                            {fileName ? <span className="text-mainTextColor">{fileName}</span> : <span className="">No file chosen</span>}
                        </div>
                        <Button className="fl-btn bg-mainTextColor font-bold text-white px-4 rounded-none rounded-r-full whitespace-nowrap cursor-pointer">Choose File</Button>
                        <input
                            className={`opacity-0 absolute left-0 top-0 bottom-0 w-full h-full `}
                            type="file"
                            name={name}
                            value={value}
                            disabled={disabled}
                            onChange={(e) => onChange(e, index)}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            id={id}
                            onKeyDown={onKeyPress}
                        />
                    </div>
                ) : (
                    <div className={`relative input-group  ${inputTextWrapper ?? ''}  ${error && 'is-invalid'}`}>
                        {labelIcon && (
                            <div className={`input-group-prepend ${labelIconClassName}`}>
                                <span className="text text-xs text-gray-500">{labelIcon}</span>
                            </div>
                        )}

                        <input
                            className={`form_input_control ${inputControlClassName}`}
                            type={type || 'text'}
                            name={name}
                            autoFocus={isFocus}
                            value={value}
                            max={max}
                            min={min}
                            disabled={disabled}
                            onChange={(e) => onChange(e, index)}
                            onFocus={(e) => onFocus && onFocus(e, index)}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            onBlur={(e) => onBlur && onBlur(e, index)}
                            autoComplete="off"
                            id={id}
                            step={type === 'number' ? 'any' : undefined}
                            onKeyDown={onKeyPress}
                            maxLength={maxLenght ?? 250}
                        />
                        {labelEndIcon && (
                            <div className={`input-group-append ${labelEndIconClassName} `}>
                                <span className="text">{labelEndIcon}</span>
                            </div>
                        )}
                    </div>
                )
            ) : (
                <>
                    <textarea
                        className={` w-full input-group ${inputControlClassName} ${error && 'is-invalid'} ${textAearClassName}`}
                        name={name}
                        autoFocus={isFocus}
                        value={value}
                        onChange={(e) => onChange(e, index)}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        rows={rows}
                        id={id}
                        maxLength={maxLenght ?? 2500}
                    />
                </>
            )}
        </div>
    </div>
);

export default InputWithLabel;
