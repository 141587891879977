import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from 'src/components/Switch/Switch';

const Appointment = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="flex flex-col flex-1">
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Client Cancellations')}</p>
                    <p className="subtitle-text">{t('Receive alerts for client appointment cancellations.')}</p>
                </div>
                <Controller
                    name="cancellations"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Late Cancellation Notification')}</p>
                    <p className="subtitle-text">{t('Be informed about late cancellations and fee options.')}</p>
                </div>
                <Controller
                    name="cancellation_past_limit"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Appointment Reschedules')}</p>
                    <p className="subtitle-text">{t('Stay updated on client rescheduling.')}</p>
                </div>
                <Controller
                    name="reschedule"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Client Appointment Modifications')}</p>
                    <p className="subtitle-text">{t('Alert for changes made by clients.')}</p>
                </div>
                <Controller
                    name="user_update_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Staff Appointment Modifications')}</p>
                    <p className="subtitle-text">{t('Be notified when staff modify appointments.')}</p>
                </div>
                <Controller
                    name="staff_update_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('New Client Appointment')}</p>
                    <p className="subtitle-text">{t('Receive notifications for new bookings.')}</p>
                </div>
                <Controller
                    name="new_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('New First-Time Client')}</p>
                    <p className="subtitle-text">{t('Be notified of first-time client bookings.')}</p>
                </div>
                <Controller
                    name="new_user_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('New Referral Client')}</p>
                    <p className="subtitle-text">{t('Alert for referred first-time clients.')}</p>
                </div>
                <Controller
                    name="new_user_booking_referral"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default Appointment;
