import React, { useState, useEffect, useMemo } from 'react';
import Account from './Account';
import AccountType from './AccountType';
import Brand from './Brand';
import { useAppSelector } from 'src/redux/hooks';
import { userMe } from 'src/redux/services/common/Common.slice';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from '../Layout/SideBarLayout/Sidebar';
import StepProgressBar from '../../components/StepProgressBar';
import Footer from '../Layout/Footer';
import Welcome from './Welcome/Welcome';
import { ROUTES } from 'src/constants/routes';
import { Edit, List, Profile, UserProfile } from 'src/theme/Images';

const SignupAdmin = () => {
    const user = useAppSelector(userMe);
    const { t } = useTranslation();
    const signupStep = user.shop_staff[0].account_step;
    const navigate = useNavigate();

    const [step, setStep] = useState<ICountStep>(0);

    const handleStep = (direction: 'next' | 'prev') => () => setStep((currentStep) => (direction === 'next' ? currentStep + 1 : currentStep - 1) as ICountStep);

    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: t('Complete your profile'),
                subTitle: t('Tell us a bit about yourself'),
                image: Profile,
                step: 1,
            },
            {
                name: t('Select account type'),
                subTitle: t('Tell us a bit about yourself'),
                image: List,
                step: 2,
            },
            {
                name: t('Configure your brand'),
                subTitle: t('Tell us a bit about your business'),
                image: Edit,
                step: 3,
            },
            {
                name: t('Welcome to', { shop_name: process.env.REACT_APP_FULL_NAME }),
                subTitle: t('Finish your account setup'),
                image: UserProfile,
                step: 4,
            },
        ],
        [],
    );

    useEffect(() => {
        if (!signupStep.complete_account) {
            setStep(1);
        } else if (!signupStep.account_type) {
            setStep(2);
        } else if (!signupStep.brand) {
            setStep(3);
        } else {
            navigate(ROUTES.DASHBOARD);
        }
    }, []);

    const renderStepComponent = () => {
        const stepToRender = STEPS[step - 1];
        switch (stepToRender.step) {
            case 1:
                return <Account setStep={setStep} stepLenght={STEPS.length} currentStep={step} />;
            case 2:
                return <AccountType setStep={setStep} stepLenght={STEPS.length} currentStep={step} />;
            case 3:
                return <Brand setStep={setStep} stepLenght={STEPS.length} currentStep={step} />;
            case 4:
                return <Welcome stepLenght={STEPS.length} currentStep={step} />;
            default:
                return null;
        }
    };

    return (
        <>
            {step !== 0 && (
                <>
                    <Sidebar activeStep={step} steps={STEPS} handleStep={handleStep} />
                    <div className="flex-grow min-h-screen">
                        {renderStepComponent()}
                        {/* <main className="px-6 h-[calc(100vh-84px)] overflow-y-scroll flex-1 flex">{renderStepComponent()}</main> */}
                        {/* <div className="bottom-0 right-0 start-[480px] fixed">
                            <div className="w-full bg-white">
                                <StepProgressBar steps={STEPS.length} currentStep={step} className="w-full mx-auto justify-center" />
                            </div>
                            <Footer />
                        </div> */}
                    </div>
                </>
            )}
        </>
    );
};

export default SignupAdmin;
