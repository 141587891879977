import React, { FC, useEffect, useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Tip from './Tip';
import { Monitor05 } from '@untitled-ui/icons-react/build/cjs';
import { selectCalendarData } from '../../Calendar.slice';
import { useAppSelector } from 'src/redux/hooks';
import InputWithLabel from 'src/components/InputWithLabel';
import { GoDotFill } from 'react-icons/go';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop } from 'src/redux/services/common/Common.slice';

interface PosPaymentProps {
    handlePaymentInput: (type: string, amount: number) => () => void;
    handleTipWithoutCalculation: (value: any, isFixed?: boolean) => () => void;
    handleBlurInput: (event: any) => void;
    errors: any;
    control: Control<FieldValues, any>;
}

const PosPayment: FC<PosPaymentProps> = ({ control, handlePaymentInput, handleTipWithoutCalculation, handleBlurInput, errors }) => {
    const { t } = useTranslation();
    const calendarData: any = useAppSelector(selectCalendarData);
    const [terminal, setTerminal] = useState<any>(null);
    const [isTerminalConnected, setIsTerminalConnected] = useState(false);
    const shop = useAppSelector(currentShop);
    useEffect(() => {
        if (window.StripeTerminal) {
            const terminalInstance = window.StripeTerminal.create({
                onFetchConnectionToken: () => axiosPost(API.STRIPE.TERMINAL_TOKEN, {}, { shop_id: shop.id }).then((response) => response.data.data.secret),
                onUnexpectedReaderDisconnect: () => setIsTerminalConnected(false),
                onConnectionStatusChange: ({ status }: any) => {
                    switch (status) {
                        case 'connected':
                            setIsTerminalConnected(true);
                            break;
                        case 'not_connected':
                            setIsTerminalConnected(false);
                            break;
                    }
                },
            });

            setTerminal(terminalInstance);
            handleTerminal(terminalInstance)();
        }
    }, []);

    const handleTerminal =
        (terminals: any, isConnect = false) =>
        async () => {
            if (isConnect) {
                terminal.disconnectReader();
            } else {
                var config = { simulated: true };
                const discoverResult = await terminals.discoverReaders(config);
                if (!discoverResult.error && discoverResult.discoveredReaders.length) {
                    const reader = discoverResult.discoveredReaders[0];
                    await terminals.connectReader(reader);
                }
            }
        };

    return (
        <div className="flex flex-col border border-gray-200  w-full rounded-xl mt-3">
            <div className={`text-center flex items-center gap-3 px-2.5 py-2 w-full`}>
                <div className="w-full flex gap-2 items-center">
                    <div className="border border-[#7191EF] bg-[#EDF1FD] rounded-lg h-9 w-9 flex items-center justify-center">
                        <Monitor05 className="text-[#143CB3] w-5" />
                    </div>
                    <p className="text-[#143CB3] font-semibold text-xs leading-[18px]">Terminal</p>
                </div>
                <p className={`flex flex-row gap-1`}>
                    <GoDotFill size={12} className={`h-4 w-4 rounded-full flex mr-[2px] ${isTerminalConnected ? 'text-[#17B26A]' : 'text-[#D92D20]'}`} />
                    {/* resd mate text-[#D92D20] */}
                    <div className="text-mainTextColor font-medium text-xs leading-[18px]">{isTerminalConnected ? 'Connected' : 'Disconnected'}</div>
                </p>
            </div>
            <hr />
            <div className={` flex items-center gap-3 px-2.5 py-2 flex-row justify-between  w-full`}>
                <div className="flex flex-col gap-1.5">
                    <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Client Terminal Total </p>
                </div>

                <Controller
                    name="pos"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div>
                            <InputWithLabel
                                type="number"
                                name="pos"
                                placeholder={t('$00.00')}
                                onChange={(e: any) => {
                                    let amount = e.target.value;
                                    if (amount.length > 1) {
                                        amount = parseFloat(amount).toString();
                                    }
                                    onChange(amount);
                                    handlePaymentInput('pos', amount)();
                                }}
                                onBlur={handleBlurInput}
                                value={value}
                                inputControlClassName="!h-[28px] !px-0 !text-center !bg-transparent !border-0 "
                                inputTextWrapper="!h-[30px] !w-[70px] !p-0 !bg-transparent !text-center"
                            />
                        </div>
                    )}
                />
            </div>
            {calendarData.payments.payments.length === 1 && (
                <>
                    <hr />
                    <Tip control={control} handleTipWithoutCalculation={handleTipWithoutCalculation} errors={errors.tip} />
                </>
            )}
        </div>
    );
};

export default PosPayment;
