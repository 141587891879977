export const APP_ROOT = '/';

export const ROUTES = {
    HOME: `${APP_ROOT}`,
    OTP: `${APP_ROOT}otp`,
    FORM: `${APP_ROOT}form`,
    TEST: `${APP_ROOT}test`,
    REGISTER: `${APP_ROOT}register`,
    FORGOT_PASSWORD: `${APP_ROOT}forgot-password`,
    DASHBOARD: `${APP_ROOT}dashboard`,
    ANALYTICS: `${APP_ROOT}analytics`,
    CALENDAR: `${APP_ROOT}calendar`,
    SIGNUP: `${APP_ROOT}signup`,
    MULTI_BRAND: `${APP_ROOT}multi-brand`,
    INVITE: `${APP_ROOT}invite`,
    FLAIR_PAYMENT: `${APP_ROOT}flair-payment`,
    PAYMENT_METHOD: `${APP_ROOT}payment-method`,
    STRIPE_CONNECT_RETURN: `${APP_ROOT}stripe-connect-return`,
    SETTINGS: `${APP_ROOT}settings/:tab?`,
    ADMIN: `${APP_ROOT}admin`,
    // CLIENT: `${APP_ROOT}client`,
    OldCLIENT: `${APP_ROOT}oldclient`,
    PAYMENT: `${APP_ROOT}payment`,
    NOTIFICATIONS: `${APP_ROOT}notifications`,
    SALES: `${APP_ROOT}sales`,

    GENERAL_SETTING: `${APP_ROOT}setting`,
    SUPPORT: `${APP_ROOT}support`,
    APPEARANCE: `${APP_ROOT}appearance`,
    STAFF: {
        LIST: `${APP_ROOT}staff`,
        CREATE: `${APP_ROOT}staff/form`,
        UPDATE: `${APP_ROOT}staff/form/:id`,
        VIEW: `${APP_ROOT}staff/:id`,
    },
    LOCATION: {
        LIST: `${APP_ROOT}locations`,
        CREATE: `${APP_ROOT}locations/form`,
        UPDATE: `${APP_ROOT}locations/form/:id`,
        VIEW: `${APP_ROOT}locations/:id`,
        WORKING: `${APP_ROOT}locations/working/:id`,
    },
    SERVICES: {
        LIST: `${APP_ROOT}services/:tab?`,
        CREATE: `${APP_ROOT}services/form`,
        UPDATE: `${APP_ROOT}services/form/:id`,
        CATEGORY: {
            CREATE: `${APP_ROOT}services/category/form`,
            UPDATE: `${APP_ROOT}services/category/form/:id`,
        },
    },
    CATEGORY: {
        CREATE: `${APP_ROOT}category/form`,
        UPDATE: `${APP_ROOT}category/form/:id`,
    },
    PRODUCT: {
        LIST: `${APP_ROOT}products/:tab?`,
        CREATE: `${APP_ROOT}products/form`,
        UPDATE: `${APP_ROOT}products/form/:id`,
        VIEW: `${APP_ROOT}products/view/:id`,
        CATEGORY: {
            CREATE: `${APP_ROOT}products/category/form`,
            UPDATE: `${APP_ROOT}products/category/form/:id`,
        },
    },
    CLIENT: {
        LIST: `${APP_ROOT}client`,
        CREATE: `${APP_ROOT}client/form`,
        UPDATE: `${APP_ROOT}client/form/:id`,
        VIEW: `${APP_ROOT}client/:id`,
    },
};
