import React, { FC } from 'react';
import { image1Location } from 'src/theme/Images';

const SLocation: FC<{ field?: string; isShow?: boolean }> = ({ field, isShow = false }) => (
    <>
        {[...Array(10)].map((_$, index) => (
            <div key={index} className="w-full max-w-[393px]">
                <div className="location-card p-4 ">
                    <div className="max-h-[200px] w-full h-full grow-0 shrink-0  rounded-xl overflow-hidden cursor-pointer custom-loading">
                        <img src={image1Location} alt={'loading'} title={'location.name'} className="h-full w-full object-cover " />
                    </div>
                    <div className="flex-auto overflow-hidden ">
                        <div className="w-full flex flex-col gap-[6px]">
                            <div>
                                <span className="text-base font-semibold leading-[140%] text-gray-700 custom-loading">makkam Street</span>
                                <p className="text-sm font-normal leading-[18px]  text-gray-500 truncate custom-loading">10820 Rue Lajeunesse Ahuntsic-Cartierville, Montréal, Canada</p>
                            </div>
                        </div>
                        {!isShow && (
                            <div className="flex mt-auto pt-4 justify-between ">
                                <div>
                                    <h3 className="location-card-heading mb-1">{field === 'product' ? 'Total variants' : ' Total clients'}</h3>
                                    <div className=" text-gray-900 text-xs font-medium custom-loading">01253</div>
                                </div>
                                <div className="text-right">
                                    <h3 className="location-card-heading mb-1">Tax District</h3>
                                    <div className="text-gray-900 text-xs font-medium custom-loading">Manitoba</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ))}
    </>
);

export default SLocation;
