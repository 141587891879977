import React, { FC, memo, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { usePhone } from 'src/hooks/usePhone';
import { getShortName } from 'src/utils/global-functions';
import { AlarmClockCheck, AlarmClockOff, Check, Clock, EyeOff, Plus, XClose } from '@untitled-ui/icons-react/build/cjs';
import { Status, StatusLabelProps } from './Calendar.Interface';

const StatusLabel: FC<StatusLabelProps> = ({ status }) => {
    const statusLabels = {
        confirmed: {
            border: 'border-blueLight-200',
            bg: 'bg-blueLight-50',
            text: 'text-blueLight-700',
            icon: <AlarmClockCheck className="w-[14px] h-[14px] text-blueLight-700 mt-[1px]" />,
            label: 'Confirmed',
        },
        pending: { border: 'border-yellow-200', bg: 'bg-yellow-50', text: 'text-yellow-700', icon: <Clock className="w-[14px] text-yellow-600 mt-[2px]" />, label: 'Pending' },
        canceled: { border: 'border-[#FECDCA]', bg: 'bg-[#FEF3F2]', text: 'text-[#D92D20]', icon: <XClose className="w-[14px] text-[#D92D20] mt-[1px]" />, label: 'Canceled' },
        completed: { border: 'border-success-100', bg: 'bg-success-50', text: 'text-success-600', icon: <Check className="w-[14px] text-success-600" />, label: 'Completed' },
        no_show: { border: 'border-purple-200', bg: 'bg-purple-50', text: 'text-purple-700', icon: <EyeOff className="w-[14px] text-purple-500" />, label: 'No Show' },
        declined: { border: 'border-gray-200', bg: 'bg-gray-50', text: 'text-gray-700', icon: <AlarmClockOff className="w-[14px] text-gray-600 mt-[1px]" />, label: 'Declined' },
        request_canceled: { border: 'border-gray-200', bg: 'bg-gray-50', text: 'text-gray-700', icon: <AlarmClockOff className="w-[14px] text-gray-600 mt-[1px]" />, label: 'Request Canceled' },
    };

    const statusData = statusLabels[status];

    if (!statusData) return null;

    const { border, bg, text, icon, label } = statusData;
    return (
        <span className={`border ${border} flex flex-row h-[21px] items-center gap-[4px] ${text} leading-[18px] text-xs font-medium w-max py-0.5 px-1.5 pl-1 rounded-md ${bg} capitalize`}>
            {icon} <span className={`truncate `}>{label}</span>
        </span>
    );
};

const StatusIcon: FC<StatusLabelProps> = ({ status }) => {
    const statusIcons = {
        confirmed: <AlarmClockCheck className="w-[14px] h-4 text-blueLight-500" />,
        pending: <Clock className="w-[14px] h-4 text-yellow-600" />,
        canceled: <XClose className="w-[14px] h-4 text-[#D92D20]" />,
        completed: <Check className="w-[14px] h-4 text-success-600" />,
        no_show: <EyeOff className="w-[14px] h-4 text-purple-500" />,
        declined: <AlarmClockOff className="w-[14px] h-4 text-gray-600" />,
        request_canceled: <AlarmClockOff className="w-[14px] h-4 text-gray-600" />,
    };

    return statusIcons[status] || null;
};

const EventCard = ({ eventInfo }: any) => {
    const { event: eventDetails } = eventInfo;
    const currentDateTime = moment();
    const bookingStartDateTime = moment(`${eventDetails.booking_date} ${eventDetails.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss');
    const bookingEndDateTime = moment(`${eventDetails.booking_date} ${eventDetails.booking_end_time}`, 'YYYY-MM-DD HH:mm:ss');
    const diff = bookingEndDateTime.diff(bookingStartDateTime, 'minutes');
    const bookingTime = bookingStartDateTime.format('hh:mm A');
    const { getCustomFormatPhone } = usePhone();
    const componentRef = useRef<HTMLDivElement>(null);
    const [componentWidth, setComponentWidth] = useState<number>(0);

    useEffect(() => {
        if (componentRef.current) {
            const width = componentRef.current.offsetWidth;
            setComponentWidth(width);
            // You can also set this width to state if you need to use it in your component
        }
    }, []);

    const calculateTotalDuration = () => {
        let totalDuration = moment.duration();

        if (eventDetails.booking_services) {
            eventDetails.booking_services.forEach((item: any) => {
                const duration = moment.duration(item.duration).asMilliseconds() * item.quantity;
                totalDuration.add(moment.duration(duration));
            });
        }

        return moment.utc(totalDuration.asMilliseconds()).format(totalDuration.hours() > 0 ? (totalDuration.minutes() > 0 ? 'H [Hours] m [Minutes]' : 'H [Hours]') : 'm [Minutes]');
    };
    const StatusTextColor = (status: Status) => {
        const statusIcons = {
            confirmed: 'text-brand-600',
            pending: 'text-yellow-600',
            canceled: 'text-gray-600',
            completed: 'text-success-600',
            no_show: 'text-purple-500',
            declined: 'text-gray-600',
            request_canceled: 'text-gray-600',
        };

        return statusIcons[status] || null;
    };
    const totalDuration = calculateTotalDuration();
    const isEventCurrent = currentDateTime.isBetween(bookingStartDateTime, bookingEndDateTime);
    const eventStatusClass = eventDetails.status === 'confirmed' && isEventCurrent ? 'current' : eventDetails.status;
    const eventCurrentDateTime = currentDateTime.isBetween(bookingStartDateTime, bookingEndDateTime);
    if (eventDetails.status === 'new') {
        return (
            <div className="event-container-wrap flex items-center h-full !outline-none ">
                <div className="rounded-md text-xs relative flex flex-col h-full px-2 justify-center">
                    <span className="border border-success-100 text-success-600 items-center gap-[3px] h-[20px] rounded-md pr-1.5 pl-1 py-0.5 flex justify-center font-medium text-xs leading-[18px] bg-success-50">
                        <Plus className="w-4 h-4 text-success-600" /> New
                    </span>
                </div>
            </div>
        );
    }

    if (eventDetails.status === 'block_time') {
        return (
            <div className="event-container-wrap h-full">
                <div className="rounded-md text-xs relative flex flex-col h-full px-2  justify-center">
                    <p className="text-gray-900 font-medium text-xs leading-[18px]">Block Time</p>
                    <p className="font-bold leading-[140%] -tracking-[0.1px] mb-[6px]">{eventDetails.name}</p>
                </div>
            </div>
        );
    }

    return (
        <div className={`event-container-wrap h-full event-${eventStatusClass}`} ref={componentRef}>
            <div className="relative flex flex-col h-full ">
                <div className="flex justify-between flex-1 flex-col">
                    <div className={`flex flex-col flex-1 ${diff >= 30 ? '' : 'justify-center'}`}>
                        {diff >= 45 && (
                            <div className="flex mt-2 ml-2">
                                <StatusLabel status={eventDetails.status} />
                            </div>
                        )}
                        <div className={`flex  ${diff > 30 ? 'flex-col' : ' flex-row justify-between items-center '}`}>
                            <div className="flex flex-col">
                                <p
                                    className={`title-color mr-1  ml-2 font-medium truncate ${
                                        diff >= 30 ? 'mt-1 text-sm leading-5 mr-1 truncate w-auto' : 'mt-0 text-xs leading-[18px] truncate mr-1'
                                    }`}
                                >
                                    {eventDetails.booking_services[0].service.name}
                                </p>
                                {diff >= 30 && <p className="text-gray-500  text-[11px] leading-[18px] font-normal ml-2  truncate">{`${bookingTime} (${totalDuration})`}</p>}
                            </div>
                            <div className={`${diff > 30 ? 'hidden' : 'flex mr-2'}`}>
                                <StatusIcon status={eventDetails.status} />
                            </div>
                        </div>
                    </div>
                    <div>
                        {diff >= 60 && (
                            <div className="flex mt-2.5 justify-between ml-2 mb-2">
                                <div className="flex-1 flex flex-row">
                                    <figure className="NoImgName w-[18px] h-[18px] text-[8px] mr-1">
                                        {eventDetails.user.profile_image_url ? (
                                            <img src={eventDetails.user.profile_image_url} alt="staff" className="w-full h-full" />
                                        ) : (
                                            getShortName(eventDetails.user.full_name)
                                        )}
                                    </figure>
                                    <p className="title-color font-medium text-xs leading-[18px] truncate capitalize-first mr-1 max-w-[56px] xxl:max-w-[85px]">
                                        {eventDetails.user.full_name ? eventDetails.user.full_name : getCustomFormatPhone(eventDetails.user?.phone, eventDetails.user?.phone_country_code)}
                                    </p>
                                </div>
                                <p className={` font-medium text-xs leading-[18px] mr-2 ${StatusTextColor(eventDetails.status)}`}>{`$${eventDetails.total}`}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(EventCard);
