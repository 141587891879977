import React, { FC, useEffect, useMemo, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentShop } from 'src/redux/services/common/Common.slice';
import { capitalizeFirstLetterDash, formatName, getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'src/components/NoDataMessage';
import { ArrowUp, ArrowDown, SearchLg, Users01 } from '@untitled-ui/icons-react/build/cjs';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { AllLocationsOptions, AllTeamMembersOptions, perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import { GoDotFill } from 'react-icons/go';

const INIT_STATE = {
    FILTER: {
        shop_location_id: null,
        shop_admin_id: null,
    },
    LAZY_STATE: {
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_location_id: { value: null },
            shop_admin_id: { value: null },
            start_date: { value: null },
            end_date: { value: null },
        },
    },
};

interface IStaffTable {
    dataType: string;
}
const StaffPayoutTable: FC<IStaffTable> = ({ dataType }) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const allShopStaffList = useAppSelector(allShopStaff);
    const shopLocationList: any = useAppSelector(allShopLocations);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [lazyState, setLazyState] = useState<any>(INIT_STATE.LAZY_STATE);
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        shop_location_id: { value: null },
        shop_admin_id: { value: null },
        start_date: { value: null },
        end_date: { value: null },
    });
    const [isPaidLoading, setIsPaidLoading] = useState<any>({ paid: { loading: false, id: null } });

    const [payoutList, setPayoutList] = useState<any>([]);
    const [totalListRecords, settotalListRecords] = useState(0);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedFilter, setSelectedFilter] = useState(INIT_STATE.FILTER);
    const [currentTab, setCurrentTab] = useState('daily');
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        setStaffOptions([AllTeamMembersOptions, ...staff]);

        setSelectedFilter(INIT_STATE.FILTER);
        setLazyState(INIT_STATE.LAZY_STATE);
    }, [shop]);

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };
    const getPayoutStaffList = async () => {
        setIsLoading(true);

        const payload = {
            ...lazyState,
            type: dataType,
        };

        await axiosGet(API.PAYMENT.LIST, { shop_id: shop.id }, payload)
            .then((response) => {
                setPayoutList(response.data.data.data);
                settotalListRecords(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const handlePaid = (id: any) => async () => {
        setIsPaidLoading((prev: any) => ({
            ...prev,
            paid: { loading: true, id: id },
        }));
        await axiosPatch(API.PAYMENT.RETRY, {}, { shop_id: shop.id, id })
            .then(async () => {
                getPayoutStaffList();
            })
            .finally(() =>
                setIsPaidLoading((prev: any) => ({
                    ...prev,
                    paid: { loading: false, id: null },
                })),
            );
    };

    useEffect(() => {
        getPayoutStaffList();
    }, [lazyState]);

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const GetFullName = (row: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);
        return (
            <div className="flex items-center">
                <figure className="NoImgName">
                    {row.staff.profile_image_url && !isImageError ? (
                        <img src={row.staff.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : (
                        getShortName(`${row.staff.full_name}`)
                    )}
                </figure>
                <div className="text-mainTextColor">
                    <p className="text-xs font-medium mb-[2px] leading-[140%] -tracking-[0.14px] w-[100px] max-w-[100px] xlm:max-w-[110px] xlm:w-[110px] 2xlm:max-w-[130px] 2xlm:w-[130px] xxl:max-w-[140px] xxl:w-[140px] truncate">
                        {row.staff.full_name ? formatName(row.staff.full_name) : ''}
                    </p>
                    <p className="text-xs font-normal leading-[150%] -tracking-[0.12px] text-secondaryTxtColor w-[100px] max-w-[100px] xlm:max-w-[110px] xlm:w-[110px] 2xlm:max-w-[130px] 2xlm:w-[130px] xxl:max-w-[140px] xxl:w-[140px] truncate">
                        {row.staff.staff_role.name ? `${row.staff.staff_role.name}` : ''}
                    </p>
                </div>
            </div>
        );
    };

    const GetCommission = (row: any) => `${dataType === 'rent' ? `$${row.rent}` : `${row.commission}%`}`;
    const GetTotal = (row: any) => `$${row.amount}`;
    const GetTaxes = (row: any) => `$${row.taxes}`;
    const GetTips = (row: any) => `$${row.tips}`;
    const GetBarber = (row: any) => `$${row.due_to_staff}`;
    const GetShop = (row: any) => `$${row.due_to_shop}`;
    const GetStatus = (row: any) => (
        <>
            {row.is_paid ? (
                <span className="text-xs font-medium leading-[140%] -tracking-[0.12px] text-[#067647] border border-[#ABEFC6] bg-[#ECFDF3] rounded-full px-2 py-[2px]">Paid</span>
            ) : (
                <CustomButton
                    secondary
                    onClick={handlePaid(row.id)}
                    isLoading={isPaidLoading.paid.loading && row.id === isPaidLoading.paid.id}
                    disabled={isPaidLoading.paid.loading && row.id === isPaidLoading.paid.id}
                >
                    {t(' Mark as paid')}
                </CustomButton>
            )}
        </>
    );
    const GetStartDate = (row: any) => moment(row.start_date).format('DD/MM/YYYY');
    const GetEndDate = (row: any) => moment(row.end_date).format('DD/MM/YYYY');

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                start_date: 'loading',
                end_date: 'loading',
                amount: 'loading',
                taxes: 'loading',
                tips: 'loading',
                due_to_staff: 'loading',
                due_to_shop: 'loading',
                commission: 'loading',
                is_paid: 'loading',
                first_name: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const handleDatePickerChange = (date: any, timeRange?: string) => {
        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setCurrentTab(timeRange);
        } else {
            setCurrentTab('');
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
        setLazyState({
            ...lazyState,
            page: 1,
            first: 0,
            filters: {
                ...lazyState.filters,
                start_date: {
                    value: moment(date[0]).format('YYYY-MM-DD'),
                },
                end_date: {
                    value: moment(date[1]).format('YYYY-MM-DD'),
                },
            },
        });
    };

    return (
        <div
            className={` w-full border rounded-xl mt-4 flex-1 flex-col mb-5 flex shadow  staff_service_table datatable-custom-service ${
                payoutList.length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className="w-full justify-between items-center">
                <div className={` flex items-center flex-col w-full border-b border-gray-200`}>
                    <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                        <div className="flex flex-col  flex-1">
                            <div className="flex justify-start items-center gap-1.5">
                                <h2 className="table-title">{dataType === 'rent' ? t('Rent Collection') : t('Commission Payouts')}</h2>
                                <p className={`pl-[4px] pr-[6px] py-[2px] text text-xs text-gray-700 font-medium  border inline-flex shadow items-center justify-start rounded-md border-gray-3001`}>
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {capitalizeFirstLetterDash(dataType === 'rent' ? shop.shop_payment.rent_frequency : shop.shop_payment.commission_frequency)}
                                </p>
                            </div>
                            <p className="table-subtitle">
                                {dataType === 'rent'
                                    ? t('Track and manage rent collection history for your rent-based team members.')
                                    : t('Track and manage commission payout history for your commission-based team members.')}
                            </p>
                        </div>
                        <div className="flex relative">
                            {/* <CustomButton primary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                                    {t('Add team member')}
                                </CustomButton> */}
                        </div>
                    </div>
                    <div className={`flex items-start px-5 py-3 justify-between w-full `}>
                        <div className="max-2xl:flex max-2xl:flex-col gap-1.5">
                            <div className="table-searchInput">
                                <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                            </div>
                            {/* <div className="max-2xl:w-[224px] w-[200px] 2xl:hidden">
                                <SelectBox
                                    options={locationOptions}
                                    placeholder={t('All locations')}
                                    noOptionsMessage="No Locations Found"
                                    value={locationOptions.find((loc) => loc.value === selectedFilter.shop_location_id)}
                                    onChangeFunc={handleFilter('shop_location_id')}
                                    classComp="outline-select-box"
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </div> */}
                        </div>
                        <div className="flex gap-3">
                            <div className="max-2xl:w-[180px] w-[200px] max-2xl:hidden">
                                <SelectBox
                                    options={locationOptions}
                                    placeholder={t('All locations')}
                                    noOptionsMessage="No Locations Found"
                                    value={locationOptions.find((loc) => loc.value === selectedFilter.shop_location_id)}
                                    onChangeFunc={handleFilter('shop_location_id')}
                                    classComp="outline-select-box"
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="max-2xl:w-[18   0px] w-[200px]">
                                <SelectBox
                                    options={staffOptions}
                                    value={staffOptions.find((staff) => staff.value === selectedFilter.shop_admin_id)}
                                    noOptionsMessage="No Team Found"
                                    placeholder={t('All Team members')}
                                    onChangeFunc={handleFilter('shop_admin_id')}
                                    classComp="outline-select-box"
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="w-[70px] xl:w-[70px] page-dropdown">
                                <SelectBox
                                    name="page"
                                    options={perPageOptions}
                                    isClearable={false}
                                    isSearchable={false}
                                    onChangeFunc={onPageHandle}
                                    value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                    classComp="outline-select-box"
                                />
                            </div>
                            <div className="location-dropdown">
                                <div className="w-full flex justify-center">
                                    <DateRangePicker
                                        isDisplayAsBox={false}
                                        handleDatePickerChange={handleDatePickerChange}
                                        selectedTimeRange={selectedTimeRange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        allTimeStartDate={shop.created_at}
                                        showYearDropdown={false}
                                        isToday={currentTab === 'daily'}
                                        isShowDropDownIcon={false}
                                        containerClassName="right-0"
                                        parentClassName="sales-datepicker w-[280px] min-w-[280px] xlm:w-[310px] xlm:min-w-[310px] xxl:w-[340px] xxl:min-w-[340px]"
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        activeFilter={currentTab}
                                        setActiveFilter={setCurrentTab}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DataTable
                paginatorTemplate={customPaginatorTemplate}
                paginatorClassName="table-pagination"
                value={isLoading ? skeletons : payoutList}
                lazy
                paginator={!isLoading && totalListRecords > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                totalRecords={totalListRecords}
                onPage={onPage}
                onSort={onSort}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                sortField={lazyState.sortField}
                dataKey="id"
                className="rounded-b-md overflow-hidden w-full overflow-x-auto"
                // selectionMode="single"
                emptyMessage={
                    <NoDataMessage
                        title={`${
                            filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.start_date.value || filters.end_date.value
                                ? t('No commission payouts found.', { dataType: dataType })
                                : t('No commission payouts recorded.', { dataType: dataType })
                        }`}
                        description={`${
                            filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.start_date.value || filters.end_date.value
                                ? t('No statements found matching your search criteria. Try adjusting your filters or record new transactions.')
                                : t('Add a team member with a commission-based payment structure to start tracking commission payouts.', { dataType: dataType })
                        }`}
                        iconComponent={
                            filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.start_date.value || filters.end_date.value ? (
                                <SearchLg className="text-gray-700" />
                            ) : (
                                <Users01 className="text-gray-700" />
                            )
                        }
                    />
                }
            >
                <Column
                    field="shop_admins.first_name"
                    header={renderHeader(t('Name'), 'shop_admins.first_name')}
                    style={{ width: '170px', minWidth: '170px', maxWidth: '170px' }}
                    body={isLoading ? <Skeleton /> : GetFullName}
                    className="font-medium text-mainTextColor text-xs"
                    sortable
                ></Column>
                <Column
                    field="is_paid"
                    header={renderHeader(t('Payment Status'), 'is_paid')}
                    body={isLoading ? <Skeleton /> : GetStatus}
                    style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                    className="font-medium text-secondaryTxtColor truncate text-xs"
                    sortable
                ></Column>
                <Column
                    field="start_date"
                    header={renderHeader(t('Period Start Date'), 'start_date')}
                    style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                    body={isLoading ? <Skeleton /> : GetStartDate}
                    className="font-medium text-secondaryTxtColor truncate  text-xs"
                    sortable
                ></Column>
                <Column
                    field="end_date"
                    header={renderHeader(t('Period End Date'), 'end_date')}
                    style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                    body={isLoading ? <Skeleton /> : GetEndDate}
                    className="font-medium text-secondaryTxtColor truncate text-xs"
                    sortable
                ></Column>
                <Column
                    field={dataType ? 'rent' : 'commission'}
                    header={renderHeader(dataType ? t('Rent') : t('Commission'), dataType ? 'rent' : 'commission')}
                    style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                    className="font-medium text-secondaryTxtColor truncate text-xs"
                    body={isLoading ? <Skeleton /> : GetCommission}
                    sortable
                ></Column>
                <Column
                    field="amount"
                    header={renderHeader(t('Total'), 'amount')}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    className="font-medium text-secondaryTxtColor truncate text-xs"
                    body={isLoading ? <Skeleton /> : GetTotal}
                    sortable
                ></Column>
                <Column
                    field="taxes"
                    header={renderHeader(t('Taxes'), 'taxes')}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    className="font-medium text-secondaryTxtColor truncate text-xs"
                    body={isLoading ? <Skeleton /> : GetTaxes}
                    sortable
                ></Column>
                <Column
                    field="tips"
                    header={renderHeader(t('Tips'), 'tips')}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    className="font-medium text-secondaryTxtColor truncate text-xs"
                    body={isLoading ? <Skeleton /> : GetTips}
                    sortable
                ></Column>
                <Column
                    field="due_to_staff"
                    header={renderHeader(t('Due to barber'), 'due_to_staff')}
                    style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                    body={isLoading ? <Skeleton /> : GetBarber}
                    className="font-medium text-secondaryTxtColor truncate text-xs"
                    sortable
                ></Column>
                <Column
                    field="due_to_shop"
                    header={renderHeader(t('Due to shop'), 'due_to_shop')}
                    style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                    body={isLoading ? <Skeleton /> : GetShop}
                    className="font-medium text-secondaryTxtColor truncate text-xs"
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default StaffPayoutTable;
