import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PopupModal from 'src/components/PopupModal';
import { IAccountChangePassword } from './ChangePassword.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { API } from 'src/constants/api';
import * as Yup from 'yup';
import { axiosPost } from 'src/utils/requestClient';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
import { errorCode } from 'src/constants/errorCode';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';

const AccountChangePassword = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        old_password: Yup.string().required(t('This field is required')),
        password: Yup.string().required(t('This field is required')),
        password_confirmation: Yup.string()
            .required(t('This field is required'))
            .oneOf([Yup.ref('password')], t('Passwords do not match')),
    }).required();

    const { handleSubmit, control, setError } = useForm<IAccountChangePassword>({
        resolver: yupResolver(schema),
        defaultValues: {
            old_password: '',
            password: '',
            password_confirmation: '',
        },
    });

    const handleSave = (data: IAccountChangePassword) => {
        setIsLoading(true);
        axiosPost(API.USER.CHANGE_PASSWORD, data)
            .then((response) => {
                handleClose();
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IAccountChangePassword, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const [validations, setValidations] = useState({
        minLength: false,
        hasDigit: false,
        hasNonDigit: false,
    });
    const validationsPassed = validations.minLength && validations.hasDigit && validations.hasNonDigit;
    const handleValidation = (value: string) => {
        setValidations({
            minLength: value.length >= 8,
            hasDigit: /\d/.test(value),
            hasNonDigit: /\D/.test(value),
        });
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <PackagePlus className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('Change password')}</p>
                <span className="text-xs font-normal text-gray-500">{t('Update the password linked to your account.')}</span>
            </div>
        </div>
    );

    return (
        <PopupModal
            dismissible
            title={<Title />}
            onClose={handleClose}
            size="w-[480px]"
            secondaryButton={t('Cancel')}
            primaryButton={t('Continue')}
            acceptAction={handleSubmit(handleSave)}
            declineAction={handleClose}
            isLoading={isLoading}
            isDisabled={!validationsPassed}
        >
            <form onSubmit={handleSubmit(handleSave)}>
                <div className="flex flex-wrap">
                    <Controller
                        name="old_password"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full">
                                <InputPasswordWithLabel
                                    name="old_password"
                                    required
                                    label={t('Current password')}
                                    placeholder={t('Current password')}
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mt-4">
                                <InputPasswordWithLabel
                                    name="password"
                                    label={t('New password')}
                                    placeholder={t('New password')}
                                    value={value}
                                    required
                                    onChange={(e: { target: { value: string } }) => {
                                        onChange(e);
                                        handleValidation(e.target.value);
                                    }}
                                    error={!!error}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        name="password_confirmation"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mt-5">
                                <InputPasswordWithLabel
                                    name="password_confirmation"
                                    label={t('Confirm password')}
                                    placeholder={t('Confirm password')}
                                    value={value}
                                    required
                                    inputClass={`${error ? 'is-invalid' : ''}`}
                                    onChange={onChange}
                                    error={!!error}
                                />
                            </div>
                        )}
                    />
                </div>
            </form>
        </PopupModal>
    );
};

export default AccountChangePassword;
