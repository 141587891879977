import React from 'react';

import { IInputTextProps } from './InputText.interface';
import { XClose } from '@untitled-ui/icons-react/build/cjs';

const InputText = ({
    inputName,
    placeholder,
    errorText,
    asTextarea,
    className,
    regularExp,
    label,
    tabIndex,
    maxLength,
    icon,
    iconLeft,
    min,
    max,
    disabled,
    inputType,
    minLength,
    onChangeFunc,
    onBlur,
    onFocus,
    required,
    requiredAfter,
    readOnly,
    rows,
    value,
    labelClassName,
    parentClassName,
    handleKeyDown,
    isClearable,
    isError,
    isLoading,
    testId,
    ...props
}: IInputTextProps) => {
    const regExpArray: any = {
        number: /(^[0-9]+$|^$)/,
        letters: /^[A-za-z]+$/,
        alphanumeric: /^[ A-Za-z0-9_@.]*$/,
        regularExp: regularExp,
    };

    const typeArr = ['text', 'email', 'password', 'file', 'textarea'];
    const HandleChange = (e: any) => {
        const testValueType: any = regularExp === '' ? inputType : 'regularExp';
        const valueTypeMatch = testValueType !== '' && !typeArr.includes(testValueType) ? regExpArray[testValueType].test(e.target.value) : true;
        if (e.target.value === '' || valueTypeMatch) {
            onChangeFunc(e);
        }
    };

    const clearInput = () => {
        onChangeFunc({ target: { name: inputName, value: '' } });
    };

    return (
        <>
            <div className={`${errorText ? 'relative mb-2' : 'relative w-full'} ${parentClassName}`}>
                <div className="w-full">
                    {label && (
                        <label className={`form_label ${labelClassName}`}>
                            {label}
                            {required && <span className="text-red-600 leading-4">*</span>}
                        </label>
                    )}

                    {requiredAfter && <span className="text-red-600 leading-4">*</span>}
                    {asTextarea === 'textarea' ? (
                        <textarea
                            name={inputName}
                            className={`form_control block resize-none ${className} ${errorText && 'is-invalid'} ${isError ? 'text-uitilityred500 border-red-600' : ''}`}
                            placeholder={placeholder}
                            onChange={HandleChange}
                            tabIndex={tabIndex}
                            rows={rows || 5}
                            maxLength={maxLength}
                            minLength={minLength}
                            disabled={disabled}
                            autoComplete="off"
                            required={required || requiredAfter}
                            onKeyDown={handleKeyDown}
                            {...props}
                        />
                    ) : (
                        <div className={`relative flex justify-center items-center ${isLoading ? 'custom-loading' : ''}`}>
                            {iconLeft && iconLeft}
                            <input
                                type={inputType}
                                name={inputName}
                                className={`form_control ${className} ${errorText && 'is-invalid'} ${isError ? 'text-uitilityred500 border-red-600' : ''}`}
                                placeholder={placeholder}
                                onChange={HandleChange}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                tabIndex={tabIndex}
                                min={min}
                                maxLength={maxLength}
                                minLength={minLength}
                                max={max}
                                value={value}
                                disabled={disabled}
                                autoComplete="off"
                                required={required || requiredAfter}
                                readOnly={readOnly}
                                onKeyDown={handleKeyDown}
                                data-testid={testId}
                                {...props}
                            />
                            {icon && icon}
                            {isClearable && value !== '' && <XClose id="clear" className="h-4 w-4 cursor-pointer absolute right-2 clear" onClick={clearInput} />}
                        </div>
                    )}
                </div>
                {/* <div className="[&:empty]:mt-0 mt-1.5">{errorText && <ErrorMsg errorText={errorText} />}</div> */}
            </div>
        </>
    );
};

InputText.defaultProps = {
    inputName: '',
    placeholder: '',
    errorText: '',
    asTextarea: '',
    inputType: 'text',
    label: '',
    regularExp: '',
    id: '',
    icon: '',
    labelcontainer: '',
    maxLength: null,
    className: '',
    labelClassName: '',
    readOnly: false,
    onChangeFunc: () => void 0,
    onBlur: () => void 0,
};

export default InputText;
