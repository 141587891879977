import React, { useEffect, useState, useLayoutEffect } from 'react';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const StaffHeatMap = ({ staff }: any) => {
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [heatmapData, setHeatmapData] = useState<any>([]);

    useEffect(() => {
        getHeatmapData();
    }, [shop]);
    useLayoutEffect(() => {
        const root = am5.Root.new('chartdiv');
        if (root._logo) {
            root._logo.dispose();
        }
        root.setThemes([am5themes_Animated.new(root)]);
        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                paddingLeft: 0,
                layout: root.verticalLayout,
            }),
        );

        // Create axes and their renderers
        var yRenderer = am5xy.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true,
        });

        yRenderer.grid.template.set('visible', false);

        var yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                renderer: yRenderer,
                categoryField: 'booking_day',
            }),
        );

        var xRenderer = am5xy.AxisRendererX.new(root, {
            visible: false,
            minGridDistance: 30,
            opposite: true,
            minorGridEnabled: true,
        });

        xRenderer.grid.template.set('visible', false);

        var xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: xRenderer,
                categoryField: 'booking_date',
            }),
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
        var series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                calculateAggregates: true,
                stroke: am5.color(0xffffff),
                clustered: false,
                xAxis: xAxis,
                yAxis: yAxis,
                categoryXField: 'booking_date',
                categoryYField: 'booking_day',
                valueField: 'total_bookings',
            }),
        );

        series.columns.template.setAll({
            tooltipText: '{value}',
            strokeOpacity: 1,
            strokeWidth: 2,
            width: am5.percent(100),
            height: am5.percent(100),
        });

        series.columns.template.events.on('pointerover', function (event) {
            var di: any = event.target.dataItem;
            if (di) {
                heatLegend.showValue(di.get('value', 0));
            }
        });

        series.events.on('datavalidated', function () {
            heatLegend.set('startValue', series.getPrivate('valueHigh'));
            heatLegend.set('endValue', series.getPrivate('valueLow'));
        });

        // Set up heat rules
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series.set('heatRules', [
            {
                target: series.columns.template,
                min: am5.color(0xfffb77),
                max: am5.color(0xfe131a),
                dataField: 'value',
                key: 'fill',
            },
        ]);

        var heatLegend = chart.bottomAxesContainer.children.push(
            am5.HeatLegend.new(root, {
                orientation: 'horizontal',
                endColor: am5.color(0xfffb77),
                startColor: am5.color(0xfe131a),
            }),
        );

        series.data.setAll(heatmapData);
        // chart.set('scrollbarY', am5.Scrollbar.new(root, { orientation: 'vertical' }));
        const scrollbarX = am5.Scrollbar.new(root, {
            orientation: 'horizontal',
            start: 0,
            end: 0.5,
            animationDuration: 2,
        });

        chart.set('scrollbarX', scrollbarX);
        chart.zoomOutButton.set('forceHidden', true);
        scrollbarX.startGrip.setAll({
            visible: false,
        });
        scrollbarX.endGrip.setAll({
            visible: false,
        });

        xAxis.data.setAll(
            heatmapData.map(function (item: any) {
                return { booking_date: item.booking_date };
            }),
        );
        yAxis.data.setAll([
            {
                booking_day: 'Sunday',
            },
            {
                booking_day: 'Monday',
            },
            {
                booking_day: 'Tuesday',
            },
            {
                booking_day: 'Wednesday',
            },
            {
                booking_day: 'Thursday',
            },
            {
                booking_day: 'Friday',
            },
            {
                booking_day: 'Saturday',
            },
        ]);
        chart.appear(1000, 100);
        am5.ready(function () {
            removeCredits();
        });

        return () => {
            root.dispose();
        };
    }, [heatmapData]);

    const removeCredits = () => {
        const titles = Array.from(document.querySelectorAll('title'));

        for (const ele of titles) {
            if (ele.textContent && ele.textContent.includes('Chart created using amCharts library')) {
                const id = ele.id;
                const elementWithAriaLabel = document.querySelector<HTMLElement>(`[aria-labelledby=${id}]`);

                if (elementWithAriaLabel) {
                    elementWithAriaLabel.style.display = 'none';
                }
            }
        }
    };

    const getHeatmapData = async () => {
        setIsLoading(true);
        await axiosGet(API.ANALYTICS.HEAT_MAP, { shop_id: shop.id })
            .then((response) => {
                setHeatmapData(response.data.data);
            })
            .finally(() => setIsLoading(false));
    };
    return <div id="chartdiv" style={{ width: '100%', height: '400px' }}></div>;

    // return (
    //     <div className="datatable-custom h-full">
    //         <div className="flex-1 relative">
    //             <div className="flex flex-col  justify-center min-h-[450px] items-center text-center bg-banner bg-center bg-no-repeat">
    //                 <div className="flex justify-center border shadow-sm border-gray-200 bg-white items-center h-12 w-12 mb-4 rounded-[10px] mt-12">
    //                     <SearchLg className="text-gray-700" />
    //                 </div>
    //                 <div className="text-gray-900 text-base font-semibold leading-[24px] mb-1">Data collection in progress</div>
    //                 <span className="text-gray-600 text-sm leading-5 font-normal">You can see data after 90 days of data collection.</span>
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default StaffHeatMap;
