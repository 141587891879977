import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { ArrowUp, ArrowDown, Users01, SearchLg, Edit01, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/path';
import CustomButton from 'src/components/CustomButton';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import NoDataMessage from 'src/components/NoDataMessage';
import { ROUTES } from 'src/constants/routes';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { Skeleton } from 'primereact/skeleton';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';

const INIT_STATE = {
    FILTER: {},
    LAZY_STATE: {
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    },
};
const initAction = {
    id: null,
    delete: false,
};
const CategoryTable = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    // const [staffListArray, setStaffListArray] = useState([]);
    const [totalRecordsLength, setTotalRecordsLength] = useState<number>(0);
    const [recordList, setRecordList] = useState([]);
    const [lazyState, setLazyState] = useState<any>(INIT_STATE.LAZY_STATE);
    const [filters, setFilters] = useState<any>({
        global: { value: null },
    });

    useEffect(() => {
        listStaff();
    }, [lazyState, shop]);
    const getCategoryDescription = (row: any) => <p className="line-clamp-2">{row.description}</p>;

    const listStaff = async () => {
        setIsLoading(true);
        await axiosGet(API.CATEGORY.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setRecordList(response.data.data);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const handleClick = () => {
        navigate(ROUTES.SERVICES.CATEGORY.CREATE);
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                pay_structure: { pay_structure_type: 'loading' },
                login_at: 'loading',
                users_count: 'loading',
                new_users_count: 'loading',
                active_users_count: 'loading',
                first_name: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );
    const [action, setAction] = useState(initAction);

    const handleAction =
        (row: any, type: string = '') =>
        () => {
            if (!type) {
                navigate(`${PATH.SERVICES.CATEGORY.UPDATE}/${row.id}`);
            } else {
                setAction((old) => ({ ...old, [type]: true, id: row.id }));
            }
        };
    const getAction = (row: any) => (
        <div className="flex items-center gap-4 justify-end">
            <CustomButton className="shadow-none w-4 !px-0 !pr-[4px]" onClick={handleAction(row)}>
                <Edit01 className="h-4 w-4 text-gray-600" />
            </CustomButton>
            <CustomButton className="shadow-none w-4 !px-0" onClick={handleAction(row, 'delete')}>
                <Trash01 className="h-4 w-4 text-gray-600" />
            </CustomButton>
        </div>
    );
    const getCategory = async () => {
        setIsLoading(true);
        await axiosGet(API.CATEGORY.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setRecordList(response.data.data);
                // setTotalCategorys(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };
    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        id: action.id,
                    };
                    await axiosDelete(API.CATEGORY.DELETE, {}, params)
                        .then(getCategory)
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );
    return (
        <div
            className={`w-full border rounded-xl mt-0 flex-1 flex-col mb-5 flex shadow staff_service_table datatable-custom-service ${
                recordList.length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className=" w-full justify-between items-center flex flex-col">
                <div className="w-full justify-between items-center">
                    <div className={`flex items-center w-full flex-col  border-b border-gray-200`}>
                        <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                            <div className="flex flex-col flex-1">
                                <div className="flex justify-start items-center gap-1.5">
                                    <h2 className="table-title">{t('Categories')}</h2>
                                    <p className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
                                        <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                        {recordList.length} {t('Categories')}
                                    </p>
                                </div>
                                <p className="table-subtitle">{t('Add, view and manage your categories.')}</p>
                            </div>
                            <div className="flex relative">
                                <CustomButton outlinePrimary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] w-full rounded-lg shadow-InputAndButton">
                                    {t('Add Service Category')}
                                </CustomButton>
                            </div>
                        </div>
                        <div className="flex items-center px-5 py-3 justify-between w-full">
                            <div className="table-searchInput">
                                <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={customPaginatorTemplate}
                value={isLoading ? skeletons : recordList}
                totalRecords={totalRecordsLength}
                paginator={!isLoading && totalRecordsLength > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                // selectionMode="single"
                dataKey="id"
                className=" rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <NoDataMessage
                        title={`${filters.global.value ? t('No service categories found.') : t('No service categories added.')}`}
                        description={`${
                            filters.global.value
                                ? t('Try adjusting your filters or add new service categories.')
                                : t('Start by adding new service categories to have them listed on your online booking website.')
                        }`}
                        iconComponent={filters.global.value ? <SearchLg className="text-gray-700" /> : <Users01 className="text-gray-700" />}
                    />
                }
            >
                <Column
                    field="name"
                    header={renderHeader(t('Category'), 'name')}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                    style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                    sortable
                ></Column>
                <Column
                    field="description"
                    header={renderHeader(t('Description'), 'description')}
                    className="text-xs leading-[18px] font-normal text-gray-600 "
                    body={isLoading ? <Skeleton /> : getCategoryDescription}
                    style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                    sortable
                ></Column>
                <Column
                    field="action"
                    header={t('')}
                    className="text-xs justify-end font-medium "
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    body={isLoading ? <Skeleton /> : getAction}
                ></Column>
            </DataTable>
            {action.delete && <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete category')} description={t('Are you sure you want to delete category?')} />}
        </div>
    );
};

export default CategoryTable;
