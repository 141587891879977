import React, { FC } from 'react';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BankNote02 } from '@untitled-ui/icons-react/build/cjs';
import { selectCalendarData } from 'src/app/Calendar/Calendar.slice';

import { useAppSelector } from 'src/redux/hooks';
import InputWithLabel from 'src/components/InputWithLabel';
import Tip from './Tip';

interface IPaymentState {
    type: string;
    amount: number;
}

interface CashPaymentProps {
    totalOptions: number[];
    handlePaymentInput: (type: string, amount: number) => () => void;
    handleTipWithoutCalculation: (value: any, isFixed?: boolean) => () => void;
    handleReturnAmountCalculation: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlurInput: (event: any) => void;
    errors: any;
    returnAmount: number;

    control: Control<FieldValues, any>;
    payment: any;
}

const CashPayment: FC<CashPaymentProps> = ({
    control,
    totalOptions,
    handlePaymentInput,
    handleTipWithoutCalculation,
    payment,
    handleReturnAmountCalculation,
    handleBlurInput,
    errors,
    returnAmount,
}) => {
    const { t } = useTranslation();
    // const calendarData: any = useAppSelector(selectCalendarData);

    return (
        <div className="flex flex-col border border-gray-200  w-full rounded-xl">
            <div className={`text-center flex items-center gap-3 px-2.5 py-2 w-full`}>
                <div className="border border-[#7191EF] bg-[#EDF1FD] rounded-lg h-9 w-9 flex items-center justify-center">
                    <BankNote02 className="text-[#143CB3] w-5" />
                </div>
                <p className="text-[#143CB3] font-semibold text-xs leading-[18px]">Cash</p>
            </div>
            <hr />
            <div className={` flex items-center gap-1    px-2.5 py-2 flex-row justify-between  w-full`}>
                <div className="flex flex-col gap-1.5">
                    <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Cash Total Amount</p>
                    <div className="flex items-center gap-1">
                        {totalOptions.map((item, index) => (
                            <div className="pay-tab-btn-view cursor-pointer" key={index} onClick={handlePaymentInput('cash', item)}>
                                <div className={`pay-tab-link ${payment.type === 'cash' && payment.amount === item ? 'active' : ''}`}>${item}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <Controller
                    name="cash"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div>
                            <InputWithLabel
                                type="number"
                                name="cash"
                                placeholder={t('$00.00')}
                                onChange={(e: any) => {
                                    let amount = e.target.value;
                                    if (amount.length > 1) {
                                        amount = parseFloat(amount).toString();
                                    }
                                    onChange(amount);
                                    handlePaymentInput('cash', amount)();
                                }}
                                onBlur={handleBlurInput}
                                value={value}
                                inputControlClassName="!h-[28px] !px-0 !text-center !bg-transparent !border-0 "
                                inputTextWrapper="!h-[30px] !w-[70px] !p-0 !bg-transparent !text-center"
                            />
                        </div>
                    )}
                />
            </div>
            <hr />
            <Tip control={control} handleTipWithoutCalculation={handleTipWithoutCalculation} errors={errors.tip} />
            <hr />
            <div className={` flex items-center gap-3 px-2.5 py-2 flex-row justify-between  w-full`}>
                <div className="flex flex-col gap-1.5">
                    <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Return amount</p>
                </div>
                <Controller
                    name="return_amount"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div>
                            <InputWithLabel
                                // label="Return amount"
                                labelClassName="text-xs"
                                type="number"
                                placeholder="$0.00"
                                onChange={handleReturnAmountCalculation}
                                value={returnAmount}
                                error={errors?.returnAmount.status}
                                name="return_amount"
                                onBlur={handleBlurInput}
                                inputControlClassName="!h-[28px] !px-0 !text-center !bg-transparent !border-0 "
                                inputTextWrapper="!h-[30px] !w-[70px] !p-0 !bg-transparent !text-center"
                            />
                        </div>
                    )}
                />
            </div>
            {errors?.returnAmount.status && <p className="text-error px-2.5 pb-1.5 !mt-0">{errors?.returnAmount.message}</p>}
        </div>
    );
};

export default CashPayment;
