import React from 'react';

type StepProgressBarProps = {
    steps: number;
    currentStep: number;
    className?: string;
    size?: string;
};

const StepProgressBar: React.FC<StepProgressBarProps> = ({ steps, currentStep, className = '', size = 'max-w-[140px]' }) => (
    <div className={`flex items-end gap-[12px] h-5  ${className}`}>
        {Array.from({ length: steps }, (_, i) => i + 1).map((step, index) => {
            const isCompleted = index < currentStep;
            return <div key={step} className={`flex-1 ${isCompleted ? 'bg-primary' : 'bg-gray-300'} h-[5px] w-full ${size}  rounded-2xl`}></div>;
        })}
    </div>
);

export default StepProgressBar;
