import React from 'react';
import moment from 'moment-timezone';
import { useAppSelector } from 'src/redux/hooks';
import { selectCalendarData } from '../../Calendar.slice';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterSpace } from 'src/utils/global-functions';

const DateManageInfo = () => {
    const { t } = useTranslation();
    const calendarData = useAppSelector(selectCalendarData);
    return (
        <div className="px-4 pb-3 -mx-4">
            <>
                <div className="flex items-center mb-1.5">
                    <p className="flex flex-row gap-1.5">
                        <span className="font-semibold text-xs leading-[18px] text-gray-600">{t('Booked on')} :</span>
                        <span className="font-normal text-xs leading-[18px] text-gray-600">
                            {moment(calendarData.bookedSlotInfo.created_at).tz(calendarData.bookedSlotInfo.location.timezone).format('ddd, MMM D [at] h:mm A')}
                        </span>
                    </p>
                </div>
                {calendarData.bookedSlotInfo.created_by_name && (
                    <div className="flex items-center mb-1.5">
                        <p className="flex flex-row gap-1.5">
                            <span className="font-semibold text-xs leading-[18px] text-gray-600">{t('Booked by')} :</span>
                            <span className="font-normal text-xs leading-[18px] text-gray-600">
                                {` ${calendarData.bookedSlotInfo.created_by_name && capitalizeFirstLetterSpace(`${calendarData.bookedSlotInfo.created_by_name}`)}`}
                            </span>
                        </p>
                    </div>
                )}
            </>
            {calendarData.bookedSlotInfo?.status === 'completed' && (
                <>
                    <div className="flex items-center mb-1.5">
                        <p className="flex flex-row gap-1.5">
                            <span className="font-semibold text-xs leading-[18px] text-gray-600">{t('Checkout on')} :</span>
                            <span className="font-normal text-xs leading-[18px] text-gray-600">
                                {moment(calendarData.bookedSlotInfo.updated_at).tz(calendarData.bookedSlotInfo.location.timezone).format('ddd, MMM D [at] h:mm A')}
                            </span>
                        </p>
                    </div>
                    <div className="flex items-center mb-1.5">
                        <p className="flex flex-row gap-1.5">
                            <span className="font-semibold text-xs leading-[18px] text-gray-600">{t('Checkout by')} :</span>
                            <span className="font-normal text-xs leading-[18px] text-gray-600">{capitalizeFirstLetterSpace(calendarData.bookedSlotInfo.checkout_by)}</span>
                        </p>
                    </div>
                </>
            )}
            {calendarData.bookedSlotInfo?.status === 'canceled' && (
                <>
                    <div className="flex items-center mb-1.5">
                        <p className="flex flex-row gap-1.5">
                            <span className="font-semibold text-xs leading-[18px] text-gray-600">{t('Canceled on')} :</span>
                            <span className="font-normal text-xs leading-[18px] text-gray-600">
                                {moment(calendarData.bookedSlotInfo.canceled_at).tz(calendarData.bookedSlotInfo.location.timezone).format('ddd, MMM D [at] h:mm A')}
                            </span>
                        </p>
                    </div>
                    <div className="flex items-center mb-1.5">
                        <p className="flex flex-row gap-1.5">
                            <span className="font-semibold text-xs leading-[18px] text-gray-600">{t('Canceled by')} :</span>
                            <span className="font-normal text-xs leading-[18px] text-gray-600"> {capitalizeFirstLetterSpace(calendarData.bookedSlotInfo.canceled_by_name)}</span>
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

export default DateManageInfo;
