import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectStaffLocation, staffLocationListAction } from './Location.slice';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InitWorkingHours, INTERVAL_OPTIONS, IWorkingHoursValue } from '../Signup/WorkingHoursStaff/WorkingHoursStaff.interface';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import WorkingHours from 'src/app/Location/WorkingHours';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop, userMe } from 'src/redux/services/common/Common.slice';
import { errorCode } from 'src/constants/errorCode';
import { IViewStaffLocation } from './Location.interface';
import PopupModal from 'src/components/PopupModal';
import { ClockRewind } from '@untitled-ui/icons-react/build/cjs';

const ViewStaffLocation = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(userMe);
    const { id } = useParams();
    const staffLocationlisting = useAppSelector(selectStaffLocation);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        frequency_booking: Yup.string().required(t('This field is required')),
        id: Yup.number().required(t('This field is required')).integer(t('The value must be an integer')).positive(t('The value must be a positive number')),
        hours: Yup.array().of(
            Yup.object().shape({
                day: Yup.string().required('Day is required').oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], 'Invalid day'),
                status: Yup.boolean().required('Status is required'),
                from_time_hours: Yup.string()
                    .required('From time hours are required')
                    .test({
                        name: 'from_valid_hours_1',
                        message: 'From hours must be between 01 to 12 and minutes must be between 00 to 59',
                        test: function (value) {
                            const { status } = this.parent;
                            if (status) {
                                const parts = value.split(':');
                                const hours = parseInt(parts[0], 10);
                                const minutes = parseInt(parts[1], 10);
                                const isValidHours = !isNaN(hours) && hours >= 1 && hours <= 12;
                                const isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes < 60;
                                return isValidHours && isValidMinutes;
                            }
                            return true;
                        },
                    })
                    .test({
                        name: 'from_valid_hours_2',
                        message: '"From" time must be less than "To" time',
                        test: function (value) {
                            const { from_time_type: fromTimeType, to_time_hours: toTimeHours, to_time_type: toTimeType, status } = this.parent;

                            if (status) {
                                const fromMoment = moment(`${value} ${fromTimeType}`, 'hh:mm a');
                                const toMoment = moment(`${toTimeHours} ${toTimeType}`, 'hh:mm a');

                                if (fromMoment.isSameOrAfter(toMoment)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                            return true;
                        },
                    }),

                to_time_hours: Yup.string()
                    .required('To time hours are required')
                    .test({
                        name: 'to_valid_hours_1',
                        message: 'To hours must be between 01 to 12 and minutes must be between 00 to 59',
                        test: function (value) {
                            const { status } = this.parent;
                            if (status) {
                                const parts = value.split(':');
                                const hours = parseInt(parts[0], 10);
                                const minutes = parseInt(parts[1], 10);
                                const isValidHours = !isNaN(hours) && hours >= 1 && hours <= 12;
                                const isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes < 60;
                                return isValidHours && isValidMinutes;
                            }
                            return true;
                        },
                    })
                    .test({
                        name: 'to_valid_hours_2',
                        message: '"To" time must be greater than "From" time',
                        test: function (value) {
                            const { from_time_hours: fromTimeHours, from_time_type: fromTimeType, to_time_type: toTimeType, status } = this.parent;

                            if (status) {
                                const fromMoment = moment(`${fromTimeHours} ${fromTimeType}`, 'hh:mm a');
                                const toMoment = moment(`${value} ${toTimeType}`, 'hh:mm a');

                                if (toMoment.isSameOrBefore(fromMoment)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                            return true;
                        },
                    }),

                from_time_type: Yup.string().required('From time type is required').oneOf(['am', 'pm'], 'Invalid from time type'),
                to_time_type: Yup.string().required('To time type is required').oneOf(['am', 'pm'], 'Invalid to time type'),
            }),
        ),
    });

    const methods = useForm<IViewStaffLocation>({
        resolver: yupResolver(schema) as Resolver<IViewStaffLocation>,
        defaultValues: {
            frequency_booking: '',
            id: null,
            hours: InitWorkingHours,
        },
    });

    useEffect(() => {
        getLocation();
    }, [staffLocationlisting]);

    const getLocation = async () => {
        const matchingStaffLocation = staffLocationlisting.find((location: any) => location.id === Number(id));
        if (matchingStaffLocation) {
            let hours = InitWorkingHours;
            if (matchingStaffLocation.staff_working_hours.length) {
                hours = matchingStaffLocation.staff_working_hours.map((hour: any) => {
                    const momentFromTime = moment(hour.from, 'HH:mm:ss');
                    const momentToTime = moment(hour.to, 'HH:mm:ss');

                    const fromTimeHours = momentFromTime.format('hh:mm');
                    const fromTimeType = momentFromTime.format('a');

                    const toTimeHours = momentToTime.format('hh:mm');
                    const toTimeType = momentToTime.format('a');

                    return {
                        day: hour.day,
                        status: hour.status,
                        from_time_hours: fromTimeHours,
                        to_time_hours: toTimeHours,
                        from_time_type: fromTimeType,
                        to_time_type: toTimeType,
                    };
                });
            }
            setValue('id', Number(matchingStaffLocation.id));
            setValue('hours', hours);
            setValue('frequency_booking', matchingStaffLocation.staff_working_hours.length ? matchingStaffLocation.staff_working_hours[0].frequency_booking : '');
        }
    };

    const {
        handleSubmit,
        setValue,
        control,
        setError,
        formState: { errors },
    } = methods;

    const onSubmit = async (data: IViewStaffLocation) => {
        setIsLoading(true);
        const payload = {
            frequency_booking: data.frequency_booking,
            hours: data.hours.map((day: IWorkingHoursValue) => {
                const from = moment(`${day.from_time_hours} ${day.from_time_type}`, 'hh:mm a').format('HH:mm:ss');
                const to = moment(`${day.to_time_hours} ${day.to_time_type}`, 'hh:mm a').format('HH:mm:ss');
                return {
                    day: day.day,
                    status: day.status,
                    from,
                    to,
                };
            }),
        };
        await axiosPatch(API.LOCATION.HOURS, payload, { shop_id: shop.id, id: Number(id) })
            .then(async (response) => {
                dispatch(staffLocationListAction({ shop_id: shop.id, id: user.id }));
                handleClose();
                return;
            })
            .catch((error: any) => {
                const response = error.response.data;

                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as keyof IViewStaffLocation, {
                                type: 'manual',
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <ClockRewind className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('Edit Your Working Hours')}</p>

                <span className="text-xs font-normal text-gray-500">{t('Set working hours of your location')}</span>
            </div>
        </div>
    );

    return (
        <>
            <PopupModal
                dismissible
                onClose={handleClose}
                size="w-[640px]"
                className="px-5 py-5"
                title={<Title />}
                primaryButton={t('Continue')}
                secondaryButton={t('Cancel')}
                acceptAction={handleSubmit(onSubmit)}
                declineAction={handleClose}
                isLoading={isLoading}
                isDisabled={isLoading}
            >
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=" ">
                            <div className="flex flex-row justify-between items-start gap-5 border border-borderSecondary rounded-3xl p-6 mb-2 shadow">
                                <div className="flex flex-col gap-3 max-w-[304px]">
                                    <div className="font-base leading-[19.36px] font-semibold text-mainTextColor">Appointment intervals</div>
                                    <p className="text-sm font-normal text-gray-500">{t('Set the time intervals at which your calendar will have openings for client bookings.')}</p>
                                </div>
                                <div className="w-full max-w-[200px] login_service">
                                    <Controller
                                        name="frequency_booking"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <SelectBox
                                                    value={INTERVAL_OPTIONS.filter((item: any) => item.value === value)}
                                                    name="frequency_booking"
                                                    id="frequency_booking"
                                                    options={INTERVAL_OPTIONS}
                                                    required
                                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                                    placeholder={t('Select')}
                                                    errorText={!!error}
                                                    classComp={`!h-[36px] ${error ? 'is-invalid' : ''}`}
                                                    isClearable={false}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col gap-5">
                                <WorkingHours errors={errors} name={`hours`} isLoginStep />
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </PopupModal>
        </>
    );
};

export default ViewStaffLocation;
