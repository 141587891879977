export const APP_ROOT = '/';

export const PATH = {
    SETTINGS: `${APP_ROOT}settings/`,
    LOCATION: {
        VIEW: `${APP_ROOT}locations`,
        UPDATE: `${APP_ROOT}locations/form`,
    },
    STAFF: {
        VIEW: `${APP_ROOT}staff`,
        UPDATE: `${APP_ROOT}staff/form`,
    },
    CLIENT: {
        VIEW: `${APP_ROOT}client`,
        UPDATE: `${APP_ROOT}client/form`,
    },
    SERVICES: {
        LIST: `${APP_ROOT}services`,
        UPDATE: `${APP_ROOT}services/form`,
        CATEGORY: {
            UPDATE: `${APP_ROOT}services/category/form`,
            LIST: `${APP_ROOT}services/category`,
        },
    },
    PRODUCT: {
        LIST: `${APP_ROOT}products`,
        UPDATE: `${APP_ROOT}products/form`,
        VIEW: `${APP_ROOT}products/view`,
        CATEGORY: {
            UPDATE: `${APP_ROOT}products/category/form`,
        },
    },
};
