import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import { Label, Radio } from 'flowbite-react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Switch from 'src/components/Switch/Switch';
import { ROLES } from 'src/constants/common';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { allShopSettings, currentRole, currentShop, me } from 'src/redux/services/common/Common.slice';
import * as Yup from 'yup';
import { appointmentTimeOptions, bufferOptions, daysOptions, ICalendarSettings, timeOptions } from './Calendar.interface';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { Tooltip } from 'primereact/tooltip';

const Calendar = () => {
    const { t } = useTranslation();
    const role = useAppSelector(currentRole);
    const shop: any = useAppSelector(currentShop);
    const shopSetting: any = useAppSelector(allShopSettings);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveEnabled, setSaveEnabled] = useState(false);

    const schema = Yup.object().shape({
        from_hours: Yup.string().required(t('This field is required')),
        to_hours: Yup.string()
            .required('From time hours are required')
            .test({
                name: 'to_valid_hours_2',
                message: '"To" time must be greater than "From" time',
                test: function (value) {
                    const { from_hours: fromTimeHours } = this.parent;
                    const fromMoment = moment(`${fromTimeHours}`, 'hh:mm a');
                    const toMoment = moment(`${value}`, 'hh:mm a');
                    if (toMoment.isSameOrBefore(fromMoment)) {
                        return false;
                    } else {
                        return true;
                    }
                },
            }),
        start_week: Yup.string().required(t('This field is required')),
        is_buffer_time: Yup.boolean().required(),
        buffer_time: Yup.string()
            .nullable()
            .when('is_buffer_time', ([is_buffer_time], customSchema) => (is_buffer_time ? customSchema.required('This field is required') : customSchema.notRequired())),
        advance_days: Yup.string().required(t('This field is required')),
        book_time_hours: Yup.string().required(t('This field is required')),
        book_time_minutes: Yup.string().required(t('This field is required')),
        cancel_time_hours: Yup.string().required(t('This field is required')),
        cancel_time_minutes: Yup.string().required(t('This field is required')),
    });

    const {
        handleSubmit,
        control,
        watch,
        setError,
        setValue,
        reset,
        formState: { isDirty },
    } = useForm<ICalendarSettings>({
        resolver: yupResolver(schema),
        defaultValues: {
            from_hours: '00:00:00',
            to_hours: '10:00:00',
            start_week: 'sunday',
            is_buffer_time: false,
            buffer_time: '00:10:00',
            advance_days: '5',
            book_time_hours: '01',
            book_time_minutes: '30',
            cancel_time_hours: '01',
            cancel_time_minutes: '30',
        },
    });

    const watchAllFields = watch();

    useEffect(() => {
        setSaveEnabled(isDirty);
    }, [watchAllFields, isDirty]);

    const bufferTime = watch('is_buffer_time');

    const extractCalendarData = (calendarDetailsValue: any): ICalendarSettings => {
        const book = calendarDetailsValue.book_time.split(':');
        const cancel = calendarDetailsValue.cancel_time.split(':');

        return {
            from_hours: calendarDetailsValue.from_hours,
            to_hours: calendarDetailsValue.to_hours,
            start_week: calendarDetailsValue.start_week,
            is_buffer_time: calendarDetailsValue.is_buffer_time,
            buffer_time: calendarDetailsValue.is_buffer_time ? calendarDetailsValue.buffer_time : '00:10:00',
            advance_days: String(calendarDetailsValue.advance_days),
            book_time_hours: book[0],
            book_time_minutes: book[1],
            cancel_time_hours: cancel[0],
            cancel_time_minutes: cancel[1],
        };
    };

    useEffect(() => {
        if (shopSetting) {
            const calendar = shopSetting.find((setting: any) => setting.type === 'calendar');
            if (calendar) {
                const existingData: ICalendarSettings = extractCalendarData(calendar.value);
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof ICalendarSettings, existingData[key as keyof ICalendarSettings]);
                });
                reset(existingData);
            } else {
                resetForm();
            }
        }
    }, [shopSetting]);

    const onSubmit = async (data: ICalendarSettings) => {
        setIsLoading(true);
        const { book_time_hours: bookTimeHours, book_time_minutes: bookTimeMinutes, cancel_time_hours: cancelTimeHours, cancel_time_minutes: cancelTimeMinutes, ...restData } = data;
        const bookTime = `${bookTimeHours}:${bookTimeMinutes}:00`;
        const cancelTime = `${cancelTimeHours}:${cancelTimeMinutes}:00`;
        const payload = {
            ...restData,
            advance_days: Number(data.advance_days),
            book_time: bookTime,
            cancel_time: cancelTime,
        };

        axiosPost(API.USER.CALENDAR, payload, {
            shop_id: shop.id,
        })
            .then(async (response) => {
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof ICalendarSettings, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const resetForm = () => {
        if (shopSetting) {
            const calendar = shopSetting.find((setting: any) => setting.type === 'calendar');
            if (calendar) {
                const existingData = extractCalendarData(calendar.value);
                reset(existingData);
            } else {
                reset();
            }
        } else {
            reset();
        }
    };
    return (
        <div className="w-full flex-1">
            <Tooltip target=".calendar-setting" position="top" />

            <form onSubmit={handleSubmit(onSubmit)} className="w-full px-2 py-1">
                <div className="detail-page-title-block !py-4 !px-0 border-borderSecondary">
                    <div className="flex flex-col ">
                        <h2 className="flex flex-row gap-1.5 items-center">
                            <div className="title-text-header">{t('Calendar Settings')}</div>
                            <InfoCircle
                                className="text-[#98A2B3] w-4 h-4 custom-tooltip-btn calendar-setting"
                                data-pr-tooltip={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}
                            />
                        </h2>
                        <p className="subtitle-text">{t('Adjust your calendar display and manage booking preferences.')}</p>
                    </div>
                    {/* <div className="flex flex-row gap-3">
                        <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton type="submit" primary isLoading={isLoading} disabled={isLoading} className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div> */}
                </div>
                <div className="w-full flex flex-row items-center border-b  border-borderSecondary py-4 ">
                    <div className="flex flex-row gap-[32px]">
                        <div className=" w-[310px]">
                            <p className="title-text ">{t('Visible Hours')}</p>
                            <p className="subtitle-text ">{t('Set the default time range shown on your calendar.')}</p>
                        </div>
                        <div className="flex flex-row w-[310px] gap-4 items-center">
                            <div className="w-[50%]">
                                <Controller
                                    name="from_hours"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <SelectBox
                                            name="from_hours"
                                            label={t('From')}
                                            options={timeOptions}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            value={timeOptions.find((time: any) => time.value === value)}
                                            error={!!error}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-[50%]">
                                <Controller
                                    name="to_hours"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <SelectBox
                                            name="to_hours"
                                            label={t('Until')}
                                            options={timeOptions}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            value={timeOptions.find((time: any) => time.value === value)}
                                            error={!!error}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center border-b border-borderSecondary py-4  ">
                    <div className="flex flex-row gap-[32px] items-center">
                        <div className=" w-[310px]">
                            <p className="title-text ">{t('Start of the Week')}</p>
                            <p className="subtitle-text ">{t('Select Sunday or Monday to start the business week.')}</p>
                        </div>
                        <div className="flex flex-row w-[512px] gap-4 items-center">
                            <Controller
                                name={`start_week`}
                                control={control}
                                render={({ field: { value, onChange } }: any) => (
                                    <>
                                        <div className="flex mb-0">
                                            <Radio className="custom-round-radio mt-[3px]" id="logo-type-logo" name="logo-type" value={'sunday'} onChange={onChange} checked={value === 'sunday'} />
                                            <Label className="ml-2 text-md text-gray-700">{t('Sunday')}</Label>
                                        </div>
                                        <div className="flex mb-0">
                                            <Radio className="custom-round-radio mt-[3px]" id="logo-type-logo" name="logo-type" value={'monday'} onChange={onChange} checked={value === 'monday'} />
                                            <Label className="ml-2 text-md text-gray-700">{t('Monday')}</Label>
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center border-b border-borderSecondary py-4  ">
                    <div className="flex flex-row gap-[32px] items-center">
                        <div className=" w-[310px]">
                            <p className="title-text ">{t('Buffer Time')}</p>
                            <p className="subtitle-text ">{t('Add buffer time before or after appointments for prep or cleanup.')}</p>
                        </div>
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-3 text-sm font-medium text-gray-700">
                                <Controller
                                    name={`is_buffer_time`}
                                    control={control}
                                    render={({ field: { value, onChange } }: any) => (
                                        <>
                                            <Switch onChange={onChange} isChecked={value} />
                                            <span>{t('Enable buffer time')}</span>
                                        </>
                                    )}
                                />
                            </div>
                            {bufferTime && (
                                <div className="w-[310px]">
                                    <Controller
                                        name={`buffer_time`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <SelectBox
                                                name="buffer_time"
                                                options={bufferOptions}
                                                onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                value={bufferOptions.find((time: any) => time.value === value)}
                                                error={!!error}
                                                isSearchable={false}
                                                isClearable={false}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center border-b border-borderSecondary py-4 ">
                    <div className="flex flex-row gap-[32px] items-center">
                        <div className=" w-[310px]">
                            <p className="title-text ">{t('Days Clients Can Book in Advance')}</p>
                            <p className="subtitle-text ">{t('Control how far ahead clients can schedule appointments.')}</p>
                        </div>
                        <div className="flex flex-row w-[512px] gap-4 items-center">
                            <div className="w-[310px]">
                                <Controller
                                    name={`advance_days`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <SelectBox
                                            name="advance_days"
                                            options={daysOptions}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            value={daysOptions.find((time: any) => time.value === value)}
                                            error={!!error}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center border-b border-borderSecondary py-4 ">
                    <div className="flex flex-row gap-[32px]">
                        <div className=" w-[310px]">
                            <p className="title-text ">{t('Minimum Time for Client Booking')}</p>
                            <p className="subtitle-text ">{t('Set required notice time before clients can book.')}</p>
                        </div>
                        <div className="flex flex-row w-[310px] gap-4 items-center">
                            <div className="w-[50%]">
                                <Controller
                                    name={`book_time_hours`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <SelectBox
                                            name="book_time_hours"
                                            label={t('Hours')}
                                            options={appointmentTimeOptions.hours}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            value={appointmentTimeOptions.hours.find((time: any) => time.value === value)}
                                            error={!!error}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-[50%]">
                                <Controller
                                    name={`book_time_minutes`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <SelectBox
                                            name="book_time_minutes"
                                            label={t('Minutes')}
                                            options={appointmentTimeOptions.minutes}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            value={appointmentTimeOptions.minutes.find((time: any) => time.value === value)}
                                            error={!!error}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row items-center border-b border-borderSecondary py-4 ">
                    <div className="flex flex-row gap-[32px]">
                        <div className=" w-[310px]">
                            <p className="title-text ">{t('Minimum Time for Client Cancellation')}</p>
                            <p className="subtitle-text ">{t('Set required notice time for cancellations.')}</p>
                        </div>
                        <div className="flex flex-row w-[310px] gap-4 items-center">
                            <div className="w-[50%]">
                                <Controller
                                    name={`cancel_time_hours`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <SelectBox
                                            name="cancel_time_hours"
                                            label={t('Hours')}
                                            options={appointmentTimeOptions.hours}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            value={appointmentTimeOptions.hours.find((time: any) => time.value === value)}
                                            error={!!error}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-[50%]">
                                <Controller
                                    name={`cancel_time_minutes`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <SelectBox
                                            name="cancel_time_minutes"
                                            label={t('Minutes')}
                                            options={appointmentTimeOptions.minutes}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            value={appointmentTimeOptions.minutes.find((time: any) => time.value === value)}
                                            error={!!error}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-3 w-full justify-end pt-5 mb-36">
                    <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                        {t('Cancel')}
                    </CustomButton>
                    <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading || !isSaveEnabled} className="!h-[36px]">
                        {t('Save Changes')}
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default Calendar;
