import React, { FC, useEffect } from 'react';
import useImageOrientation from 'src/hooks/useImageOrientation';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { getFontWeight } from 'src/utils/global-functions';

interface LogoDisplayScreenProps {
    formDetails: any;
}

const LogoDisplayScreen: FC<LogoDisplayScreenProps> = ({ formDetails }) => {
    const shop = useAppSelector(currentShop);
    const { isImageHorizontal } = useImageOrientation();

    useEffect(() => {
        if (shop.logo_image_url && shop.logo_image_url !== '') {
            isImageHorizontal(shop.logo_image_url);
        }
    }, [formDetails]);

    return (
        <div className="border borderSecondary rounded-2xl p-1 bg-white w-[280px] h-[160px]">
            <div className="bg-gray-200 rounded-lg pt-[14px] pl-[14px] h-full  overflow-hidden">
                <div className="bg-white rounded-l-lg">
                    <div className="border-b border-borderSecondary flex px-2 py-[5px]">
                        <div className="flex gap-[2px] items-center">
                            <span className="w-[6px] h-[6px] bg-red-500 rounded-full"></span>
                            <span className="w-[6px] h-[6px] bg-yellow-500 rounded-full"></span>
                            <span className="w-[6px] h-[6px] bg-success-500 rounded-full"></span>
                        </div>
                    </div>
                    <div className="pt-[9px] pl-[9px] flex flex-col ">
                        {/* logo section */}
                        <div className="flex gap-2 items-center h-[60px] mb-1.5">
                            {/* logo is rectangular */}
                            {/* <div className="bg-gray-300 w-[56px] h-[28px] overflow-hidden"></div> */}
                            {/* logo is square */}
                            {formDetails.logo_type === 'logo_text' || formDetails.logo_type === 'logo' ? (
                                <div className={`${!shop.logo_image_url && shop.logo_image_url === '' ? 'bg-gray-300' : ''} overflow-hidden w-[70px] max-w-[70px] min-w-[70px]`}>
                                    {shop.logo_image_url && shop.logo_image_url !== '' && (
                                        <img
                                            src={shop.logo_image_url}
                                            alt="logo"
                                            // className={`w-full object-contain h-full ${
                                            //     isHorizontal ? 'w-[80px] min-w-[80px] max-w-[80px] h-[80px] object-contain' : ' w-[56px] min-w-[56px] max-w-[56px] h-[56px] object-contain'
                                            // } object-contain`}
                                            style={{
                                                height: `${formDetails?.logo_height !== '' ? formDetails?.logo_height : 50}px`,
                                            }}
                                            className={`w-full max-w-[80px] min-w-[50px]  object-contain `}
                                        />
                                    )}
                                </div>
                            ) : null}

                            {/* logo is rounded */}
                            {/* <div className="bg-gray-300 w-[56px] h-[56px] overflow-hidden rounded-full"></div> */}
                            {/* logo name bg*/}
                            {formDetails.logo_type === 'logo_text' || formDetails.logo_type === 'text' ? (
                                <div className={`${!formDetails.name || formDetails.name === '' ? 'bg-gray-300 w-[86px] h-2 rounded-lg' : ''} `}>
                                    <h6
                                        style={{
                                            fontSize: `${formDetails.font_size !== '' ? formDetails.font_size : 20}px`,
                                        }}
                                        className={`truncate ${formDetails.logo_type === 'logo_text' ? 'max-w-[168px]' : 'max-w-[245px]'} ${
                                            formDetails.logo_font_family !== '' ? `${formDetails.logo_font_family}` : 'inter'
                                        } ${formDetails.font_weight !== '' ? `font-${getFontWeight(formDetails.font_weight)}` : 'font-[600]'}`}
                                    >
                                        {/* <h6 className={`truncate font-[800]`}>{formDetails.name}</h6> */}
                                        {formDetails.name}
                                    </h6>
                                </div>
                            ) : null}
                            {/* logo name */}
                            {/* <div className="text-xs font-semibold text-mainTextColor">Your Website</div> */}
                        </div>
                        {/* logo section */}
                        <div className="bg-gray-200 w-full h-2 rounded-l-lg mb-2"></div>
                        <div className="flex gap-[6px] w-full">
                            <div className="bg-gray-200 rounded-md w-[50px] h-[50px]"></div>
                            <div className="bg-gray-200 rounded-l-md flex-auto"></div>
                        </div>
                        <div className="flex gap-[6px] w-full">
                            <div className="bg-gray-200 rounded-md w-[50px] h-[50px]"></div>
                            <div className="bg-gray-200 rounded-l-md flex-auto"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoDisplayScreen;
