import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { getShortName } from 'src/utils/global-functions';
import { usePhone } from 'src/hooks/usePhone';
import { currentShop } from 'src/redux/services/common/Common.slice';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Controller, useFormContext } from 'react-hook-form';
import CustomButton from 'src/components/CustomButton';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import AddClient from '../../Sidebar/Client/AddClient';

const Client = () => {
    const [isNewClient, setIsNewClient] = useState(false);
    const [clientArrayList, setClientArrayList] = useState<any[]>([]);
    const [selectedClientInfo, setSelectedClientInfo] = useState<any>(null);
    const shop = useAppSelector(currentShop);
    const { getCustomFormatPhone } = usePhone();
    const { control, setValue } = useFormContext();
    useEffect(() => {
        getClientList();
    }, []);
    const getClientList = () => {
        axiosGet(API.CLIENT.ALL, { shop_id: shop.id }).then((response) => {
            const clientOptionData = response.data.data.map((item: any) => ({
                ...item,
                value: item.id,
                label: `${item.full_name ?? getCustomFormatPhone(item.phone, item.phone_country_code)}`,
                image: item?.profile_image_url,
                shortName: `${getShortName(item.full_name) ?? getCustomFormatPhone(item.phone, item.phone_country_code)}`,
            }));
            setClientArrayList(clientOptionData);
        });
    };

    const handleSelectChange = (selectedOptionInfo: any) => {
        setValue('user_id', selectedOptionInfo?.id ?? null);
        setValue('user_info', selectedOptionInfo ?? null);
        setSelectedClientInfo(selectedOptionInfo);
    };
    const handleNewClient = (selectedOptionInfo: any) => {
        const newClient: any = {
            ...selectedOptionInfo,
            value: selectedOptionInfo.id,
            label: selectedOptionInfo.full_name ?? getCustomFormatPhone(selectedOptionInfo.phone, selectedOptionInfo.phone_country_code),
            image: selectedOptionInfo?.profile_image_url,
            shortName: getShortName(selectedOptionInfo.full_name) ?? getCustomFormatPhone(selectedOptionInfo.phone, selectedOptionInfo.phone_country_code),
        };
        setClientArrayList((prevClientArrayList) => [...prevClientArrayList, newClient]);
        setTimeout(() => {
            setValue('user_id', newClient.id ?? null);
            setValue('user_info', newClient ?? null);
            setSelectedClientInfo(newClient);
        }, 300);
    };

    return (
        <div className="mb-4">
            <>
                <div className="mb-4">
                    <div className="flex justify-between">
                        <label htmlFor="newclient" className="fl-field-title-label text-gray-700 text-xs font-semibold leading-[18px] mb-1.5">
                            Select a client
                        </label>
                        {!selectedClientInfo && (
                            <CustomButton
                                type="button"
                                onClick={() => setIsNewClient(true)}
                                className="!text-xs text-primary font-semibold flex items-center gap-1.5 h-[15px] shadow-none pr-0.5 "
                                icon={<Plus width="16" />}
                            >
                                Add new client
                            </CustomButton>
                        )}
                    </div>
                    <Controller
                        name="user_id"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full">
                                <SelectBox
                                    name="user_id"
                                    options={clientArrayList}
                                    onChangeFunc={handleSelectChange}
                                    value={(clientArrayList.length > 0 && clientArrayList.find((client: any) => client.value === value)) || null}
                                    placeholder="Search or add new client"
                                    isClearable
                                    error={error}
                                />
                                {error?.type && error.type !== 'required' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                </div>
            </>
            {isNewClient && <AddClient getClientList={getClientList} handleSelectChange={handleNewClient} setIsNewClient={setIsNewClient} />}
        </div>
    );
};

export default Client;
