import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import GuardedRoute from './GuardedRoute';
import NotGuardedRoute from './NotGuardedRoute';
import { useAppDispatch } from 'src/redux/hooks';
import { me } from 'src/redux/services/common/Common.slice';
import { LOCAL_STORAGE_KEYS } from 'src/constants/common';
import Admin from 'src/app/Admin/Admin';
import Appearance from 'src/app/Appearance/Appearance';
import Password from 'src/app/Auth/Password/Password';
import Calendar from 'src/app/Calendar/Calendar';
import Client from 'src/app/Client/Client';
import Dashboard from 'src/app/Dashboard';
import Location from 'src/app/Location/Location';
import Payment from 'src/app/Payment/Payment';
import Services from 'src/app/Services/Services';
import Setting from 'src/app/Setting';
import FlairPaymentReturn from 'src/app/Signup/FlairPayment/FlairPaymentReturn';
import Staff from 'src/app/Staff/Staff';
import { ROUTES } from 'src/constants/routes';
import Analytics from 'src/app/Analytics/Analytics';
import Home from 'src/app/Auth/Home';
import Register from 'src/app/Auth/Register/Register';
import NonLayout from 'src/app/Layout/NonLayout/NonLayout';
import SidebarLayout from 'src/app/Layout/SideBarLayout/SidebarLayout';
import DashboardLayout from 'src/app/Layout/Dashboard/DashboardLayout';
import LocationForm from 'src/app/Location/LocationForm';
import ViewLocation from 'src/app/Location/ViewLocation';
import StaffForm from 'src/app/Staff/StaffForm';
import ServiceForm from 'src/app/Services/ServiceForm';
import CategoryForm from 'src/app/Services/Category/CategoryForm';
import PageNotFound from 'src/components/Error/404';
import StaffView from 'src/app/Staff/StaffView';
import Signup from 'src/app/Signup/Signup';
import SignupStaff from 'src/app/Signup/SignupStaff';
import Products from 'src/app/Products/Products';
import ProductsForm from 'src/app/Products/ProductsForm';
import ProductCategoryForm from 'src/app/Products/Category/CategoryForm';
import Loader from 'src/components/Loader/Loader';
import ClientForm from 'src/app/Client/ClientForm';
import ClientView from 'src/app/Client/ClientView';
import ProductDetails from 'src/app/Products/ProductDetails';
import MultiBrand from 'src/app/MultiBrand/MultiBrand';
import ViewStaffLocation from 'src/app/Location/ViewStaffLocation';
import Sales from 'src/app/Sales/Sales';
import Support from 'src/app/Setting/Support/Support';

const MainRoutes = (): JSX.Element => {
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.authToken);
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(me());
            setLoading(false);
        };
        token ? fetchData() : setLoading(false);
    }, []);

    return token && loading && location.pathname !== ROUTES.REGISTER ? (
        <Loader />
    ) : (
        <Routes>
            <Route element={<NonLayout />}>
                <Route path={ROUTES.HOME} element={<NotGuardedRoute Component={Home} title="Login" />} />
                <Route path={ROUTES.REGISTER} element={<GuardedRoute permission="allow" Component={Register} title="Register" />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<NotGuardedRoute Component={Password} title="Forgot Password" />} />
            </Route>
            <Route element={<SidebarLayout />}>
                <Route path={ROUTES.SIGNUP} element={<GuardedRoute permission="allow" Component={Signup} title="Sign Up" />} />
                <Route path={ROUTES.INVITE} element={<GuardedRoute permission="allow" Component={SignupStaff} title="Invite" />} />
                <Route path={ROUTES.MULTI_BRAND} element={<GuardedRoute permission="shop_add" Component={MultiBrand} title="Multi-brand" />} />
            </Route>
            <Route element={<DashboardLayout />}>
                <Route path={ROUTES.STRIPE_CONNECT_RETURN} element={<GuardedRoute permission="allow" Component={FlairPaymentReturn} title="Stripe Connect" />} />
                <Route path={ROUTES.DASHBOARD} element={<GuardedRoute permission="dashboard_view" Component={Dashboard} title="Dashboard" />} />
                <Route path={ROUTES.CALENDAR} element={<GuardedRoute permission="calendar_view" Component={Calendar} title="Calendar" />} />
                <Route path={ROUTES.ANALYTICS} element={<GuardedRoute permission="analytics_view" Component={Analytics} title="Analytics" />} />
                <Route path={ROUTES.SALES} element={<GuardedRoute permission="booking_view" Component={Sales} title="Sales" />} />

                <Route path={ROUTES.ADMIN} element={<GuardedRoute permission="user_view" Component={Admin} title="Admin" />} />
                <Route path={ROUTES.PAYMENT} element={<GuardedRoute permission="setting_view" Component={Payment} title="Payment" />} />
                <Route path={ROUTES.SETTINGS} element={<GuardedRoute permission="setting_view" Component={Setting} title="Setting" />} />
                <Route path={ROUTES.SUPPORT} element={<GuardedRoute permission="setting_view" Component={Support} title="Setting" />} />

                <Route path={ROUTES.CLIENT.LIST} element={<GuardedRoute permission="user_view" Component={Client} title="Client" />} />
                <Route path={ROUTES.CLIENT.CREATE} element={<GuardedRoute permission="user_view" Component={ClientForm} title="Create Client" />} />
                <Route path={ROUTES.CLIENT.UPDATE} element={<GuardedRoute permission="user_view" Component={ClientForm} title="Update Client" />} />
                <Route path={ROUTES.CLIENT.VIEW} element={<GuardedRoute permission="user_view" Component={ClientView} title="View Client" />} />

                <Route path={ROUTES.LOCATION.LIST} element={<GuardedRoute permission="location_view" Component={Location} title="Location" />} />
                <Route path={ROUTES.LOCATION.CREATE} element={<GuardedRoute permission="location_view" Component={LocationForm} title="Create Location" />} />
                <Route path={ROUTES.LOCATION.UPDATE} element={<GuardedRoute permission="location_view" Component={LocationForm} title="Update Location" />} />
                <Route path={ROUTES.LOCATION.VIEW} element={<GuardedRoute permission="location_view" Component={ViewLocation} title="View Location" />} />
                <Route path={ROUTES.LOCATION.WORKING} element={<GuardedRoute permission="location_view" Component={ViewStaffLocation} title="View Location" />} />

                <Route path={ROUTES.SERVICES.CATEGORY.CREATE} element={<GuardedRoute permission="service_view" Component={CategoryForm} title="Create Category" />} />
                <Route path={ROUTES.SERVICES.CATEGORY.UPDATE} element={<GuardedRoute permission="service_view" Component={CategoryForm} title="Update Category" />} />

                <Route path={ROUTES.SERVICES.LIST} element={<GuardedRoute permission="service_view" Component={Services} title="Service" />} />
                <Route path={ROUTES.SERVICES.CREATE} element={<GuardedRoute permission="service_view" Component={ServiceForm} title="Create Service" />} />
                <Route path={ROUTES.SERVICES.UPDATE} element={<GuardedRoute permission="service_view" Component={ServiceForm} title="Update Service" />} />

                <Route path={ROUTES.STAFF.LIST} element={<GuardedRoute permission="staff_view" Component={Staff} title="Staff" />} />
                <Route path={ROUTES.STAFF.CREATE} element={<GuardedRoute permission="staff_view" Component={StaffForm} title="Create Staff" />} />
                <Route path={ROUTES.STAFF.UPDATE} element={<GuardedRoute permission="staff_view" Component={StaffForm} title="Update Staff" />} />
                <Route path={ROUTES.STAFF.VIEW} element={<GuardedRoute permission="staff_view" Component={StaffView} title="View Staff" />} />

                <Route path={ROUTES.APPEARANCE} element={<GuardedRoute permission="appearance_view" Component={Appearance} title="Appearance" />} />

                <Route path={ROUTES.PRODUCT.VIEW} element={<GuardedRoute permission="product_view" Component={ProductDetails} title="View Product" />} />
                <Route path={ROUTES.PRODUCT.LIST} element={<GuardedRoute permission="product_view" Component={Products} title="Product" />} />
                <Route path={ROUTES.PRODUCT.CREATE} element={<GuardedRoute permission="product_view" Component={ProductsForm} title="Create Product" />} />
                <Route path={ROUTES.PRODUCT.UPDATE} element={<GuardedRoute permission="product_view" Component={ProductsForm} title="Update Product" />} />
                <Route path={ROUTES.PRODUCT.CATEGORY.CREATE} element={<GuardedRoute permission="product_view" Component={ProductCategoryForm} title="Create Category" />} />
                <Route path={ROUTES.PRODUCT.CATEGORY.UPDATE} element={<GuardedRoute permission="product_view" Component={ProductCategoryForm} title="Update Category" />} />
            </Route>
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} />
        </Routes>
    );
};

export default MainRoutes;
