import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { allShopLocations, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import { Box, Edit01, Save01, Trash01, ArrowUp, ArrowDown, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { DURATIONS, IStaffFormData, initAction } from './Services.interface';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { axiosDelete, axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import InputWithLabel from 'src/components/InputWithLabel';
import { Checkbox } from 'flowbite-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import NoDataMessage from 'src/components/NoDataMessage';
import { Skeleton } from 'primereact/skeleton';

const StaffServiceTable = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(userMe);
    const shopLocationList = useAppSelector(allShopLocations);
    const [totalServices, setTotalServices] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState({});
    const [action, setAction] = useState(initAction);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [schema, setSchema] = useState(Yup.object());
    const [formData, setFormData] = useState<IStaffFormData>({
        rows: [],
        subRows: {},
    });
    const [selectedFilter, setSelectedFilter] = useState({
        shop_location_id: null,
    });
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_location_id: { value: null },
            shop_admin_id: { value: user.id },
        },
    });
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        shop_location_id: { value: null },
        shop_admin_id: { value: user.id },
    });

    const { control, handleSubmit, getValues, setValue, setError } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            rows: [],
            subRows: {},
        },
    });

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name');
        setLocationOptions(locationData);
    }, []);

    useEffect(() => {
        listServices();
    }, [lazyState, shop]);

    useEffect(() => {
        setSchema(generateSchema(formData));
    }, [formData]);

    const generateSchema = (data: IStaffFormData) => {
        const initSchema = Yup.array().of(
            Yup.object({
                price: Yup.number().positive(t('Price must be a positive value')).typeError(t('Price must be a number')).nullable(),
                duration: Yup.string().nullable(),
            }),
        );

        const subRowsSchema = Object.keys(data.subRows).reduce((rows: any, id: string) => {
            rows[id] = initSchema;
            return rows;
        }, {});

        return Yup.object({
            rows: initSchema,
            subRows: Yup.object().shape(subRowsSchema),
        });
    };

    const listServices = async () => {
        setIsLoading(true);
        await axiosGet(API.SERVICE.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setTotalServices(response.data.data.totalRecords);
                const services = response.data.data.data;
                const subRows: any = {};
                const rows = services.map((service: any) => {
                    if (service.variables.length) {
                        subRows[service.id] = service.variables.map((variable: any) => ({
                            id: variable.id,
                            name: variable.name,
                            description: variable.description,
                            type: variable.type,
                            parent_id: variable.parent_id,
                            duration: variable.price ? variable.price.duration : null,
                            price: variable.price ? variable.price.price : undefined,
                            starting_price: variable.price ? variable.price.starting_price : false,
                        }));
                    }
                    return {
                        id: service.id,
                        name: service.name,
                        description: service.description,
                        type: service.type,
                        parent_id: service.parent_id,
                        category: service.category,
                        variables: service.variables,
                        locations: service.locations,
                        duration: service.price ? service.price.duration : null,
                        price: service.price ? service.price.price : undefined,
                        starting_price: service.price ? service.price.starting_price : false,
                    };
                });
                setValue('rows', rows);
                setValue('subRows', subRows);
                setFormData({ ...formData, rows, subRows });
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const handleSave = (id: number) => async () => {
        setIsLoading(true);
        const values = getValues();
        let data = values.rows.find((row: any) => row.id === id);
        if (!data) {
            data = Object.values(values.subRows)
                .flatMap((subRow) => subRow)
                .find((row: any) => row.id === id);
        }
        const payload: any = {
            price: data.price,
            duration: data.duration,
            starting_price: data.starting_price,
        };
        await axiosPatch(API.SERVICE.STAFF_UPDATE, payload, { shop_id: shop.id, shop_service_id: id })
            .then(() => listServices())
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IStaffFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                } else {
                    toast.error(response.message);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : null;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleAction =
        (row: any, type: string = '') =>
        () => {
            if (!type) {
                //
            } else {
                setAction((old) => ({ ...old, [type]: true, id: row.id }));
            }
        };

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        shop_service_id: action.id,
                    };
                    await axiosDelete(API.SERVICE.STAFF_DELETE, {}, params)
                        .then(listServices)
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const onRowToggle = (event: any) => {
        setExpandedRows(event.data);
    };

    const onRowEditSave = (event: any) => {
        const {
            data: { id },
        } = event;
        handleSubmit(handleSave(id))();
    };

    const allowExpansion = (row: any) => row.variables.length > 0;
    const rowEditor = (row: any) => !row.variables.length;

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const getFields = (name: string, type: string, disabled: boolean = true) => {
        switch (type) {
            case 'action':
                return <Controller name={name} control={control} render={({ field: { onChange, value } }) => <Checkbox name={name} value={value} checked={value} onChange={onChange} />} />;
            case 'starting_price':
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => <Checkbox name={name} value={value} checked={value} disabled={disabled} onChange={onChange} />}
                    />
                );
            case 'duration':
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="max-w-[131px]">
                                <SelectBox
                                    name={name}
                                    isDisabled={disabled}
                                    options={DURATIONS}
                                    value={DURATIONS.find((duration) => duration.value === value)}
                                    onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                    classComp="outline-select-box"
                                    placeholder={t('Minutes')}
                                />
                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                );
            case 'price':
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="max-w-[131px]">
                                <InputWithLabel type="number" onChange={onChange} value={value} name={name} placeholder={t('Price')} disabled={disabled} />
                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                );
            default:
                break;
        }
    };

    const getType = (row: any) => {
        const color = row.type === 'variable' ? '#17B26A' : '#2E90FA';
        return (
            <p className="px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max">
                <GoDotFill size={12} color={color} />
                {row.type}
            </p>
        );
    };

    const getLocation = (row: any) => row.locations[0].name;
    const getCategories = (row: any) => row.category.name;

    const getDuration = (data: any, row: any) => (data.type === 'regular' || data.parent_id !== null ? getFields(`rows.${row.rowIndex}.${row.field}`, 'duration') : null);
    const getDuration2 = (id: number) => (data: any, row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'duration');
    const editorDuration = (row: any) => getFields(`rows.${row.rowIndex}.${row.field}`, 'duration', false);
    const editorDuration2 = (id: number) => (row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'duration', false);

    const getPrice = (data: any, row: any) => (data.type === 'regular' || data.parent_id !== null ? getFields(`rows.${row.rowIndex}.${row.field}`, 'price') : null);
    const getPrice2 = (id: number) => (data: any, row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'price');
    const editorPrice = (row: any) => getFields(`rows.${row.rowIndex}.${row.field}`, 'price', false);
    const editorPrice2 = (id: number) => (row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'price', false);

    const getStartingPrice = (data: any, row: any) => (data.type === 'regular' || data.parent_id !== null ? getFields(`rows.${row.rowIndex}.${row.field}`, 'starting_price') : null);
    const getStartingPrice2 = (id: number) => (data: any, row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'starting_price');
    const editorStartingPrice = (row: any) => getFields(`rows.${row.rowIndex}.${row.field}`, 'starting_price', false);
    const editorStartingPrice2 = (id: number) => (row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'starting_price', false);

    const getAction = (row: any) => (
        <CustomButton className="shadow-none w-4 !px-0" onClick={handleAction(row, 'delete')}>
            <Trash01 className="h-4 w-4 text-gray-600" />
        </CustomButton>
    );

    const rowExpansionTemplate = (data: any) => (
        <DataTable
            value={data.variables}
            showHeaders={false}
            editMode="row"
            onRowEditSave={onRowEditSave}
            rowEditorInitIcon={<Edit01 className="h-4 w-4 text-gray-600" />}
            rowEditorSaveIcon={<Save01 className="h-4 w-4 text-gray-600" />}
        >
            <Column expander={false} style={{ width: '30px', minWidth: '30px', maxWidth: '30px' }} />
            <Column
                field="name"
                header={t('Service name')}
                className="text-xs font-medium text-mainTextColor truncate"
                colSpan={3}
                style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
            ></Column>
            <Column expander={false} style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }} />
            <Column expander={false} style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }} />
            <Column field="description" header={t('Service description')} className="text-xs font-medium" style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}></Column>
            <Column
                field="location"
                header={t('Location')}
                className="text-xs font-semibold text-mainTextColor truncate"
                style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                body={data.locations[0].name}
            ></Column>
            <Column
                field="duration"
                header={t('Duration (minutes)')}
                body={getDuration2(data.id)}
                editor={editorDuration2(data.id)}
                style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
            ></Column>
            <Column field="price" header={t('Price')} body={getPrice2(data.id)} editor={editorPrice2(data.id)} style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}></Column>
            <Column
                field="starting_price"
                header={t('Starting price')}
                body={getStartingPrice2(data.id)}
                editor={editorStartingPrice2(data.id)}
                style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
            ></Column>
            <Column body={getAction} style={{ width: '50px', minWidth: '50px', maxWidth: '50px' }}></Column>
            <Column rowEditor style={{ width: '50px', minWidth: '50px', maxWidth: '50px' }} bodyStyle={{ textAlign: 'center' }}></Column>
        </DataTable>
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                type: 'loading',
                name: 'loading',
                category: 'loading',
                description: 'loading',
                locations: 'loading',
                staff: 'loading',
                action: 'loading',
                variables: ['loading'],
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div
            className={`w-full border border-gray-200 rounded-xl shadow staff_service_table datatable-custom-service flex-1 flex flex-col mt-5 ${
                getValues('rows').length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <form className="flex-1 flex flex-col">
                <div className="flex items-center flex-col ">
                    <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                        <div className="flex flex-col flex-1">
                            <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                                <h2 className="table-title">{t('All services')}</h2>
                                <p className="userCounter">
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {totalServices} {t('Services')}
                                </p>
                            </div>
                            <p className="table-subtitle">{t('View and manage your services.')}</p>
                        </div>
                        <div className="flex gap-3 xl:gap-4"></div>
                    </div>
                    <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 ">
                        <div className="table-searchInput">
                            <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                        </div>
                        <div className="w-[170px] xl:w-[200px]">
                            <SelectBox
                                options={locationOptions}
                                value={selectedFilter.shop_location_id}
                                placeholder={t('All locations')}
                                noOptionsMessage="No Locations Found"
                                onChangeFunc={handleFilter('shop_location_id')}
                                classComp="outline-select-box"
                            />
                        </div>
                    </div>
                </div>

                <DataTable
                    lazy
                    paginatorTemplate={customPaginatorTemplate}
                    value={isLoading ? skeletons : getValues('rows')}
                    totalRecords={totalServices}
                    paginator={!isLoading && totalServices > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    expandedRows={expandedRows}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onRowToggle={onRowToggle}
                    onRowEditSave={onRowEditSave}
                    rowEditorInitIcon={<Edit01 className="h-4 w-4 text-gray-600" />}
                    rowEditorSaveIcon={<Save01 className="h-4 w-4 text-gray-600" />}
                    editMode="row"
                    dataKey="id"
                    className=" rounded-b-md overflow-hidden"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <NoDataMessage
                            title={`${filters.global.value || filters.shop_location_id.value ? 'No Services Found.' : 'No Services Added.'}`}
                            description={`${
                                filters.global.value || filters.shop_location_id.value
                                    ? 'There are no services matching your search criteria. Try adjusting your filters or create a new service to list it here.'
                                    : 'Start by adding your brand’s first service and make it available for client bookings.'
                            }`}
                            iconComponent={filters.global.value || filters.shop_location_id.value ? <SearchLg className="text-gray-700" /> : <Box className="text-gray-700" />}
                        />
                    }
                >
                    <Column expander={isLoading ? false : allowExpansion} body={isLoading && <Skeleton />} className="table-arrow-btn" style={{ width: '30px', minWidth: '30px', maxWidth: '30px' }} />
                    <Column
                        field="name"
                        body={isLoading ? <Skeleton /> : undefined}
                        header={renderHeader(t('Service Name'), 'name')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                        style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                        sortable
                    ></Column>
                    <Column
                        field="type"
                        header={renderHeader(t('Type'), 'type')}
                        className="font-medium text-mainTextColor"
                        body={isLoading ? <Skeleton /> : getType}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        sortable
                    ></Column>
                    <Column
                        field="shop_categories.name"
                        body={isLoading ? <Skeleton /> : getCategories}
                        header={renderHeader(t('Category'), 'shop_categories.name')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        sortable
                    ></Column>
                    <Column
                        field="description"
                        body={isLoading ? <Skeleton /> : undefined}
                        header={renderHeader(t('Service description'), 'description')}
                        className="text-xs leading-[18px] font-normal text-gray-600 "
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        sortable
                    ></Column>
                    <Column
                        field="shop_locations.name"
                        header={renderHeader(t('Location'), 'shop_locations.name')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                        body={isLoading ? <Skeleton /> : getLocation}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        sortable
                    ></Column>
                    <Column
                        field="duration"
                        header={t('Duration (minutes)')}
                        body={isLoading ? <Skeleton /> : getDuration}
                        editor={editorDuration}
                        style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    ></Column>
                    <Column field="price" header={t('Price')} body={isLoading ? <Skeleton /> : getPrice} editor={editorPrice} style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}></Column>
                    <Column
                        field="starting_price"
                        header={t('Starting price')}
                        body={isLoading ? <Skeleton /> : getStartingPrice}
                        editor={editorStartingPrice}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    ></Column>
                    <Column
                        header={t('')}
                        className="text-xs font-medium"
                        body={isLoading ? <Skeleton /> : getAction}
                        style={{ width: '18px', minWidth: '18px', maxWidth: '18px' }}
                        bodyStyle={{ textAlign: 'right', alignItems: 'end', justifyContent: 'end', paddingLeft: '8px', paddingRight: '0px' }}
                    ></Column>
                    <Column
                        rowEditor={rowEditor}
                        body={isLoading && <Skeleton />}
                        style={{ width: '62px', minWidth: '62px', maxWidth: '62px' }}
                        bodyStyle={{ textAlign: 'end', alignItems: 'center', display: 'flex', justifyContent: 'end', paddingLeft: '4px', paddingRight: '4px' }}
                    ></Column>
                </DataTable>
            </form>
            {action.delete && <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete service')} description={t('Are you sure you want to delete service?')} />}
        </div>
    );
};

export default StaffServiceTable;
