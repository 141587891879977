import React, { useState } from 'react';
import { Checkbox, Label } from 'flowbite-react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Plus, XClose } from '@untitled-ui/icons-react/build/cjs';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { InputMask } from 'primereact/inputmask';
import { timeTypeOptions } from './Location.interface';

const WorkingHours = ({ errors, name = 'hours', isLoginStep = false, baseTabIndex = 0 }: any) => {
    const { t } = useTranslation();

    const { control, setValue, getValues } = useFormContext();

    const { fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name,
    });

    const valueWatch = useWatch({
        name,
        control,
    });

    const [lastUpdatedWorkingHours, setLastUpdatedWorkingHours] = useState({
        from_time_hours: '00:00',
        to_time_hours: '00:00',
        from_time_type: 'am',
        to_time_type: 'pm',
    });

    const handleTimeTypeChange = (onChange: any, field: string) => (event: any) => {
        const selectedTimeType = event.value;
        onChange(event.value);
        if (field === 'from') {
            setLastUpdatedWorkingHours((prevWorkingHours) => ({
                ...prevWorkingHours,
                from_time_type: selectedTimeType,
            }));
        } else {
            setLastUpdatedWorkingHours((prevWorkingHours) => ({
                ...prevWorkingHours,
                to_time_type: selectedTimeType,
            }));
        }
    };

    const handleTimeChange = (onChange: any, field: string) => (event: any) => {
        const value = event.target.value;
        onChange(value);
        if (field === 'from') {
            setLastUpdatedWorkingHours((prevWorkingHours) => ({
                ...prevWorkingHours,
                from_time_hours: value,
            }));
        } else {
            setLastUpdatedWorkingHours((prevWorkingHours) => ({
                ...prevWorkingHours,
                to_time_hours: value,
            }));
        }
    };

    return (
        <div>
            {fields.map((item: any, index: number) => (
                <label
                    className={`opening-hours-row ${fields?.length === index + 1 && !isLoginStep ? 'pb-0 border-0' : ''} ${!getValues(`${name}.[${index}].status`) ? 'cursor-pointer' : ''}`}
                    key={item.uuid}
                    htmlFor={!getValues(`${name}.[${index}].status`) ? `day_${item.day}_${name}` : ''}
                >
                    <div className="flex items-center gap-3 xl:gap-5 day-checkbox">
                        <Controller
                            key={`${name}.[${index}].status`}
                            name={`${name}.[${index}].status`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <>
                                    <Label className="cursor-pointer" htmlFor={`day_${item.day}_${name}`}>
                                        {value ? <XClose className="w-5 h-5 text-error-600" /> : <Plus className="w-5 h-5 text-primary" />}
                                    </Label>
                                    <Checkbox
                                        className="day-check-box"
                                        id={`day_${item.day}_${name}`}
                                        name={`${name}.[${index}].status`}
                                        value={value}
                                        checked={value}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setValue(`${name}[${index}].from_time_hours`, lastUpdatedWorkingHours.from_time_hours);
                                                setValue(`${name}[${index}].to_time_hours`, lastUpdatedWorkingHours.to_time_hours);
                                                setValue(`${name}[${index}].from_time_type`, lastUpdatedWorkingHours.from_time_type);
                                                setValue(`${name}[${index}].to_time_type`, lastUpdatedWorkingHours.to_time_type);
                                            } else {
                                                setValue(`${name}[${index}].from_time_hours`, '00:00');
                                                setValue(`${name}[${index}].to_time_hours`, '00:00');
                                                setValue(`${name}[${index}].from_time_type`, 'am');
                                                setValue(`${name}[${index}].to_time_type`, 'pm');
                                            }
                                            onChange(event);
                                        }}
                                    />
                                    <Label className={`day-checkbox-label capitalize cursor-pointer ${value ? 'text-gray-700' : 'text-primary'}`} htmlFor={`day_${item.day}_${name}`}>
                                        {item.day}
                                    </Label>
                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                </>
                            )}
                        />
                    </div>
                    {valueWatch?.[index]?.status ? (
                        <div className="flex flex-col flex-1 justify-end xl:items-end">
                            <div className="opening-hour-right ">
                                <span className="label-from">From</span>
                                <div
                                    className={`flex w-[123px] xl:w-[130px] h-[36px] border custom-hover-effect border-borderPrimary rounded-lg shadow-InputAndButton ${
                                        errors?.hours && errors?.hours[index] && errors?.hours[index]?.from_time_hours && errors?.hours[index]?.from_time_hours.message ? 'is-invalid' : ''
                                    }`}
                                >
                                    <Controller
                                        name={`${name}.[${index}].from_time_hours`}
                                        // name="time"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <InputMask
                                                id={`${name}.${index}.from_time_hours`}
                                                tabIndex={baseTabIndex * 14 + index * 2 + 1}
                                                className="w-[66px] pl-[14px] py-2.5 rounded-lg"
                                                placeholder="hh:mm"
                                                mask="99:99"
                                                value={value}
                                                onChange={handleTimeChange(onChange, 'from')}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={`${name}.[${index}].from_time_type`}
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div id={`${name}.${index}.from_time_type`} className="w-1/2">
                                                <SelectBox
                                                    name={`${name}.${index}.from_time_type`}
                                                    id="handlingUnit"
                                                    placeholder="AM"
                                                    classComp="time_select"
                                                    options={timeTypeOptions}
                                                    value={timeTypeOptions.find((option) => option.value === value)}
                                                    onChangeFunc={handleTimeTypeChange(onChange, 'from')}
                                                    isWorkingHoursSelect
                                                    isClearable={false}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>

                                <span className="label-to">to</span>
                                <div
                                    className={`flex w-[123px] xl:w-[130px] h-[36px] custom-hover-effect border border-borderPrimary rounded-lg shadow-InputAndButton ${
                                        errors?.hours && errors?.hours[index] && errors?.hours[index]?.to_time_hours && errors?.hours[index]?.to_time_hours.message ? 'is-invalid' : ''
                                    }`}
                                >
                                    <Controller
                                        name={`${name}.[${index}].to_time_hours`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <InputMask
                                                tabIndex={baseTabIndex * 14 + index * 2 + 2}
                                                id={`${name}.${index}.to_time_hours`}
                                                className="w-[66px] pl-[14px] py-2.5 rounded-lg"
                                                placeholder="hh:mm"
                                                mask="99:99"
                                                value={value}
                                                onChange={handleTimeChange(onChange, 'to')}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${name}.[${index}].to_time_type`}
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div id={`${name}.${index}.to_time_type`} className="w-1/2">
                                                <SelectBox
                                                    name="time_type"
                                                    id="handlingUnit"
                                                    placeholder="PM"
                                                    classComp="time_select"
                                                    options={timeTypeOptions}
                                                    value={timeTypeOptions.find((option) => option.value === value)}
                                                    onChangeFunc={handleTimeTypeChange(onChange, 'to')}
                                                    isWorkingHoursSelect
                                                    isClearable={false}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            {errors?.hours && errors?.hours[index] ? (
                                errors?.hours[index]?.from_time_hours && errors?.hours[index]?.from_time_hours.message ? (
                                    <p className="text-error w-[364px]">{errors.hours[index].from_time_hours.message}</p>
                                ) : errors?.hours[index]?.to_time_hours && errors?.hours[index]?.to_time_hours.message ? (
                                    <p className="text-error w-[364px]">{errors?.hours[index]?.to_time_hours?.message}</p>
                                ) : null
                            ) : null}
                        </div>
                    ) : (
                        <div className="flex xl:justify-end items-center flex-1 self-center">
                            <span className="text-xs leading-[18px] text-gray-500 font-semibold w-[364px]">{t('Not working on this day')}</span>
                        </div>
                    )}
                </label>
            ))}
        </div>
    );
};

export default WorkingHours;
