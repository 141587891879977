import React, { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getShortName } from 'src/utils/global-functions';
import { usePhone } from 'src/hooks/usePhone';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { selectCalendarData, setData } from '../../Calendar.slice';
import AddClient from './AddClient';
import ClientDetail from './ClientDetail';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';

const Client = () => {
    const dispatch = useAppDispatch();
    const calendarData = useAppSelector(selectCalendarData);
    const [isNewClient, setIsNewClient] = useState(false);
    const [clientArrayList, setClientArrayList] = useState<any[]>([]);
    const { t } = useTranslation();
    // const [defaultCard, setDefaultCard] = useState<any>(null);
    const shop = useAppSelector(currentShop);
    const { getCustomFormatPhone } = usePhone();
    const { control, setValue } = useFormContext();
    useEffect(() => {
        // calendarData.selectedClientInfo && getClientCard();
        getClientList();
    }, []);
    const getClientList = () => {
        axiosGet(API.CLIENT.ALL, { shop_id: shop.id }).then((response) => {
            const clientOptionData = response.data.data.map((item: any) => ({
                ...item,
                value: item.id,
                label: `${item.full_name ?? getCustomFormatPhone(item.phone, item.phone_country_code)}`,
                image: item?.profile_image_url,
                shortName: `${getShortName(item.full_name) ?? getCustomFormatPhone(item.phone, item.phone_country_code)}`,
            }));
            setClientArrayList(clientOptionData);
        });

        // const result = await dispatch(clientList(payload));
        // if (result.type === clientList.fulfilled.toString()) {
        //     const clientOptionData = result.payload.data.data.map((item: any) => ({
        //         ...item,
        //         value: item.id,
        //         label: `${item.full_name ?? getCustomFormatPhone(item.phone, item.phone_country_code)}`,
        //         image: item?.profile_image_url,
        //         shortName: `${getShortName(item.full_name) ?? getCustomFormatPhone(item.phone, item.phone_country_code)}`,
        //     }));
        //     setClientArrayList(clientOptionData);
        // }
    };
    // const getClientCard = async () => {
    //     const data = {
    //         shop_id: shop.id,
    //         id: calendarData?.selectedClientInfo?.id,
    //     };

    //     await axiosGet(API.CLIENT.CARD_LIST, data).then((response) => {
    //         let filterData = response.data.data.cards.filter((items: any) => items.id === response.data.data.default_card_id);
    //         setDefaultCard(filterData[0]);
    //     });
    //     //     const result = await dispatch(clientCardList(data));
    //     //     if (result.type === clientCardList.fulfilled.toString()) {
    //     //         let filterData = result.payload.data.cards.filter((items: any) => items.id === result.payload.data.default_card_id);
    //     //         setDefaultCard(filterData[0]);
    //     //     }
    // };
    const handleSelectChange = (selectedOptionInfo: any) => {
        setIsNewClient(false);
        setValue('user_id', selectedOptionInfo?.id ?? null);
        dispatch(setData({ selectedClientInfo: selectedOptionInfo }));
    };
    const handleNewClient = (selectedOptionInfo: any) => {
        const newClient: any = {
            ...selectedOptionInfo,
            value: selectedOptionInfo.id,
            label: selectedOptionInfo.full_name ?? getCustomFormatPhone(selectedOptionInfo.phone, selectedOptionInfo.phone_country_code),
            image: selectedOptionInfo?.profile_image_url,
            shortName: getShortName(selectedOptionInfo.full_name) ?? getCustomFormatPhone(selectedOptionInfo.phone, selectedOptionInfo.phone_country_code),
        };
        setClientArrayList((prevClientArrayList) => [...prevClientArrayList, newClient]);
        setTimeout(() => {
            setValue('user_id', newClient.id ?? null);
            dispatch(setData({ selectedClientInfo: newClient }));
        }, 300);
    };

    return (
        <div className="mb-4">
            {calendarData.selectedClientInfo && !calendarData.calendarStep.newAppointment && !calendarData.calendarStep.updateAppointment && !calendarData.calendarStep.rescheduleAppointment ? (
                <ClientDetail />
            ) : (
                <>
                    <div className="mb-4">
                        <Controller
                            name="user_id"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                // <div className="w-full">
                                //     <SelectBox
                                //         isSearchable
                                //         name="user_id"
                                //         id="newclient"
                                //         errorText={!!errors?.user_id}
                                //         options={clientArrayList}
                                //         onChangeFunc={handleSelectChange}
                                //         className=""
                                //         placeholder="Search or add new client"
                                //     />
                                //     {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                // </div>
                                <>
                                    <div className="flex justify-between">
                                        <label htmlFor="newclient" className="fl-field-title-label text-gray-700 text-xs font-semibold leading-[18px] mb-1.5">
                                            Select a client
                                        </label>
                                        {!value && (
                                            <CustomButton
                                                type="button"
                                                onClick={() => setIsNewClient(true)}
                                                className="!text-xs text-primary font-semibold flex items-center gap-1.5 h-[15px] shadow-none pr-0.5 "
                                                icon={<Plus width="16" />}
                                            >
                                                Add new client
                                            </CustomButton>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <SelectBox
                                            name="user_id"
                                            options={clientArrayList}
                                            onChangeFunc={handleSelectChange}
                                            value={(clientArrayList.length > 0 && clientArrayList.find((client: any) => client.value === value)) || null}
                                            placeholder={t('Search or add new client')}
                                            isClearable
                                            error={error}
                                        />
                                        {error?.type && error.type !== 'required' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                                    </div>
                                </>
                            )}
                        />
                    </div>
                </>
            )}
            {isNewClient && <AddClient getClientList={getClientList} handleSelectChange={handleNewClient} setIsNewClient={setIsNewClient} />}
        </div>
    );
};

export default memo(Client);
