import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceAcademy } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import moment from 'moment';
import { convertBase64ToFile } from 'src/utils/global-functions';
import { s3Path } from 'src/constants/s3Path';
import { s3Upload } from 'src/utils/s3Operations';
import { AcademyProps, IFormData } from './Academy.interface';
import TemplateLayout from '../Layout/Layout';
import ImageBoxView from '../../Image/ImageBoxView';
import ImageInput from '../../Image/ImageInput';
import { allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import RangeDateSelect from 'src/components/RangeDateSelect/RangeDateSelect';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import CropperModal from 'src/components/CropperModal/CropperModal';

const Academy: FC<AcademyProps> = ({ template }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [image, setImage] = useState<any>('');
    const [isUpload, setIsUpload] = useState(false);
    const [fieldName, setFieldName] = useState<string>('');
    const onChangeRange = (dates: any) => {
        const [start, end] = dates;
        const startDate = start ? moment(start).format('YYYY-MM-DD') : null;
        const endDate = end ? moment(end).format('YYYY-MM-DD') : null;
        const result = endDate || startDate ? `${startDate ?? ''} - ${endDate ?? ''}` : '';
        setValue('date', result);
    };

    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template_preview')?.value?.academy;
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const shopId = shop.id;
    const schema = Yup.object({
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        title: Yup.string()
            .nullable()
            .when('is_title', ([is_title], customSchema) => (is_title ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        price: Yup.string().required(t('This field is required')),
        date: Yup.string()
            .nullable()
            .when('status', ([status], customSchema) => (status ? customSchema.required(t('This field is required')) : customSchema.nullable()))
            .test('is-valid-range', 'Both start and end dates are required', (value: any) => {
                const [startDate, endDate] = value?.split(' - ') ?? [];
                return startDate ? (endDate ? true : false) : true;
            }),
        is_button: Yup.bool().required(t('This field is required')),
        button: Yup.string()
            .nullable()
            .when('is_button', ([is_button], customSchema) => (is_button ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        is_description: Yup.bool().required(t('This field is required')),
        description: Yup.string()
            .nullable()
            .when('is_description', ([is_description], customSchema) => (is_description ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        image: Yup.object()
            .shape({
                file: Yup.mixed().nullable(),
                url: Yup.string().nullable(),
                name: Yup.string().required(t('This field is required')),
            })
            .test('image-validation', t('This field is required'), function (value) {
                if (!value) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                const { name, url } = value;
                if (!name || !url) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                return true;
            }),
    });

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            status: !!shopSettingInfo?.status,
            is_title: !!shopSettingInfo?.is_title,
            title: shopSettingInfo?.title || '',
            price: shopSettingInfo?.price || '',
            date: shopSettingInfo?.date || '',
            is_button: !!shopSettingInfo?.is_button,
            button: shopSettingInfo?.button || '',
            is_description: !!shopSettingInfo?.is_description,
            description: shopSettingInfo?.description || '',
            image: shopSettingInfo?.image
                ? {
                      name: shopSettingInfo.image,
                      url: shopSettingInfo.image && shopSettingInfo.image_url,
                  }
                : null,
        },
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');

    const imageData = watch('image');
    const handleChange = async (data: IFormData) => {
        setIsLoading(true);
        let imageStatus = errorCode.updateSuccess;
        if (imageData?.file && imageData?.name) {
            const logoImageResponse: any = await s3Upload(imageData?.file, `${s3Path.SHOP_ACADEMY}${imageData?.name}`);
            imageStatus = logoImageResponse?.status;
        }

        if (imageStatus === errorCode.updateSuccess) {
            const payload = {
                academy: { ...data, image: data?.image?.name },
                section: 'academy',
            };
            try {
                const response = await axiosPost(API.THEME.TEMPLATE.SECTION, payload, {
                    shop_id: shopId,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(getAllShopSettings({ shop_id: shop.id }));

                    return;
                }
                if (response.data.status === errorCode.unprocessable) {
                    if (response.data.data) {
                        Object.keys(response.data.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data.data[field][0],
                            });
                        });
                    }
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsLoading(false);
            }
        }
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    const onChangeCrop = (field: any) => (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setIsUpload(false);
        };
        reader.onloadend = () => {
            setFieldName(field);
            setImage(reader.result as any);
            setIsUpload(true);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };
    const imageUpload = async (data: any) => {};

    const handleRemoveImage = () => {
        setValue('image', null);
    };

    const handleCropData = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fieldName) {
            setValue('image', {
                file: convertedFile.convertedFile as File,
                url: data,
                name: convertedFile.filename,
            });
        }
    };

    useFormErrorFocus<IFormData>({ errors, formSectionName: 'academy' });
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange)} className="h-full">
                <Collapse
                    title={t('Acadamy')}
                    description={t('The Academy section provides information about training programs or  courses offered by your brand.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange)}
                >
                    <TemplateLayout ImgProp={AppearanceAcademy}>
                        <div id="academy-image" className="flex gap-4">
                            <div className="flex gap-4 mb-4">
                                <Controller
                                    name={`image`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div>
                                            {value && value.url ? (
                                                <ImageBoxView item={value} name="image" onChangeCrop={onChangeCrop} handleRemoveImage={handleRemoveImage} />
                                            ) : (
                                                <ImageInput error={error} onChangeCrop={onChangeCrop} name="image" title="Academy Picture" />
                                            )}

                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex-1 flex flex-col gap-4 mb-3">
                                <Controller
                                    name={`title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Title')}
                                                id={`academy-title`}
                                                required
                                                placeholder={t('Enter acadamy title')}
                                                onChange={onChange}
                                                value={value}
                                                name={'title'}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_title"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />

                                <div className="flex gap-3 xl:gap-4 ">
                                    <Controller
                                        name="price"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        label={t('Price')}
                                                        id="academy-price"
                                                        required
                                                        placeholder={t('Enter acadamy price')}
                                                        onChange={onChange}
                                                        value={value}
                                                        name={'price'}
                                                        error={!!error}
                                                        className={'!mb-2'}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div id="academy-date" className="w-full">
                                                {/* <label className={`fl-field-title-label `}>{t('Date')}</label> */}
                                                <RangeDateSelect
                                                    onChange={onChangeRange}
                                                    selected={value && value.split(' - ')[0] ? new Date(value.split(' - ')[0]) : null}
                                                    minDate={new Date()}
                                                    startDate={value && value.split(' - ')[0] ? new Date(value.split(' - ')[0]) : null}
                                                    endDate={value && value.split(' - ')[1] ? new Date(value.split(' - ')[1]) : null}
                                                    selectsRange
                                                    label="Date"
                                                    classInput={` ${error ? 'is-invalid' : ''}`}
                                                    className={'!mb-2'}
                                                />

                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="button"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label={t('Button')}
                                                    id="academy-button"
                                                    required
                                                    placeholder={t('Enter button name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    name="button"
                                                    toggle={
                                                        <Controller
                                                            name="is_button"
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>

                                <Controller
                                    name={`description`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name={`description`}
                                                id={`academy-description`}
                                                label={t('Description')}
                                                textArea
                                                required
                                                placeholder={t('Enter acadamy Description')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                rows={15}
                                                maxLenght={275}
                                                textAearClassName="rounded-md h-[100px]"
                                                toggle={
                                                    <Controller
                                                        name="is_description"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        {isUpload && fieldName && (
                            <CropperModal
                                imageUpload={imageUpload}
                                imageUrl={image}
                                setUpload={setIsUpload}
                                setCropData={handleCropData}
                                defaultCropType="Square"
                                title={t('Brand Logo')}
                                description={t('Upload a  max 800Kb image for best results.')}
                                isCropType={false}
                                btnTitle={t('Confirm')}
                            />
                        )}
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Academy;
