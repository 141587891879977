import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import InputWithLabel from 'src/components/InputWithLabel';
import { IUpdateEmailField, IUpdateEmailFieldKey, IUpdateEmailPayload, IUpdateEmailVerifyPayload } from './Profile.interface';
import OTPField from 'src/components/OTPField';
import { me } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import PopupModal from 'src/components/PopupModal';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';

const UpdateEmail = ({ handleClose }: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isVerify, setIsVerify] = useState(false);
    const [counter, setCounter] = useState(60);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        email: Yup.string().email(t('Valid Gmail Address Needed')).required(t('This field is required')),
    }).required();

    const { handleSubmit, control, setError, watch } = useForm<IUpdateEmailField>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            otp: new Array(4).fill(''),
        },
    });

    const emailValue = watch('email');

    useEffect(() => {
        const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const handleSave = async (data: IUpdateEmailField) => {
        setIsLoading(true);
        if (isVerify) {
            const payload: IUpdateEmailVerifyPayload = {
                ...data,
                otp: data.otp ? data.otp.join('') : '',
            };
            await axiosPatch(API.USER.VERIFY_EMAIL, payload)
                .then(async (response) => {
                    dispatch(me());
                    handleClose();
                })
                .catch((error: any) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            let message = '';
                            Object.keys(response.data).forEach((field) => {
                                message = response.data[field][0];
                                setError(field as IUpdateEmailFieldKey, {
                                    type: 'manual',
                                    message: message,
                                });
                                return;
                            });
                        }
                        return;
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            const asyncFunction = handlerSendCode(data);
            asyncFunction();
        }
    };

    const handlerSendCode = (data: IUpdateEmailField) => async () => {
        setIsLoading(true);
        const payload: IUpdateEmailPayload = {
            email: data.email,
        };
        await axiosPatch(API.USER.UPDATE_EMAIL, payload)
            .then(async (response) => {
                setIsVerify(true);
                setCounter(60);
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as IUpdateEmailFieldKey, {
                                type: 'manual',
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <PackagePlus className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('Email update')}</p>
                <span className="text-xs font-normal text-gray-500">{t('Update the email linked to your account.')}</span>
            </div>
        </div>
    );

    return (
        <PopupModal
            dismissible
            title={!isVerify && <Title />}
            onClose={handleClose}
            size="w-[480px]"
            secondaryButton={t('Cancel')}
            primaryButton={t('Continue')}
            acceptAction={handleSubmit(handleSave)}
            declineAction={handleClose}
            isLoading={isLoading}
            isCrossSign={isVerify && false}
        >
            <form onSubmit={handleSubmit(handleSave)}>
                <div className="flex flex-wrap modal-input-field">
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full">
                                {isVerify && (
                                    <div className="flex flex-col gap-8 text-center">
                                        <div className="flex flex-col">
                                            <p className="text-mainTextColor leading-[28px] text-lg font-semibold">{t('Please check your email.')}</p>
                                            <div className="text-secondaryTxtColor leading-[20px] text-sm font-normal">
                                                We have sent a code to <span className="font-semibold">{emailValue}.</span>
                                            </div>
                                        </div>
                                        <div className=" mx-12">
                                            <OTPField otp={value} setOtp={onChange} errors={error} />
                                        </div>
                                        <div>
                                            <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px] mb-2">{t("Haven't received the code yet?")}</p>
                                            {counter ? (
                                                <span className="leading-[19.6px] text-sm font-bold text-mainTextColor">
                                                    {t('Send again')}
                                                    <span className="leading-5 text-primary text-sm font-bold">({counter}sec)</span>
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={handlerSendCode({
                                                        email: emailValue,
                                                    })}
                                                    className="text-primary cursor-pointer leading-[19.6px] text-sm font-bold text-mainTextColor"
                                                >
                                                    {t('Send again')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full">
                                {!isVerify && (
                                    <>
                                        <InputWithLabel name="email" label={t('Email')} placeholder={t('Email')} value={value} onChange={onChange} error={!!error} />
                                    </>
                                )}
                            </div>
                        )}
                    />
                </div>
            </form>
        </PopupModal>
    );
};

export default UpdateEmail;
