import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import InputWithLabel from 'src/components/InputWithLabel';
import Number from 'src/components/Number';
import { errorCode } from 'src/constants/errorCode';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { CountryCode } from 'libphonenumber-js';
import { IAddClient } from '../Sidebar.Interface';
import { useTranslation } from 'react-i18next';
import { currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import PopupModal from 'src/components/PopupModal';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { UsersPlus } from '@untitled-ui/icons-react/build/cjs';
import { usePhone } from 'src/hooks/usePhone';

type FieldKey = 'first_name' | 'last_name' | 'phone' | 'phone_country_code' | 'email' | 'gender';
interface IAddClientForm {
    first_name: string;
    last_name: string;
    phone: string;
    phone_country_code: string;
    gender: string;
    email: string;
}
const AddClient: FC<IAddClient> = ({ getClientList, handleSelectChange, setIsNewClient }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const { isPhoneValid } = usePhone();

    const schema = Yup.object({
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
        gender: Yup.string().required('This field is required'),
        email: Yup.string().required('This field is required'),
        phone: Yup.string()
            .required(t('This field is required'))
            .test({
                message: t('Invalid phone number'),
                name: 'invalid',
                test: function (value) {
                    const { phone_country_code: phoneCountryCode } = this.parent;
                    const isValid = isPhoneValid(value, phoneCountryCode);
                    return isValid;
                },
            }),
        phone_country_code: Yup.string().required('This field is required'),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        trigger,
    } = useForm<IAddClientForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: '',
            last_name: '',
            phone: '',
            gender: 'male',
            email: '',
            phone_country_code: 'US',
        },
    });
    const handleNumberChange = (country: CountryCode, phone: string, code: string, isValid: boolean) => {
        setValue(`phone`, `+${code}${phone}`);
        setValue(`phone_country_code`, country);
        trigger(['phone', 'phone_country_code']);
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const payload = { ...data, is_book_with_card: true };

        axiosPost(API.CLIENT.CREATE, payload, { shop_id: shop.id })
            .then(async (response) => {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
                handleSelectChange(response.data.data);
                setIsNewClient(false);
                return;
            })
            .catch((error) => {
                if (error.response.data.status === errorCode.unprocessable) {
                    if (error.response.data.data) {
                        Object.keys(error.response.data.data).forEach((field) => {
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: error.response.data.data[field][0],
                            });
                        });
                    }

                    return;
                }
                toast.error(error.response.data.message);
            })
            .finally(() => setIsLoading(false));
    };
    const Title = () => (
        <div className="flex flex-row gap-3 items-center">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <UsersPlus className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">Add a New Client</p>
                <span className="text-xs font-normal leading-[18px]">{t('Please fill out the client information below.')}</span>
            </div>
        </div>
    );

    return (
        <PopupModal
            dismissible
            onClose={() => setIsNewClient(false)}
            size="w-[570px]"
            className="px-5 py-5 flex"
            title={<Title />}
            primaryButton={t('Continue')}
            acceptAction={handleSubmit(onSubmit)}
            isLoading={isLoading}
            declineAction={() => {}}
        >
            <div className="relative flex flex-1 w-full">
                <form className="w-full">
                    <div className="flex flex-row gap-3 w-full">
                        <div className=" w-1/2 mb-4">
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <>
                                        <InputWithLabel
                                            label={t('First name')}
                                            placeholder="Enter Client’s First Name"
                                            onChange={onChange}
                                            value={value}
                                            name="first_name"
                                            error={!!errors.first_name}
                                        />
                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                        <div className=" w-1/2 mb-4">
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <>
                                        <InputWithLabel label={t('Last Name')} placeholder="Enter Client’s Last Name" onChange={onChange} value={value} name="last_name" error={!!errors.last_name} />
                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-3">
                        <div className="flex flex-col w-1/2 ">
                            <label htmlFor="phone" className="fl-field-title-label">
                                Phone Number
                            </label>
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <>
                                        <div className="flex flex-col ">
                                            <Number getNumber={onChange} errors={error} onNumberChange={handleNumberChange} />
                                        </div>
                                        {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                        <div className="flex flex-col w-1/2">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <>
                                        <InputWithLabel label="Email" placeholder="Enter member’s email" onChange={onChange} value={value} error={!!errors.email} name="email" />
                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* <div className="mb-4 flex">
                        <Button type="button" onClick={() => setIsNewClient(false)} className="fl-btn btn_outline_black mr-1 w-1/2 justify-center font-bold">
                            Cancel
                        </Button>
                        <Button type="button" onClick={handleSubmit(onSubmit)} className="fl-btn btn_primary ml-1 w-1/2">
                            Submit
                        </Button>
                    </div> */}
                </form>
            </div>
        </PopupModal>
    );
};

export default AddClient;
