import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/path';
import { onError } from 'src/utils/global-functions';

const LocationBox = ({ location }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const remainingCount = staffList.length - visibleStaff.length;

    const handleClick = () => {
        navigate(`${PATH.LOCATION.VIEW}/${location.id}`);
    };

    return (
        <div className="w-full">
            <div className="location-card p-4 cursor-pointer" onClick={handleClick}>
                <div className="2xl:h-[200px] h-[194px] min-h-[194px] 2xl:min-h-[200px] w-full  grow-0 shrink-0  rounded-xl overflow-hidden ">
                    <img src={location.profile_image_url} alt={location.name} title={location.name} className="h-full w-full object-cover" onError={onError} />
                </div>
                <div className="flex-auto overflow-hidden ">
                    <div className="w-full flex flex-col gap-[6px]">
                        <h2 className="text-base font-semibold leading-[140%] text-gray-700 truncate">{location.name}</h2>
                        <p className="text-sm font-normal leading-[18px]  text-gray-500 truncate">
                            {location.street}, {location.city}, {location.country}
                        </p>
                        {/* <span className="text-sm font-normal text-gray-500">{getFormatPhone(location.phone, location.phone_country_code)}</span> */}
                    </div>
                    <div className="flex mt-auto pt-4 justify-between ">
                        <div className="w-1/2">
                            <h3 className="location-card-heading mb-1">Total Clients</h3>
                            <div className=" text-sm font-medium truncate  text-secondaryTxtColor">{location.total_users > 0 ? location.total_users : t('No clients')}</div>
                        </div>
                        <div className="text-right w-1/2">
                            <h3 className="location-card-heading mb-1">Tax District</h3>
                            <div className="text-sm font-medium truncate  text-secondaryTxtColor">{location.sales_tax.territory ?? '-'}</div>
                        </div>
                    </div>

                    {/* <div>
                        <h3 className="location-card-heading mb-1">Staff</h3>
                        <div className="flex items-center ">
                            <div className="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-2 -gap-[10px] ">
                                {visibleStaff.map((staff: any) =>
                                    staff.profile_image_url ? (
                                        <img
                                            key={staff.id}
                                            src={staff.profile_image_url}
                                            alt={staff.profile_image}
                                            width="48"
                                            height="48"
                                            className="w-6 h-6 rounded-full bg-gray-100 border-2 border-white"
                                        />
                                    ) : (
                                        <span
                                            className="w-6 h-6 rounded-full border-2 border-white flex justify-center items-center bg-gray-100 text-secondaryTxtColor text-xs leading-[18px] font-semibold "
                                            key={staff.id}
                                        >
                                            {getShortName(`${staff.first_name ?? ''} ${staff.last_name ?? ''}`)}
                                        </span>
                                    ),
                                )}
                                <div className="h-6 rounded-full  bg-gray-100 border-2 border-white text-secondaryTxtColor text-xs leading-[18px] font-semibold flex items-center justify-center">
                                    {remainingCount === 0 ? 'No team members' : remainingCount > 0 ? `+${remainingCount}` : 'No team members'}
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default LocationBox;
