import React from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { GoogleIcon, InstagramColor, SocialIcon } from 'src/theme/Images';
import { useAppSelector } from 'src/redux/hooks';
import { currentRole } from 'src/redux/services/common/Common.slice';
import { OWNER_RIGHTS_ROLES } from 'src/constants/common';

const OnlineLinks = ({ errors }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const role = useAppSelector(currentRole);
    const hasOwnerPermission = OWNER_RIGHTS_ROLES.includes(role?.name);
    return (
        <>
            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]  flex flex-col">
                    <p className="title-text">{t('Social Media Links')}</p>
                    <p className="subtitle-text">{t('Add links to your brand’s social media accounts.')}</p>
                </div>
                <div className="flex flex-col max-2xl:w-[400px] w-[512px] ">
                    <Controller
                        name="instagram_page"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full pt-1 pb-5">
                                <InputWithLabel
                                    placeholder="Instagram page"
                                    onChange={onChange}
                                    value={value}
                                    name="instagram_page"
                                    id="instagram_page"
                                    error={!!error}
                                    disabled={!hasOwnerPermission}
                                    labelIcon={
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <InstagramColor />
                                            <p className="text-xs font-medium text-mainTextColor">instagram.com/</p>
                                        </div>
                                    }
                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                    inputControlClassName="pl-3"
                                />
                            </div>
                        )}
                    />
                    <Controller
                        name="facebook_page"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full pb-5">
                                <InputWithLabel
                                    placeholder="Facebook page"
                                    onChange={onChange}
                                    value={value}
                                    name="facebook_page"
                                    id="facebook_page"
                                    disabled={!hasOwnerPermission}
                                    labelIcon={
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <SocialIcon />
                                            <p className="text-xs font-medium text-mainTextColor">facebook.com/</p>
                                        </div>
                                    }
                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                    inputControlClassName="pl-3"
                                    error={!!error}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        name="google_review"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="relative w-full brand_social pb-4">
                                <InputWithLabel
                                    placeholder="Google review page"
                                    onChange={onChange}
                                    disabled={!hasOwnerPermission}
                                    value={value}
                                    name="google_review"
                                    id="google_review"
                                    labelIcon={
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <GoogleIcon />
                                            <p className="text-xs font-medium text-mainTextColor">google.com/</p>
                                        </div>
                                    }
                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                    inputControlClassName="pl-3"
                                    error={!!error}
                                />
                                {errors && errors?.google_review && <p className="text-error">{errors?.google_review?.message}</p>}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row gap-[32px] border-b  border-borderSecondary py-4">
                <div className=" w-[310px]  flex flex-col">
                    <p className="title-text">{t('Additional Admin Accounts')}</p>
                    <p className="subtitle-text">{t('Invite team members with admin privileges.')}</p>
                </div>
                <div className="flex flex-col max-2xl:w-[400px] w-[512px] ">
                    <Controller
                        name="admin_emails"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div id="admin_emails" className={`max-2xl:w-[400px] w-[512px] !h-auto hover:border-black ${errors.admin_emails ? 'is-invalid' : ''}`}>
                                <Tags
                                    placeholder="Add admin emails"
                                    settings={{
                                        maxTags: 4,
                                        dropdown: {
                                            enabled: 0,
                                        },
                                        callbacks: {
                                            add: (e) => {
                                                onChange(e.detail.tagify.value);
                                            },
                                            remove: (e) => {
                                                onChange(e.detail.tagify.value);
                                            },
                                        },
                                    }}
                                    value={value}
                                    readOnly={!hasOwnerPermission}
                                    className="rounded-lg h-[36px]"
                                />
                            </div>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default OnlineLinks;
