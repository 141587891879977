import React, { useEffect, useRef, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import About from './About/About';
import Location from './Location/Location';
import Team from './Team/Team';
import Service from './Service/Service';
import Product from './Product/Product';
import Testimonial from './Testimonial/Testimonial';
import Academy from './Academy/Academy';
import Footer from './Footer/Footer';
import Gallery from './Gallery/Gallery';
import Hero from './Hero/Hero';
import { SectionIdentifier, SectionRefs, TemplateSettingsProps } from './TemplateSettings.interface';

// const TemplateSettings = FC<{}> () => {
const TemplateSettings: FC<TemplateSettingsProps> = ({ template }) => {
    const { t } = useTranslation();
    const [currentSectionRef, setCurrentSectionRef] = useState<SectionIdentifier | null>(null);
    const [isScrollable, setIsScrollable] = useState<boolean>(false);
    const sectionRefs: SectionRefs = {
        hero: useRef<HTMLDivElement>(null),
        about_us: useRef<HTMLDivElement>(null),
        locations: useRef<HTMLDivElement>(null),
        professionals: useRef<HTMLDivElement>(null),
        services: useRef<HTMLDivElement>(null),
        course: useRef<HTMLDivElement>(null),
        gallery: useRef<HTMLDivElement>(null),
        testimonials: useRef<HTMLDivElement>(null),
        products: useRef<HTMLDivElement>(null),
        language: useRef<HTMLDivElement>(null),
        footer: useRef<HTMLDivElement>(null),
    };

    const handleClick = (value: SectionIdentifier) => {
        const ref = sectionRefs[value];
        if (ref?.current) {
            setIsScrollable(true);
            setCurrentSectionRef(value);
        }
    };
    const debounce = (func: (...args: any[]) => void, wait: number): ((...args: any[]) => void) => {
        let timeout: number | undefined; // Use undefined as the initial state

        return function (...args: any[]): void {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = window.setTimeout(later, wait); // Use window.setTimeout for clarity in a browser environment
        };
    };

    useEffect(() => {
        const handleScroll = () => {
            const templateSettingsWrap = document.getElementById('templateSettingsWrap');
            if (templateSettingsWrap) {
                const wrapTopOffset = templateSettingsWrap.getBoundingClientRect().top + window.scrollY;
                const scrollPosition = window.scrollY - wrapTopOffset;

                let closestSection = null;
                let smallestDistance = Infinity;

                Object.entries(sectionRefs).forEach(([key, ref]) => {
                    if (ref.current) {
                        const { offsetTop } = ref.current;
                        // Calculate distance from the section's top to the current scroll position
                        const distance = Math.abs(scrollPosition - offsetTop);
                        if (distance < smallestDistance) {
                            smallestDistance = distance;
                            closestSection = key;
                        }
                    }
                });

                if (closestSection && closestSection !== currentSectionRef) {
                    setIsScrollable(false);
                    setCurrentSectionRef(closestSection as SectionIdentifier);
                }
            }
        };
        // Debounce the scroll event handler to improve performance
        const debouncedHandleScroll = debounce(handleScroll, 100);

        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [sectionRefs]);

    useEffect(() => {
        if (isScrollable && currentSectionRef && sectionRefs[currentSectionRef]?.current) {
            sectionRefs[currentSectionRef]!.current!.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentSectionRef]);

    const Menus = [
        { label: 'Hero', value: 'hero' },
        { label: 'Locations', value: 'locations' },
        { label: 'About', value: 'about_us' },
        { label: 'Team', value: 'professionals' },
        { label: 'Services', value: 'services' },
        { label: 'Gallery', value: 'gallery' },
        { label: 'Academy', value: 'course' },
        { label: 'Products', value: 'products' },
        { label: 'Testimonials', value: 'testimonials' },
        { label: 'Footer', value: 'footer' },
    ];

    return (
        <div className="w-full flex flex-col h-full mt-5 border-t pt-5 appearance-switch-btn">
            <div className="fl-data-block">
                <div className="title-block w-[310px] sticky top-[136px] flex flex-col h-full">
                    <h3 className="title-text">{t('Template configuration')}</h3>
                    <p className="subtitle-text">{t('Configure the specific sections and content that will be add to your booking website.')}</p>

                    <div className="TemplateSettingsNavWrap  sticky top-[105px]">
                        <p className="text-[10px] uppercase font-semibold text-gray-400 leading-[18px] mb-1">Sections</p>
                        <ul className="TemplateSettingsNavList flex flex-col text-sm mb-3">
                            {Menus.map((item, index) => (
                                <li key={index} onClick={() => handleClick(item.value as SectionIdentifier)} className={currentSectionRef === item.value ? 'active cusror-pointer' : 'cusror-pointer'}>
                                    <>
                                        {/* <i className="icon">
                                            <img src={item.icon} alt={item.text} />
                                        </i> */}
                                        <span className="text">{item.label}</span>
                                    </>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="data-wrap-block space-y-5 w-full max-w-[1180px] mb-4" id="templateSettingsWrap">
                    <div ref={sectionRefs.hero} className="rounded-xl border border-borderPrimary ">
                        <Hero template={template} />
                    </div>
                    <div ref={sectionRefs.locations} className="">
                        <Location template={template} />
                    </div>
                    <div ref={sectionRefs.about_us} className="rounded-xl border border-borderPrimary">
                        <About template={template} />
                    </div>

                    <div ref={sectionRefs.professionals} className="">
                        <Team template={template} />
                    </div>
                    <div ref={sectionRefs.services} className="">
                        <Service template={template} />
                    </div>
                    <div ref={sectionRefs.gallery} className="">
                        <Gallery template={template} />
                    </div>
                    <div ref={sectionRefs.course} className="rounded-xl border border-borderPrimary">
                        <Academy template={template} />
                    </div>
                    <div ref={sectionRefs.products} className="">
                        <Product template={template} />
                    </div>
                    <div ref={sectionRefs.testimonials} className="">
                        <Testimonial template={template} />
                    </div>
                    <div ref={sectionRefs.footer} className="rounded-xl border border-borderPrimary">
                        <Footer template={template} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemplateSettings;
