import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/path';
import { allShopLocations, allShopStaff, currentShop } from 'src/redux/services/common/Common.slice';
import { Box, Edit01, Trash01, ArrowUp, ArrowDown, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { initAction } from './Services.interface';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import NoDataMessage from 'src/components/NoDataMessage';
import { ROUTES } from 'src/constants/routes';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { useRolePermission } from 'src/hooks/useRolePermission';
import { Skeleton } from 'primereact/skeleton';
import { AllLocationsOptions, AllTeamMembersOptions, perPageOptions } from 'src/utils/global-variables';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';

const INIT_STATE = {
    FILTER: {
        shop_location_id: null,
        shop_admin_id: null,
    },
    LAZY_STATE: {
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_location_id: { value: null },
            shop_admin_id: { value: null },
        },
    },
};
const BrandServicesTable = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const allShopStaffList = useAppSelector(allShopStaff);
    const { hasPermission } = useRolePermission();
    const [totalServices, setTotalServices] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [serviceListArray, setServiceListArray] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [action, setAction] = useState(initAction);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedFilter, setSelectedFilter] = useState(INIT_STATE.FILTER);
    const [lazyState, setLazyState] = useState<any>(INIT_STATE.LAZY_STATE);
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        shop_location_id: { value: null },
        shop_admin_id: { value: null },
    });

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        setStaffOptions([AllTeamMembersOptions, ...staff]);
        setSelectedFilter(INIT_STATE.FILTER);
        setLazyState(INIT_STATE.LAZY_STATE);
    }, [shop]);

    useEffect(() => {
        listServices();
    }, [lazyState, shop]);

    const listServices = async () => {
        setIsLoading(true);
        await axiosGet(API.SERVICE.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setServiceListArray(response.data.data.data);
                setTotalServices(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const handleClick = (path: string) => () => {
        navigate(path);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleAction =
        (row: any, type: string = '') =>
        () => {
            if (!type) {
                navigate(`${PATH.SERVICES.UPDATE}/${row.id}`);
            } else {
                setAction((old) => ({ ...old, [type]: true, id: row.id }));
            }
        };

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        id: action.id,
                    };
                    await axiosDelete(API.SERVICE.DELETE, {}, params)
                        .then(listServices)
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const onRowToggle = (event: any) => {
        setExpandedRows(event.data);
    };

    const allowExpansion = (row: any) => row.variables.length > 0;

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const getType = (row: any) => {
        const color = row.type === 'variable' ? '#17B26A' : '#2E90FA';
        return (
            <p className="px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max">
                <GoDotFill size={12} color={color} />
                {row.type}
            </p>
        );
    };

    const getLocation = (row: any) => row.locations[0].name;
    const getCategories = (row: any) => row.category.name;
    const getServiceDescription = (row: any) => <p className="line-clamp-2">{row.description}</p>;

    const getStaff = (row: any) => {
        const visibleStaff = row.staff.slice(0, 4);
        const remainingCount = row.staff.length - visibleStaff.length;
        if (row.type === 'variable' && row.parent_id === null) {
            return <div className="flex justify-end sm:justify-start  -space-x-2 -gap-[10px] "></div>;
        }
        return (
            <div className="flex justify-end sm:justify-start  -space-x-2 -gap-[10px] ">
                {visibleStaff.map((staff: any) =>
                    staff.profile_image_url ? (
                        <img key={staff.id} src={staff.profile_image_url} alt={staff.profile_image} width="48" height="48" className="w-7 h-7 rounded-full bg-gray-100 border-2 border-white" />
                    ) : (
                        <span className="w-7 h-7 rounded-full border-2 border-white flex justify-center items-center bg-primary text-white text-[10px] " key={staff.id}>
                            {getShortName(`${staff.first_name ?? ''} ${staff.last_name ?? ''}`)}
                        </span>
                    ),
                )}
                {row.staff.length === 0 && t('-')}
                {remainingCount !== 0 && (
                    <div className="w-7 h-7 rounded-full  bg-gray-200 border-2 border-white text-secondaryTxtColor text-xs  font-semibold  flex items-center justify-center">
                        {`+${remainingCount}`}
                    </div>
                )}
            </div>
        );
    };

    const getAction = (row: any) => {
        if ((row.type === 'variable' && row.parent_id === null) || row.type === 'regular') {
            return (
                <div className="flex items-center gap-4 justify-end">
                    <CustomButton className="shadow-none w-4 !px-0 text-gray-600 hover:text-gray-900 !pr-[4px]" onClick={handleAction(row)}>
                        <Edit01 className="h-4 w-4" />
                    </CustomButton>
                    <CustomButton className="shadow-none text-gray-600 hover:text-gray-900 w-4 !px-0" onClick={handleAction(row, 'delete')}>
                        <Trash01 className="h-4 w-4 " />
                    </CustomButton>
                </div>
            );
        }
        return null;
    };

    const rowExpansionTemplate = (data: any) => (
        <DataTable value={data.variables} showHeaders={false}>
            <Column expander={false} style={{ width: '30px', minWidth: '30px', maxWidth: '30px', paddingRight: '0px' }} />
            <Column
                field="name"
                header={t('Service name')}
                className="text-xs font-medium text-mainTextColor truncate"
                colSpan={3}
                style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
            ></Column>
            <Column expander={false} style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }} />
            <Column expander={false} style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }} />
            <Column
                field="description"
                header={t('Service description')}
                className="text-xs leading-[18px] font-normal text-gray-600"
                style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
            ></Column>
            <Column
                field="location"
                header={t('Location')}
                className="text-xs font-semibold text-mainTextColor truncate"
                style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                body={data.locations[0].name}
            ></Column>
            <Column field="staff" header={t('Team members')} className="text-xs font-medium truncate" body={getStaff} style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}></Column>
            <Column field="action" header={t('Action')} className="text-xs font-medium" style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }} body={getAction}></Column>
        </DataTable>
    );
    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                type: 'loading',
                name: 'loading',
                staff_count: 'loading',
                description: 'loading',
                shop_categories: { name: 'loading' },
                shop_locations: { name: 'loading' },
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div
            className={`w-full border border-gray-200 rounded-xl shadow staff_service_table datatable-custom-service flex-1 flex flex-col ${
                serviceListArray.length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className={`flex items-center w-full flex-col`}>
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                            <h2 className="table-title">{t('All Services')}</h2>
                            <p className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                {totalServices} {t('Services')}
                            </p>
                        </div>
                        <p className="table-subtitle">{t('Add, view and manage your different brand services.')}</p>
                    </div>
                    <div className="flex gap-3">
                        {hasPermission('service_add') && (
                            <>
                                {/* <CustomButton secondary onClick={handleClick(ROUTES.CATEGORY.CREATE)} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                                    {t('Add new category')}
                                </CustomButton> */}
                                <CustomButton outlinePrimary onClick={handleClick(ROUTES.SERVICES.CREATE)} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                                    {t('Add new service')}
                                </CustomButton>
                            </>
                        )}
                    </div>
                </div>
                <div className="flex items-center px-5 py-3 justify-between w-full ">
                    <div className="table-searchInput">
                        <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                    </div>
                    <div className="flex gap-3">
                        {/* <div className="flex justify-start gap-1 bg-gray-50 p-1 rounded-lg border border-gray-200 max-h-9">
                                <CustomButton type="button" onClick={() => {}} className={`!px-2 !py-1 w-full max-w-[252px] shadow-none text-sm font-bold !text-blue-700 !bg-blue-50 uppercase`}>
                                    en
                                </CustomButton>
                                <CustomButton type="button" onClick={() => {}} className={`!px-2 !py w-full max-w-[252px] shadow-none text-sm font-bold text-gray-500 uppercase`}>
                                    fr
                                </CustomButton>
                            </div> */}
                        {/* <div className="flex rounded-lg w-fit h-[36px] currency-tab quoting-currency ">
                            <div className="border border-borderPrimary border-r-0 bg-gray-100 text-gray-900 cursor-pointer text-xs font-semibold last:rounded-r-md last:border-r first:rounded-l-md text-center  w-[65px] text-textSecondary  inline">
                                <div className="xl:px-4 lg:px-4 sm:px-3 px-2   lg:h-[34px] h-[34px] flex items-center uppercase justify-center">
                                    <span>en</span>
                                </div>
                            </div>

                            <div className="border border-borderPrimary border-r-0  cursor-pointer text-xs font-semibold last:rounded-r-md last:border-r first:rounded-l-md text-center text-grayLight900 w-[65px] text-gray-700 bg-white inline">
                                <div className="xl:px-4 lg:px-4 sm:px-3 px-2 lg:h-[34px] h-[34px] flex items-center uppercase justify-center">
                                    <span>fr</span>
                                </div>
                            </div>
                        </div> */}
                        <div className="w-[170px] xl:w-[200px]">
                            <SelectBox
                                options={locationOptions}
                                value={locationOptions.find((option) => option.value === selectedFilter.shop_location_id)}
                                noOptionsMessage="No Locations Found"
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('shop_location_id')}
                                classComp="outline-select-box"
                                isClearable={false}
                                isSearchable={false}
                            />
                        </div>
                        <div className="w-[170px] xl:w-[200px]">
                            <SelectBox
                                options={staffOptions}
                                noOptionsMessage="No Team Found"
                                value={staffOptions.find((option) => option.value === selectedFilter.shop_admin_id)}
                                placeholder={t('All Team members')}
                                onChangeFunc={handleFilter('shop_admin_id')}
                                classComp="outline-select-box"
                                isClearable={false}
                                isSearchable={false}
                            />
                        </div>
                        <div className="w-[70px] xl:w-[70px] page-dropdown">
                            <SelectBox
                                name="page"
                                options={perPageOptions}
                                isClearable={false}
                                isSearchable={false}
                                onChangeFunc={onPageHandle}
                                value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                classComp="outline-select-box"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={customPaginatorTemplate}
                value={isLoading ? skeletons : serviceListArray}
                totalRecords={totalServices}
                paginator={!isLoading && totalServices > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                rowExpansionTemplate={rowExpansionTemplate}
                expandedRows={expandedRows}
                onRowToggle={onRowToggle}
                dataKey="id"
                className="border-t rounded-b-md overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value ? (
                        <NoDataMessage
                            title={`${filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value ? 'No Services Found.' : 'No Services Added.'}`}
                            description={`${
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value
                                    ? 'There are no services matching your search criteria. Try adjusting your filters or create a new service to list it here.'
                                    : 'Start by adding your brand’s first service and make it available for client bookings.'
                            }`}
                            iconComponent={
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value ? <SearchLg className="text-gray-700" /> : <Box className="text-gray-700" />
                            }
                        />
                    ) : (
                        <div className="flex h-full w-full justify-center items-center">
                            <NoDataMessage
                                title="No Services Found."
                                description="Start by adding your brand’s first service and make it available for client bookings."
                                buttonText="Create Services"
                                onButtonClick={() => navigate(ROUTES.SERVICES.CREATE)}
                            />
                        </div>
                    )
                }
            >
                <Column
                    expander={isLoading ? false : allowExpansion}
                    body={isLoading && <Skeleton />}
                    className="table-arrow-btn"
                    style={{ width: '30px', minWidth: '30px', maxWidth: '30px', paddingRight: '0px' }}
                />
                <Column
                    field="name"
                    header={renderHeader(t('Service Name'), 'name')}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                    style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                    sortable
                ></Column>
                <Column
                    field="type"
                    header={renderHeader(t('Type'), 'type')}
                    className="font-medium text-mainTextColor"
                    body={isLoading ? <Skeleton /> : getType}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    sortable
                ></Column>
                <Column
                    field="shop_categories.name"
                    header={renderHeader(t('Category'), 'shop_categories.name')}
                    body={isLoading ? <Skeleton /> : getCategories}
                    className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    sortable
                ></Column>
                <Column
                    field="description"
                    header={renderHeader(t('Service description'), 'description')}
                    body={isLoading ? <Skeleton /> : getServiceDescription}
                    className="text-xs leading-[18px] font-normal text-gray-600 "
                    style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    sortable
                ></Column>
                <Column
                    field="shop_locations.name"
                    header={renderHeader(t('Location'), 'shop_locations.name')}
                    className="text-xs leading-[18px] font-semibold text-mainTextColor truncate"
                    body={isLoading ? <Skeleton /> : getLocation}
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    sortable
                ></Column>
                <Column
                    field="staff_count"
                    header={renderHeader(t('Team members'), 'staff_count')}
                    className="text-xs font-medium truncate"
                    body={isLoading ? <Skeleton /> : getStaff}
                    style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                    sortable
                ></Column>
                <Column field="action" className="text-xs font-medium" style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }} body={isLoading ? <Skeleton /> : getAction}></Column>
            </DataTable>

            {action.delete && <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete service')} description={t('Are you sure you want to delete service?')} />}
        </div>
    );
};

export default BrandServicesTable;
