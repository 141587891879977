import React from 'react';
import NoDataMessage from 'src/components/NoDataMessage';
import { MarkerPin04, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import SLocation from '../Skeleton/Location/SLocation';
import LocationBox from './LocationBox';

const AdminLocation = ({ loading, locations, searchValue }: any) =>
    !loading && locations.length === 0 ? (
        <NoDataMessage
            title={`${searchValue ? 'No Locations Found' : 'No Locations Added.'}`}
            description={`${
                searchValue
                    ? 'There are no locations matching your search criteria. Try adjusting your filters or add a new location to list it here.'
                    : 'Start by adding your brand’s first location and make it available for client bookings.'
            }`}
            iconComponent={searchValue ? <SearchLg className="text-gray-700" /> : <MarkerPin04 className="text-gray-700" />}
        />
    ) : loading ? (
        <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
            <SLocation />
        </div>
    ) : (
        <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
            {locations.map((location: any) => (
                <LocationBox location={location} key={location.id} />
            ))}
        </div>
    );

export default AdminLocation;
