import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import Map from 'src/components/Map/Map';
import { ILocation } from './Location.interface';

const scriptId = 'google-maps-api';
const LocationMap = ({ errors, address, setAddress }: any) => {
    const { t } = useTranslation();
    const { setValue, getValues, watch, trigger } = useFormContext();
    const [isUpdated, setIsUpdated] = useState(true);
    const [location, setLocation] = useState<ILocation>({
        fullAddress: '',
        city: getValues('city'),
        country: getValues('country'),
        street: getValues('street'),
        postal_code: getValues('postal_code'),
        latitude: getValues('latitude'),
        longitude: getValues('longitude'),
    });
    const city = watch('city');
    const country = watch('country');
    const street = watch('street');
    const postalCode = watch('postal_code');
    const latitude = watch('latitude');
    const longitude = watch('longitude');

    const [isValid, setIsValid] = useState(true);
    const [status, setStatus] = useState(document.getElementById(scriptId) ? true : false);

    window.initMap = () => {
        setStatus(true);
    };

    useEffect(() => {
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places,marker&loading=async&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (isUpdated) {
                setLocation((prev) => ({
                    ...prev,
                    city: city,
                    country: country,
                    street: street,
                    postal_code: postalCode,
                    latitude: latitude,
                    longitude: longitude,
                }));
                setIsUpdated(false);
            }
        }, 2000);
    }, [city, country, street, postalCode, latitude, longitude]);

    const handleChange = (value: any) => {
        if (value.type === 'blur') {
            if (!location.fullAddress && value.target.value) {
                setIsValid(false);
            }
            return;
        } else {
            setIsValid(true);
            setAddress(value);
        }
        setLocation(value);

        setValue('country', value.country);
        setValue('city', value.city);
        setValue('street', value.address1);
        setValue('postal_code', value.postal);
        setValue('latitude', value.latitude);
        setValue('longitude', value.longitude);
        trigger(['country', 'city', 'street', 'postal_code', 'latitude', 'longitude']);
    };

    return (
        <div className="flex gap-5 flex-wrap">
            <div>
                {status && (
                    <AutoCompleteGoogle
                        inputName="address"
                        className="form_control w-[400px]"
                        placeholder={t('Enter location address')}
                        value={location.fullAddress || address}
                        error={!isValid || !!errors.latitude}
                        onChangeFunc={handleChange}
                        onBlur={handleChange}
                    />
                )}
                {/* {!isValid && <p className="text-error">{t('Please select valid address')}</p>} */}
                {/* {errors.latitude && <p className="text-error">{errors.latitude.message}</p>} */}
            </div>

            {isValid && status && location.latitude && location.longitude && (
                <div className="w-[400px] h-[160px]">
                    <Map id="address" height={160} latitude={location.latitude} longitude={location.longitude} onReverseAddressFunc={handleChange} isCurrentLocation draggable />
                </div>
            )}
        </div>
    );
};

export default LocationMap;
