import React from 'react';
import NoDataMessage from 'src/components/NoDataMessage';
import { MarkerPin04, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import SLocation from '../Skeleton/Location/SLocation';
import { onError } from 'src/utils/global-functions';
import { PATH } from 'src/constants/path';
import { useNavigate } from 'react-router-dom';

const StaffLocation = ({ loading, locations, searchValue }: any) => {
    const navigate = useNavigate();
    const handleClick = (locationData: any) => () => {
        navigate(`${PATH.LOCATION.VIEW}/${locationData.id}`);
    };
    return !loading && locations.length === 0 ? (
        <NoDataMessage
            title={`${searchValue ? 'No Locations Found' : 'No Locations Added.'}`}
            description={`${
                searchValue
                    ? 'There are no locations matching your search criteria. Try adjusting your filters or add a new location to list it here.'
                    : 'Start by adding your brand’s first location and make it available for client bookings.'
            }`}
            iconComponent={searchValue ? <SearchLg className="text-gray-700" /> : <MarkerPin04 className="text-gray-700" />}
        />
    ) : loading ? (
        <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
            <SLocation isShow={true} />
        </div>
    ) : (
        <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
            {locations.map((location: any) => (
                <>
                    <div className="w-full">
                        <div className="location-card p-4 ">
                            <div className="2xl:h-[200px] h-[194px] min-h-[194px] 2xl:min-h-[200px] w-full  grow-0 shrink-0  rounded-xl overflow-hidden cursor-pointer" onClick={handleClick(location)}>
                                <img src={location.profile_image_url} alt={location.name} title={location.name} className="h-full w-full object-cover" onError={onError} />
                            </div>
                            <div className="flex-auto overflow-hidden ">
                                <div className="w-full flex flex-col gap-[6px]">
                                    <h2 className="text-base font-semibold leading-[140%] text-gray-700 truncate">{location.name}</h2>
                                    <p className="text-sm font-normal leading-[18px]  text-gray-500 truncate">
                                        {location.street}, {location.city}, {location.country}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ))}
        </div>
    );
};

export default StaffLocation;
