import React from 'react';

interface IProps {
    title: string;
    subtitle: string;
    titleGray?: string;
    children?: React.ReactNode;
}
const PageHeader = ({ title, subtitle, children, titleGray }: IProps) => (
    <div className="flex justify-between items-start  xxl:pt-5 pt-4 top-0 w-full sticky  bg-white z-[11] side-spaching">
        <div className="flex justify-between items-start mb-4 gap-6 pb-4 border-b border-[#d0d5dd] w-full bg-white z-[89]">
            <div>
                <h1 className="title_text_page">
                    {title} {titleGray && <span className="text-gray-500">{titleGray}</span>}{' '}
                </h1>
                <p className="subtitle_text_page">{subtitle}</p>
            </div>
            {children && <div className="flex gap-4 mt-auto">{children}</div>}
        </div>
    </div>
);

export default PageHeader;
