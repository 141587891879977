import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';

import { format } from 'date-fns';
import { ArrowLeft, ArrowRight, CalendarDate, Clock } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import { IDatePickerWithArrow } from './DatePickerWithArrow.interface';

const DatePickerWithArrow = React.forwardRef(
    (
        {
            inputName,
            placeholder,
            dateFormat,
            className,
            onKeyDown,
            required,
            label,
            labelClassName,
            isFormate = true,
            tabIndex,
            onChangeValue,
            value = new Date(),
            disabled = false,
            labelEndIcon,
            labelIconClassName,
            InputClass,
            readOnly = false,
            isTime = false,
            timeIntervals = 15,
            timeCaption = 'Time',
            isRange = false,
            startDate = null,
            endDate = null,
            ...props
        }: IDatePickerWithArrow,
        ref: React.ForwardedRef<any>,
    ) => {
        const datePickerRef = useRef<DatePicker | null>(null);
        const [startDateState, setStartDateState] = useState(value);
        const [endDateState, setEndDateState] = useState(endDate);
        const onChangeDate = (date: any) => {
            if (disabled) {
                return true;
            }

            if (isRange) {
                const [start, end] = date;
                setStartDateState(start);
                setEndDateState(end);
                onChangeValue([start, end]);
                return;
            } else if (date) {
                if (isFormate) {
                    const formattedDate = format(date, dateFormat);
                    onChangeValue(formattedDate);
                } else {
                    onChangeValue(date);
                }
            }
        };
        const nextDate = () => {
            !disabled && onChangeDate(moment(value).add(1, 'days').toDate());
        };

        const prevDate = () => {
            !disabled && onChangeDate(moment(value).subtract(1, 'days').toDate());
        };
        const getFormattedDate = (date: any) => {
            const momentDate = moment(date).startOf('day');
            const today = moment().startOf('day');
            const yesterday = today.clone().subtract(1, 'days');
            const tomorrow = today.clone().add(1, 'days');

            switch (true) {
                case momentDate.isSame(today, 'day'):
                    return `Today, ${momentDate.format('MMM Do, YYYY')}`;
                case momentDate.isSame(yesterday, 'day'):
                    return `Yesterday, ${momentDate.format('MMM Do, YYYY')}`;
                case momentDate.isSame(tomorrow, 'day'):
                    return `Tomorrow, ${momentDate.format('MMM Do, YYYY')}`;
                default:
                    return momentDate.format('dddd, MMM Do, YYYY');
            }
        };
        return (
            <>
                <div className="flex items-center gap-[10px] mb-[6px]">
                    <label htmlFor={''} className={`fl-field-title-label flex-col leading-5 mb-0 ${labelClassName}`}>
                        {label}
                        {required && <span className="text-red-600">*</span>}
                    </label>
                </div>
                <div className={`input-group  date-field-block date-picker-group w-full ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${InputClass}`}>
                    <div className={`flex items-center  pl-[10px] pr-2  font-normal text-textSecondary ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${labelIconClassName}`} onClick={prevDate}>
                        {isTime && <span className="text">{isTime ? <Clock className="text-gray-700 w-[18px] h-5" /> : <CalendarDate className="text-gray-700 w-5" />}</span>}
                        {!isTime && <ArrowLeft className={`xxl:w-4 xxl:h-4 xls:w-3 xls:h-3 w-4 h-4 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} />}
                    </div>

                    <DatePicker
                        ref={datePickerRef}
                        placeholderText={placeholder}
                        onChange={onChangeDate}
                        className={`datepicker_control min-h-[34px] pl-0 ${className}`}
                        tabIndex={tabIndex}
                        id={props.id}
                        onKeyDown={(e) => {
                            if (e?.keyCode === 9 || e?.which === 9) {
                                if (datePickerRef.current) {
                                    datePickerRef.current.setOpen(false);
                                }
                            }
                        }}
                        selected={value ? new Date(value) : null}
                        value={value}
                        autoComplete="off"
                        name={inputName}
                        dropdownMode="select"
                        popperPlacement="top-end"
                        {...props}
                        disabled={disabled}
                        readOnly={readOnly}
                        showTimeSelect={isTime}
                        showTimeSelectOnly={isTime}
                        timeIntervals={timeIntervals}
                        timeCaption={isTime ? timeCaption : ''}
                        dateFormat={dateFormat}
                        startDate={startDateState}
                        endDate={endDateState}
                        selectsRange={isRange}
                        customInput={
                            !isTime && (
                                <div className={`flex flex-1 justify-center items-center `}>
                                    <CalendarDate className={`w-4 h-4 xl:mr-2 mr-1 ${!startDate && !endDate ? 'text-gray-900' : ''}`} />
                                    <div className={`text-textSecondary xl:text-xs xls:text-[11px] text-xs font-medium leading-none ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}}`}>
                                        {getFormattedDate(new Date(value))}
                                    </div>
                                </div>
                            )
                        }
                    />
                    <div className={`flex items-center  pl-[10px] pr-2  font-normal text-textSecondary ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${labelIconClassName}`} onClick={nextDate}>
                        {!isTime && <ArrowRight className={`xxl:w-4 xxl:h-4 xls:w-3 xls:h-3 w-4 h-4 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} />}
                    </div>
                </div>
            </>
        );
    },
);

export default DatePickerWithArrow;
