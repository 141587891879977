import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICountStep } from 'src/components/Stepper/Stepper.interface';
import { ROUTES } from 'src/constants/routes';
import { logout } from 'src/utils/global-functions';

interface IProps {
    handleStep: (value: 'prev' | 'next') => () => void;
    activeStep: ICountStep;
}

const Footer: React.FC<IProps> = ({ handleStep, activeStep }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleClick = (status: boolean) => () => {
        if (status) {
            navigate(ROUTES.DASHBOARD);
        } else {
            logout();
        }
    };

    return (
        <footer className="w-[420px] xl:w-[480px] flex bottom-0 justify-between items-center p-8 bg-gray-100">
            {/* {activeStep !== 1 ? (
                <button className="flex items-center text-mainTextColor leading-6 font-semibold" onClick={handleStep('prev')}>
                    <FaAngleLeft size={18} className="mr-1" />
                    Back To
                </button>
            ) : (
                <div></div>
            )} */}
            <div></div>
            <button className="text-mainTextColor text-base font-semibold" onClick={handleClick(pathname === ROUTES.INVITE || pathname === ROUTES.MULTI_BRAND)}>
                {pathname === ROUTES.INVITE || pathname === ROUTES.MULTI_BRAND ? t('Dashboard') : t('Back to home')}
            </button>
        </footer>
    );
};
export default Footer;
