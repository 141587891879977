import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppearanceGallery } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { convertBase64ToFile } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import { GalleryProps, IFormData } from './Gallery.interface';
import TemplateLayout from '../Layout/Layout';
import ImageInput from '../../Image/ImageInput';
import ImageBoxView from '../../Image/ImageBoxView';
import { allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import CustomButton from 'src/components/CustomButton';

const Gallery: FC<GalleryProps> = ({ template }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template_preview')?.value?.gallery;
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const shopId = shop.id;
    const schema = Yup.object({
        status: Yup.bool().required(t('This field is required')),
        image: Yup.array()
            .test({
                name: 'image',
                message: 'Please add at least one image',
                test: function (value) {
                    const { status } = this.parent;
                    if (status && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string().required(t('This field is required')),
                        file: Yup.mixed().nullable(),
                        url: Yup.string().nullable(),
                    })
                    .test('image-validation', t('This field is required'), function (value) {
                        if (!value) {
                            return this.createError({
                                path: `${this.path}`,
                                message: t('This field is required'),
                            });
                        }
                        const { name, url } = value;
                        if (!name || !url) {
                            return this.createError({
                                path: `${this.path}`,
                                message: t('This field is required'),
                            });
                        }
                        return true;
                    }),
            )
            .nullable()
            .when('status', ([status], customSchema) => (status ? customSchema.required() : customSchema.nullable())),
    });

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            status: false,
            image: null,
        },
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');
    const imageWatch = watch('image');

    const handleChange = async (data: any) => {
        setIsLoading(true);
        let imageStatus = errorCode.updateSuccess;
        const imagePromises = imageWatch?.map((image: any) => (image?.file && image?.name ? s3Upload(image.file, `${s3Path.SHOP_GALLERY}${image.name}`) : Promise.resolve(null)));

        if (imagePromises) {
            const imageResponses = await Promise.allSettled(imagePromises);
            const isSuccess = imageResponses.every((response: any) => response.status === 'fulfilled' && (response.value?.status === errorCode.updateSuccess || response?.value === null));
            if (isSuccess) {
                imageStatus = errorCode.updateSuccess;
            }
        }

        if (imageStatus === errorCode.updateSuccess) {
            submitForm(data);
        } else {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (shopSettingInfo) {
            const updatedImages = shopSettingInfo?.images?.map((image: any, index: number) => {
                if (image) {
                    return {
                        name: image,
                        url: (image && shopSettingInfo.urls[index]) ?? null,
                    };
                }
                return null;
            });

            setValue('status', shopSettingInfo.status);
            setValue('image', updatedImages);
        }
    }, [shopSettingInfo]);

    const submitForm = async (data: any) => {
        const payload = {
            gallery: {
                status: data?.status,
                images: data?.image?.map((image: any) => image?.name),
            },
            section: 'gallery',
        };

        await axiosPost(API.THEME.TEMPLATE.SECTION, payload, {
            shop_id: shopId,
        })
            .then(async (response) => {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
                return;
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };
    const onChangeCrop = (fieldName: any) => (e: any) => {
        e.preventDefault();
        let files;

        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const promises = Array.from(files).map(
            (file: any) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const newFile = convertBase64ToFile(reader.result);
                        if (newFile && newFile.filename && newFile.convertedFile) {
                            resolve({
                                file: newFile.convertedFile as File,
                                url: reader.result as string,
                                name: newFile.filename,
                            });
                        } else {
                            reject(new Error('Invalid file'));
                        }
                    };
                    reader.onerror = () => reject(reader.error);
                    reader.readAsDataURL(file);
                }),
        );
        Promise.all(promises).then((results: any) => {
            setValue('image', [...(imageWatch || []), ...results]);
        });

        e.target.value = null;
    };
    const handleRemoveImage = (index: number) => () => {
        const image: any = imageWatch?.filter((_: any, i: number) => i !== index);
        setValue('image', image);
    };
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange)} className={`h-full ${errors.image ? 'is-invalid rounded-xl border' : 'rounded-xl border border-borderPrimary'}`}>
                <Collapse
                    title={t('Gallery')}
                    description={t('The Gallery section showcases a collection of photos displaying your work and creativity.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange)}
                >
                    <TemplateLayout ImgProp={AppearanceGallery}>
                        <div className="flex gap-4 mb-4 flex-wrap">
                            <Controller
                                name="image"
                                control={control}
                                render={({ field: { value }, fieldState: { error } }: any) => (
                                    <>
                                        {value && Array.isArray(value) && (
                                            <>
                                                {value.map((item: any, index: number) => (
                                                    <ImageBoxView item={item} name="image" onChangeCrop={onChangeCrop} handleRemoveImage={handleRemoveImage(index)} key={index} isEdit={false} />
                                                ))}
                                            </>
                                        )}
                                        <ImageInput error={error} onChangeCrop={onChangeCrop} name="image" title="Gallery Picture" />
                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Gallery;
