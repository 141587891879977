import React from 'react';
import { IInputWithToggle } from './IInputWithToggle.interface';

const InputWithToggle: React.FC<IInputWithToggle> = ({
    error,
    onChange,
    value,
    name,
    placeholder,
    label,
    type,
    index,
    readOnly,
    className,
    toggleValue,
    toggleClassName,
    setselected,
    disabled = false,
    max,
}): JSX.Element => {
    const toggleChange = (val: string) => {
        setselected(val);
    };
    return (
        <div className={` flex flex-col  w-full`}>
            {type !== 'hidden' && label && (
                <label htmlFor={name} className="fl-field-title-label mb-1.5">
                    {label}
                </label>
            )}
            <div className={`flex items-center h-[36px] relative input-group  border ${error && 'is-invalid'}`}>
                <input
                    className={`form_input_control !pl-2  ${className} ${error && 'is-invalid'} `}
                    type={type || 'text'}
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => onChange(e, index)}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    max={max}
                />
                <div className="flex items-center justify-center gap-1 mr-2">
                    <span
                        className={`flex items-center justify-center font-normal w-5 h-5 text-xs leading-[18px] basis-5 text-gray-500 grow-0 shrink-0 rounded-full cursor-pointer ${
                            toggleValue === '$' ? toggleClassName : ''
                        } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={() => !disabled && toggleChange('$')}
                        role="button"
                        aria-disabled={disabled}
                    >
                        $
                    </span>

                    <span
                        className={`flex items-center justify-center font-normal w-5 h-5 text-xs leading-[18px] basis-5 text-gray-500 grow-0 shrink-0 rounded-full cursor-pointer ${
                            toggleValue === '%' ? toggleClassName : ''
                        } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={() => !disabled && toggleChange('%')}
                        role="button"
                        aria-disabled={disabled}
                    >
                        %
                    </span>
                </div>
            </div>
        </div>
    );
};

export default InputWithToggle;
