import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import { defaultImage } from 'src/theme/Images';
import SCurrentInventory from './SCurrentInventory';
import { PATH } from 'src/constants/path';

const SProductDetails = () => {
    const { t } = useTranslation();
    return (
        <div className="flex-1 flex flex-col mb-4">
            <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                <div className="flex items-center justify-between w-full">
                    <Link to={PATH.PRODUCT.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5">
                        <ArrowLeft className="w-4 cursor-pointer text-gray-600" /> {t('Back to Product')}
                    </Link>
                </div>
            </div>
            <div className="flex flex-col flex-1">
                <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                    <div className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px] custom-loading">
                        <img src={defaultImage} alt="" />
                    </div>
                    <div className="flex justify-between items-center w-full gap-2">
                        <div className="flex gap-2">
                            <div className="flex flex-col">
                                <div className="flex flex-row">
                                    <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] custom-loading">Concealer</h1>
                                </div>
                                <p className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center custom-loading">
                                    Concealer is a versatile cosmetic product designed to mask dark circles,
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-t border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 xlm:px-8 px-5">
                    <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_342px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8">
                        <div className="fl-tab-btn-view w-full">
                            <div className="w-[785px] h-[25px]">
                                {[...Array(4)].map((_$, index) => (
                                    <button key={index} type="button" className={`w-full`}></button>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="mb-8">
                                <div className="flex justify-between items-center gap-2 mb-4 mt-6">
                                    <h2 className="text-lg font-semibold custom-loading">{t('Analyticsinging')}</h2>
                                    <div className="fl-tab-btn-view2  flex  ">
                                        {[...Array(5)].map((_$, index5) => (
                                            <button key={index5} type="button" className={`fl-tab-link2`}></button>
                                        ))}
                                    </div>
                                </div>
                                <div className="grid grid-cols-4 gap-5">
                                    {[...Array(8)].map((_$, index) => (
                                        <div key={index} className="border flex flex-col rounded-lg p-3">
                                            <div>
                                                <span className="text-gray-600 text-xs font-normal mb-1.5 custom-loading">loadingloadinging</span>
                                                <p className="text-gray-900 text-xs font-medium custom-loading">loading</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-8 datatable-custom">
                                <SCurrentInventory />
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-[306px] xls:w-[306px]  xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
                        <div className="flex flex-col">
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Product Name')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">loadinging loadinging</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Category')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">loadinging</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Sub Category')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">loadinging</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Unit of Measure')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">gram</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Brand')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">loadinging</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Description')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">
                                        Concealer is a versatile cosmetic product designed to mask dark circles, age spots, large pores, and various other small
                                    </h3>
                                </div>
                            </div>
                            <h6 className="text-sm font-semibold mb-1.5 text-gray-900">{t('Product Images')}</h6>
                            <div className="flex flex-wrap gap-3">
                                {[...Array(4)].map((_$, index3) => (
                                    <div key={index3} className="w-[65px] h-[65px] bg-blue-500 flex-shrink-0 rounded-md mb-3 custom-loading">
                                        <img src={defaultImage} alt="" className="object-cover w-full h-full" />
                                    </div>
                                ))}
                            </div>
                            <h6 className="text-sm font-semibold mb-0 text-gray-900">{t('Additional Information')}</h6>
                            <div className="flex flex-col ">
                                {[...Array(4)].map((_$, index2) => (
                                    <div className="border-b py-4" key={index2}>
                                        <div className="flex  justify-between items-center  border-headerBorderColor text-textGrey cursor-pointer">
                                            <span className="custom-loading">Ingredient & Packaging</span>
                                            <button className="text-xl">+</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <h6 className="text-sm font-semibold mb-4 text-gray-900 pt-4">{t('Supplier Information')}</h6>
                            <div className="flex flex-col">
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('First name')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">loadinging</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Email')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">loadinging@gmail.com</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Website')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">loadinging.com</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SProductDetails;
