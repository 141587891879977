import React from 'react';

const ProductBox = ({ product, onClick }: any) => {
    const mainImage = product.images.find((image: any) => image.is_main) || product.images[0];
    return (
        <div onClick={onClick(product.id)} className="w-full">
            <div className="location-card p-4 cursor-pointer">
                <div className="2xl:h-[200px] h-[194px] min-h-[194px] 2xl:min-h-[200px] w-full  grow-0 shrink-0  rounded-xl overflow-hidden cursor-pointer">
                    {product.images.length && <img className="h-full w-full object-cover" src={mainImage.image_url} alt="product" title={product.name} />}
                </div>
                <div className="flex-auto overflow-hidden ">
                    <div className="w-full flex flex-col gap-[6px]">
                        <h2 className="text-base font-semibold leading-[140%] text-gray-700 truncate">{product.name}</h2>
                        <p className="text-sm font-normal leading-[18px]  text-gray-500 truncate">{product.description}</p>
                    </div>
                    <div className="flex mt-auto pt-4 justify-between gap-2">
                        <div className="w-1/2">
                            <h3 className="location-card-heading mb-1">Total Variants</h3>
                            <div className=" text-sm font-medium truncate  text-secondaryTxtColor">
                                {product.variants.length > 1 ? `${product.variants.length} Options` : `$${product.variants[0].price}`}
                            </div>
                        </div>
                        <div className="text-right w-1/2">
                            <h3 className="location-card-heading mb-1">Brand</h3>
                            <div className="text-sm font-medium truncate  text-secondaryTxtColor">{product.brand}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductBox;
