import React, { FC } from 'react';
import { IKpiCard } from './KpiCard.interface';
import { ArrowDown, ArrowUp, ArrowUpRight, Check } from '@untitled-ui/icons-react/build/cjs';

const KpiCard: FC<IKpiCard> = ({ signIcon, headerTitle, contentLable, growthDescription, growth, fromDate, isActive = false, isAnalytics = false, isLoading = false, isEdit = false }) =>
    isLoading ? (
        <div className={`overview-card xxl:p-5 2xl:p-3 p-4 !rounded-xl ${isActive ? 'active' : ''} ${isAnalytics ? 'analytics-card !rounded-xl' : ''}`}>
            <div className="flex items-center justify-between ">
                <h4 className="text-gray-900  font-medium flex justify-start items-start text-xs 2xl:text-[11px] 2xl:leading-[16px] xxl:text-sm xxl:leading-[20px]">{headerTitle}</h4>
                {isAnalytics ? (
                    <div className="flex flex-row ">
                        <h4 className="font-semibold text-[11px] leading-[16px] text-[#143CB3]">{isEdit ? 'Edit' : 'View Details'}</h4>
                        <ArrowUpRight className="w-4 h-4 leading-[16px] text-[#143CB3]" />
                    </div>
                ) : (
                    <Check className="saw-icon" />
                )}
            </div>
            <div className="flex flex-row items-start mt-4">
                <div className="flex flex-row items-center custom-loading ">
                    <h3 className="font-semibold xxl:text-3xl text-2xl">loading</h3>
                    <sup className="text-sm font-semibold leading-tight 2xl:-top-[4px] xxl:-top-[8px]">{signIcon}</sup>
                </div>
            </div>

            <div className="flex flex-row justify-between items-center gap-3 mt-2 ">
                <div className="flex flex-row items-center custom-loading">
                    <span className={`mr-[4px]  flex items-center`}>
                        {growth >= 0 ? (
                            <ArrowUp className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4  text-[#17B26A]" />
                        ) : (
                            <ArrowDown className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4 text-[#ff4d54] " />
                        )}
                        <span className="font-medium text-[11px] text-xs xxl:text-sm text-secondaryTxtColor ">{`${Math.abs(growth)}%`}</span>
                    </span>

                    <span className="text-[11px] xxl:text-xs tracking-normal font-medium text-secondaryTxtColor max-2xl:max-w-[120px] max-xlm:max-w-[90px] max-xlm:truncate ">
                        {`vs. ${fromDate}`}{' '}
                    </span>
                </div>
                {growthDescription && (
                    <span className="text-[11px] xxl:text-xs tracking-normal  font-medium text-secondaryTxtColor max-xlm:max-w-[99x] max-2xl:max-w-[120px] max-2xlm:truncate custom-loading">
                        {growthDescription}
                    </span>
                )}
            </div>
        </div>
    ) : (
        <div className={`overview-card xxl:p-5 2xl:p-3 p-4 !rounded-xl ${isActive ? 'active' : ''} ${isAnalytics ? 'analytics-card !rounded-xl' : ''}`}>
            {/* active class aapi dejo */}
            <div className="flex items-center justify-between ">
                {/* <i className="rounded-xl border border-lineColor flex items-center justify-center mr-1 w-10 h-10">
                <img src={headerIcon} alt="" className="w-5" />
            </i> */}
                <h4 className="text-gray-900  font-medium flex justify-start items-start text-xs 2xl:text-[11px] 2xl:leading-[16px] xxl:text-sm xxl:leading-[20px]">{headerTitle}</h4>

                {isAnalytics ? (
                    <div className="flex flex-row ">
                        <h4 className="font-semibold text-[11px] leading-[16px] text-[#143CB3]">{isEdit ? 'Edit' : 'View Details'}</h4>
                        <ArrowUpRight className="w-4 h-4 leading-[16px] text-[#143CB3]" />
                    </div>
                ) : (
                    <Check className="saw-icon" />
                )}
            </div>
            <div className="flex flex-row items-start mt-4">
                <div className="flex flex-row items-center">
                    <h3 className="font-semibold xxl:text-3xl text-2xl">{contentLable}</h3>
                    <sup className="text-sm font-semibold leading-tight 2xl:-top-[4px] xxl:-top-[8px]">{signIcon}</sup>
                </div>
                {/* {signIcon && <span className=" font-semibold text-sm ml-[2px] analytics-second-text2">{signIcon}</span>} */}
                {/* {contentLable} {contentDescription && <span className="text-xs text-secondaryTxtColor font-normal -tracking-[0.3px]">{contentDescription}</span>}
            {secondaryContentLabel && <span className="ml-1 text-xs font-bold -tracking-[0.5px]">{secondaryContentLabel}</span>}
            {secondaryContentDescription && <span className="ml-1 text-xs text-secondaryTxtColor font-normal -tracking-[0.3px]">{secondaryContentDescription}</span>} */}
            </div>

            <div className="flex flex-row justify-between items-center gap-3 mt-2">
                <div className="flex flex-row items-center">
                    <span className={`mr-[4px]  flex items-center`}>
                        {growth >= 0 ? (
                            <ArrowUp className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4  text-[#17B26A]" />
                        ) : (
                            <ArrowDown className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4 text-[#ff4d54] " />
                        )}
                        <span className="font-medium text-[11px] text-xs xxl:text-sm text-secondaryTxtColor ">{`${Math.abs(growth)}%`}</span>
                    </span>

                    <span className="text-[11px] xxl:text-xs tracking-normal font-medium text-secondaryTxtColor max-2xl:max-w-[120px] max-xlm:max-w-[90px] max-xlm:truncate flex">
                        {`vs.`}
                        <p className="capitalize ml-1"> {fromDate}</p>
                    </span>
                </div>
                {growthDescription && (
                    <span className="text-[11px] xxl:text-xs tracking-normal  font-medium text-secondaryTxtColor max-xlm:max-w-[99x] max-2xl:max-w-[120px] max-2xlm:truncate">{growthDescription}</span>
                )}
            </div>
        </div>
    );
export default KpiCard;
