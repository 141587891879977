import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { GoDotFill } from 'react-icons/go';
import CustomButton from 'src/components/CustomButton';

export const colorArray = [
    '#0E34A5',
    '#1642C5',
    '#2C59DE',
    '#5279F0',
    '#819DF1',
    '#B4C6FC',
    '#D3DEFD',
    '#EBF0FF',
    '#D5D9EB',
    '#B3B8DB',
    '#717BBC',
    '#4E5BA6',
    '#3E4784',
    '#363F72',
    '#CFF9FE',
    '#A5F0FC',
    '#67E3F9',
    '#22CCEE',
    '#06AED4',
    '#088AB2',
    '#0E7090',
    '#155B75',
];
const defaultColor = '#0E34A5';

const TextSlideshow = ({ salesIncome, product = false, isProductGraph = false }: any) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [chunkedSalesIncome, setChunkedSalesIncome] = useState<any[][]>([]);
    const [totalSlides, setTotalSlides] = useState(0);
    const [grid, setGrid] = useState(6);

    useEffect(() => {
        if (salesIncome && salesIncome.length > 0) {
            const chunkSize = isProductGraph ? 6 : 9;
            setGrid(chunkSize);
            const chunked = [];
            for (let i = 0; i < salesIncome.length; i += chunkSize) {
                const chunk = salesIncome.slice(i, i + chunkSize).map((item: any, index: any) => ({
                    ...item,
                    color: colorArray[i + index] || defaultColor,
                }));
                chunked.push(chunk);
            }
            setChunkedSalesIncome(chunked);
        }
    }, [salesIncome, isProductGraph]);

    useEffect(() => {
        if (chunkedSalesIncome.length > 0) {
            setTotalSlides(chunkedSalesIncome.length);
        }
    }, [chunkedSalesIncome]);

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % totalSlides);
    };

    const prevSlide = () => {
        setCurrentSlide((currentSlide - 1 + totalSlides) % totalSlides);
    };

    return (
        <>
            <div className="  grid  md:grid-cols-3 2xl:gap-3 gap-5 w-full justify-between py-1">
                {chunkedSalesIncome[currentSlide] &&
                    chunkedSalesIncome[currentSlide].map((item: any, index: any) => (
                        <div key={index} className="flex flex-row gap-1 justify-start  2xl:w-[100px] xlm:w-[140px] xxl:w-[165px] truncate  ">
                            <GoDotFill size={12} style={{ color: item.color }} className=" h-4 w-4 rounded-full flex mt-[3px] " />
                            <div className="flex flex-col gap-[2px] items-center justify-start text-start">
                                <p className="text-sm max-xlm:text-sm 2xl:text-sm font-normal text-secondaryTxtColor w-full  2xl:w-[80px] xlm:w-[120px] xxl:w-[145px] text-start truncate">
                                    {item.name}
                                </p>
                                <p className="xlm:text-xl text-xl 2xl:text-base  font-semibold text-secondaryTxtColor w-full 2xl:w-[80px] xlm:w-[120px] xxl:w-[145px] xlm:leading-[30px] text-start truncate">
                                    ${product ? item.total_amount : item.total_booking_service_amount}
                                </p>
                            </div>
                        </div>
                    ))}
            </div>

            {salesIncome && salesIncome.length > grid && (
                <div className=" absolute flex 2xl:flex-col gap-1.5 xxl:flex-row bottom-0 right-0 justify-center  ">
                    <CustomButton secondary onClick={prevSlide} className="!w-[32px] !px-1 !max-w-[32px] !min-w-[32px] !h-[32px]">
                        <ArrowLeft className="w-4 h-4" />
                    </CustomButton>
                    <CustomButton secondary onClick={nextSlide} className=" !w-[32px] !px-1 !max-w-[32px] !min-w-[32px] !h-[32px]">
                        <ArrowRight className="w-4 h-4" />
                    </CustomButton>
                </div>
            )}
        </>
    );
};

export default TextSlideshow;
