import { Dispatch, SetStateAction } from 'react';

export interface INotification {
    id: number;
    admin_id: number;
    staff_id: number;
    user_id: number;
    booking_id: number;
    name: string;
    description: string;
    is_read: boolean;
    deleted_at: string;
    created_at: string;
    updated_at: string;
    status: string;
    booking_status: 'pending' | 'confirmed' | 'declined' | 'completed' | 'canceled' | 'request_canceled' | 'expired' | 'no_show';
}

export interface INotificationCount {
    all: number;
    general: number;
    appointments: number;
    statements: number;
}
export interface IProps {
    show: boolean;
    notificationCount: INotificationCount;
    notificationButtonRef: any;
    setNotificationCount: Dispatch<SetStateAction<{ all: number; general: number; appointments: number; statements: number }>>;
    handleClose: () => void;
}

export interface IInitialState {
    data: {
        notificationToken: string | undefined | null;
    };
    loading: boolean;
    error: { message: string | undefined; status?: string | undefined } | undefined | null;
}

export interface INotificationAction {
    cancelledAppointment: boolean;
}

export const NOTIFICATION_ACTION: INotificationAction = {
    cancelledAppointment: false,
};

export const NOTIFICATION_STATUS = {
    cancelledAppointment: 'request_canceled',
};
