import { useTranslation } from 'react-i18next';

export interface IFormData {
    commission_frequency: string;
    commission_day?: string;
    commission_start_date?: string;
    commission_month_day?: string;
    rent_frequency: string;
    rent_day?: string;
    rent_start_date?: string;
    rent_month_day?: string;
    tip_frequency: string;
    tip_day?: string;
    tip_start_date?: string;
    tip_month_day?: string;
    product_commission_frequency: string;
    product_commission_day?: string;
    product_commission_start_date?: string;
    product_commission_month_day?: string;
}

export const frequencyOptions = [
    {
        value: 'weekly',
        label: 'Weekly',
    },
    {
        value: 'bi_weekly',
        label: 'Bi-Weekly',
    },
    {
        value: 'monthly',
        label: 'Monthly',
    },
];

export const monthOptions = [
    {
        value: 'first',
        label: 'First day of month',
    },
    {
        value: 'last',
        label: 'Last day of month',
    },
];

export const dayOptions = [
    {
        value: 'sunday',
        label: 'Sunday',
    },
    {
        value: 'monday',
        label: 'Monday',
    },
    {
        value: 'tuesday',
        label: 'Tuesday',
    },
    {
        value: 'wednesday',
        label: 'Wednesday',
    },
    {
        value: 'thursday',
        label: 'Thursday',
    },
    {
        value: 'friday',
        label: 'Friday',
    },
    {
        value: 'saturday',
        label: 'Saturday',
    },
];
