/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Number from 'src/components/Number';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputWithLabel from 'src/components/InputWithLabel';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { ISelectedLocation } from '../Admin.interface';
import { useAppDispatch } from 'src/redux/hooks';
import { updateAdmin } from '../Admin.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { locationListAction } from 'src/app/Location/Location.slice';
import Button from 'src/components/Button';

type FieldKey = 'first_name' | 'last_name' | 'phone' | 'phone_country_code' | 'email' | 'shop_location_id' | 'role_type';
const Info = ({
    adminInfo,
    shopId,
    isEditProfile,
    getAdminList,
    setIsEditProfile,
    getAdminDetailFun,
}: {
    adminInfo: any;
    shopId: number;
    setIsEditProfile: Dispatch<SetStateAction<boolean>>;
    isEditProfile: boolean;
    getAdminList: any;
    getAdminDetailFun: any;
}) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [locationOptions, setLocationOptions] = useState<ISelectedLocation[]>([]);
    const optionAccType = [
        { label: 'Admin', value: 'admin' },
        // { label: 'Reseptionist', value: 'receptionist' },
    ];

    const getLocationOptions = async () => {
        setLoading(true);
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(locationListAction(data));
        if (result.type === locationListAction.fulfilled.toString()) {
            const locationListArray: any = getSelectBoxOptions(result.payload.data, 'id', 'name');

            setLocationOptions(locationListArray);
            const locationId = adminInfo?.locations.map((item: any) => item.id);
            setValue('shop_location_id', locationId);
        }
        setLoading(false);
    };

    const schema = Yup.object({
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
        phone: Yup.string().required('This field is required'),
        phone_country_code: Yup.string().required('This field is required'),
        email: Yup.string().email('Please enter valid email address').required('This field is required'),
        role_type: Yup.string().required('This field is required'),
        shop_location_id: Yup.array().min(1, 'This field is required'),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: adminInfo.first_name,
            last_name: adminInfo.last_name,
            email: adminInfo.email,
            phone_country_code: adminInfo.phone_country_code,
            phone: adminInfo.phone,
            role_type: adminInfo.shop_admin_location[0].role_type,
            shop_location_id: adminInfo.shop_location_id ?? [],
        },
    });

    const onSubmit = async (data: any) => {
        const submitData = {
            payload: data,
            param: { shop_id: shopId, id: adminInfo?.id },
        };
        const result = await dispatch(updateAdmin(submitData));
        if (result.type === updateAdmin.fulfilled.toString()) {
            getAdminList();
            setIsEditProfile(false);
            getAdminDetailFun();
        }

        if (result.type === updateAdmin.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    useEffect(() => {
        // const locationOptiondata: any = getSelectBoxOptionsIdAsValue(
        //     adminInfo.locations,
        // );
        // setSelectedLocation(locationOptiondata);
        // seLocationOptions(locationOptiondata);

        getLocationOptions();
    }, []);
    return (
        <>
            <div className="drawer-content-block">
                {!loading && adminInfo ? (
                    <>
                        <div className="py-6 px-8">
                            <form className="mb-12" onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex -mx-[10px] flex-wrap">
                                    {isEditProfile && (
                                        <div className="w-full relative">
                                            <Button className=" btn_primary w-[200px] h-10 fixed right-[75px] top-3">Save changes</Button>
                                        </div>
                                    )}
                                    <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="first_name"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <InputWithLabel
                                                    label="First Name"
                                                    placeholder="Enter Member’s First Name"
                                                    onChange={onChange}
                                                    value={value}
                                                    disabled={!isEditProfile}
                                                    name="first_name"
                                                    error={!!errors.first_name}
                                                />
                                            )}
                                        />
                                        {errors?.first_name && <p className="text-error">{errors?.first_name?.message as React.ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="last_name"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <InputWithLabel
                                                    label="Last Name"
                                                    placeholder="Enter Member’s Last Name"
                                                    onChange={onChange}
                                                    value={value}
                                                    disabled={!isEditProfile}
                                                    name="last_name"
                                                    error={!!errors.last_name}
                                                />
                                            )}
                                        />
                                        {errors?.last_name && <p className="text-error">{errors?.last_name?.message as React.ReactNode}</p>}
                                    </div>

                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <label htmlFor="phone" className="fl-field-title-label">
                                            Phone Number
                                        </label>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            render={({ field: { onChange } }: any) => (
                                                <div className="flex flex-col ">
                                                    <Number
                                                        getNumber={onChange}
                                                        errors={null}
                                                        onNumberChange={() => {}}
                                                        disabled={!isEditProfile}
                                                        defaultValues={{
                                                            phone: adminInfo.phone,
                                                            phone_country_code: adminInfo.phone_country_code,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                        {errors?.phone && <p className="text-error">{errors?.phone?.message as React.ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <InputWithLabel label="Email" placeholder="Enter member’s email" onChange={onChange} value={value} disabled={true} name="email" />
                                            )}
                                        />
                                        {errors?.email && <p className="text-error">{errors?.email?.message as React.ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] mb-5 w-full grow-0 shrink-0 basis-[100%] working_location">
                                        <Controller
                                            name="shop_location_id"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <SelectBox
                                                    name="shop_location_id"
                                                    id="handlingUnit"
                                                    label="Working Location"
                                                    isDisabled={!isEditProfile}
                                                    options={locationOptions}
                                                    value={locationOptions.filter((item: any) => value.includes(item.id))}
                                                    errorText={errors.shop_location_id && true}
                                                    onChangeFunc={(selectedOption: any) =>
                                                        !value.includes(selectedOption.value) && onChange(Array.isArray(selectedOption) ? selectedOption.map((x: any) => x.value) : [])
                                                    }
                                                    isMulti
                                                    isClearable
                                                    placeholder="Working Location"
                                                />
                                            )}
                                        />
                                        {errors.shop_location_id && <p className="text-error">{errors.shop_location_id.message as ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] mb-5 w-1/2">
                                        <Controller
                                            name="role_type"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <div className="w-full">
                                                    <SelectBox
                                                        name="role_type"
                                                        id="handlingUnit"
                                                        label="Role type"
                                                        isDisabled={!isEditProfile}
                                                        options={optionAccType}
                                                        value={optionAccType.filter((item: any) => item.value === value)}
                                                        errorText={errors.role_type && true}
                                                        onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                                        placeholder="Select account type"
                                                    />
                                                    {errors.role_type && <p className="text-error">{errors.role_type.message as ReactNode}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default Info;
