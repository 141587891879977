import React, { useEffect } from 'react';
import ReactSelect, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import { ISelectBoxProps } from './SelectBox.interface';
import { FaCheck } from 'react-icons/fa6';
import { getFontWeight } from 'src/utils/global-functions';

const colourStyles = (error: any) => ({
    control: (defaultStyles: any, state: any) => ({
        ...defaultStyles,
        border: `1px solid ${state.isFocused && error ? '#E33B3B' : state.isFocused ? '#3570F8' : error ? '#E33B3B' : '#EFF1F5'}`,

        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
            border: `1px solid  ${state.isFocused && error ? '#E33B3B' : state.isFocused ? '#3570F8' : error ? '#E33B3B' : '#EFF1F5'}`,
        },
        backgroundColor: error ? '#f9e0e0' : '#EFF1F5',
        padding: '0.375rem 0.5rem',
        color: '#1F293C',
        borderRadius: '40px',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menuList: (styles: any) => ({
        ...styles,
        borderRadius: '24px',
    }),
    menu: (styles: any) => ({
        ...styles,
        borderRadius: '24px',
        border: 'none',
        boxShadow: '0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);',
    }),
    option: (styles: any, state: any) => ({
        ...styles,
        '&:hover': {
            backgroundColor: !state.isSelected && '#EFF1F5',
        },
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        marginRight: '5px',
        color: 'white',
        backgroundColor: '#ffffff',
        '&:hover': {
            color: 'white',
            backgroundColor: '#ffffff',
        },
    }),
});

export const CustomOption = ({ children, isFontFamily, isFontWeight, ...props }: any) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return (
        <components.Option {...newProps}>
            <div className="flex justify-between items-center font-medium select-box-custom">
                {/* {children} {props.isSelected && <FaCheck size={16} />} */}
                <div className={`dropdown-detail gap-2 ${props.isSelected ? 'max-w-[calc(100%-19px)]' : 'max-w-full'}`}>
                    {props.data.image ? (
                        <img src={props.data.image} alt={props.data.label} className="w-[20px] h-[20px] rounded-full" />
                    ) : props.data.shortName ? (
                        <span className="w-[20px] h-[20px] leading-[20px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                            {props.data.shortName}
                        </span>
                    ) : null}
                    {props?.data?.icon ? props.data.icon : null}
                    {/* {props.data.image && <img src={props.data.image} alt={props.data.label} className="w-[18px] h-[18px]" />} */}
                    <div className={`${isFontFamily ? props.data.value : ''} ${isFontWeight ? `font-${getFontWeight(props.data.value)}` : ''}  truncate`}>{children}</div>
                </div>
                {props.data.rightLabel && <span className={`font-medium  ${props.isDisabled ? props.rightLabelClassName : ''}`}>{props.data.rightLabel}</span>}
                {props.isSelected && <FaCheck size={12} className="text-primary opacity-80" />}
            </div>
        </components.Option>
    );
};
const CustomControl = ({ children, ...props }: any) => (
    <components.Control {...props}>
        {props?.allowIcon || ''}
        {children}
    </components.Control>
);

const Placeholder = ({ children, labelIcon, ...props }: any) => <components.Placeholder {...props}> {labelIcon}</components.Placeholder>;

const CustomSingleValue = ({ children, labelIcon, isFontFamily, isFontWeight, ...props }: any) => (
    <components.SingleValue {...props}>
        {labelIcon ? (
            labelIcon
        ) : (
            <div className="flex items-center gap-2 font-medium select-box-custom capitalize">
                {props.data.image ? (
                    <img src={props.data.image} alt={props.data.label} className="w-[20px] h-[20px] rounded-full" />
                ) : props.data.shortName ? (
                    <span className="w-[20px] h-[20px] leading-[20px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                        {props.data.shortName}
                    </span>
                ) : null}
                {props?.data?.icon ? props.data.icon : null}
                <div className={`${isFontFamily ? props.data.value : ''} ${isFontWeight ? `font-${getFontWeight(props.data.value)}` : ''}`}>{children}</div>
                {/* {children} */}
            </div>
        )}
    </components.SingleValue>
);

const MultiValueContainer = ({ children, labelIcon, ...props }: any) => {
    const getVal = props.selectProps.value;
    const selected = props.data.value;
    const isFirst = selected == getVal[0].value;
    if (!isFirst) return null;
    return labelIcon;
};

const SelectBox = ({
    name,
    errorText,
    error,
    label,
    id,
    options,
    className,
    classNamePrefix,
    tabIndex,
    isSearchable = true,
    labelClassname,
    outerClassName = '',
    isDisabled,
    tooltip,
    value,
    onChangeFunc,
    isClearable = true,
    isLoading,
    placeholder,
    classComp,
    isMulti,
    allowIcon,
    defaultValue,
    menuIsOpen = false,
    isWorkingHoursSelect = false,
    required = false,
    noOptionsMessage = 'No options',
    labelIcon,
    handleIsOptionDisabled = () => false,
    rightLabelClassName = '',
    isMenuPortalTarget = true,
    isMultiIcon = false,
    isFontFamily = false,
    isFontWeight = false,
    ...props
}: ISelectBoxProps) => {
    useEffect(() => {
        const handleScroll = () => {
            document.querySelectorAll('.fwpo_select__menu').forEach((el) => {
                el.classList.add('d-none');
            });
        };

        const scrollableContainer = document.querySelector('.dashboard_content');
        if (scrollableContainer) {
            scrollableContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollableContainer) {
                scrollableContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const handleChange = (newValue: any, actionMeta: any) => {
        onChangeFunc(newValue);
        if (actionMeta.action === 'clear') {
            document.querySelectorAll('.fwpo_select__menu').forEach((el) => {
                el.classList.add('d-none');
            });
        }
    };

    return (
        <div id={id} className={`w-full ${outerClassName} ${labelIcon ? 'filterWraper' : ''}`}>
            {label && (
                <label className={`fl-field-title-label ${labelClassname}`}>
                    {label}
                    {required && <span className="asterisk">*</span>}
                </label>
            )}
            <ReactSelect
                value={value}
                defaultValue={defaultValue}
                menuPortalTarget={labelIcon ? null : !isMenuPortalTarget ? null : document.body}
                // defaultMenuIsOpen
                menuPlacement="auto"
                onChange={handleChange}
                options={options}
                tabIndex={tabIndex}
                classNamePrefix={classNamePrefix ? classNamePrefix : 'fwpo_select'}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isOptionDisabled={handleIsOptionDisabled}
                isLoading={isLoading}
                // menuIsOpen={menuIsOpen}
                styles={colourStyles(errorText)}
                placeholder={placeholder}
                // menuIsOpen
                className={` ${classComp} ${error ? 'is-invalid' : ''} ${!isWorkingHoursSelect ? 'select_border' : ''}`}
                components={
                    !isMulti
                        ? {
                              Option: (prop: any) => <CustomOption {...prop} rightLabelClassName={rightLabelClassName} isFontFamily={isFontFamily} isFontWeight={isFontWeight} />,
                              SingleValue: (prop: any) => <CustomSingleValue {...prop} labelIcon={labelIcon} isFontFamily={isFontFamily} isFontWeight={isFontWeight} />,
                              Control: (prop: any) => <CustomControl {...prop} allowIcon={allowIcon} />,
                          }
                        : isMultiIcon
                        ? {
                              Option: (prop: any) => <CustomOption {...prop} rightLabelClassName={rightLabelClassName} />,
                              ClearIndicator: undefined,
                              MultiValueContainer: (prop: any) => <MultiValueContainer {...prop} labelIcon={labelIcon} />,
                              Placeholder: (prop: any) => <Placeholder {...prop} labelIcon={labelIcon} />,
                          }
                        : makeAnimated()
                }
                isMulti={isMulti}
                hideSelectedOptions={false}
                noOptionsMessage={() => noOptionsMessage}
            />
        </div>
    );
};

export default SelectBox;
