import React, { FC } from 'react';
import ProductBox from './ProductBox';
import { PATH } from 'src/constants/path';
import { useNavigate } from 'react-router-dom';
import NoDataMessage from 'src/components/NoDataMessage';
import { Archive } from '@untitled-ui/icons-react/build/cjs';
import { IProductsGrid } from './Products.interface';
import SLocation from '../Skeleton/Location/SLocation';

const ProductsGrid: FC<IProductsGrid> = ({ productListArray, lazyState, setLazyState, totalProducts, isLoading, filters }) => {
    const navigate = useNavigate();
    // const customPaginatorTemplate = {
    //     layout: 'CurrentPageReport PrevPageLink NextPageLink ',
    //     PrevPageLink: ({ onClick }: any) => (
    //         <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
    //             {t('Previous')}
    //         </CustomButton>
    //     ),
    //     NextPageLink: ({ onClick }: any) => (
    //         <CustomButton secondary onClick={onClick}>
    //             {t('Next')}
    //         </CustomButton>
    //     ),
    //     CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
    //         const currentPage = Math.floor(first / rows) + 1;
    //         const totalPages = Math.ceil(totalRecords / rows);

    //         return (
    //             <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
    //                 <span>
    //                     Page {currentPage} of {totalPages}
    //                 </span>
    //             </div>
    //         );
    //     },
    // };
    // const onPageChange = (event: any) => {
    //     setLazyState((old: any) => ({
    //         ...old,
    //         first: event.first,
    //         rows: event.rows,
    //     }));
    // };

    const handleClick = (id: string) => () => {
        navigate(`${PATH.PRODUCT.VIEW}/${id}`);
    };

    return (
        <>
            {!isLoading && productListArray.length === 0 ? (
                <NoDataMessage
                    title={`${lazyState.filters.global.value ? 'No products found' : 'No products added.'}`}
                    description={`${lazyState.filters.global.value ? 'Try adjusting your filters or add new products.' : 'Get started by adding your first product.'}`}
                    iconComponent={<Archive className="text-gray-700" />}
                />
            ) : isLoading ? (
                <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
                    <SLocation field="product" />
                </div>
            ) : (
                <>
                    <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
                        {productListArray.map((product: any, index: any) => (
                            <ProductBox onClick={handleClick} key={index} product={product} />
                        ))}
                    </div>
                    {/* {totalProducts > lazyState.rows && (
                        <div className="border-t border-gray-200">
                            <Paginator template={customPaginatorTemplate} first={lazyState.first} rows={lazyState.rows} totalRecords={totalProducts} onPageChange={onPageChange} />
                        </div>
                    )} */}
                </>
            )}
        </>
    );
};

export default ProductsGrid;
