import React, { useMemo } from 'react';
import KpiCard from 'src/components/KpiCard';
import { IconMoney, IconNewClient, IconClientRotation } from 'src/theme/Images';
import { formatKpiContentLable } from 'src/utils/global-functions';
import { ArrowUp, ArrowUpRight } from '@untitled-ui/icons-react/build/cjs';

const Overview = ({ AnalyticsDetails, boxLabel, isLoading }: any) => {
    const AnalyticsTotal = AnalyticsDetails ? AnalyticsDetails.total : null;
    const KPIS = useMemo(
        () => [
            {
                name: 'Total Revenue',
                sign: '$',
            },
            {
                name: 'Total Appointments',
                sign: ``,
            },
            {
                name: 'Client Retention',
                sign: `%`,
            },
            {
                name: 'Productivity',
                sign: `%`,
            },
            {
                name: 'New Customers',
                sign: ``,
            },
            {
                name: 'Client Booking Freq.',
                sign: ``,
            },
        ],
        [],
    );
    return (
        <>
            {isLoading ? (
                <div className=" grid max-2xl:grid-cols-2 grid-cols-3 w-full gap-3">
                    {KPIS.map((item, index) => (
                        <div key={index} className="">
                            <div className={`overview-card xxl:p-5 xl:p-3 p-4 analytics-card !rounded-xl`}>
                                <div className="flex items-center justify-between analytics-card-header">
                                    <h4 className="text-gray-900 xxl:text-sm xl:text-[11px] text-xs font-medium flex justify-start items-start xxl:pr-4 pr-2 analytics-first-text">{item.name}</h4>
                                    <div className="flex flex-row custom-loading">
                                        <h4 className="font-semibold text-[11px] text-[#143CB3]">View Details</h4>
                                        <ArrowUpRight className="w-4 h-4  text-[#143CB3]" />
                                    </div>
                                </div>
                                <div className="flex flex-row items-start mb-1 pt-4 analytics-card-header2">
                                    <h3 className="font-semibold xxl:text-3xl text-2xl analytics-second-text custom-loading">loading</h3>
                                    <span className="text-[24px] font-semibold leading-[30px] -tracking-[0.5px] analytics-second-text2">{item.sign}</span>
                                </div>
                                <div className="flex flex-row justify-between items-center gap-3">
                                    <div className="flex flex-row items-center">
                                        <span className={`mr-[4px]  flex items-center custom-loading`}>
                                            <ArrowUp className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4  text-[#17B26A] " />
                                            <span className="font-medium text-sm max-xlm:text-xs text-secondaryTxtColor analytics-last-text">100%</span>
                                            <span className="xxl:text-sm text-xs font-medium text-secondaryTxtColor max-2xl:max-w-[120px] max-xlm:max-w-[90px] max-xlm:truncate analytics-last-text">
                                                {' '}
                                                yesterday
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                AnalyticsTotal && (
                    <>
                        <div className=" grid max-2xl:grid-cols-2  grid-cols-3 w-full gap-3">
                            <div className="">
                                <KpiCard
                                    headerIcon={IconMoney}
                                    headerTitle="Total Revenue"
                                    contentLable={`${formatKpiContentLable(AnalyticsTotal?.amount)}`}
                                    signIcon={'$'}
                                    growth={AnalyticsTotal?.amount_growth}
                                    fromDate={boxLabel}
                                />
                            </div>
                            <div className="">
                                <KpiCard
                                    headerIcon={IconNewClient}
                                    headerTitle="Total Appointments"
                                    contentLable={`${formatKpiContentLable(AnalyticsTotal?.bookings)}`}
                                    growth={AnalyticsTotal?.bookings_growth}
                                    fromDate={boxLabel}
                                />
                            </div>
                            <div className="">
                                <KpiCard
                                    headerIcon={IconClientRotation}
                                    headerTitle="Client Retention"
                                    contentLable={`${AnalyticsTotal?.client_retention_rate}`}
                                    signIcon={'%'}
                                    growth={AnalyticsTotal?.client_retention_rate_growth}
                                    fromDate={boxLabel}
                                />
                            </div>
                            <div className="">
                                <KpiCard
                                    headerIcon={IconMoney}
                                    headerTitle="Productivity"
                                    contentLable={`${formatKpiContentLable(AnalyticsTotal?.productivity)}`}
                                    signIcon={'%'}
                                    growth={AnalyticsTotal?.productivity_growth}
                                    fromDate={boxLabel}
                                />
                            </div>
                            <div className="">
                                <KpiCard
                                    headerIcon={IconNewClient}
                                    headerTitle="New Customers"
                                    contentLable={`${formatKpiContentLable(AnalyticsTotal?.client)}`}
                                    growth={AnalyticsTotal?.client_growth}
                                    fromDate={boxLabel}
                                />
                            </div>
                            <div className="">
                                <KpiCard
                                    headerIcon={IconClientRotation}
                                    headerTitle="Client Booking Freq."
                                    contentLable={`${AnalyticsTotal?.avg_bookings_frequency}`}
                                    signIcon={'days'}
                                    growth={AnalyticsTotal?.avg_bookings_frequency_growth}
                                    fromDate={boxLabel}
                                />
                            </div>
                        </div>
                    </>
                )
            )}
        </>
    );
};

export default Overview;
