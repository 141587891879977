import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { me, userMe } from 'src/redux/services/common/Common.slice';
import CustomButton from 'src/components/CustomButton';
import { IFormData } from './Brand.interface';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import { s3Upload } from 'src/utils/s3Operations';
import CropperModal from 'src/components/CropperModal/CropperModal';
import InputWithLabel from 'src/components/InputWithLabel';
import Tags from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { Edit, GoogleIcon, InstagramColor, SocialIcon, Url } from 'src/theme/Images';
import { BackgroundDesign } from 'src/theme/Images';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import StepProgressBar from 'src/components/StepProgressBar';
import Footer from 'src/app/Layout/Footer';
import ImageUpload from 'src/components/ImageUpload';
import { XClose } from '@untitled-ui/icons-react/build/cjs';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

const Brand: FC<any> = ({ setStep, currentStep, stepLenght }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const user = useAppSelector(userMe);
    const [upload, setUpload] = useState(false);
    const completeAccountStep = user.shop_staff[0].account_step.complete_account;
    const imageUrl = completeAccountStep && user.logo_image_name ? user.logo_image_name : null;
    const [cropData, setCropData] = useState<any>(imageUrl || '');
    const [image, setImage] = useState<any>(imageUrl || '');
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [isLoading, setIsLoading] = useState(false);
    const {
        clearErrors,
        setError,
        getValues,
        trigger,
        formState: { errors },
        control,
        setValue,
    } = useFormContext();
    useEffect(() => {}, []);
    const navigate = useNavigate();

    const handleClose = () => {
        navigate(ROUTES.DASHBOARD);
    };
    const onChangeCrop = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);
        if (result) {
            clearErrors('logo_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError('logo_image_name' as 'logo_image_name', {
                type: 'manual',
                message: message,
            });
        }
    };
    const handleContinue = async () => {
        const isValid = await trigger();
        if (isValid) {
            const data = getValues();
            handleChange(data);
        }
    };
    const handleChange = async (data: any) => {
        setIsLoading(true);

        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/business_logos/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                submitForm(updatedData);
            }
        } else {
            submitForm(data);
        }
    };
    const imageUploadFun = (data: any) => {
        const fileInfo = convertBase64ToFile(data);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('logo_image_name', fileInfo.filename);
        }
    };

    const submitForm = async (data: any) => {
        let adminEmailsPayload = [];
        if (Array.isArray(data.admin_emails)) {
            adminEmailsPayload = data.admin_emails.map((item: any) => item.value);
        }
        let payload = {
            ...data,
            admin_emails: adminEmailsPayload,
        };
        axiosPost(API.SHOP.CREATE, payload)
            .then(async () => {
                await dispatch(me());
                setStep(3);
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    useFormErrorFocus<IFormData>({ errors });

    return (
        <>
            <main className="px-6 h-[calc(100vh-138px)] overflow-y-scroll flex-1 flex">
                <div className="w-full flex justify-center items-center relative">
                    <img src={BackgroundDesign} alt="" className="absolute top-0 z-[-1]" />
                    <div className="w-[600px] mt-10 pb-5">
                        <div className="flex flex-col rounded-md items-center mb-4">
                            <div className="p-[14px] bg-white border border-btnborder rounded-xl">
                                <Edit />
                            </div>
                        </div>
                        <div className="text-center">
                            <h3 className="fwpo_heading_title">{t('Configure your brand')}</h3>
                            <p className="font-normal leading-[22.4px] mt-[2px] text-base text-secondaryTxtColor">{t('Tell us a bit about your business')}.</p>
                        </div>
                        <div className="mt-6">
                            <div className="flex flex-col">
                                <div className="w-full mb-4">
                                    <label className={`fl-field-title-label text-start`}>
                                        {t('Brand Logo')} <span className="asterisk">*</span>
                                    </label>
                                    <ImageUpload cropData={cropData} onChangeCrop={onChangeCrop} id="logo_image_name" />
                                </div>
                            </div>
                            <div className="flex w-full gap-x-8 justify-center mb-4">
                                <div className="w-[600px] flex gap-x-4">
                                    <div className="w-1/2">
                                        <Controller
                                            name="business_name"
                                            control={control}
                                            render={({ field: { onChange, value, error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        id="business_name"
                                                        label="Brand name"
                                                        placeholder="Enter brand name"
                                                        onChange={onChange}
                                                        required
                                                        value={value}
                                                        inputControlClassName="h-[36px]"
                                                        name="business_name"
                                                        isFocus={true}
                                                        error={!!errors.business_name}
                                                    />
                                                    {error && <p className="text-error">{error?.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <Controller
                                            name="slug"
                                            control={control}
                                            render={({ field: { onChange, value, error } }: any) => (
                                                <div className="w-full">
                                                    <div className="relative rounded-md">
                                                        <InputWithLabel
                                                            id="slug"
                                                            label="Website URL"
                                                            placeholder="Enter Website URL"
                                                            onChange={onChange}
                                                            value={value}
                                                            required
                                                            labelIcon={<Url />}
                                                            inputControlClassName="pl-1 h-[36px]"
                                                            name="slug"
                                                            isFocus={true}
                                                            error={!!errors.slug}
                                                            labelEndIcon={'.getflair.ca'}
                                                        />
                                                    </div>
                                                    {/* {error && <p className="text-error">{error?.message}</p>} */}
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col w-full justify-center items-center">
                                <div className="relative w-full  pb-4">
                                    <Controller
                                        name="instagram_page"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label="Brand socials"
                                                    placeholder="Username"
                                                    onChange={onChange}
                                                    value={value}
                                                    name="instagram_page"
                                                    isFocus={true}
                                                    labelIcon={
                                                        <div className="flex flex-row gap-1.5 items-center">
                                                            <InstagramColor />
                                                            <p className="text-xs font-medium text-mainTextColor">instagram.com/</p>
                                                        </div>
                                                    }
                                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                                    inputControlClassName="pl-3"
                                                    error={!!errors.instagram_page}
                                                />

                                                {/* {errors && errors?.instagram_page && <p className="text-error">{errors?.instagram_page?.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full justify-center items-center">
                                <div className="relative w-full  pb-4">
                                    <Controller
                                        name="facebook_page"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label=""
                                                    placeholder="Username or page "
                                                    onChange={onChange}
                                                    value={value}
                                                    name="facebook_page"
                                                    isFocus={true}
                                                    labelIcon={
                                                        <div className="flex flex-row gap-1.5 items-center">
                                                            <SocialIcon />
                                                            <p className="text-xs font-medium text-mainTextColor">facebook.com/</p>
                                                        </div>
                                                    }
                                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                                    inputControlClassName="pl-3"
                                                    error={!!errors.facebook_page}
                                                />
                                                {/* {errors && errors?.facebook_page && <p className="text-error">{errors?.facebook_page?.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col w-full justify-center items-center">
                                <Controller
                                    name="google_review"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="relative w-full brand_social pb-4">
                                            <InputWithLabel
                                                label=""
                                                placeholder="Google review page"
                                                onChange={onChange}
                                                value={value}
                                                name="google_review"
                                                isFocus={true}
                                                labelIcon={
                                                    <div className="flex flex-row gap-1.5 items-center">
                                                        <GoogleIcon />
                                                        <p className="text-xs font-medium text-mainTextColor">google.com/</p>
                                                    </div>
                                                }
                                                labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                                inputControlClassName="pl-3"
                                                error={!!errors.google_review}
                                            />
                                            {/* {errors && errors?.google_review && <p className="text-error">{errors?.google_review?.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col w-full justify-center items-center">
                                <Controller
                                    name="admin_emails"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div id="admin_emails" className="w-[600px]">
                                            <label htmlFor="additionalAdminUsers" className="fl-field-title-label">
                                                Invite additional admin users
                                            </label>
                                            <Tags
                                                placeholder="Add admin emails"
                                                settings={{
                                                    maxTags: 4,
                                                    // pattern: emailRegex,
                                                    dropdown: {
                                                        enabled: 0, // always show suggestions dropdown
                                                    },
                                                    callbacks: {
                                                        add: (e) => {
                                                            onChange(e.detail.tagify.value);
                                                        },
                                                        remove: (e) => {
                                                            onChange(e.detail.tagify.value);
                                                        },
                                                    },
                                                }}
                                                value={value}
                                                className="rounded-lg h-[36px]"
                                                /* onChange={(e: any) => {
                                                    const newResult = e.target.tagify.value;
                                                    if (Array.isArray(newResult)) {
                                                        const finalResult = newResult.map((item: any) => item.value);
                                                        onChange(finalResult);
                                                    }
                                                }} */
                                            />
                                            {errors.admin_emails && <p className="text-error">Invalid email format</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            {/* <div className="flex w-full justify-center mt-5">
                                <CustomButton primary isLoading={isLoading} disabled={isLoading} onClick={handleContinue} size="w-full" className="fl-btn">
                                    {t('Continue')}
                                </CustomButton>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="close-page-btn absolute right-8 top-8">
                    <XClose className="xl:w-5 xl:h-5 w-4 h-4 text-gray-900" onClick={handleClose} />
                </div>
            </main>
            {upload && (
                <CropperModal
                    imageUpload={imageUploadFun}
                    imageUrl={image}
                    setUpload={setUpload}
                    setCropData={setCropData}
                    defaultCropType="Square"
                    title={t('Crop header image')}
                    description={t('Upload a 1600 x 480px image for best results.')}
                />
            )}
            <div className="bottom-0 right-0 max-2xl:start-[420px] start-[480px] fixed">
                <div className="w-full mt-5   mb-2  gap-4 flex justify-center mx-auto">
                    <CustomButton secondary onClick={() => setStep(1)} size="w-[292px]">
                        Back
                    </CustomButton>
                    <CustomButton primary isLoading={isLoading} disabled={isLoading} type="button" onClick={handleContinue} size="w-[292px]" className="w-[292px]">
                        {t('Continue')}
                    </CustomButton>
                </div>
                <div className="w-full bg-white">
                    <StepProgressBar steps={stepLenght} currentStep={currentStep} className="w-full mx-auto justify-center" size="max-w-[190px]" />
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Brand;
