import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { perPageOptions } from './Location.interface';
import { dateTimeFormate, getShortName } from 'src/utils/global-functions';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import moment from 'moment';
import NoDataMessage from 'src/components/NoDataMessage';
import CustomButton from 'src/components/CustomButton';
import { Skeleton } from 'primereact/skeleton';
import { ArrowUp, ArrowDown, Users01 } from '@untitled-ui/icons-react/build/cjs';
import { GoDotFill } from 'react-icons/go';

const LocationBooking = ({ location }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [totalBookings, setTotalBookings] = useState(0);
    const [bookings, setBookings] = useState([]);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {},
    });

    useEffect(() => {
        listBooking();
    }, [lazyState, shop]);

    const listBooking = async () => {
        setIsLoading(true);
        await axiosGet(API.LOCATION.BOOKINGS, { shop_id: shop.id, id: location.id }, lazyState)
            .then((response) => {
                const data = response.data.data;
                setBookings(data.data);
                setTotalBookings(data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const getName = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.user.profile_image_url ? (
                    <img src={row.user.profile_image_url} alt={row.user.full_name} title={row.user.full_name} className="w-full h-full object-cover" />
                ) : (
                    getShortName(row.user.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs leading-[18px] font-medium w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.user.full_name}
                </p>
                <p className="text-xs leading-[18px] font-normal text-secondaryTxtColor w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {moment(row.created_at).fromNow()}
                </p>
            </div>
        </div>
    );

    const getTotal = (row: any) => `$${row.total}`;

    const getService = (row: any) => row.booking_services[0].service.name;

    const getTime = (row: any) => dateTimeFormate(`${row.booking_date}' ' ${row.booking_start_time}`);

    const getDuration = (row: any) => {
        const duration = moment.duration(row.total_duration);
        const hours = duration.hours();
        const minutes = duration.minutes();

        let formattedDuration = '';
        if (hours > 0) {
            formattedDuration += `${hours} hr.`;
            if (minutes > 0) {
                formattedDuration += `' ${minutes} min.`;
            }
        } else {
            formattedDuration += `${minutes} min.`;
        }
        return formattedDuration;
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'confirmed':
                return 'text-[#175CD3]';
            case 'completed':
                return 'text-[#067647]';
            case 'pending':
                return 'text-[#F38744]';
            case 'declined':
                return 'text-[#B54708]';
            case 'canceled':
                return 'text-[#B54708]';
            case 'request_canceled':
                return 'text-[#B54708]';
            case 'expired':
                return 'text-[#B54708]';
            case 'no_show':
                return 'text-[#B54708]';
            default:
                return 'text-blueLight-700';
        }
    };

    const GetStatus = (row: any) => {
        const statusClass = getStatusColor(row.status);
        return (
            <div className="flex items-center">
                <span
                    className={`px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max `}
                >
                    {' '}
                    <GoDotFill size={12} className={statusClass} />
                    {row.status}
                </span>
            </div>
        );
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                users: { first_name: 'loading' },
                shop_services: { name: 'loading' },
                booking_date: 'loading',
                total_duration: 'loading',
                total: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    return (
        <div className="w-full border rounded-xl flex-col mb-5 flex shadow staff_service_child_table datatable-custom-service">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div>
                    <h2 className="view-table-title">{t('Latest booking activity')}</h2>
                    <p className="view-table-subtitle">{t('View location latest booking activity', { name: location.name })}</p>
                </div>
                <div>
                    <SelectBox
                        name="page"
                        isClearable={false}
                        isSearchable={false}
                        options={perPageOptions}
                        onChangeFunc={onPageHandle}
                        value={perPageOptions.find((option) => option.value === lazyState.rows)}
                        classComp="w-[70px]"
                    />
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={customPaginatorTemplate}
                paginatorClassName="table-pagination"
                value={isLoading ? skeletons : bookings}
                totalRecords={totalBookings}
                paginator={!isLoading && totalBookings > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className=" rounded-b-xl overflow-hidden"
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage title="Latest booking activity" description="Bookings not available." iconComponent={<Users01 className="text-gray-700" />} />
                    </div>
                }
            >
                <Column
                    field="users.created_at"
                    header={renderHeader(t('Client'), 'users.created_at')}
                    body={isLoading ? <Skeleton /> : getName}
                    style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                    className="font-normal text-mainTextColor truncate"
                    sortable
                ></Column>
                <Column
                    field="status"
                    header={renderHeader(t('Status'), 'status')}
                    style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    className="text-xs leading-[18px] font-medium"
                    body={isLoading ? <Skeleton /> : GetStatus}
                    sortable
                ></Column>
                <Column
                    field="booking_date"
                    header={renderHeader(t('Date and Time'), 'booking_date')}
                    style={{ width: '135px', minWidth: '135px', maxWidth: '135px' }}
                    body={isLoading ? <Skeleton /> : getTime}
                    className="text-xs leading-[18px] font-normal text-secondaryTxtColor truncate"
                    sortable
                ></Column>
                <Column
                    field="total_duration"
                    header={renderHeader(t('Duration'), 'total_duration')}
                    style={{ width: '85px', minWidth: '85px', maxWidth: '85px' }}
                    body={isLoading ? <Skeleton /> : getDuration}
                    className="text-xs leading-[18px] font-normal text-secondaryTxtColor truncate"
                    sortable
                ></Column>
                <Column
                    field="shop_services.name"
                    header={renderHeader(t('Service'), 'shop_services.name')}
                    style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                    body={isLoading ? <Skeleton /> : getService}
                    className="text-xs leading-[18px] font-medium text-secondaryTxtColor truncate"
                    sortable
                ></Column>
                <Column
                    field="total"
                    header={renderHeader(t('Price'), 'total')}
                    style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                    body={isLoading ? <Skeleton /> : getTotal}
                    className="text-xs leading-[18px] font-medium text-tableText truncate"
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default LocationBooking;
