export interface IGraphOptions {
    label: string;
    value: string;
    valueXField: string;
    seriesArray: any;
}
export const initAction = {
    expense: false,
};

export interface IExpenseGraphData {
    name: string;
    value: string;
}
export interface IAddExpense {
    handleClose: () => void;
    fetchAnalytics: () => Promise<void>;
}
interface ITimeFileterOptions {
    label: string;
    value: string;
    endDate?: any;
    startDate?: any;
}

export const timeFileterOptions: ITimeFileterOptions[] = [
    { label: 'All time', value: 'all' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Last Month', value: 'last_month' },
];
export interface IAddExpenseForm {
    shop_location_id: string;
    rent: null | number;
    bills: null | number;
    tools: null | number;
    insurance: null | number;
    maintenance: null | number;
    other: null | number;
    total: null | number;
    type: null | string;
}

export const timeOption = [
    {
        value: 'weekly',
        label: 'Weekly',
    },
    {
        value: 'bi_weekly',
        label: 'Bi-Weekly',
    },

    {
        value: 'monthly',
        label: 'Monthly',
    },
    {
        value: 'yearly',
        label: 'Yearly',
    },
];
export interface IClientAndPaymentSplit {
    paymentMethod: PaymentMethodItem[];
    clientSplit: any[];
    isLoading: boolean;
}
export interface PaymentMethodItem {
    name: string;
    total: string;
    percentage: string | null;
}
export interface IAnalyticsDetails {
    from_date: Date | null;
    total: {
        amount: number;
        amount_growth: number;
        client: number;
        client_growth: number;
        client_retention_rate: number;
        client_retention_rate_growth: number;
        client_repeat_rate: number;
        avg_bookings_frequency: number;
        avg_bookings_frequency_growth: number;
        productivity: number;
        productivity_growth: number;
        total_service_amount: number;
        total_product_amount: number;
    };
    sales_line_graph: any[];
    payment_method_split: any[];
    gross_revenue: any[];
    staff: any[];
    sales_by_services: any[];
    graph: any[];
    services_graph: any[];
    products_graph: any[];
    user_split: any[];
}
export interface ITeamPerformance {
    startDate: string;
    endDate: string;
    selectedFilter: {
        location_id: null;
        staff_id: null;
    };
    role: string;
}
export interface IAdvAnalyticsDetails {
    from_date: Date | null;
    total: {
        amount: number;
        amount_growth: number;
        commission: number;
        commission_growth: number;
        expense: number;
        expense_growth: number;
        rent: number;
        rent_growth: number;
    };
}
export interface IAdvAnalytics {
    selectedFilter: {
        location_id: null;
        staff_id: null;
    };
}
