import React, { useMemo } from 'react';
import { GoDotFill } from 'react-icons/go';
import { IClientAndPaymentSplit } from './Analytics.interface';
import { formatKpiContentLable } from 'src/utils/global-functions';

const ClientAndPaymentSplit = ({ paymentMethod, clientSplit, isLoading }: IClientAndPaymentSplit) => {
    const paymentMethodMapping: Record<string, { name: string; color: string; index: number }> = {
        card: { name: 'Card on file', color: '#0E34A5', index: 1 },
        cash: { name: 'Cash', color: '#1642C5', index: 2 },
        pos: { name: 'Front Desk', color: '#2C59DE', index: 3 },
        other: { name: 'Other', color: '#5279F0', index: 4 },
        loyalty: { name: 'Loyalty Points', color: '#819DF1', index: 5 },
    };

    const clientSplitMapping: Record<string, { name: string; color: string; index: number }> = {
        total: { name: 'Total Clients', color: '#0E34A5', index: 1 },
        active: { name: 'Active Clients', color: '#1642C5', index: 2 },
        new: { name: 'New Clients', color: '#2C59DE', index: 3 },
        risk: { name: 'At-risk Clients', color: '#5279F0', index: 4 },
        lapsed: { name: 'Lapsed Clients', color: '#819DF1', index: 5 },
    };

    const updatedPaymentMethods = useMemo(
        () =>
            paymentMethod
                .map((item) => {
                    const mappedItem = paymentMethodMapping[item.name] || { name: item.name, color: '#000000', index: 1 };
                    return {
                        ...item,
                        name: mappedItem.name,
                        color: mappedItem.color,
                        index: mappedItem.index,
                    };
                })
                .sort((a, b) => a.index - b.index),
        [paymentMethod],
    );

    const clientSplitMethods = useMemo(
        () =>
            clientSplit
                .map((item) => {
                    const mappedItem = clientSplitMapping[item.name] || { name: item.name, color: '#000000', index: 1 };
                    return {
                        ...item,
                        name: mappedItem.name,
                        color: mappedItem.color,
                        index: mappedItem.index,
                    };
                })
                .sort((a, b) => a.index - b.index),
        [clientSplit],
    );

    return (
        <>
            <div className="w-full flex flex-row gap-5">
                <div className="w-[50%] border border-borderSecondary rounded-xl shadow  p-5">
                    <div className="font-semibold text-xl text-mainTextColor mb-4  max-2xl:text-lg">Payment Method Split</div>
                    {updatedPaymentMethods.map((item, index) => (
                        <div key={index} className=" grid grid-cols-1 spacing-line2  spacing-line  justify-between items-center  ">
                            <div className="flex flex-row justify-between  items-center  " key={index}>
                                <div className="flex flex-row items-center gap-1">
                                    <GoDotFill size={12} color={item.color} className=" h-4 w-4 rounded-full flex" />
                                    <div className="font-normal text-base max-xlm:text-sm max-2xl:text-base text-secondaryTxtColor">
                                        {item.name}{' '}
                                        <span className={`max-xlm:text-[11px] max-2xl:text-xs text-xs text-[#079455] font-medium ${isLoading ? 'custom-loading' : ''}`}>
                                            ({item.percentage || '0.00'}%)
                                        </span>
                                    </div>
                                </div>
                                <div className={`font-semibold text-base max-2xl:text-base max-xlm:text-sm max-xlm:leading-[24px] text-secondaryTxtColor ${isLoading ? 'custom-loading' : ''}`}>
                                    ${formatKpiContentLable(item.total) || '0.00'}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-[50%] border border-borderSecondary rounded-xl shadow p-5">
                    <div className="font-semibold text-xl text-mainTextColor mb-4  max-2xl:text-lg">Client Split </div>
                    {clientSplitMethods.map((item, index) => (
                        <div className="flex flex-row justify-between items-center  spacing-line2  spacing-line" key={item.name}>
                            <div className="flex flex-row items-center gap-1">
                                <GoDotFill size={12} color={item.color} className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                                <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-base">
                                    {item.name}{' '}
                                    <span className={`text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-xs ${isLoading ? 'custom-loading' : ''}`}>({item.percentage}%)</span>
                                </div>
                            </div>
                            <div className={`font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-base max-xlm:leading-[24px] ${isLoading ? 'custom-loading' : ''}`}>
                                {item.total}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ClientAndPaymentSplit;
