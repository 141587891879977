import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DateRangeFormatFunction, Calendar as ReactCalendar, SlotInfo, View, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';
import 'react-datepicker/dist/react-datepicker.css';
import Toolbar from '../Calendar/Toolbar';
import ResourceHeader from './ResourceHeader';
import { IActionType, IResourceMap } from './Calendar.Interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { addEvent, initAction, selectCalendarData, setClearBooking, setData, setGetBookingList } from './Calendar.slice';
import { generateTimeRanges, getResourceOptions, getShortName, splitBookingTime } from 'src/utils/global-functions';
import Sidebar from './Sidebar/Sidebar';
import moment from 'moment-timezone';
import { axiosGet, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import EventCard from './EventCard';
import usePrevious from 'src/hooks/usePrevious';
import { debounce, isEqual } from 'lodash';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import Loader from 'src/components/Loader/Loader';
import { IStaff } from 'src/redux/services/common/Common.interface';
import PageHeader from 'src/components/PageHeader';
import CustomButton from 'src/components/CustomButton';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { GoDotFill } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import CheckoutSidebar from './NewCheckout/Sidebar/CheckoutSidebar';

const Calendar = () => {
    const localizer = momentLocalizer(moment);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const calendarData: any = useAppSelector(selectCalendarData);
    const shopLocationList = useAppSelector(allShopLocations);
    const prevStateValue = usePrevious(calendarData);
    const calendarRef = useRef<HTMLDivElement>(null);
    const [appointmentEventList, setAppointmentEventList] = useState<any>([]);
    const [filter, setFilter] = useState(['confirmed', 'new', 'completed', 'no_show']);
    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const [checkout, setCheckout] = useState(false);
    const [terminal, setTerminal] = useState<any>(null);
    const [isTerminalConnected, setIsTerminalConnected] = useState(false);
    const [isTerminalLoading, setIsTerminalLoading] = useState(true);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClearBooking();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        if (calendarData.selectedLocation) {
            const { timezone } = calendarData.selectedLocation;
            moment.tz.setDefault(timezone);
            const defaultDate = moment().tz(timezone).toDate();
            dispatch(setData({ timeZone: timezone, defaultDate }));
        }
    }, [calendarData.selectedLocation]);

    useEffect(() => {
        if (
            !isEqual(prevStateValue?.selectedStaff, calendarData.selectedStaff) ||
            !isEqual(prevStateValue?.selectedLocation, calendarData.selectedLocation) ||
            !isEqual(prevStateValue?.view, calendarData.view) ||
            !isEqual(prevStateValue?.getBookingCalendarDate, calendarData.getBookingCalendarDate)
        ) {
            getBookingList();
        }
    }, [calendarData.selectedStaff, calendarData.selectedLocation, calendarData.view, calendarData.getBookingCalendarDate]);

    useEffect(() => {
        if (calendarData.selectedLocation) {
            getStaff(calendarData.selectedLocation.shop_staff);
        }
    }, [calendarData.selectedLocation, calendarData.view]);

    useEffect(() => {
        if (calendarData.getBookingList) {
            getBookingList();
        }
    }, [calendarData.getBookingList]);

    useEffect(() => {
        const fetchData = async () => {
            await getLocation();
        };
        fetchData();
        return () => {
            handleClose();
            dispatch(setClearBooking());
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTimeIndicator = calendarRef.current?.querySelector('.rbc-current-time-indicator');
            if (currentTimeIndicator) {
                currentTimeIndicator.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                });
                clearInterval(interval); // Stop polling once the element is found
            }
        }, 500); // Check every 100 milliseconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [calendarData.view]);

    useEffect(() => {
        handleAppointmentStatus(filter);
    }, [calendarData.appointmentList]);

    useEffect(() => {
        if (window.StripeTerminal) {
            const terminalInstance = window.StripeTerminal.create({
                onFetchConnectionToken: () => axiosPost(API.STRIPE.TERMINAL_TOKEN, {}, { shop_id: shop.id }).then((response) => response.data.data.secret),
                onUnexpectedReaderDisconnect: () => setIsTerminalConnected(false),
                onConnectionStatusChange: ({ status }: any) => {
                    switch (status) {
                        case 'connected':
                            setIsTerminalConnected(true);
                            break;
                        case 'not_connected':
                            setIsTerminalConnected(false);
                            break;
                    }
                },
            });

            setTerminal(terminalInstance);
            handleTerminal(terminalInstance)();
        }
    }, []);

    /* useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/terminal/v1/';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []); */

    const isNewEventAvailable = () => calendarData.appointmentList.some((event: any) => event.status === 'new');

    const updatedCalendarStep = useCallback(
        (activeKey: keyof IActionType) =>
            Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IActionType] = key === activeKey; // Only the activeKey is true, others false
                return acc;
            }, {} as IActionType),
        [],
    );

    const handleClose = useCallback(() => {
        dispatch(setData({ action: initAction }));
    }, []);

    const getStaff = useCallback(
        async (staffArray: IStaff[]) => {
            if (staffArray?.length > 0) {
                let staffOptionData = staffArray?.map((item: any) => ({
                    ...item,
                    value: item.id,
                    label: item.full_name,
                    image: item?.profile_image_url,
                    shortName: getShortName(item.full_name),
                }));

                const allOption = { value: null, label: 'All Team Members', id: null };
                if (calendarData.view === 'day') {
                    staffOptionData = [allOption].concat(staffOptionData);
                }

                const staffListResourceArray: any = getResourceOptions(staffArray);
                dispatch(
                    setData({
                        resourceList: staffListResourceArray,
                        staffOptions: staffOptionData,
                        isResourceListLoad: false,
                        selectedStaff: staffOptionData.length > 1 ? (calendarData.view === 'day' ? allOption : staffOptionData[0]) : null,
                    }),
                );
            }
        },
        [calendarData.view],
    );

    const getLocation = useCallback(async () => {
        const updateState = {
            locationOptions: shopLocationList,
            selectedLocation: shopLocationList.length > 0 ? shopLocationList[0] : null,
        };
        dispatch(setData(updateState));
    }, [shopLocationList]);

    const messages = {
        previous: 'Previous',
        today: 'Today',
        next: 'Next',
    };

    const handleNavigate = useCallback((date: Date) => {
        dispatch(setData({ calendarDate: moment(date).toDate() }));
        setTimeout(() => {
            const currentTimeElement = document.querySelector('.rbc-current-time-indicator');
            if (currentTimeElement) {
                currentTimeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 100);
    }, []);

    const handleRangeChange = useCallback((range: Date[] | { start: Date; end: Date }) => {
        if (Array.isArray(range)) {
            dispatch(setData({ getBookingCalendarDate: moment(range[0]).toDate() }));
        } else {
            dispatch(setData({ getBookingCalendarDate: moment(range.start).toDate() }));
        }
    }, []);

    const handleCheckoutShow = useCallback(() => {
        if (checkout) {
            setCheckout(false);
        } else {
            dispatch(setData({ action: initAction, isSidebarOpen: false }));
            setCheckout(true);
        }
    }, [checkout]);

    const handleSelectSlot = useCallback(
        async (slotInfo: SlotInfo) => {
            if (slotInfo.action === 'select') {
                setCheckout(false);
                try {
                    const formattedDate = calendarData.timeZone && slotInfo.start.toLocaleString('en-US', { timeZone: calendarData.timeZone });
                    dispatch(setData({ isLoading: true, calendarStep: updatedCalendarStep('newAppointment') }));
                    dispatch(setClearBooking());
                    const isNewAppoinment = await isNewEventAvailable();
                    let updatedEvents = calendarData.appointmentList;
                    if (isNewAppoinment) {
                        updatedEvents = calendarData.appointmentList.filter((event: any) => event.status !== 'new');
                    }

                    const isTimeslotOccupied = updatedEvents.some((event: any) => {
                        const eventStart = moment(`${event.booking_date} ${event.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').toDate();
                        const eventEnd = moment(`${event.booking_date} ${event.booking_end_time}`, 'YYYY-MM-DD HH:mm:ss').toDate();

                        return event.staff_id === slotInfo.resourceId && ((eventStart >= slotInfo.start && eventStart < slotInfo.end) || (eventEnd > slotInfo.start && eventEnd <= slotInfo.end));
                    });

                    if (!isTimeslotOccupied) {
                        const newSlotInfo = {
                            status: 'new',
                            booking_date: moment(slotInfo.start).format('YYYY-MM-DD'),
                            booking_start_time: moment(slotInfo.start).format('HH:mm:ss'),
                            booking_end_time: moment(slotInfo.end).format('HH:mm:ss'),
                            staff_id: slotInfo.resourceId ?? calendarData.selectedStaff.id,
                        };

                        dispatch(
                            setData({
                                newSlotInfo: slotInfo,
                                selectedDate: moment(slotInfo.start).toDate(),
                                selectedTime: moment(formattedDate).toDate(),
                                selectedSlotStaffId: Number(slotInfo.resourceId ?? calendarData.selectedStaff.id),
                                isSidebarOpen: true,
                                appointmentList: [...updatedEvents, newSlotInfo],
                            }),
                        );

                        dispatch(addEvent(newSlotInfo));
                        setTimeout(() => {
                            setSelectedEvent(newSlotInfo);
                        }, 2000);
                    } else {
                        dispatch(setData({ selectedStaff: Number(slotInfo.resourceId ?? calendarData.selectedStaff.id), isSidebarOpen: true, calendarStep: initAction }));
                    }
                } catch (error) {
                    // console.error('Error handling slot selection:', error);
                } finally {
                    dispatch(setData({ isLoading: false }));
                }
            } else {
                handleClearBooking();
            }
        },
        [calendarData],
    );

    const handleClearBooking = useCallback(() => {
        const updatedEvents = calendarData.appointmentList.filter((event: any) => event.status !== 'new');

        setSelectedEvent(null);
        dispatch(setData({ appointmentList: updatedEvents, isSidebarOpen: false, calendarStep: initAction, bookedSlotInfo: null }));
        setCheckout(false);
    }, [calendarData.appointmentList]);

    const getBookingInfo = useCallback(async (id: number) => {
        let payload = {
            shop_id: shop.id,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
            dispatch(setData({ isLoading: false }));
        }
    }, []);

    const handleSelectEvent = useCallback(
        async (handleSelectEventInto: any) => {
            if (handleSelectEventInto.status === 'new') {
                return;
            }
            dispatch(setData({ isLoading: true, isSidebarOpen: true }));

            const isNewAppoinment = isNewEventAvailable();
            if (isNewAppoinment) {
                const updatedEvents = calendarData.appointmentList.filter((event: any) => event.status !== 'new');
                dispatch(setData({ appointmentList: updatedEvents }));
            } else if (handleSelectEventInto.status !== 'block_time') {
                handleSelectEventInto = await getBookingInfo(handleSelectEventInto.id);
            }

            dispatch(
                setData({
                    calendarStep: updatedCalendarStep(handleSelectEventInto.status === 'block_time' ? 'blockTime' : 'bookedAppointment'),
                    bookedSlotInfo: handleSelectEventInto,
                    selectedSlotStaffId: Number(handleSelectEventInto.staff_id),
                }),
            );
            setTimeout(() => {
                setSelectedEvent(handleSelectEventInto);
            }, 200);
        },
        [calendarData.appointmentList],
    );

    const getBookingList = useCallback(async () => {
        if (calendarData.selectedLocation) {
            const payload: {
                location_id: number;
                booking_date: string;
                type: View;
                shop_id: number;
                staff_id?: number;
            } = {
                location_id: calendarData.selectedLocation.id,
                booking_date: moment(calendarData.getBookingCalendarDate).format('YYYY-MM-DD'),
                type: calendarData.view,
                shop_id: shop.id,
            };

            if (calendarData.selectedStaff && calendarData.selectedStaff.id) {
                payload.staff_id = calendarData.selectedStaff.id;
            }

            try {
                const response = await axiosPost(API.BOOKING.LIST_BY_DATE, payload, {
                    shop_id: shop.id,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    const newArray = response.data.data.block_times.flatMap((item: any) => generateTimeRanges(item.from, item.to, item));
                    const bookingArray = response.data.data.bookings.flatMap((item: any) => splitBookingTime(item.booking_date, item.booking_start_time, item.booking_end_time, item));

                    const events = [...bookingArray, ...newArray];
                    dispatch(setData({ appointmentList: events }));
                    return events;
                }
            } catch (err: any) {
                toast.error(err?.message);
                return err;
            } finally {
                dispatch(setGetBookingList(false));
            }
        } else {
            dispatch(setData({ appointmentList: [] }));
        }
    }, [calendarData]);

    const handleTerminal = useCallback(
        (terminals: any, isConnect = false, isBtnClick = false) =>
            async () => {
                setIsTerminalLoading(true);

                if (isConnect) {
                    terminal.disconnectReader();
                } else {
                    var config = { simulated: true };
                    const discoverResult = await terminals.discoverReaders(config);

                    if (!discoverResult.error && discoverResult.discoveredReaders.length) {
                        const reader = discoverResult.discoveredReaders[0];
                        const connected = await terminals.connectReader(reader);
                        if (isBtnClick && connected?.error) {
                            toast.error(connected.error.message);
                        }
                    }
                }
                setIsTerminalLoading(false);
            },
        [],
    );

    const handleChangeFilter = useCallback(
        debounce((selectedOptions) => {
            const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
            setFilter(selectedValues);
            handleAppointmentStatus(selectedValues);
        }, 300),
        [],
    );

    // const handleAppointmentStatus = (status: any) => {
    //     setFilter(status);
    //     const filteredData = status === 'all' ? calendarData.appointmentList : calendarData.appointmentList.filter((item: any) => item.status === status || item.status === 'new');
    //     setAppointmentEventList(filteredData);
    // };

    const handleAppointmentStatus = useCallback(
        (statuses: string[]) => {
            const filteredData = calendarData.appointmentList.filter((item: any) => statuses.includes(item.status) || statuses.includes('new'));
            setAppointmentEventList(filteredData);
        },
        [calendarData.appointmentList],
    );
    const selectRangeFormat: DateRangeFormatFunction = (range, culture, localizer1) => {
        localizer1!.format(range.start, 'MMMM D', culture); // TODO: Remove
        localizer1!.format(range.end, 'MMMM D', culture); // TODO: Remove

        return 'New';
    };
    localizer.formats = {
        ...localizer.formats,
        selectRangeFormat,
    };
    const onKeyPressEvent = useCallback(async (event: any, keypressEvent: any) => {
        if (keypressEvent.keyCode === 27) {
            handleClearBooking();
        }
    }, []);
    const startAccessor = useCallback((event: any) => (event.status === 'block_time' ? moment(event.startTime).toDate() : moment(`${event.booking_date} ${event.booking_start_time}`).toDate()), []);

    const endAccessor = useCallback((event: any) => (event.status === 'block_time' ? moment(event.endTime).toDate() : moment(`${event.booking_date} ${event.booking_end_time}`).toDate()), []);

    return (
        <div className="inner-page-wrape">
            <PageHeader title={'Calendar'} subtitle="Here’s a detailed overview of your completed and upcoming appointments.">
                <CustomButton
                    outlineSecondary
                    icon={<GoDotFill size={12} color={isTerminalLoading ? '#F29339' : isTerminalConnected ? '#17B26A' : '#D9512C'} className="h-4 w-4 rounded-full flex " />}
                    className="w-full rounded-lg shadow-InputAndButton min-w-max pl-[10px]"
                    onClick={handleTerminal(terminal, isTerminalConnected, true)}
                >
                    {isTerminalLoading ? 'Connecting...' : isTerminalConnected ? 'Terminal Connected' : 'Connect a Terminal'}
                </CustomButton>
                <CustomButton outlinePrimary icon={<Plus width="16" />} onClick={handleCheckoutShow} className="!px-4 py-[9px] w-full rounded-lg shadow-InputAndButton">
                    New Checkout
                </CustomButton>
            </PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full transition-all !duration-[0.5s]">
                <div
                    ref={calendarRef}
                    className={`calendar-block ${calendarData.isSidebarOpen ? 'w-[calc(100%-360px)]' : 'w-full'} flex-1 transition-all !duration-[0.5s] ${
                        calendarData.isSidebarOpen || checkout ? 'open_calendar !transition-all !duration-[0.5s]' : 'close_calendar transition-all !duration-[0.5s]'
                    }  ${calendarData.resourceList.length >= 8 ? 'calendar-without-resource' : ''} ${calendarData.view === 'week' ? 'calendar-with-resource' : ''} `}
                >
                    <ReactCalendar
                        selectable
                        enableAutoScroll
                        showMultiDayTimes={false}
                        showAllEvents={false}
                        timeslots={4}
                        step={15}
                        scrollToTime={moment().toDate()}
                        events={appointmentEventList}
                        messages={messages}
                        localizer={localizer}
                        selected={selectedEvent}
                        defaultView={Views.DAY}
                        view={calendarData.view}
                        date={calendarData.calendarDate}
                        defaultDate={calendarData.defaultDate}
                        resources={calendarData.view === 'day' ? calendarData.resourceList : undefined}
                        resourceIdAccessor="staff_id"
                        className={calendarData.view === 'week' ? 'week-calendar' : 'custom-calendar'}
                        dayLayoutAlgorithm="no-overlap"
                        onView={() => {}}
                        resourceAccessor={(resource: IResourceMap) => resource.staff_id}
                        startAccessor={startAccessor}
                        endAccessor={endAccessor}
                        onNavigate={handleNavigate}
                        onRangeChange={handleRangeChange}
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                        onKeyPressEvent={onKeyPressEvent}
                        // onDrillDown={(e) => console.log(e)}
                        components={{
                            resourceHeader: (eventInfo: any) => <ResourceHeader eventInfo={eventInfo} />,
                            timeGutterWrapper:
                                calendarData.isResourceListLoad && calendarData.resourceList.length === 0 && calendarData.view === 'day'
                                    ? () => (
                                          <div className="flex h-full w-full justify-center items-center">
                                              <Loader />
                                          </div>
                                      )
                                    : calendarData.view === 'day' && calendarData.resourceList.length === 0
                                    ? () => (
                                          <div className="flex h-full w-full justify-center items-center">
                                              <EmptyMsgWithBtn
                                                  title="No Team Members Available"
                                                  description="Team Members data will be available once Team Member added."
                                                  btnLabel="Create Team Member"
                                                  onClick={() => navigate(ROUTES.STAFF.CREATE)}
                                              />
                                          </div>
                                      )
                                    : undefined,

                            week: {
                                header: (e) => (
                                    <>
                                        <div className="day-name ">{moment(e.date).format('ddd')}</div>
                                        <div className="day-date">{moment(e.date).format('D')}</div>
                                    </>
                                ),
                            },
                            event: (props: any) => <EventCard eventInfo={props} />,
                            toolbar: (props) => (
                                <Toolbar date={props.date} view={props.view} onNavigate={props.onNavigate} onView={props.onView} handleAppointmentStatus={handleChangeFilter} filter={filter} />
                            ),
                        }}
                    />
                </div>

                {calendarData.isSidebarOpen && <Sidebar handleClose={handleClose} />}
                {checkout && <CheckoutSidebar isOpen={checkout} handleClose={() => setCheckout(false)} />}
            </div>
        </div>
    );
};

export default memo(Calendar);
