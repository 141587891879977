import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import { allShopLocations, allShopRoles, currentShop } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { ArrowUp, ArrowDown, Users01, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dateFormate, getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/path';
import CustomButton from 'src/components/CustomButton';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import NoDataMessage from 'src/components/NoDataMessage';
import { ROUTES } from 'src/constants/routes';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { Skeleton } from 'primereact/skeleton';
import { AllLocationsOptions, AllRolesOptions, perPageOptions } from 'src/utils/global-variables';

const INIT_STATE = {
    FILTER: {
        location_id: null,
        role_id: null,
    },
    LAZY_STATE: {
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            location_id: { value: null },
            role_id: { value: null },
        },
    },
};
const StaffTable = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const shopRoleList = useAppSelector(allShopRoles);
    const [totalStaff, setTotalStaff] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [staffListArray, setStaffListArray] = useState([]);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [roleOptions, setRoleOptions] = useState<any[]>([]);
    const [selectedFilter, setSelectedFilter] = useState(INIT_STATE.FILTER);
    const [lazyState, setLazyState] = useState<any>(INIT_STATE.LAZY_STATE);
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        location_id: { value: null },
        role_id: { value: null },
    });

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);

        const roleData = getSelectBoxOptions(shopRoleList, 'id', 'name');
        setRoleOptions([AllRolesOptions, ...roleData]);
        setSelectedFilter(INIT_STATE.FILTER);
        setLazyState(INIT_STATE.LAZY_STATE);
    }, [shop]);

    useEffect(() => {
        listStaff();
    }, [lazyState, shop]);

    const listStaff = async () => {
        setIsLoading(true);
        await axiosGet(API.STAFF.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setStaffListArray(response.data.data.data);
                setTotalStaff(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleRowClick = (row: any) => {
        navigate(`${PATH.STAFF.VIEW}/${row.data.id}`);
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const handleClick = () => {
        navigate(ROUTES.STAFF.CREATE);
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const getFullName = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.profile_image_url ? <img src={row.profile_image_url} alt={row.full_name} title={row.full_name} className="w-full h-full object-cover" /> : getShortName(row.full_name)}
            </figure>
            <div>
                <p className="text-xs font-medium capitalize">{row.full_name}</p>
                <p className="text-xs font-normal text-secondaryTxtColor">{row.staff_role.name}</p>
            </div>
        </div>
    );

    const getStatus = (row: any) => (
        <div className="px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max">
            <GoDotFill size={12} className={row.shop_staff[0].is_accepted_invitation ? 'text-[#6172F3]' : row.shop_staff[0].is_declined_invitation ? 'text-[#F00]' : 'text-[#F38744]'} />
            {row.shop_staff[0].is_accepted_invitation ? t('Active') : row.shop_staff[0].is_declined_invitation ? t('Decline') : t('Pending')}
        </div>
    );

    const getType = (row: any) => <div className="capitalize">{row.pay_structure.pay_structure_type.replace(/_/g, ' ')}</div>;

    const getLastActive = (row: any) => (row.login_at ? dateFormate(row.login_at) : '-');

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                pay_structure: { pay_structure_type: 'loading' },
                login_at: 'loading',
                users_count: 'loading',
                new_users_count: 'loading',
                active_users_count: 'loading',
                first_name: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    return (
        <div
            className={`w-full border rounded-xl mt-4 flex-1 flex-col mb-5 flex shadow staff_service_table datatable-custom-service ${
                staffListArray.length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className=" w-full justify-between items-center flex flex-col">
                <div className="w-full justify-between items-center">
                    <div className={`flex items-center w-full flex-col  border-b border-gray-200`}>
                        <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                            <div className="flex flex-col flex-1">
                                <div className="flex justify-start items-center gap-1.5">
                                    <h2 className="table-title">{t('All Team Members')}</h2>
                                    <p className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
                                        <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                        {totalStaff} {t('Members')}
                                    </p>
                                </div>
                                <p className="table-subtitle">{t('Add, view and manage your brand team members.')}</p>
                            </div>
                            <div className="flex relative">
                                <CustomButton outlinePrimary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] w-full rounded-lg shadow-InputAndButton">
                                    {t('Add team member')}
                                </CustomButton>
                            </div>
                        </div>
                        <div className="flex items-center px-5 py-3 justify-between w-full">
                            <div className="table-searchInput">
                                <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                            </div>
                            <div className="flex gap-3">
                                <div className="w-[200px] location-dropdown">
                                    <SelectBox
                                        options={locationOptions}
                                        value={locationOptions.find((option) => option.value === selectedFilter.location_id)}
                                        noOptionsMessage="No Locations Found"
                                        placeholder={t('All locations')}
                                        onChangeFunc={handleFilter('location_id')}
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                </div>
                                <div className="w-[200px] location-dropdown">
                                    <SelectBox
                                        options={roleOptions}
                                        value={roleOptions.find((option) => option.value === selectedFilter.role_id)}
                                        placeholder={t('All role')}
                                        onChangeFunc={handleFilter('role_id')}
                                        noOptionsMessage="No Role Found"
                                        // allowIcon={<User01 />}
                                        classComp="outline-select-box h-[36px]"
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                </div>
                                <div className="w-[70px] xl:w-[70px] page-dropdown">
                                    <SelectBox
                                        name="page"
                                        options={perPageOptions}
                                        isClearable={false}
                                        isSearchable={false}
                                        onChangeFunc={onPageHandle}
                                        value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                        classComp="outline-select-box"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={customPaginatorTemplate}
                value={isLoading ? skeletons : staffListArray}
                totalRecords={totalStaff}
                paginator={!isLoading && totalStaff > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                onRowClick={handleRowClick}
                selectionMode="single"
                dataKey="id"
                className=" rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <NoDataMessage
                        title={`${filters.global.value || filters.location_id.value || filters.role_id.value ? t('No team members found.') : t('No team members added.')}`}
                        description={`${
                            filters.global.value || filters.location_id.value || filters.role_id.value
                                ? t('Try adjusting your filters or add new team members.')
                                : t('Start by adding new members to have them listed on your online booking website.')
                        }`}
                        iconComponent={filters.global.value || filters.location_id.value || filters.role_id.value ? <SearchLg className="text-gray-700" /> : <Users01 className="text-gray-700" />}
                    />
                }
            >
                <Column
                    field="first_name"
                    className="font-normal text-mainTextColor"
                    style={{ width: '20%' }}
                    body={isLoading ? <Skeleton /> : getFullName}
                    header={renderHeader(t('Team member'), 'first_name')}
                    sortable
                ></Column>
                <Column header={renderHeader(t('Status'), 'status')} field="status" className="font-medium text-mainTextColor" body={isLoading ? <Skeleton /> : getStatus} sortable></Column>
                <Column
                    field="pay_structure.pay_structure_type"
                    header={renderHeader(t('Team type'), 'pay_structure.pay_structure_type')}
                    className="text-xs font-medium"
                    body={isLoading ? <Skeleton /> : getType}
                    sortable
                ></Column>
                {/* <Column field="locations" header={t('Location')} className="text-xs font-semibold text-primary" body={getLocation}></Column> */}
                <Column field="login_at" header={renderHeader(t('Last active'), 'login_at')} className="text-xs font-medium" body={isLoading ? <Skeleton /> : getLastActive} sortable></Column>
                <Column field="users_count" header={renderHeader(t('Total clients'), 'users_count')} body={isLoading ? <Skeleton /> : undefined} className="text-xs font-medium" sortable></Column>
                <Column
                    field="new_users_count"
                    header={renderHeader(t('New clients'), 'new_users_count')}
                    className="text-xs font-medium"
                    body={isLoading ? <Skeleton /> : undefined}
                    sortable
                ></Column>
                <Column
                    field="active_users_count"
                    className="text-xs font-medium"
                    body={isLoading ? <Skeleton /> : undefined}
                    header={renderHeader(t('Active clients'), 'active_users_count')}
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default StaffTable;
