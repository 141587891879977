import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AmChart from './AmChart';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import moment from 'moment';
import Transactions from './Transactions';
import { sortBy } from 'lodash';
import { DataItem } from './Payment.interface';
import SChart from '../Skeleton/Payment/SChart';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import NoDataMessage from 'src/components/NoDataMessage';
import { SearchLg } from '@untitled-ui/icons-react/build/cjs';

const MainPayment = ({ setStep }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [currentTab, setCurrentTab] = useState('hours');
    const [paymentDetails, setPaymentDetails] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [balance, setBalance] = useState<number>(0);

    const timePeriodTabs = useMemo(
        () => [
            {
                name: t('24 hours'),
                value: 'hours',
            },
            {
                name: t('7 days'),
                value: 'week',
            },
            {
                name: t('30 days'),
                value: 'days',
            },
            {
                name: t('12 months'),
                value: 'months',
            },
        ],
        [],
    );
    const getStartAndEndDate = (value: string) => {
        let startDate, endDate;

        switch (value) {
            case 'hours':
                startDate = moment().startOf('day');
                endDate = moment().endOf('day');
                break;

            case 'week':
                startDate = moment().startOf('isoWeek');
                endDate = moment().endOf('isoWeek');
                break;

            case 'days':
                startDate = moment().startOf('month');
                endDate = moment().endOf('month');
                break;

            case 'months':
                startDate = moment().startOf('year');
                endDate = moment().endOf('year');
                break;

            default:
                startDate = moment().startOf('day');
                endDate = moment().endOf('day');
        }

        return {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
        };
    };

    const processArray = (array: any, groupBy: string) => {
        const resultMap: Record<string, DataItem> = {};

        if (array.length === 0) {
            return [];
        }
        array = sortBy(array, (item) => moment(item.created_at).valueOf());

        array.forEach((item: any) => {
            const { created_at: created, amount, balance: initBalance, object } = item;

            let createdAt: string;

            if (groupBy === 'months') {
                createdAt = moment(created).format('YYYY-MM');
            } else if (groupBy === 'hours') {
                createdAt = moment(created).format('YYYY-MM-DD-HH');
            } else {
                createdAt = moment(created).format('YYYY-MM-DD');
            }

            if (!resultMap[createdAt]) {
                resultMap[createdAt] = {
                    date:
                        groupBy === 'months'
                            ? moment(created).startOf('month').format('YYYY-MM-DDTHH:mm:ss')
                            : groupBy === 'hours'
                            ? moment(created).startOf('hour').format('YYYY-MM-DDTHH:mm:ss')
                            : moment(created).format('YYYY-MM-DDTHH:mm:ss'),
                    originalDate: createdAt,
                    value: initBalance,
                };
            } else {
                if (object === 'transfer') {
                    resultMap[createdAt].value += amount;
                } else if (object === 'payout') {
                    resultMap[createdAt].value -= amount;
                }
            }
        });
        const sortedArray = Object.entries(resultMap).sort(([, a], [, b]: [string, DataItem]) => moment(a.date).valueOf() - moment(b.date).valueOf());
        const sortedObject = Object.fromEntries(sortedArray);
        return Object.values(sortedObject);
    };

    useEffect(() => {
        setIsLoading(true);
        const fetchPayment = async () => {
            const { startDate, endDate } = getStartAndEndDate(currentTab);
            await getPaymentDetails(startDate, endDate, currentTab);
            await getBalance();
        };
        fetchPayment();
    }, [currentTab]);

    const getBalance = async () => {
        await axiosGet(API.STRIPE.BALANCE)
            .then((response) => {
                if (response.data.data) {
                    setBalance(response.data.data.total_amount);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getPaymentDetails = async (start_date: any, end_date: any, currentTabValue: string) => {
        await axiosGet(API.STRIPE.STATEMENTS, { shop_id: shop.id }, { start_date, end_date })
            .then((response) => {
                const data = response.data.data;
                const filteredData = processArray(data, currentTabValue);
                setPaymentDetails(filteredData);
            })
            .finally(() => setIsLoading(false));
    };

    const handleTab = (val: string) => () => {
        if (currentTab === val) return;
        setCurrentTab(val);
        setPaymentDetails(null);
    };

    return (
        <div className="flex flex-col flex-1 w-full px-5">
            <div className="flex flex-row items-center justify-between text-center w-full pb-4">
                <div className="flex flex-row">
                    <div className="font-semibold text-xl font-[#000000]">Account Balance</div>
                    <div className="text-xl font-semibold ml-2 text-[#079455]">${balance.toFixed(2)}</div>
                </div>
                <div className="flex flex-row gap-3">
                    <div className="fl-tab-btn-view2 min-h-[36px] w-full">
                        {timePeriodTabs.map((tab: any, index) => (
                            <button
                                key={index}
                                type="button"
                                onClick={handleTab(tab.value)}
                                className={`w-full fl-tab-link2 justify-center items-center min-h-[34px] min-w-fit cursor-pointer ${tab.value === currentTab ? 'active' : ''}`}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="h-[226px] max-h-[226px]">
                {isLoading ? (
                    <SChart />
                ) : paymentDetails && paymentDetails.length > 0 ? (
                    <AmChart currentTab={currentTab} paymentDetails={paymentDetails} />
                ) : (
                    // <div className="border max-h-[226px] h-[226px] flex justify-center items-center border-borderSecondary rounded-xl shadow ">
                    //     <EmptyMsgWithBtn title={t('No Payments Data')} description={t('Payments will be available once clients start booking appointments online')} />
                    // </div>
                    <div className="pie-chart-wrapper border max-h-[226px] h-[226px] flex justify-center items-center border-borderSecondary rounded-xl shadow">
                        {/* <EmptyMsgWithBtn title={t('No Service Data')} description={t('No_Service_Data_description')} /> */}
                        <NoDataMessage
                            title="No Payments Data"
                            description={'Payments will be available once clients start booking appointments online'}
                            iconComponent={<SearchLg className="text-gray-700" />}
                            buttonText={t('Create Booking')}
                            // onButtonClick={createBooking}
                        />
                    </div>
                )}
            </div>
            <Transactions />
        </div>
    );
};

export default MainPayment;
