import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import MainRoutes from './routes/MainRoutes';
import 'react-toastify/dist/ReactToastify.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import ToastBodyContainer from './components/ToastContainer';

const App = () => (
    <div className="h-full w-full bg-background relative">
        <Provider store={store}>
            <ToastBodyContainer />
            <BrowserRouter>
                <MainRoutes />
            </BrowserRouter>
        </Provider>
    </div>
);

export default App;
