import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCalendarData } from 'src/app/Calendar/Calendar.slice';

import { useAppSelector } from 'src/redux/hooks';

interface RemainingChargeProps {
    returnAmount: number;
}

const RemainingCharge: FC<RemainingChargeProps> = ({ returnAmount }) => {
    const { t } = useTranslation();
    const calendarData: any = useAppSelector(selectCalendarData);

    return (
        <div className="flex flex-col border border-gray-200  w-full rounded-xl mt-3">
            <div className={` flex items-center gap-3 px-2.5 py-2 flex-row justify-between  w-full`}>
                <div className="flex flex-col gap-1.5">
                    <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Outstanding amount</p>
                </div>
                <div>${returnAmount}</div>
            </div>
        </div>
    );
};

export default RemainingCharge;
