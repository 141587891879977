import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { allShopSettings, currentShop } from 'src/redux/services/common/Common.slice';
import { selectCalendarData, setCalendarStep, setClearPayment, setData, setPayment } from '../../Calendar.slice';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Checkbox, Label } from 'flowbite-react';
import CustomButton from 'src/components/CustomButton';
import { ArrowLeft, BankNote02, Check, CreditCard02, Gift01, Grid02, Monitor05 } from '@untitled-ui/icons-react/build/cjs';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { GoDotFill } from 'react-icons/go';

type PaymentMethod = 'cash' | 'pos' | 'stripe' | 'loyalty' | 'other';

const Checkout = ({ className }: { className?: string }) => {
    const calendarData: any = useAppSelector(selectCalendarData);
    const bookedSlotInfo = calendarData.bookedSlotInfo;

    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const { t } = useTranslation();
    const [totalPayable, setTotalPayable] = useState<number>(bookedSlotInfo.total);
    const [isSplitPayment, setIsSplitPayment] = useState<boolean>(false);
    const [paymentType, setPaymentType] = useState<PaymentMethod[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const formattedDuration = (duration: any) => moment.utc(duration.asMilliseconds()).format(duration.hours() > 0 ? (duration.minutes() > 0 ? 'H [Hours] m [Minutes]' : 'H [Hours]') : 'm [Minutes]');
    const isPaymentMethodAvailable = (type: PaymentMethod) => paymentType.includes(type);
    const shopSettingInfo = useAppSelector(allShopSettings).find((setting: any) => setting.type === 'loyalty');

    const handlePaymenttype = (event: any) => {
        const value = event.target.value;
        let filteredPayments = paymentType;
        if (!isSplitPayment) {
            const updatePayment = paymentType.includes(value) ? [] : [value];
            let payment = {
                type: value,
                amount: 0,
            };
            if (value === 'loyalty') {
                payment.amount = bookedSlotInfo.total;
            }
            dispatch(setPayment({ payments: [payment] }));
            setPaymentType(updatePayment);
            return;
        }
        if (paymentType.includes(value)) {
            filteredPayments = filteredPayments.filter((item) => item !== value);
        } else {
            if (value !== 'cash') {
                filteredPayments = filteredPayments.filter((item) => item === 'cash');
            }
            filteredPayments = [...filteredPayments, value];
        }
        const payment = filteredPayments.map((filteredPayment) => ({
            type: filteredPayment,
            amount: 0,
        }));
        dispatch(setPayment({ payments: payment }));
        setPaymentType(filteredPayments);
    };

    const handleContinue = () => {
        dispatch(setCalendarStep('paymentAppointment'));
    };
    useEffect(() => {
        const init = async () => {
            dispatch(setClearPayment());
            if (calendarData.bookedSlotInfo.payment_option === 'pay_later') {
                handlePaymenttype({ target: { value: calendarData.bookedSlotInfo.payment_type } });
            }
        };
        init();
    }, []);
    const handleSplitPayment = () => {
        setPaymentType([]);
        setIsSplitPayment(!isSplitPayment);
    };
    const handleBack = () => dispatch(setCalendarStep('bookedAppointment'));
    const getBookingInfo = async () => {
        let payload = {
            shop_id: shop.id,
            id: calendarData.bookedSlotInfo?.id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
        }
    };
    const handleComplete = async () => {
        setIsLoading(true);

        await axiosPatch(
            API.BOOKING.COMPLETE,
            {},
            {
                shop_id: shop.id,
                id: calendarData.bookedSlotInfo.id,
            },
        )
            .then(async () => {
                const info = await getBookingInfo();
                const updateState = {
                    bookedSlotInfo: info,
                    getBookingList: true,
                };
                dispatch(setData(updateState));
                dispatch(setCalendarStep('bookedAppointment'));
            })
            .catch((err: any) => {
                toast.error(err?.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <>
            <div className="p-4 flex items-center border-b gap-3">
                <Link className="close-btn" to="" onClick={handleBack}>
                    <ArrowLeft className="w-[18px] text-gray-700" />
                </Link>

                <div className="flex flex-col">
                    <span className="text-base font-semibold leading-6 text-gray-900">Checkout</span>
                    <span className="text-gray-600 text-xs font-medium leading-[18px]"> Appointment #{bookedSlotInfo.receipt_code}</span>
                </div>
            </div>

            <div className={`p-4 flex-1 overflow-y-auto ${className}`}>
                {bookedSlotInfo.booking_services.length > 0 && (
                    <div className="mb-[10px] pb-[10px] border-b border-gray-200">
                        <h1 className="text-gray-600 text-xs font-semibold leading-[18px] mb-[6px]">Service(s)</h1>
                        {bookedSlotInfo.booking_services.map((service: any, index: number) => (
                            <div className="flex justify-between mb-[4px] last:mb-0 " key={`${service.id}-${index}`}>
                                <p className="text-gray-900 font-normal text-xs leading-[18px] gap-1 flex flex-row">
                                    <span className="truncate max-w-[145px]">{`${service.service.name}`}</span>
                                    <span className="text-gray-600 font-normal text-xs leading-[18px]">({formattedDuration(moment.duration(service.duration))})</span>
                                </p>
                                <p className="ml-auto text-gray-600 font-normal text-xs leading-[18px]">${service.price}</p>
                            </div>
                        ))}
                    </div>
                )}

                {bookedSlotInfo.booking_products.length > 0 && (
                    <div className="border-b border-gray-200  pb-[10px] mb-[10px]">
                        <h1 className="text-gray-600 text-xs font-semibold leading-[18px] mb-[6px]">Product(s)</h1>
                        {bookedSlotInfo.booking_products.map((product: any, index: number) => (
                            <div className="flex justify-between mb-[4px] last:mb-0" key={`${product.id}-${index}`}>
                                <p className="text-gray-900 font-normal text-xs leading-[18px] gap-1 flex flex-row">
                                    <span className="truncate max-w-[145px]">{`${product.product.name}`}</span>
                                    <span className="text-gray-600 font-normal text-xs leading-[18px]">{`(${product.product_variant.size} ${product.product.unit_of_measure})`}</span>
                                </p>
                                <p className="ml-auto text-gray-600 font-normal text-xs leading-[18px]">${product.product_variant.price}</p>
                            </div>
                        ))}
                    </div>
                )}

                <div className="flex justify-between mb-[10px] pb-[10px] border-b border-gray-200">
                    {/* <p className="text-xs font-medium leading-[18px] text-gray-900">Subtotal</p> */}
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Subtotal</p>
                        {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )}
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${bookedSlotInfo.subtotal}</p>
                </div>
                <div className="flex justify-between mb-[12px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Taxes</p>
                        {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )}
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${bookedSlotInfo.taxes_and_fees}</p>
                </div>

                <div className="flex justify-between mb-3 pb-3 border-b border-gray-200">
                    <p className="text-gray-900 font-bold text-sm leading-5">Total</p>
                    <p className="ml-auto text-gray-900 font-bold text-sm leading-5">${calendarData.bookedSlotInfo.payment_option === 'pay_now' ? '0.00' : totalPayable}</p>
                </div>
            </div>
            <div className="flex flex-col ">
                <div className="flex flex-col px-4">
                    <div className="mb-4 flex justify-between pt-3">
                        <span className="text-gray-900 text-sm font-semibold leading-5"> Select a Payment Method {calendarData.bookedSlotInfo.payment_option === 'pay_now' && 'for tip'}</span>
                        {calendarData.bookedSlotInfo.payment_option !== 'pay_now' && (
                            <span className="text-gray-900 text-xs font-medium leading-[18px] flex">
                                <Checkbox id="isSplitPayment" checked={isSplitPayment} onChange={handleSplitPayment} />
                                <p className="ml-2">Split Payment</p>
                            </span>
                        )}
                    </div>

                    <Label htmlFor="cashPayment" className={`payment-radio-block   ${isPaymentMethodAvailable('cash') && 'active'}`}>
                        <input type="button" id="cashPayment" name="paymentMethod" className="payment-radio" value={'cash'} onClick={handlePaymenttype} />
                        <div className="payment-radio-content  ">
                            <div className={`text-center flex items-center gap-3`}>
                                <div className="payment-icon-container ">
                                    <BankNote02 className="payment-icon " />
                                </div>
                                <p className="payment-radio-text ">Cash</p>
                            </div>
                        </div>
                    </Label>
                    <Label htmlFor="frontDeskPayment" className={`payment-radio-block ${isPaymentMethodAvailable('pos') && 'active'}`}>
                        <input type="button" id="frontDeskPayment" name="paymentMethod" className="payment-radio" value={'pos'} onClick={handlePaymenttype} />
                        <div className="payment-radio-content">
                            <div className="text-center flex items-center gap-3 w-full">
                                <div className="payment-icon-container">
                                    <Monitor05 className="payment-icon" />
                                </div>
                                <p className="payment-radio-text">Terminal</p>

                                <p className="flex flex-row gap-1 ml-auto items-center ">
                                    <GoDotFill className="h-3 mb-[2px] w-3 rounded-full flex mr-[2px]  text-[#D92D20]" />
                                    {/* resd mate text-[#D92D20] */}
                                    <span className="text-mainTextColor font-medium text-xs leading-[18px]">Disconnected</span>
                                </p>
                            </div>
                        </div>
                    </Label>

                    {calendarData.selectedClientInfo?.default_card && (
                        <Label htmlFor="creditCardPayment" className={`payment-radio-block ${isPaymentMethodAvailable('stripe') && 'active'}`}>
                            <input type="button" id="creditCardPayment" name="paymentMethod" className="payment-radio" value={'stripe'} onClick={handlePaymenttype} />
                            <div className="payment-radio-content">
                                <div className={`text-center flex items-center gap-3 w-full`}>
                                    <div className="payment-icon-container">
                                        <CreditCard02 className="payment-icon" />
                                    </div>
                                    <p className="payment-radio-text">Credit Card</p>
                                    <p className="ml-auto text-gray-600 font-normal text-xs leading-[18px]">⭑⭑⭑⭑ - ⭑⭑⭑⭑ - ⭑⭑⭑⭑ -{calendarData.selectedClientInfo?.default_card?.card?.last4}</p>
                                </div>
                            </div>
                        </Label>
                    )}

                    <Label htmlFor="otherPayment" className={`payment-radio-block group hover:border-[#7191EF] ${isPaymentMethodAvailable('other') && 'active'}`}>
                        <input type="button" id="otherPayment" name="paymentMethod" className="payment-radio" value={'other'} onClick={handlePaymenttype} />
                        <div className="payment-radio-content">
                            <div className={`text-center flex items-center gap-3 w-full`}>
                                <div className="payment-icon-container">
                                    <Grid02 className="payment-icon" />
                                </div>
                                <p className="payment-radio-text">Other</p>
                            </div>
                        </div>
                    </Label>
                    {calendarData.bookedSlotInfo.payment_option !== 'pay_now' && shopSettingInfo !== undefined && shopSettingInfo.status && (
                        <Label htmlFor="loyalPointPayment" className={`payment-radio-block ${isPaymentMethodAvailable('loyalty') && 'active'}`}>
                            <input
                                type="button"
                                id="loyalPointPayment"
                                disabled={isSplitPayment || !(calendarData.bookedSlotInfo?.user?.total_loyalty_amount && calendarData.bookedSlotInfo?.user?.total_loyalty_amount >= totalPayable)}
                                name="paymentMethod"
                                className="payment-radio"
                                value={'loyalty'}
                                onClick={handlePaymenttype}
                            />
                            <div className="payment-radio-content">
                                <div
                                    className={`text-center flex items-center gap-3 w-full ${
                                        isSplitPayment || !(calendarData.bookedSlotInfo?.user?.total_loyalty_amount && calendarData.bookedSlotInfo?.user?.total_loyalty_amount >= totalPayable)
                                            ? 'opacity-50'
                                            : ''
                                    }`}
                                >
                                    <div className="payment-icon-container">
                                        <Gift01 className="payment-icon" />
                                    </div>
                                    <p className="payment-radio-text">Loyalty points</p>
                                    <p className="ml-auto text-gray-600 font-normal text-xs leading-[18px]">{calendarData.bookedSlotInfo.user.total_loyalty_points} points</p>
                                </div>
                            </div>
                        </Label>
                    )}
                </div>
                {calendarData.bookedSlotInfo.payment_option === 'pay_now' ? (
                    <div className="border-t px-4 py-4 w-full">
                        <div className="flex gap-4 w-full">
                            <CustomButton className="w-1/2" disabled={paymentType.length < (isSplitPayment ? 2 : 1)} secondary onClick={handleContinue}>
                                {t('Pay tip')}
                            </CustomButton>
                            <CustomButton type="button" className="w-1/2" disabled={isLoading} isLoading={isLoading} primary onClick={handleComplete}>
                                {t('Complete')}
                            </CustomButton>
                        </div>
                    </div>
                ) : (
                    <div className="border-t px-4 py-4 w-full">
                        <div className="flex gap-4 w-full">
                            <CustomButton className="w-1/2" secondary onClick={handleBack}>
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton type="button" disabled={paymentType.length < (isSplitPayment ? 2 : 1)} className="w-1/2" primary onClick={handleContinue}>
                                {t('Continue')}
                            </CustomButton>
                        </div>
                    </div>
                )}
            </div>
            {/* <h1>checkout</h1> */}
        </>
    );
};
export default Checkout;
