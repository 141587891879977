import _ from 'lodash';
import moment from 'moment';
import { ALLOW_IMAGE_TYPES, DATE_FORMAT, DATE_TIME_FORMAT, LOCAL_STORAGE_KEYS } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import americanCardIcon from 'src/assets/img/cards/american.png';
import defaultCardIcon from 'src/assets/img/cards/credit-card.png';
import dinerCardIcon from 'src/assets/img/cards/diners.png';
import discoverCardIcon from 'src/assets/img/cards/discover.png';
import jcbCardIcon from 'src/assets/img/cards/jcb.png';
import masterCardIcon from 'src/assets/img/cards/mastercard.png';
import unionCardIcon from 'src/assets/img/cards/unionpay.png';
import visaCardIcon from 'src/assets/img/cards/visa.png';
import { defaultImage } from 'src/theme/Images';

declare global {
    interface Window {
        google: any;
        initMap: () => void;
    }
}
export enum EStripeCardType {
    VISA = 'visa',
    MASTER = 'mastercard',
    AMERICAN = 'amex',
    DINER = 'diners',
    DISCOVER = 'discover',
    JCB = 'jcb',
    UNION = 'unionpay',
}
const logout = () => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem(LOCAL_STORAGE_KEYS.authToken);
    window.location.href = `${window.location.origin}/`;
};
const formateAmount = (amount: any) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD', // Change the currency code as needed
    }).format(amount);

const endpointReplace = (url: string, params?: { [key: string]: string }) =>
    url.replace(/{(\w+)}/g, (match: string, param: string) => {
        if (params && params.hasOwnProperty(param)) {
            return params[param];
        }
        return match; // Keep the original placeholder if the parameter is missing
    });

const handleNavigate = (user: any, pathname: string) => {
    if (user) {
        const shopStaff = user.shop_staff[0];
        const accountStep = shopStaff.account_step;
        const isPasswordSet = user.is_password_set;
        const social = user.social_id.google || user.social_id.facebook || user.social_id.apple;

        if ((!isPasswordSet && !social) || (!user.phone && social)) {
            return ROUTES.REGISTER;
        }

        let requiredSteps = ['complete_account', 'account_type', 'brand'];
        let isAdmin = true;
        let isStaff = false;
        let isIndividual = false;

        if (user && user.roles && user.roles.length > 0) {
            isStaff = user.roles.some((role: any) => role.name === 'staff_rent' || role.name === 'staff_commission');
            isAdmin = user.roles.some((role: any) => role.name === 'owner' || role.name === 'individual');
            isIndividual = user.roles.some((role: any) => role.name === 'individual');
        }

        if (isStaff && !isAdmin) {
            requiredSteps = ['invitation', 'profile', 'working_hours'];
        } else if (!isStaff && !isIndividual && shopStaff.created_by_id) {
            requiredSteps = ['complete_account'];
        }

        const isAllStepsCompleted = requiredSteps.every((step) => (step === 'stripe_account' ? accountStep[step] === true || accountStep[step] === 'pending' : accountStep[step] === true));

        if (!isAllStepsCompleted) {
            return ROUTES.SIGNUP;
        }
    }
    return pathname !== ROUTES.SIGNUP && pathname !== ROUTES.REGISTER ? pathname : ROUTES.DASHBOARD;
};

const getResourceOptions = (
    data: Array<any>,
    first_name: string = 'first_name',
    last_name: string = 'last_name',
    id: string = 'id',
    profile_image_url: string = 'profile_image_url',
    role: string = 'staff_role',
) => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        name: `${res[first_name]} ${res[last_name]}`,
        staff_id: res[id],
        profile_image_url: res[profile_image_url],
        role: res[role].name,
    }));
};

const getSelectBoxOptions = (data: Array<any>, value: string = 'name', label: string = 'name', id: string = 'id', shortName: string | null = 'shortname', includeImage: string | null = null) => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        ...res,
        value: res[value],
        label: res[label],
        id: res[id],
        shortName: (shortName && res[shortName]) ?? null,
        image: includeImage ? res[includeImage] : null,
    }));
};
const getSelectBoxOptionsIdAsValue = (data: Array<any>, value: string = 'id', label: string = 'name', id: string = 'id') => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        value: res[value],
        label: res[label],
        id: res[id],
    }));
};
const getSelectBoxStaffOptionsIdAsValue = (data: Array<any>, value: string = 'id', first_name: string = 'first_name', last_name: string = 'last_name', id: string = 'id') => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        value: res[value],
        label: `${res[first_name]} ${res[last_name]}`,
        id: res[id],
    }));
};

const convertBase64ToFile = (appLogoImage: any) => {
    try {
        const imageExtension = appLogoImage.substring(appLogoImage.indexOf('/') + 1, appLogoImage.indexOf(';base64'));

        const currentTimestamp = Date.now();
        const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
        const filename = `${currentTimestamp}_${randomNum}.${imageExtension}`;

        const base64Data = appLogoImage.replace(/^data:[^;]+;base64,/, '');

        const uint8Array = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));

        const blob = new Blob([uint8Array], {
            type: 'application/octet-stream',
        });

        const convertedFile = new File([blob], filename, {
            type: 'application/octet-stream',
        });
        return {
            convertedFile: convertedFile,
            filename: filename,
        };
    } catch (error) {
        // console.error('Error converting base64 to file:', error);
    }
};

const capitalizeFirstLetter = (str: string) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str);
const formattedDuration = (duration: string) => {
    const durationFormate = moment.duration(duration);
    return moment.utc(durationFormate.asMilliseconds()).format(durationFormate.hours() > 0 ? 'H [hours] m [minutes]' : 'm [minutes]');
};

const getShortName = (name: string) => {
    const shortName = name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
    return shortName;
};

function capitalizeFirstLetterAndUnderscore(str: string) {
    let result = str;
    if (str) {
        const words = str.split('_');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        result = capitalizedWords.join(' ');
    }
    return result;
}
function capitalizeFirstLetterSpace(str: string) {
    const words = str.split(' ');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join(' ');
    return result;
}
function capitalizeFirstLetterDash(str: string | null | undefined) {
    if (!str) {
        return '';
    }

    const words = str.split('_');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join('-');
    return result;
}

const checkFileTypeValidation = (uploadedFile: any, size: any) => {
    const validationResults = _.map(uploadedFile, (file: File) => {
        if (!ALLOW_IMAGE_TYPES.includes(file.type)) {
            return {
                result: false,
                message: 'Invalid file format. Please upload a valid image file.',
            };
        } else if (file.size > size * 1024) {
            return {
                result: false,
                message: `Please ensure your image is within the maximum allowed size of ${size}kb`,
            };
        } else {
            return { result: true, message: '' };
        }
    });

    const validationResult = _.every(validationResults, (result: { result: boolean }) => result.result === true);

    return {
        result: validationResult,
        message: validationResult ? '' : validationResults[0].message,
    };
};

const fontWeightMap: { [key: number]: string } = {
    100: 'thin',
    200: 'extralight',
    300: 'light',
    400: 'normal',
    500: 'medium',
    600: 'semibold',
    700: 'bold',
    800: 'extrabold',
    900: 'black',
};

const getFontWeight = (weight: any) => fontWeightMap[weight] || 'unknown'; // Return 'unknown' if the weight is not found

const generateTimeRanges = (startTime: Date, endTime: Date, item: any) => {
    const ranges = [];

    const start = moment(startTime, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(endTime, 'YYYY-MM-DD HH:mm:ss');

    while (start.isBefore(end)) {
        const nextEnd = moment.min(start.clone().endOf('day'), end);

        ranges.push({
            ...item,
            startTime: start.format('YYYY-MM-DD HH:mm:ss'),
            endTime: nextEnd.format('YYYY-MM-DD HH:mm:ss'),
            status: 'block_time',
        });

        start.add(1, 'day').startOf('day');
    }

    return ranges;
};
const splitBookingTime = (bookingDate: string, startTime: string, endTime: string, item: any) => {
    const start = moment(`${bookingDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(`${bookingDate} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');

    if (end.isBefore(start)) {
        const firstPart = {
            ...item,
            booking_date: start.format('YYYY-MM-DD'),
            booking_start_time: start.format('HH:mm:ss'),
            booking_end_time: '23:59:59',
        };

        const secondPart = {
            ...item,
            booking_date: end.add(1, 'day').format('YYYY-MM-DD'),
            booking_start_time: '00:00:00',
            booking_end_time: end.format('HH:mm:ss'),
        };

        return [firstPart, secondPart];
    } else {
        return [
            {
                ...item,
                booking_date: start.format('YYYY-MM-DD'),
                booking_start_time: start.format('HH:mm:ss'),
                booking_end_time: end.format('HH:mm:ss'),
            },
        ];
    }
};

const dateFormatTZtoDate = (str: string) => moment(str, moment.ISO_8601).format('DD/MM/YYYY');

const changeDateformat = (str: string, fromFormat: string, toFormat: string) => moment(str, fromFormat).format(toFormat);

const isUpcomingStatus = ['no_show', 'request_canceled', 'pending'];

const getStatusClass = (status: string) => {
    if (isUpcomingStatus.includes(status)) {
        return 'text-yellow-400';
    } else if (status === 'canceled' || status === 'declined' || status === 'expired') {
        return 'text-dangerText';
    } else if (status === 'confirmed') {
        return 'text-primary';
    } else {
        return 'text-green';
    }
};

const getCardTypeImage = (cardType: string) => {
    switch (cardType?.toUpperCase()) {
        case EStripeCardType.VISA.toUpperCase():
            return visaCardIcon;
        case EStripeCardType.MASTER.toUpperCase():
            return masterCardIcon;
        case EStripeCardType.AMERICAN.toUpperCase():
            return americanCardIcon;
        case EStripeCardType.DINER.toUpperCase():
            return dinerCardIcon;
        case EStripeCardType.DISCOVER.toUpperCase():
            return discoverCardIcon;
        case EStripeCardType.JCB.toUpperCase():
            return jcbCardIcon;
        case EStripeCardType.UNION.toUpperCase():
            return unionCardIcon;
        default:
            return defaultCardIcon;
    }
};
function formatName(name: string) {
    if (name === null) {
        return null;
    }
    const words = name.split(' ');
    const firstWordCapitalized = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    const lastNameInitial = words.length > 1 ? `${words[1].charAt(0).toUpperCase()}.` : '';
    return `${firstWordCapitalized} ${lastNameInitial}`;
}

function formatShortName(firstName: string, lastName: string) {
    if (firstName === null || lastName === null) {
        return null;
    }
    const formattedFirstName = firstName.length > 0 ? firstName[0].toUpperCase() + firstName.slice(1) : firstName;

    const formattedLastName = `${lastName.charAt(0).toUpperCase()}.`;

    return `${formattedFirstName} ${formattedLastName}`;
}

function checkFieldIsZero(array: [], fieldName: string) {
    return array.every((item) => parseFloat(item[fieldName]) === 0);
}

function formatKpiContentLable(num: any) {
    const roundedValue = Number(num).toFixed(2);
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(Number(roundedValue));
}

const onError = (event: any) => {
    event.target.src = defaultImage;
};

const getDayNumber = (day: string) => {
    let number = 0;
    switch (day) {
        case 'sunday':
            number = 0;
            break;
        case 'monday':
            number = 1;
            break;
        case 'tuesday':
            number = 2;
            break;
        case 'wednesday':
            number = 3;
            break;
        case 'thursday':
            number = 4;
            break;
        case 'friday':
            number = 5;
            break;
        case 'saturday':
            number = 6;
            break;
        default:
            break;
    }
    return number;
};
const getDateRange = (range: any, allTimeStartDate?: any) => {
    switch (range) {
        case 'last_7_days':
            return {
                start: moment().subtract(6, 'days').toDate(),
                end: moment().toDate(),
            };
        case 'last_30_days':
            return {
                start: moment().subtract(29, 'days').toDate(),
                end: moment().toDate(),
            };
        case 'last_90_days':
            return {
                start: moment().subtract(89, 'days').toDate(),
                end: moment().toDate(),
            };
        case 'last_180_days':
            return {
                start: moment().subtract(179, 'days').toDate(),
                end: moment().toDate(),
            };
        case 'last_year':
            return {
                start: moment().subtract(1, 'year').toDate(),
                end: moment().toDate(),
            };
        case 'monthly':
            return {
                start: moment().startOf('month').toDate(),
                end: moment().endOf('month').toDate(),
            };
        case 'weekly':
            return {
                start: moment().isoWeekday(0).startOf('week').toDate(),
                end: moment().isoWeekday(0).endOf('week').toDate(),
            };
        case 'quarterly':
            return {
                start: moment(moment().startOf('quarter'), 'YYYY-MM-DD').toDate(),
                end: moment(moment().endOf('quarter'), 'YYYY-MM-DD').toDate(),
            };
        case 'yearly':
            return {
                start: moment(moment().startOf('year'), 'YYYY-MM-DD').toDate(),
                end: moment(moment().endOf('year'), 'YYYY-MM-DD').toDate(),
            };
        case 'daily':
            return {
                start: moment().toDate(),
                end: moment().toDate(),
            };
        case 'all_time':
            return {
                start: moment(allTimeStartDate).toDate(),
                end: moment().toDate(),
            };
        case 'yesterday':
            return {
                start: moment().subtract(1, 'days').toDate(),
                end: moment().subtract(1, 'days').toDate(),
            };
        case 'last_quarter':
            return {
                start: moment().subtract(3, 'months').startOf('month').toDate(),
                end: moment().subtract(1, 'months').endOf('month').toDate(),
            };
        case 'last_week':
            return {
                start: moment().subtract(1, 'weeks').day(0).toDate(),
                end: moment().subtract(1, 'weeks').day(6).toDate(),
            };
        case 'last_month':
            return {
                start: moment().subtract(1, 'months').startOf('month').toDate(),
                end: moment().subtract(1, 'months').endOf('month').toDate(),
            };
        case 'this_week':
            return {
                start: moment().day(0).toDate(),
                end: moment().day(6).toDate(),
            };
        case 'this_month':
            return {
                start: moment().startOf('month').toDate(),
                end: moment().endOf('month').toDate(),
            };
        case 'this_year':
            return {
                start: moment().startOf('year').toDate(),
                end: moment().endOf('year').toDate(),
            };
        default:
            return {};
    }
};

const formatDate = (dateString: string) => {
    const date = moment(dateString);
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (date.isSame(today, 'day')) {
        return `Today, ${date.format('h:mm A')}`;
    } else if (date.isSame(tomorrow, 'day')) {
        return `Tomorrow, ${date.format('h:mm A')}`;
    } else {
        return date.format('MMM Do · h:mm A'); // "Jan 10th · 10:30AM" format
    }
};
const calculateDueDate = ({ currentDateTime, day, frequency, monthDay }: any) => {
    const currentMoment = moment();
    const selectedMoment = moment(currentDateTime);

    const isBeforeDate = selectedMoment.isBefore(currentMoment);
    const selectedDate = isBeforeDate ? currentMoment : selectedMoment;

    const selectedDayNumber = getDayNumber(day);

    let startDate;
    let endDate;

    switch (frequency) {
        case 'monthly':
            if (monthDay === 'first') {
                endDate = selectedDate.clone().add(1, 'month').startOf('month');
            } else {
                endDate = selectedDate.clone().endOf('month');
            }
            break;
        case 'weekly':
            startDate = selectedDate.clone().day(selectedDate.day() >= selectedDayNumber ? selectedDayNumber : selectedDayNumber - 7);
            endDate = startDate.clone().add(6, 'days');
            break;
        case 'bi_weekly':
            startDate = selectedDate.clone().day(selectedDate.day() >= selectedDayNumber ? selectedDayNumber : selectedDayNumber - 7);
            endDate = startDate.clone().add(13, 'days');
            break;
        case 'daily':
            endDate = currentMoment.clone().add(1, 'day');
            break;
    }
    return { startDate, endDate };
};
const dateFormate = (date: string) => moment(date).format(DATE_FORMAT);
const dateTimeFormate = (date: string) => moment(date, 'YYYY-MM-DD HH:mm:ss').format(DATE_TIME_FORMAT);

export {
    logout,
    formateAmount,
    endpointReplace,
    handleNavigate,
    getSelectBoxOptions,
    convertBase64ToFile,
    capitalizeFirstLetter,
    getShortName,
    getSelectBoxOptionsIdAsValue,
    checkFileTypeValidation,
    capitalizeFirstLetterAndUnderscore,
    dateFormatTZtoDate,
    changeDateformat,
    getSelectBoxStaffOptionsIdAsValue,
    capitalizeFirstLetterSpace,
    getResourceOptions,
    generateTimeRanges,
    getStatusClass,
    getCardTypeImage,
    formatName,
    formatShortName,
    checkFieldIsZero,
    formatKpiContentLable,
    onError,
    getDayNumber,
    formattedDuration,
    splitBookingTime,
    getDateRange,
    formatDate,
    getFontWeight,
    capitalizeFirstLetterDash,
    calculateDueDate,
    dateFormate,
    dateTimeFormate,
};
