import { Check, ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import React, { memo, useEffect, useLayoutEffect } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { getCountries } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import InputText from '../InputText/InputText';

interface IProps {
    disabled?: boolean;
    name: string;
    errors: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
    phoneInputParentClassName?: string;
    value: string;
    onChangeFunc: (e: any) => void;
}

const CountryInput = ({ disabled, name, value, onChangeFunc, errors, phoneInputParentClassName = '' }: IProps) => {
    const initialCountry = getCountries().find((country) => country === value) || 'CA';

    const initialSelectedNumber = {
        image: initialCountry ? require(`src/assets/flags/${initialCountry}.svg`) : require(`src/assets/flags/CA.svg`).default,
        name: en[initialCountry ? initialCountry : 'CA'],
        country: initialCountry ? initialCountry : 'CA',
    };

    const [searchTerm, setSearchTerm] = React.useState<string | undefined>(undefined);
    const [Switch, setSwitch] = React.useState(false);
    const [numberList, setNumberList] = React.useState<any[]>([]);
    const [selectedNumber, setSelectedNumber] = React.useState<any>(initialSelectedNumber);

    useEffect(() => {
        const handler = (e: any) => {
            if (!e.target.classList.contains('country') && !e.target.classList.contains('clear')) {
                setSwitch(false);
            }
        };
        window.addEventListener('click', handler);

        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);

    const handlerInputClick = (e: any) => {
        e.stopPropagation();

        if (!Switch) {
            setSearchTerm('');
        }
        setSwitch(!Switch);
    };

    const handleCountryCode = (option: any) => {
        setSelectedNumber(option);
        onChangeFunc(option.country);
    };

    const [filterList, setFilterList] = React.useState<any[]>([]);

    useLayoutEffect(() => {
        const fetchData = async () => {
            let list = getCountries().map((country) => ({
                image: require(`src/assets/flags/${country}.svg`),
                name: en[country],
                country: country,
            }));
            const priorityCountries = ['Canada', 'United States'];

            const customSortValue = (country: string) => {
                const priorityIndex = priorityCountries.indexOf(country);

                return priorityIndex !== -1 ? priorityIndex : priorityCountries.length + 1;
            };

            const shortedList = list.sort((a, b) => customSortValue(a.name) - customSortValue(b.name) || a.name.localeCompare(b.name));
            setNumberList(shortedList);
            setFilterList(shortedList);
        };

        fetchData();
    }, []);

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
    };

    useEffect(() => {
        const filteredList = numberList.filter((country) => country.name.toLowerCase().includes(searchTerm));

        if (searchTerm === '') {
            setFilterList(numberList);
        } else {
            setFilterList(filteredList);
        }
    }, [searchTerm]);

    return (
        <div className={`relative ${phoneInputParentClassName}`}>
            <div className={`${disabled ? '' : ''} fl-input-field fl-phone-field form_input_control !h-10 custom-hover-effect ${Switch && ''} ${errors ? 'is-invalid' : ''}`}>
                <span className={`flex items-center justify-between w-full flex-none ${disabled ? '!cursor-default' : 'cursor-pointer'}`} onClick={handlerInputClick}>
                    <div className="flex gap-x-[5px] items-center">
                        <img src={selectedNumber.image} alt="" className="w-[18px] h-[18px] object-contain" />
                        <span className="font-normal text-grayLight600 mr-2">{selectedNumber.name}</span>
                    </div>
                    <ChevronDown className="w-4 h-4 text-gray400 flex-none" />
                </span>
            </div>
            {!disabled && Switch && (
                <div id="dropdownDelay" className="z-10 absolute shadow-lg bg-white w-full border border-utilityGray200 my-1 rounded-lg">
                    {/* <div className="input-group"> */}
                    <div className="p-1">
                        <InputText
                            className="form_input_control country"
                            placeholder="Search Country"
                            inputName={name}
                            value={searchTerm}
                            onChangeFunc={handleSearchTermChange}
                            onIconClick={handleSearchTermClick}
                            isClearable={true}
                            parentClassName="input-group"
                        />
                    </div>
                    <ul className="country-code-dropdown-list py-1  max-h-[150px] overflow-y-auto scrollbar-hide" aria-labelledby="dropdownDelayButton">
                        {filterList.map((country: any, indexNumberCode: number) => (
                            <li
                                onClick={() => handleCountryCode(country)}
                                className={`country-code-dropdown-li px-2 py-1.5 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] flex justify-between items-center align-middle ${
                                    selectedNumber.name === country.name && 'active'
                                }`}
                                key={indexNumberCode}
                            >
                                <div className="flex items-center">
                                    <img src={country.image} className="w-[18px] h-[18px] object-contain mx-1.5" alt="" />
                                    <span className="font-normal text-grayLight600 mr-2">{country.name}</span>
                                </div>

                                {selectedNumber.country === country.country && <Check className="w-5 h-5 text-primary" />}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default memo(CountryInput);
