import React, { FC, Ref, forwardRef, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { AlarmClockCheck, AlarmClockOff, Check, ChevronDown, ChevronUp, Clock, DotsVertical, EyeOff, X, XClose } from '@untitled-ui/icons-react/build/cjs';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import { actionBtnIcon } from './Sidebar.const';
import { IAppointmentOption } from './Sidebar.Interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { initAction, selectCalendarData, setCalendarStep, setClearBooking, setData, updateAppointmentStatus } from '../Calendar.slice';
import moment from 'moment-timezone';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IconChevronRight } from 'src/theme/Images';
import { currentShop } from 'src/redux/services/common/Common.slice';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { useTranslation } from 'react-i18next';
import CancelModal from './CancelAppointment/CancelModal';
import { StatusLabelProps } from '../Calendar.Interface';
import DatePickerWithArrow from 'src/components/DatePickerWithArrow/DatePickerWithArrow';

const AppointmentHeader = () => {
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(currentShop);
    const { t } = useTranslation();
    const [appointmentOptions, setAppointmentOptions] = useState<IAppointmentOption[]>([]);
    const calendarData: any = useAppSelector(selectCalendarData);
    const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
    const [isColapse, setIsColapse] = useState<boolean>(true);
    const getButtonsForStatus = () => {
        if (calendarData.bookedSlotInfo.status === 'confirmed') {
            let options: string[] = ['edit_appointment', 'no_show_appointment'];
            if (calendarData.bookedSlotInfo.payment_option === 'pay_now') {
                options.push('cancel_refund_appointment');
            } else {
                options.push('cancel_appointment');
            }

            return options;
        } else if (
            calendarData.bookedSlotInfo.status === 'canceled' ||
            calendarData.bookedSlotInfo.status === 'no_show' ||
            calendarData.bookedSlotInfo.status === 'declined' ||
            calendarData.bookedSlotInfo.status === 'completed'
        ) {
            return ['reschedule_appointment'];
        } else {
            return [];
        }
    };
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
        if (calendarData.selectedLocation) {
            const filteredData = calendarData.locationOptions.filter((item: any) => item.id === calendarData.selectedLocation.id);
            moment.tz.setDefault(filteredData[0].timezone);
            setTimeZone(filteredData[0].timezone);
        }
    }, [calendarData.selectedLocation]);

    useEffect(() => {
        if (calendarData.bookedSlotInfo) {
            const options: IAppointmentOption[] = actionBtnIcon.filter((btn) => getButtonsForStatus().includes(btn.value));
            setAppointmentOptions(options);
        }
    }, [calendarData.bookedSlotInfo]);

    const TimePickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        <span className="text-primary font-medium" onClick={props.onClick}>
            {props.value}
        </span>
    ));
    const handleDateSelect = (e: any) => {
        const updateState = {
            selectedDate: format(new Date(e), 'eee, MMM d'),
        };
        dispatch(setData(updateState));
    };
    const CustomDatepickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        // <div onClick={props.onClick} className="text-primary font-medium">
        //     {props.value ? format(new Date(props.value), 'eee, MMM d') : 'Select date'}
        // </div>
        // <SelectBox />
        <div className="w-[193px] ml-6">
            <div className="relative">
                <DateSelect
                    inputName="start_date"
                    className={`form_control_date  font-medium text-xs relative w-full `}
                    placeholder={t('Select date')}
                    dateFormat="yyyy-MM-dd"
                    isFormate={false}
                    // InputClass={`${fieldState.error && 'is-invalid'}`}
                    value={calendarData.selectedDate}
                    onChangeValue={handleSelectedDate}
                    labelEndIcon={<ChevronDown className="text-gray-700 w-[22px]" />}
                />
            </div>
        </div>
    ));

    const handleTimeBlock = async () => {
        // const updateState = {
        //     action: { ...initAction, blockTime: true },
        // };
        // await dispatch(setData(updateState));
        dispatch(setCalendarStep('blockTime'));
    };
    const handleAppointmentEvent = async (e: any) => {
        if (e.value === 'edit_appointment') {
            dispatch(setCalendarStep('updateAppointment'));
        } else if (e.value === 'cancel_appointment' || e.value === 'cancel_refund_appointment') {
            dispatch(setCalendarStep('cancelAppointment'));
        } else if (e.value === 'declined_appointment') {
            await updateBookingStatus(calendarData.bookedSlotInfo.id, 'declined');
            // await dispatch(setGetBookingList(true));
        } else if (e.value === 'no_show_appointment') {
            dispatch(setCalendarStep('noShowAppointment'));
            // await dispatch(setGetBookingList(true));
        } else if (e.value === 'reschedule_appointment') {
            // setSidebarState((prevState: any) => ({
            //     ...prevState,
            //     isEditable: true,
            // }));
            // const updateState = {
            //     action: { ...initAction, rescheduleAppointment: true },

            // };
            // await dispatch(setData(updateState));
            dispatch(setCalendarStep('rescheduleAppointment'));
        }
    };

    const getBookingInfo = async (id: number) => {
        let payload = {
            shop_id: shop.id,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
                // setDefaultCard(response.data.data.user.card);
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
        }
    };
    const updateBookingStatus = async (id: number, status: string) => {
        const payload = { status: status };
        const data = {
            shop_id: shop.id,
            id: id,
            payload: payload,
        };
        const result = await dispatch(updateAppointmentStatus(data));
        if (result.type === updateAppointmentStatus.fulfilled.toString()) {
            const info = await getBookingInfo(result.payload.data.id);
            if (status === 'canceled' || status === 'declined') {
                const updateState = {
                    bookingInfo: info,
                    getBookingList: true,
                    // action: { ...initAction, bookedAppointment: true },
                };
                dispatch(setData(updateState));
                dispatch(setCalendarStep('bookedAppointment'));
            }
        }
    };
    const handleBackButton = async () => {
        // const updateState = {
        //     action: { ...initAction, bookedAppointment: true },
        // };
        // await dispatch(setData(updateState));
        dispatch(setCalendarStep('bookedAppointment'));
    };
    const handleClose = async () => {
        dispatch(setData({ calendarStep: initAction, getBookingList: true }));
        dispatch(setClearBooking());
    };
    const handleSelectedDate = async (date: any) => {
        const updateState = {
            selectedDate: date,
        };
        dispatch(setData(updateState));
    };
    const handleSelectedTime = async (time: any) => {
        const updateState = {
            selectedTime: time,
        };
        dispatch(setData(updateState));
    };

    const handleCollapse = () => {
        setIsColapse((prev) => !prev);
    };

    const StatusLabel: FC<StatusLabelProps> = ({ status }) => {
        const statusLabels = {
            confirmed: {
                border: 'border-blueLight-200',
                bg: 'bg-blueLight-50',
                text: 'text-blueLight-700',
                icon: <AlarmClockCheck className="w-[14px] h-[14px] text-blueLight-700 mt-[1px]" />,
                label: 'Confirmed',
            },
            pending: { border: 'border-yellow-200', bg: 'bg-yellow-50', text: 'text-yellow-700', icon: <Clock className="w-[14px] h-[14px] text-yellow-600 mt-[2px]" />, label: 'Pending' },
            canceled: { border: 'border-[#FECDCA]', bg: 'bg-[#FEF3F2]', text: 'text-[#D92D20]', icon: <XClose className="w-[14px] h-[14px] text-[#D92D20] mt-[1px]" />, label: 'Canceled' },
            completed: { border: 'border-success-100', bg: 'bg-success-50', text: 'text-success-600', icon: <Check className="w-[14px] h-[14px] text-success-600" />, label: 'Completed' },
            no_show: { border: 'border-purple-200', bg: 'bg-purple-50', text: 'text-purple-700', icon: <EyeOff className="w-[14px] h-[14px] text-purple-500" />, label: 'No Show' },
            declined: { border: 'border-gray-200', bg: 'bg-gray-50', text: 'text-gray-700', icon: <AlarmClockOff className="w-[14px] h-[14px] text-gray-600 mt-[1px]" />, label: 'Declined' },
            request_canceled: {
                border: 'border-gray-200',
                bg: 'bg-gray-50',
                text: 'text-gray-700',
                icon: <AlarmClockOff className="w-[14px] h-[14px] text-gray-600 mt-[1px]" />,
                label: 'Request Canceled',
            },
        };

        const statusData = statusLabels[status];

        if (!statusData) return null;

        const { border, bg, text, icon, label } = statusData;
        return (
            <span className={`border ${border} flex flex-row h-[21px] items-center gap-[4px] ${text} leading-[18px] text-xs font-medium w-max py-0.5 px-1.5 pl-1 rounded-md ${bg} capitalize`}>
                {icon} <span className={`truncate mt-[1px] h-[14px] leading-[14px] flex`}>{label}</span>
            </span>
        );
    };

    return (
        <>
            {calendarData.bookedSlotInfo && !calendarData.calendarStep.updateAppointment && !calendarData.calendarStep.rescheduleAppointment ? (
                <div className="p-4 flex items-center border-b ">
                    {calendarData.calendarStep.payemntAppointment && (
                        <Link className="border border-lineColor rounded-lg p-1 mr-3" to="" onClick={handleBackButton}>
                            <img src={IconChevronRight} alt="" className="" />
                        </Link>
                    )}

                    <h4 className="text-base font-semibold leading-6 text-gray-900">
                        <div className="flex flex-col gap-1">
                            <span> Appointment #{calendarData.bookedSlotInfo.receipt_code}</span>
                            <StatusLabel status={calendarData.bookedSlotInfo.status} />
                        </div>
                    </h4>

                    <DropDownWithIcon
                        dropwDownContainerClass="ml-auto close-btn"
                        className=""
                        labelIcon={<DotsVertical className="w-[18px]" />}
                        labelActiveIcon={<DotsVertical className="w-[18px]" />}
                        dropDownMenuClassName="top-[39px] w-[140px]"
                        dropDownMenuItemClassName="border-b text-left last:border-0"
                        dropDownMenuLinkClassName="pl-2"
                        data={appointmentOptions}
                        setselected={handleAppointmentEvent}
                    />
                </div>
            ) : (
                <div className="">
                    <div className="header flex justify-between px-4 py-4 items-center">
                        <div className="">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">
                                {calendarData.bookedSlotInfo && calendarData.calendarStep.updateAppointment
                                    ? 'Edit Appointment'
                                    : calendarData.bookedSlotInfo && calendarData.calendarStep.rescheduleAppointment
                                    ? 'Reschedule Appointment'
                                    : 'New Appointment'}
                            </h1>
                            {!calendarData.calendarStep.updateAppointment && !calendarData.calendarStep.rescheduleAppointment && (
                                <div className="text-xs font-normal leading-[18px] text-mainTextColor flex items-center">
                                    <div className="flex items-center">
                                        <span className="mr-1.5 text-gray-600 leading-[18px] font-medium text-xs">Or</span>
                                        <div onClick={handleTimeBlock} className="text-primary cursor-pointer font-semibold text-xs leading-[18px]">
                                            New Time Block
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <button className="close-btn" type="button" onClick={() => handleClose()}>
                                <X className="text-gray-700" />
                            </button>
                        </div>
                    </div>
                    <div className="date and time  border-y border-lineColor  pt-2.5 pb-2.5 items-center">
                        <div className="w-full gap-4 px-4 border-r-[1px] border-solid border-lineColor ">
                            <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor recurring-date">
                                {/* On <ReactDatePicker customInput={<CustomDatepickerInput />} selected={moment(calendarData.selectedDate, timeZone).toDate()} onChange={handleSelectedDate} /> */}
                                {/* <CustomDatepickerInput /> */}
                                <DatePickerWithArrow
                                    inputName="start_date"
                                    className={`form_control_date  font-medium text-xs relative w-full  `}
                                    placeholder={t('Select date')}
                                    dateFormat="EEEE, MMMM do, yyyy"
                                    isFormate={false}
                                    // InputClass={`${fieldState.error && 'is-invalid'}`}
                                    value={calendarData.selectedDate}
                                    onChangeValue={handleSelectedDate}
                                    labelEndIcon={<ChevronDown className="text-gray-700 w-[20px]" />}
                                />
                            </div>
                        </div>
                        <div className="w-full mt-2 gap-4 px-4 date-range-select">
                            <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor ">
                                <DateSelect
                                    inputName="start_date"
                                    className={`form_control_date  font-medium text-xs relative w-full `}
                                    placeholder={t('Select date')}
                                    isFormate={false}
                                    isTime={true}
                                    timeIntervals={15}
                                    timeCaption="Time Selected"
                                    dateFormat="h:mm aa"
                                    // InputClass={`${fieldState.error && 'is-invalid'}`}
                                    value={calendarData.selectedTime}
                                    onChangeValue={handleSelectedTime}
                                    labelEndIcon={<ChevronDown className="text-gray-700 w-[20px]" />}
                                />
                            </div>
                        </div>
                        {(calendarData.calendarStep.updateAppointment || calendarData.calendarStep.newAppointment) && calendarData.overlapBooking.status && calendarData.overlapBooking.info && (
                            <div className="w-full mt-3.5 px-4">
                                <div className={` mb-4 border border-[#ECE0DE] bg-[#FEF3F2] rounded-lg flex flex-col`}>
                                    <div className={`flex w-full  py-2 px-3  items-center`}>
                                        <div className="flex-1">
                                            <p className="font-medium text-xs text-gray-900 leading-[18px]">
                                                Booking overlaps with {calendarData.overlapBooking.info.length} appointment at {format(calendarData.selectedTime, 'h:mm a')}
                                            </p>
                                        </div>
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <div className="cursor-pointer" onClick={handleCollapse}>
                                                {isColapse ? <ChevronUp className="text-gray-700 w-[20px] cursor-pointer " /> : <ChevronDown className="text-gray-700 w-[20px] cursor-pointer" />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={` py-2 px-3 gap-1.5 flex flex-col border-t border-[#ECE0DE] 'pt-2 pb-3   `}>
                                        {calendarData.overlapBooking.info.map((item: any) => (
                                            <div className="flex items-center text-xs font-normal leading-[18px] text-[#1F293C] " key={item.id}>
                                                This booking overlaps with <span className="font-medium pl-1"> #{item.receipt_code}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isCancelModal && calendarData?.bookedSlotInfo?.id && (
                <CancelModal isOpen={isCancelModal} setIsOpen={(type, status) => setIsCancelModal(status)} bookingId={calendarData.bookedSlotInfo.id} />
            )}
        </>
    );
};

export default memo(AppointmentHeader);
