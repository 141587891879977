export const unitOptions = [
    {
        label: 'ML',
        value: 'ml',
    },
    {
        label: 'grams',
        value: 'grams',
    },
    {
        label: 'Units',
        value: 'units',
    },
];

export interface IImage {
    name: string;
    file: File;
    url: string;
}

export interface IFormData {
    name: string;
    description: string;
    brand?: string;
    shop_product_category_id: string | null;
    shop_product_sub_category_id: string | null;
    unit_of_measure: string;
    main_image_name: string;
    is_active: boolean;
    images: IImage[] | null;
    variants: Array<{
        size: null | number | string;
        quantity: null | number;
        color?: string;
        cost: null | number;
        price: null | number;
        is_stock_alert: boolean;
        stock_alert_quantity?: string;
        shop_location_id: string;
    }>;
    details: Array<{ name: string; description: string }>;
    supplier_name?: string;
    supplier_email?: string;
    supplier_website?: string;
}

export interface ISelectBox {
    label: string;
    value: string;
}

export interface ExistingFormData {
    [key: string]: any;
}

export const initAction = {
    id: null,
    delete: false,
};
export interface IProductTable {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    productListArray: any;
    totalProducts: number;
    lazyState: any;
    setLazyState: React.Dispatch<any>;
    productServices: () => Promise<void>;
    isLoading: boolean;
    filters: any;
}

export enum View {
    GRID = 'grid',
    TABLE = 'table',
}
export interface IProductsGrid {
    productListArray: any;
    setLazyState: React.Dispatch<any>;
    lazyState: any;
    totalProducts: number;
    isLoading: boolean;
    filters: any;
}

export const InventoryAction = {
    create: false,
    update: false,
    row: null,
};

export interface IInventoryFormData {
    shop_product_variant_id: string;
    shop_location_id: string;
    cost: null | number;
    price: null | number;
    init_quantity: null | number;
    is_new_price?: boolean;
}
