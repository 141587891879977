import React, { useEffect, useRef, useState } from 'react';

const AutoCompleteGoogle = ({ inputName, placeholder, errorText, labelcontainer, className, label, required, onChangeFunc, name, error, disabled, listClassName, ...props }: any) => {
    let autoComplete: any;
    const autoCompleteRef = useRef(null);
    const [ctrlPressedCount, setCtrlPressedCount] = useState<number>(0);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
            autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
                // types: ['establishment'],
                componentRestrictions: { country: ['us', 'ca'] },
            });
            autoComplete.setFields(['address_components', 'formatted_address', 'geometry', 'name']);
            autoComplete.addListener('place_changed', handlePlaceSelect);
        }
        // return () => autoComplete.removeListener('place_changed', handlePlaceSelect);
    }, []);

    useEffect(() => {
        const autoCompleteDropdown = autoCompleteRef.current as any;

        const positionDropdown = () => {
            const containerElement = document.querySelectorAll('.pac-container');

            if (listClassName && containerElement) {
                containerElement.forEach((container: any) => {
                    container.classList.add(listClassName);
                    const inputRect = autoCompleteDropdown.getBoundingClientRect();

                    if (container.offsetHeight === 0) {
                        container.style.top = `${inputRect.top + window.scrollY - 240}px`;
                        container.style.left = `${inputRect.left}px`;
                    } else {
                        container.style.top = `${inputRect.top + window.scrollY - container.offsetHeight - 20}px`;
                        container.style.left = `${inputRect.left}px`;
                    }
                });
            }
        };

        // const observer = new MutationObserver(() => {
        //   positionDropdown();
        // });

        // const config = { childList: true, subtree: true };
        // observer.observe(document.body, config);

        setTimeout(() => {
            positionDropdown();
            autoCompleteDropdown.addEventListener('focus', positionDropdown);
            autoCompleteDropdown.addEventListener('input', positionDropdown);

            autoCompleteDropdown.addEventListener('blur', () => {
                const containerElement = document.querySelectorAll('.pac-container');

                if (listClassName && containerElement) {
                    containerElement.forEach((container: any) => {
                        container.classList.remove(listClassName);
                    });
                }
            });
        }, 500);
        positionDropdown();
        autoCompleteDropdown.addEventListener('focus', positionDropdown);
        autoCompleteDropdown.addEventListener('input', positionDropdown);

        autoCompleteDropdown.addEventListener('blur', () => {
            const containerElement = document.querySelectorAll('.pac-container');

            if (listClassName && containerElement) {
                containerElement.forEach((container: any) => {
                    container.classList.remove(listClassName);
                });
            }
        });
        // Reposition dropdown on scroll
        window.addEventListener('scroll', positionDropdown, true);

        // Reposition dropdown on window resize (optional)
        window.addEventListener('resize', positionDropdown);

        // Clean up event listeners on component unmount

        return () => {
            // observer.disconnect();
            document.removeEventListener('mousedown', removeClass);
            autoCompleteDropdown.removeEventListener('focus', positionDropdown);
            autoCompleteDropdown.removeEventListener('input', positionDropdown);
            window.removeEventListener('scroll', positionDropdown);
            window.removeEventListener('resize', positionDropdown);
        };
    }, [autoCompleteRef, inputValue, ctrlPressedCount]);

    const removeClass = () => {
        const containerElement = document.querySelectorAll('.pac-container');
        containerElement.forEach((container: any) => {
            container.classList.remove(listClassName);
        });
    };
    let isRecheckAddress = false;
    const checkNearByAddress = async (lat: any, lng: any): Promise<any> => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);

        const details = {
            postalCode: '',
            city: '',
            state: '',
            country: '',
            address: '',
        };

        let lastLat, lastLng;
        if (response.ok) {
            const responseJson = await response.json();

            if (responseJson.status === 'OK') {
                for (const result of responseJson.results) {
                    lastLat = result.geometry.location.lat;
                    lastLng = result.geometry.location.lng;
                    for (const component of result.address_components) {
                        if (component.types.includes('postal_code')) {
                            details.postalCode = component.long_name.length > 3 ? component.long_name : details.postalCode;
                        } else if (component.types.includes('locality')) {
                            details.city = component.long_name;
                        } else if (component.types.includes('administrative_area_level_1')) {
                            details.state = component.long_name;
                        } else if (component.types.includes('country')) {
                            details.country = component.long_name;
                        } else if (component.types.includes('premise')) {
                            details.address += `${component.long_name}`;
                        } else if (component.types.includes('street_number')) {
                            details.address += `${component.long_name}`;
                        } else if (component.types.includes('route')) {
                            details.address += `${component.short_name}`;
                        } else if (component.types.includes('sublocality')) {
                            details.address += `${component.long_name}`;
                        }
                    }
                }
            }
        }
        if ((!details.address || !details.city || !details.postalCode || !details.state || !details.country || details.postalCode?.length < 4) && !isRecheckAddress) {
            isRecheckAddress = true;
            return checkNearByAddress(lastLat, lastLng);
        }

        return details;
    };

    const handlePlaceSelect = async () => {
        const addressObject = autoComplete.getPlace();
        let latitude = addressObject.geometry.location.lat();
        let longitude = addressObject.geometry.location.lng();
        const fullAddress = addressObject.formatted_address;
        let city = '';
        let state = '';
        let postcode = '';
        let address = addressObject?.name;
        let country = '';
        let stateCode = '';
        let countryCode = '';
        let isCity = false;

        addressObject.address_components.forEach((component: any) => {
            const componentType = component.types;
            if (componentType.includes('premise')) {
                address += ` ${component.long_name}`;
            } else if (componentType.includes('street_number')) {
                address += ` ${component.long_name}`;
            } else if (componentType.includes('route')) {
                address += ` ${component.short_name}`;
            } else if (componentType.includes('sublocality')) {
                address += ` ${component.long_name}`;
            } else if (componentType.includes('postal_code')) {
                postcode = ` ${component.long_name}${postcode}`;
            } else if (componentType.includes('postal_code_suffix')) {
                // postcode = `${postcode}-${component.long_name}`;
            } else if (componentType.includes('locality')) {
                city = component.long_name;
                isCity = true;
            } else if (componentType.includes('administrative_area_level_1')) {
                state = component.long_name;
                stateCode = component.short_name;
            } else if (componentType.includes('country')) {
                country = component.long_name;
                countryCode = component.short_name;
            }

            if (!isCity && (componentType.includes('sublocality_level_1') || componentType.includes('sublocality'))) {
                city = component.long_name;
            }
        });

        let extraAddress;
        let address1;
        if (address) {
            extraAddress = fullAddress.substring(0, fullAddress.indexOf(address.split(' ')[0]));
            // extraAddress = fullAddress.split(address).shift();
            address1 = `${extraAddress} ${address}`;
        } else {
            address1 = city;
        }

        if (!address1 || !city || !postcode || !state || !country || postcode.length < 4) {
            let addressDetails: any = await checkNearByAddress(latitude, longitude);
            if (!address1) {
                address1 = addressDetails.address;
            }
            if (!city) {
                city = addressDetails.city;
            }
            if (!postcode || postcode.length < 4) {
                postcode = addressDetails.postalCode;
            }
            if (!state) {
                state = addressDetails.state;
            }
            if (!country) {
                country = addressDetails.country;
            }
        }

        const addressObj = {
            fullAddress,
            address1: address1.trim(),
            city,
            state,
            country,
            postal: postcode,
            latitude,
            longitude,
            stateCode,
            countryCode,
        };

        onChangeFunc(addressObj);
    };

    const handleChange = (event: any) => {
        const { value } = event.target;
        onChangeFunc(value);
        setInputValue(value);
    };
    const handleKeyPress = (event: any) => {
        if (event.ctrlKey) {
            setCtrlPressedCount((prev) => prev + 1);
        }
    };

    return (
        <div className={`relative mb-2 border border-borderGray rounded-lg custom-hover-effect shadow-InputAndButton ${error && 'is-invalid'}`}>
            {required && <span className="text-red-600">*</span>}
            {label && <label className="form_label">{label}</label>}
            <input
                className={`form_input_control  ${className} `}
                type="text"
                autoComplete={inputName}
                ref={autoCompleteRef}
                onChange={handleChange}
                required={required}
                {...props}
                placeholder={placeholder}
                onKeyDown={handleKeyPress}
                onKeyUp={handleKeyPress}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); // Prevent form submission
                    }
                }}
                disabled={disabled}
            />
        </div>
    );
};

export default AutoCompleteGoogle;
