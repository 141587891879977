import React, { useEffect } from 'react';
import { useAppDispatch } from 'src/redux/hooks';
import { updateStripeAccountStatus, verifyStripeAccount } from './FlairPayment.slice';
import { shopDetail } from 'src/app/Auth/Login/Login.slice';

const FlairPaymentReturn = () => {
    const dispatch = useAppDispatch();

    const handleCallback = (message: string) => {
        window.opener.postMessage(message, window.location.origin);
    };

    const verifyAccount = async () => {
        const result = await dispatch(verifyStripeAccount());

        if (result.type === verifyStripeAccount.fulfilled.toString()) {
            handleCallback('stripe_connect_verified');
        }

        if (result.type === verifyStripeAccount.rejected.toString()) {
            updateAccountStatus();
            /* const response = result.payload.data;

            if (response.status === errorCode.unprocessable) {
                handleCallback('stripe_connect_not_verified');
            } */
        }
    };

    const updateAccountStatus = async () => {
        const response = await dispatch(updateStripeAccountStatus());

        if (response.type === updateStripeAccountStatus.fulfilled.toString()) {
            await dispatch(shopDetail());
            handleCallback('stripe_connect_pending');
        }

        if (response.type === updateStripeAccountStatus.rejected.toString()) {
            handleCallback('stripe_connect_not_verified');
        }
    };

    useEffect(() => {
        verifyAccount();
        const timer = setTimeout(() => {
            handleCallback('stripe_connect_pending');
        }, 30000);

        return () => clearTimeout(timer);
    }, []);

    return <h1>Loading...</h1>;
};

export default FlairPaymentReturn;
