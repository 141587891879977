import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import Appointment from './Appointment';
import General from './General';
import { FormProvider, useForm } from 'react-hook-form';
import StaffGeneral from './StaffGeneral';
import StaffAppointment from './StaffAppointment';
import { API } from 'src/constants/api';
import { axiosPatch } from 'src/utils/requestClient';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentRole, currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { errorCode } from 'src/constants/errorCode';
import { ROLES } from 'src/constants/common';
import { IFormData, NOTIFICATION_TABS } from './Notifications.interface';
import { Tooltip } from 'primereact/tooltip';

const keysArray = [
    'daily_checkout',
    'new_user',
    'monthly_statement',
    'commission_statement',
    'rent_statement',
    'tip_statement',
    'product_commission_statement',
    'low_stock',
    'staff_completed_registration',
    'cancellations',
    'cancellation_past_limit',
    'reschedule',
    'user_update_booking',
    'staff_update_booking',
    'update_booking',
    'new_booking',
    'new_user_booking',
    'new_user_booking_referral',
    'payment_structure_update',
];

const Notifications = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(userMe);
    const shop: any = useAppSelector(currentShop);
    const role = useAppSelector(currentRole);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS.GENERAL);
    const [isSaveEnabled, setSaveEnabled] = useState(false);
    const tabs = useMemo(
        () => [
            {
                name: 'General Notifications',
                type: NOTIFICATION_TABS.GENERAL,
            },
            {
                name: 'Appointment Notifications',
                type: NOTIFICATION_TABS.APPOINTMENT,
            },
        ],
        [],
    );
    const handleTabClick = (type: string) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(type);
    };

    const methods = useForm<IFormData>({
        defaultValues: keysArray.reduce((acc, key) => {
            acc[key] = { push: true, email: true, sms: true };
            return acc;
        }, {} as Record<string, { push: boolean; email: boolean; sms: boolean }>),
    });

    const {
        handleSubmit,
        setError,
        setValue,
        reset,
        watch,
        formState: { isDirty },
    } = methods;

    const watchAllFields = watch();

    useEffect(() => {
        setSaveEnabled(isDirty);
    }, [watchAllFields, isDirty]);

    const extractNotificationData = (notificationData: any) => {
        const notificationSettings = keysArray.reduce((acc, key) => {
            acc[key] = notificationData[key];
            return acc;
        }, {} as Record<string, any>);
        keysArray.forEach((key) => {
            if (notificationSettings[key] !== undefined) {
                setValue(key as keyof IFormData, notificationSettings[key]);
            }
        });
        reset(notificationSettings);
    };

    useEffect(() => {
        const notification = user.notification;
        if (notification) {
            extractNotificationData(notification);
        }
    }, [user]);

    const onSubmit = async (data: any) => {
        const payload = { type: activeTab, ...data };
        setIsLoading(true);
        await axiosPatch(API.NOTIFICATION.SETTINGS, payload, { shop_id: shop.id })
            .then(async (response) => {
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const resetForm = () => {
        if (user) {
            const notification = user.notification;
            if (notification) {
                extractNotificationData(notification);
            } else {
                reset();
            }
        } else {
            reset();
        }
    };

    return (
        <div className="w-[full]  bg-white  ">
            <div className="detail-page-data-block !p-0">
                <Tooltip target=".notification-setting" position="top" />
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full px-2 py-1">
                        <div className="data-wrap-block w-full">
                            <div className="detail-page-title-block !border-b-0 !py-4 border-borderSecondary !px-0">
                                <div className="flex flex-col gap-0">
                                    <h2 className=" flex flex-row gap-1.5 items-center">
                                        <div className="title-text-header">{t('Notifications Settings')}</div>
                                        <InfoCircle
                                            className="text-[#98A2B3] w-4 h-4 custom-tooltip-btn notification-setting"
                                            data-pr-tooltip={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}
                                        />
                                    </h2>
                                    <p className="subtitle-text">{t('Customize your notification preferences to stay informed about your account activities and updates.')}</p>
                                </div>
                                {/* <div className="flex flex-row gap-3">
                                    <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                                        {t('Cancel')}
                                    </CustomButton>
                                    <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" className="!h-[36px]">
                                        {t('Save Changes')}
                                    </CustomButton>
                                </div> */}
                            </div>
                            <div className=" flex flex-1 flex-col w-full">
                                <div className="fl-tab-btn-view w-full ">
                                    {tabs.map((tab, index) => (
                                        <button key={index} type="button" className={`fl-tab-link  !max-w-[182px] ${activeTab === tab.type ? 'active' : ''}`} onClick={handleTabClick(tab.type)}>
                                            {tab.name}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            {activeTab === 'general' ? role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? <StaffGeneral /> : <General /> : null}
                            {activeTab === 'appointment' ? role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? <StaffAppointment /> : <Appointment /> : null}
                            <div className="flex flex-row gap-3 w-full pt-5 mb-36 justify-end">
                                <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary isLoading={isLoading} disabled={isLoading || !isSaveEnabled} type="submit" className="!h-[36px]">
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default Notifications;
