/* eslint-disable complexity */
import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { FaAngleDown, FaCheck } from 'react-icons/fa6';
import en from 'react-phone-number-input/locale/en.json';
import { usePhone } from 'src/hooks/usePhone';

import { INumberList, ISelectedNumber } from './PhoneOrEmail.interface';
import { CountryCode, formatIncompletePhoneNumber, parseIncompletePhoneNumber } from 'libphonenumber-js';
import IconClose from 'src/assets/img/icons/icon-close.svg';
import { useTranslation } from 'react-i18next';

const PhoneOrEmail = ({ onNumberChange, disabled, errors, name, defaultValues, isFocus = false, singleType, isButtonClicked, inputType }: any) => {
    const { setFormatPhone, isPhoneValid, phoneInfo } = usePhone();
    const { t } = useTranslation();

    const getphoneinfo = phoneInfo(defaultValues?.phone, defaultValues?.phone_country_code);
    const [isPhone, setIsPhone] = useState(singleType && singleType === 'phone' ? true : false);

    const initialSelectedNumber: ISelectedNumber = {
        code: getphoneinfo && getphoneinfo.countryCallingCode ? getphoneinfo.countryCallingCode : '1',
        number: getphoneinfo && getphoneinfo.nationalNumber ? getphoneinfo.nationalNumber : '',
        // image: defaultValues && defaultValues.phone_country_code ? require(`src/assets/flags/${defaultValues.phone_country_code}.svg`) : require(`src/assets/flags/CA.svg`).default,
        name: en[defaultValues && defaultValues.phone_country_code ? (defaultValues.phone_country_code as CountryCode) : 'CA'],
        country: defaultValues && defaultValues.phone_country_code ? defaultValues.phone_country_code : 'CA',
    };

    const [Switch, setSwitch] = React.useState(false);
    const [selectedNumber, setSelectedNumber] = useState<ISelectedNumber>(initialSelectedNumber);
    const [numberList, setNumberList] = React.useState<INumberList[]>([]);
    const [isInit, setIsInit] = React.useState(true);

    useEffect(() => {
        const handler = () => setSwitch(false);
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);

    const handlerInputClik = (e: any) => {
        e.stopPropagation();
        setSwitch(!Switch);
    };

    const handleCountryCode = (option: ISelectedNumber) => {
        setSelectedNumber({ ...selectedNumber, ...option });
    };

    const handleInputNumberChange = (e: any) => {
        let value = e.target.value;
        const numberPattern = /^\d+$/;
        if (!singleType) {
            if (value && value.length === 2 && numberPattern.test(value)) {
                setIsPhone(true);
            } else if (!value) {
                setIsPhone(false);
            }
        }

        let payload = {};
        setSelectedNumber({ ...selectedNumber, number: value });
        if (isPhone) {
            let newValue = parseIncompletePhoneNumber(value);
            const newValueFormatted = formatIncompletePhoneNumber(newValue, selectedNumber.country);
            if (newValue === selectedNumber.number) {
                if (newValueFormatted.indexOf(e.target.value) === 0) {
                    newValue = newValue.slice(0, -1);
                }
            }
            setSelectedNumber({ ...selectedNumber, number: newValue });
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            payload = {
                isEmail: true,
                data: value,
                error: !emailPattern.test(value) ? 'Please enter valid email' : '',
            };
            onNumberChange(payload);
        }
    };
    useEffect(() => {
        if (isPhone) {
            const isValid = isPhoneValid(selectedNumber.number, selectedNumber.country);
            const payload = {
                isEmail: false,
                data: { country: selectedNumber.country, number: selectedNumber.number, isValid: isValid, code: selectedNumber.code },
                error: !isValid ? 'Please enter valid number' : '',
            };
            onNumberChange(payload);
        }
    }, [selectedNumber.code, selectedNumber.number, selectedNumber.country]); // TODO
    const [filterList, setFilterList] = React.useState<INumberList[]>([]);

    useLayoutEffect(() => {
        const fetchData = async () => {
            let list = getCountries().map((country) => ({
                // image: require(`src/assets/flags/${country}.svg`),
                code: getCountryCallingCode(country),
                name: en[country],
                country: country,
            }));
            const priorityCountries = ['Canada', 'United States'];

            const customSortValue = (country: string) => {
                const priorityIndex = priorityCountries.indexOf(country);
                return priorityIndex !== -1 ? priorityIndex : priorityCountries.length + 1;
            };

            const shortedList = list.sort((a, b) => customSortValue(a.name) - customSortValue(b.name) || a.name.localeCompare(b.name));
            setNumberList(shortedList);
            setFilterList(shortedList);
        };

        fetchData();
    }, []);

    const [searchTerm, setSearchTerm] = React.useState('');

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
    };

    const clearSearchTerm = (event: { stopPropagation(): unknown; preventDefault: () => void }) => {
        event.preventDefault();
        event.stopPropagation();
        setSearchTerm('');
    };

    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            return;
        }
        const filteredList = numberList.filter((country) => country.name.toLowerCase().includes(searchTerm));
        if (searchTerm === '') {
            setFilterList(numberList);
        } else {
            setFilterList(filteredList);
        }
    }, [searchTerm]);

    return (
        <div className="relative">
            <div className={`fl-input-field fl-phone-field !h-10 rounded-lg shadow-InputAndButton ${Switch && ''} ${errors ? 'is-invalid' : 'border border-borderGray border-hover-custom'}`}>
                {isPhone && (
                    <>
                        <span className={`fl-flag  focus:ring-0 outline-none ${disabled ? '!cursor-default' : ''}`} onClick={handlerInputClik}>
                            {/* <img src={selectedNumber.image} alt="" className="w-7 rounded" /> */}
                            <span className="text-gray-900 font-medium text-sm">{selectedNumber.country}</span>
                            <FaAngleDown className="ml-2" />
                        </span>
                        <span className="text-gray-900 font-medium text-sm"> +{selectedNumber.code}</span>
                    </>
                )}

                <input
                    className="rounded-md w-full py-0 border-0 text-sm focus:ring-0 outline-none placeholder:text-placeholderTxt placeholder:text-xs placeholder:font-medium text-gray-900 font-medium  "
                    type="text"
                    name={name}
                    disabled={disabled}
                    value={
                        isPhone ? setFormatPhone(selectedNumber.code, selectedNumber.number, selectedNumber.country) : defaultValues // Assuming `defaultValues.email` holds the email value.
                    }
                    onChange={handleInputNumberChange}
                    placeholder={
                        inputType === 'phone'
                            ? setFormatPhone(selectedNumber.code, '8708708866', selectedNumber.country) // Format for phone
                            : inputType === 'email'
                            ? t('Enter your email address') // Placeholder for email
                            : t('Enter phone number or email') // General placeholder
                    }
                    autoFocus={isFocus}
                    maxLength={isPhone ? 20 : 255}
                />
            </div>
            {errors && <p className="text-error">{errors.message}</p>}
            {isPhone && (
                <>
                    {!disabled && Switch && (
                        <div id="dropdownDelay" className="z-10 absolute top-15  bg-white rounded-xl  w-full fl-dropdown-shadow">
                            <div className="px-3 pt-3 pb-2">
                                <div className="relative w-full input-group">
                                    <input
                                        type="text"
                                        placeholder={t('Search Country')}
                                        value={searchTerm}
                                        onChange={handleSearchTermChange}
                                        onClick={handleSearchTermClick}
                                        autoFocus
                                        className="form_input_control"
                                    />
                                    <button className="px-3" onClick={clearSearchTerm}>
                                        <img src={IconClose} alt="" className="w-4" />
                                    </button>
                                </div>
                            </div>
                            <ul className="country-code-dropdown-list py-2 mb-2 px-3" aria-labelledby="dropdownDelayButton">
                                {filterList.map((country: any, indexNumberCode: number) => (
                                    <li
                                        onClick={() => handleCountryCode(country)}
                                        className={`country-code-dropdown-li text-xs font-medium text-mainTextColor ${selectedNumber.name === country.name && 'active'}`}
                                        key={indexNumberCode}
                                        value={country.code}
                                    >
                                        <div className="flex item-center">
                                            {/* <img src={country.image} className="w-6 h-auto max-h-6 rounded-md" alt="" /> */}
                                            <span className="w-6 h-auto max-h-6 rounded-md"> {country.country}</span>
                                            <span className="w-10 text-xs font-medium mx-2">+{country.code}</span>
                                            <span className="text-xs font-medium">{country.name}</span>
                                        </div>
                                        {selectedNumber.country === country.country && (
                                            // <img
                                            //     src={Tick}
                                            //     className="w-3 "
                                            //     alt=""
                                            // />
                                            <FaCheck size={16} className="text-primary" />
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default memo(PhoneOrEmail);
