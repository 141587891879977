import React, { useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { capitalizeFirstLetterSpace, getShortName } from 'src/utils/global-functions';
import { usePhone } from 'src/hooks/usePhone';
import { selectCalendarData } from '../../Calendar.slice';

const ClientDetail = () => {
    const calendarData = useAppSelector(selectCalendarData);
    const { getCustomFormatPhone } = usePhone();
    const [isImageError, setIsImageError] = useState<boolean>(false);

    const isEditable = calendarData.calendarStep.newAppointment;
    const fullName =
        calendarData?.selectedClientInfo.first_name && calendarData?.selectedClientInfo.last_name
            ? `${calendarData?.selectedClientInfo.first_name} ${calendarData?.selectedClientInfo.last_name}`
            : null;

    return (
        <>
            {!isEditable && <div className="text-gray-700 text-xs font-semibold leading-[18px] mb-1.5 w-full">Client Details</div>}
            <div className={`${isEditable ? 'mb-4 border border-borderSecondary rounded-lg flex flex-col' : 'mb-3 pb-3 flex flex-row border-b border-borderSecondary items-center'}`}>
                <div className={` ${isEditable ? 'flex  w-full  py-2 px-3 items-center justify-between ' : 'flex flex-col  '}`}>
                    <div className="flex items-center">
                        <figure className="NoImgName w-[20px] h-[20px] mr-2 text-[8px]">
                            {calendarData?.selectedClientInfo.profile_image_url && !isImageError ? (
                                <img src={calendarData?.selectedClientInfo.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                            ) : fullName ? (
                                getShortName(fullName)
                            ) : (
                                '+'
                            )}
                        </figure>
                        <div className="flex">
                            <p className="font-medium text-xs text-gray-900 leading-[18px] max-w-[110px] truncate">
                                {fullName ? capitalizeFirstLetterSpace(fullName) : getCustomFormatPhone(calendarData?.selectedClientInfo?.phone, calendarData?.selectedClientInfo?.phone_country_code)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientDetail;
