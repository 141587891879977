import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import Switch from 'src/components/Switch/Switch';
import { ChevronDown, ChevronUp, XClose } from '@untitled-ui/icons-react/build/cjs';

export const Collapse: FC<{
    title: string;
    children: any;
    description?: string;
    isCollapsed?: boolean;
    handleSave?: () => void;
    handleSync?: () => void;
    syncButtonTitle?: string;
    handleStatus?: (isShow: boolean) => void;
    isLoading?: boolean;
    classNames?: string;
    handleRemove?: () => void;
}> = ({ title, children, description, isCollapsed, handleSync, handleSave, syncButtonTitle, isLoading = false, handleStatus, classNames, handleRemove }) => {
    // const [isCollapsedState, setIsCollapsedState] = useState(isCollapsed);
    const [isShow, setIsShow] = useState(false);
    const { t } = useTranslation();
    const handleSwitch = (event: any) => {
        if (handleStatus && isCollapsed !== undefined) {
            handleStatus(!isCollapsed);
        }
        if (isCollapsed !== undefined && !isCollapsed) {
            setIsShow(true);
        }
    };

    const handleCollapse = () => {
        setIsShow((prev) => !prev);
    };
    const handleSyncFun = (event: any) => {
        event.stopPropagation();
        handleSync && handleSync();
    };
    // const handleSaveFun = (event: any) => {
    //     event.stopPropagation();
    //     handleSave && handleSave();
    // };
    return (
        <>
            <div
                onClick={handleCollapse}
                className={`rounded-b-xl ${isShow ? ' border-b border-borderPrimary !rounded-b-none' : ' '} rounded-t-xl  cursor-pointer p-5 hover:bg-gray-50   text-mainTextColor  ${classNames}`}
            >
                <div className="flex items-center gap-4">
                    <div className="flex-1 ">
                        <div className="flex items-center gap-[10px]">
                            <h3 className="text-sm font-semibold leading-5">{title}</h3>
                            {typeof isCollapsed !== 'undefined' && <Switch isChecked={isCollapsed} onChange={handleSwitch} />}
                        </div>
                        {description && <div className="text-sm text-secondaryTxtColor mt-1">{description}</div>}
                    </div>

                    <div className="text-right flex gap-3 items-center">
                        {syncButtonTitle && handleSync && (
                            <CustomButton type="button" secondary className="mr-2" onClick={handleSyncFun}>
                                <span className="h-[8px] w-[8px] bg-success-500 rounded-full"></span>
                                <span className="flex items-center ">{syncButtonTitle}</span>
                            </CustomButton>
                        )}
                        {/* {handleSave && (
                            <CustomButton primary type="button" onClick={handleSaveFun} disabled={isLoading} isLoading={isLoading}>
                                {t('Save changes')}
                            </CustomButton>
                        )} */}
                        {handleRemove && (
                            <span className="text-error-700 cursor-pointer" onClick={handleRemove}>
                                <XClose width={18} className="text-error-700" />
                            </span>
                        )}
                        <button type="button" className=" text-gray-600">
                            {isShow ? <ChevronDown width={20} /> : <ChevronUp width={20} />}
                        </button>
                    </div>
                </div>
            </div>
            {isShow && children}
        </>
    );
};
