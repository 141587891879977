import React, { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Tip from './Tip';
import { Grid02 } from '@untitled-ui/icons-react/build/cjs';
import InputWithLabel from 'src/components/InputWithLabel';
import CustomButton from 'src/components/CustomButton';
import { Checkbox } from 'flowbite-react';
import { useAppSelector } from 'src/redux/hooks';
import { selectCalendarData } from 'src/app/Calendar/Calendar.slice';

interface OtherPaymentProps {
    handlePaymentInput: (type: string, amount: number) => () => void;
    handleTipWithoutCalculation: (value: any, isFixed?: boolean) => () => void;
    handleBlurInput: (event: any) => void;
    errors: any;
    methods: any;
    handleRadioChange: (method: string) => () => void;
    selectedMethod: string | null;
    paymentArray: any;
}

const OtherPayment: FC<OtherPaymentProps> = ({ methods, handlePaymentInput, handleTipWithoutCalculation, handleRadioChange, errors, selectedMethod, paymentArray }) => {
    const { t } = useTranslation();
    const [otherPaymentMethods, setOtherPaymentMethods] = useState<string[]>([]);
    const { control, setValue } = methods;
    // const calendarData: any = useAppSelector(selectCalendarData);
    const handleAddPaymentMethod = (newMethod: string) => () => {
        if (newMethod && !otherPaymentMethods.includes(newMethod)) {
            setOtherPaymentMethods([...otherPaymentMethods, newMethod]);
            setValue('other_payment_methods', '');
        }
    };

    return (
        <div className="flex flex-col border border-gray-200  w-full rounded-xl mt-3">
            <div className={`text-center flex items-center justify-between  gap-3 px-2.5 py-2 w-full`}>
                <div className="flex flex-row gap-3 items-center">
                    <div className="border border-[#7191EF] bg-[#EDF1FD] rounded-lg h-9 w-9 flex items-center justify-center">
                        <Grid02 className="text-[#143CB3] w-5" />
                    </div>
                    <p className="text-[#143CB3] font-semibold text-xs leading-[18px]">Other</p>
                </div>
                {/* <p className=" text-secondaryTxtColor font-normal text-xs leading-[18px] ">1200 points</p> */}
            </div>
            <hr />

            <div className={` flex items-center gap-3 px-2.5 py-2.5 flex-row justify-between  w-full`}>
                <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Enter payment amount</p>
                <Controller
                    name="other"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div>
                            <InputWithLabel
                                type="number"
                                name="other"
                                placeholder={t('$00.00')}
                                onChange={(e: any) => {
                                    let amount = e.target.value;
                                    if (amount.length > 1) {
                                        amount = parseFloat(amount).toString();
                                    }

                                    onChange(amount);
                                    handlePaymentInput('other', amount)();
                                }}
                                value={value}
                                inputControlClassName="!h-[28px] !px-0 !text-center !bg-transparent !border-0 "
                                inputTextWrapper="!h-[30px] !w-[70px] !p-0 !bg-transparent !text-center"
                            />
                        </div>
                    )}
                />
            </div>
            <hr />
            {paymentArray.payments.length === 1 && (
                <>
                    <Tip control={control} handleTipWithoutCalculation={handleTipWithoutCalculation} errors={errors.tip} />
                </>
            )}
            <hr />
            <div className={` flex  gap-0 px-2.5 py-2 flex-col w-full`}>
                <p className="text-mainTextColor font-medium text-xs leading-[18px] mb-1.5">Other payment methods</p>

                <Controller
                    name="other_payment_methods"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="input-with-button w-full ">
                            <InputWithLabel
                                inputControlClassName="!border !rounded-l-lg !rounded-r-none !h-[34px]"
                                inputTextWrapper="!border !rounded-l-lg !rounded-r-none !h-[36px]"
                                onChange={onChange}
                                value={value}
                                name="other_payment_methods"
                            />
                            <CustomButton
                                primary
                                className="!px-4 py-[9px] !rounded-l-none !border-0 !rounded-r-lg shadow-InputAndButton"
                                type="button"
                                onClick={handleAddPaymentMethod(value)} // Handle the add click
                            >
                                Add
                            </CustomButton>
                        </div>
                    )}
                />
                <span className="text-gray-900 text-xs font-medium leading-[18px] grid grid-cols-3 mt-2 ">
                    {otherPaymentMethods.map((method, index) => (
                        <div key={index} className="flex items-center">
                            <Checkbox id={`payment_method_${index}`} name="paymentMethod" checked={selectedMethod === method} onClick={handleRadioChange(method)} />

                            <p className="ml-2 mr-2">{method}</p>
                        </div>
                    ))}
                </span>
            </div>
        </div>
    );
};

export default OtherPayment;
