import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { selectCalendarData, setCalendarStep, setData, updateAppointmentStatus } from '../../Calendar.slice';
import { CalendarMinus02, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import PopupModal from 'src/components/PopupModal';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputWithToggle from 'src/components/InputWithToggle';
import { Label, Radio } from 'flowbite-react';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { getValue } from '@testing-library/user-event/dist/utils';

const CancelAppointment = ({}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const calendarData = useAppSelector(selectCalendarData);
    interface IAction {
        cancelCharge: boolean;
        confirm: boolean;
        refundConfimation: boolean;
    }
    const initAction = {
        cancelCharge: false,
        confirm: true,
        refundConfimation: false,
    };
    const [action, setAction] = useState<IAction>({
        cancelCharge: false,
        confirm: true,
        refundConfimation: false,
    });
    const shop = useAppSelector(currentShop);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleConfirm = () => {
        setIsLoading(false);
        setAction({ cancelCharge: true, confirm: false, refundConfimation: false });
    };
    useEffect(() => {
        if (calendarData.calendarStep.cancelAppointment || calendarData.calendarStep.noShowAppointment) {
            setAction(initAction);
            setIsLoading(false);
        }
    }, [calendarData.calendarStep]);

    const handleRefundModal = () => {
        setAction({ cancelCharge: false, confirm: false, refundConfimation: true });
        setIsLoading(false);
    };
    const handleClose = () => {
        setAction({ cancelCharge: false, confirm: false, refundConfimation: false });
    };

    const schema = Yup.object().shape({
        charge: Yup.number()
            .nullable()
            .when('is_charge', ([is_charge], customSchema) => (is_charge ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        charge_type: Yup.string()
            .nullable()
            .when('is_charge', ([is_charge], customSchema) => (is_charge ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        is_charge: Yup.string().required('This field is required'),
    });
    const initData = {
        charge_type: '%',
        charge: 0,
        is_charge: 'false',
    };
    const {
        handleSubmit,
        control,
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: initData,
    });
    const [chargeType, setChargeType] = useState('$');
    const handleInputChange = (e: any) => {
        setValue('charge_type', e);
        setChargeType(e);
    };
    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                setIsLoading(true);
                if (status) {
                    if (action.refundConfimation) {
                        await handleSubmit(onSubmit)();
                    } else {
                        if (calendarData.bookedSlotInfo.payment_option === 'pay_now') {
                            handleConfirm();
                        } else {
                            handleSubmit(onSubmit)();
                        }
                    }
                } else {
                    handleClose();
                }
            },
        [action],
    );
    const getBookingInfo = async (id: number) => {
        let payload = {
            shop_id: shop.id,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
        }
    };
    const onSubmit = async (data: any) => {
        let payload: any = {
            status: calendarData.calendarStep.cancelAppointment ? 'canceled' : 'no_show',
        };
        if (data.is_charge === 'true') {
            payload = {
                ...payload,
                charge_type: data.charge_type === '%' ? 'percentage' : 'value',
                charge: data.charge,
            };
        }
        const payloadData = {
            shop_id: shop.id,
            id: calendarData.bookedSlotInfo.id,
            payload: payload,
        };
        const result = await dispatch(updateAppointmentStatus(payloadData));
        if (result.type === updateAppointmentStatus.fulfilled.toString()) {
            const info = await getBookingInfo(result.payload.data.id);
            const updateState = {
                bookedSlotInfo: info,
                getBookingList: true,
            };
            dispatch(setData(updateState));
            dispatch(setCalendarStep('bookedAppointment'));
        }
        if (result.type === updateAppointmentStatus.rejected.toString()) {
            const response = result.payload.data;

            if (response.status === errorCode.unprocessable) {
                toast.error(response.message);
            }
        }
        setIsLoading(false);
    };
    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px] ">
                <CalendarMinus02 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('Appointment Cancellation')}</p>
                <span className="text-xs font-normal leading-[18px]">{t('View detailed appointment information.')}</span>
            </div>
        </div>
    );
    const isChargeWatch = watch('is_charge');
    const chargeTypeWatch = watch('charge_type');

    const handleIsCharge = (value: any) => () => {
        if (value === 'false') {
            setValue('charge', initData.charge);
            setValue('charge_type', initData.charge_type);
        }
        setValue('is_charge', value);
    };
    const totalReturnAmount = () => {
        const total = +calendarData.bookedSlotInfo.total;
        const serviceTotal = +calendarData.bookedSlotInfo.subtotal - +calendarData.bookedSlotInfo.product_amount;
        const charge = +getValues('charge');
        const chargeTypeValue = chargeTypeWatch === '%' ? serviceTotal * (charge / 100) : charge;
        const calculatedAmount = total - chargeTypeValue;
        return calculatedAmount.toFixed(2);
    };
    return (
        <div className="flex flex-col">
            {action.confirm && (
                <DeletePopupModal
                    onClose={handleModalClose}
                    size="w-[400px]"
                    isLoading={isLoading}
                    title={
                        calendarData.bookedSlotInfo.payment_option === 'pay_now'
                            ? t(`${calendarData.calendarStep.noShowAppointment ? 'No Show' : 'Cancel'} Appointment and refund`)
                            : t(`${calendarData.calendarStep.noShowAppointment ? 'No Show' : 'Cancel'} Appointment`)
                    }
                    description={
                        calendarData.bookedSlotInfo.payment_option === 'pay_now'
                            ? t(`Are you sure you want to ${calendarData.calendarStep.noShowAppointment ? 'No Show' : 'Cancel'} this appointment and refund amount ?`)
                            : t(`Are you sure you want to ${calendarData.calendarStep.noShowAppointment ? 'No Show' : 'Cancel'} this apointment? This action cannot be undone.`)
                    }
                    confirmButtonText={t('Yes, I’m sure')}
                />
            )}
            {action.refundConfimation && (
                <DeletePopupModal
                    onClose={handleModalClose}
                    size="w-[400px]"
                    isLoading={isLoading}
                    title={`Return Amount $${totalReturnAmount()}`}
                    description={`Return amount will be credited in your account in next 2 or 3 working day. If not credited please fill free to contact us on query@flair.com`}
                    confirmButtonText={t('Okay')}
                />
            )}
            {action.cancelCharge && (
                <PopupModal
                    dismissible
                    onClose={handleClose}
                    size="w-[500px]"
                    className="px-5 py-5"
                    title={<Title />}
                    primaryButton={t("Yes, I'm sure")}
                    secondaryButton={t('Not now')}
                    declineAction={handleClose}
                    acceptAction={handleRefundModal}
                    isLoading={isLoading}
                >
                    <div>
                        <h3 className="mb-5 text-gray-900 font-semibold text-sm leading-5">Set this appointment as a cancel appointment?</h3>
                        <div className="border border-gray-200 rounded-lg p-5">
                            <div className="mb-3">
                                <span className="text-gray-700 font-semibold text-sm leading-5 mb-2">Please select the cancellation type</span>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name={`is_charge`}
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className={`flex  ${value === 'true' ? 'mb-4' : ''}`}>
                                            <div id="isCharge" className={` flex items-center `}>
                                                <Radio
                                                    className="custom-round-radio h-4 w-4 !ring-0 focus:!ring-0 focus:!inset-0 focus:!outline-0 !outline-0 !focus:ring-offset-0 !ring-offset-0"
                                                    id="logo-type-logo"
                                                    name="isCharge"
                                                    value={'true'}
                                                    onChange={handleIsCharge('true')}
                                                    checked={value === 'true'}
                                                />
                                                <Label htmlFor="logo-type-logo" className="ml-2 cursor-pointer text-xs text-gray-700 leading-[18px] font-medium">
                                                    {t('Charge client')}
                                                </Label>
                                            </div>

                                            <div className={` flex items-center ml-5`}>
                                                <Radio
                                                    className="custom-round-radio h-4 w-4 !ring-0 focus:!ring-0 focus:!inset-0 focus:!outline-0 !outline-0 !focus:ring-offset-0 !ring-offset-0"
                                                    id="logo-type-logo-text"
                                                    name="isCharge"
                                                    value="false"
                                                    onChange={handleIsCharge('false')}
                                                    checked={value === 'false'}
                                                />
                                                <Label htmlFor="logo-type-logo-text" className="ml-2 cursor-pointer text-xs text-gray-700 leading-[18px] font-medium">
                                                    {t("Don't charge")}
                                                </Label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {isChargeWatch === 'true' && (
                                    <Controller
                                        name="charge"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithToggle
                                                    label={t('Enter charge amount')}
                                                    type="number"
                                                    name="charge"
                                                    placeholder={t('Enter spend')}
                                                    toggleClassName="text-white bg-blue-500"
                                                    value={value}
                                                    toggleValue={chargeTypeWatch}
                                                    setselected={handleInputChange}
                                                    onChange={onChange}
                                                    error={!!errors.refferer}
                                                />

                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}

                                                {chargeTypeWatch === '%' && calendarData.bookedSlotInfo && (
                                                    <span className="text-gray-600 font-normal text-xs leading-[18px]">
                                                        Charge amount{' '}
                                                        <span className="font-semibold">
                                                            ${+((+calendarData.bookedSlotInfo.subtotal - +calendarData.bookedSlotInfo.product_amount) * (value / 100)).toFixed(2)}
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                )}
                            </form>
                        </div>
                    </div>
                </PopupModal>
            )}
        </div>
    );
};

export default CancelAppointment;
