import React from 'react';
import PopupModal from 'src/components/PopupModal';
import Complete from 'src/assets/svgs/complete.svg';
import { useTranslation } from 'react-i18next';

const StripeSuccess = ({ setStep, className, handleClose, size }: any) => {
    const { t } = useTranslation();
    const handleNext = () => {
        setStep(3);
    };
    return (
        <>
            <PopupModal dismissible onClose={handleClose} size={size} className={className} primaryButton={t('Complete')} acceptAction={handleNext}>
                <div className="text-center">
                    <img src={Complete} alt="" className="inline-block" />

                    <h3 className=" text-[28px] font-bold -tracking-[0.336px] leading-[124%] mt-3 mb-[14px]">{t('Stripe Connected')}</h3>
                    <p className="text-sm text-secondaryTxtColor -tracking-[0.14px] leading-[140%] mb-[30px]">{t('stripe_connected_description')}</p>
                </div>
            </PopupModal>
        </>
    );
};

export default StripeSuccess;
