import React from 'react';
import { FaCheck, FaCircle } from 'react-icons/fa6';

export type IStepNum = 1 | 2 | 3 | 4;
export type IStep = {
    name: string;
    description: string;
    step: number;
};

interface IPops {
    steps: IStep[];
    activeStep: IStepNum;
}

const Steper: React.FC<IPops> = ({ steps, activeStep }) => (
    <ol className="fwpo__steper ">
        {steps.map(({ name, description, step }, index) => (
            <li
                key={index}
                className={`flex ${step !== steps?.length ? 'w-full' : ''} items-center ${
                    activeStep > step ? 'step_completed' : activeStep === 3 ? 'last_step step_inprogress' : activeStep === step ? 'step_inprogress' : ''
                }`}
            >
                {activeStep === step ? (
                    <>
                        <div className="relative flex flex-col items-center">
                            <span className="steps__round border-0">
                                <FaCircle className="w-2.5 h-2.5" />
                            </span>
                            <div className="steper_text">
                                <div className="font-semibold text-sm">{name}</div>
                                <div className="font-normal text-sm">{description}</div>
                            </div>
                        </div>

                        {activeStep !== 3 && <div className="border_start"></div>}
                    </>
                ) : activeStep > step ? (
                    <>
                        <div className="relative flex flex-col items-center">
                            <span className="steps__round border-0">
                                <FaCheck className="" />
                            </span>
                            <div className="steper_text">
                                <div className="font-semibold text-sm">{name}</div>
                                <div className="font-normal text-sm">{description}</div>
                            </div>
                        </div>
                        <div className="border_start"></div>
                    </>
                ) : (
                    <>
                        <div className="relative flex flex-col items-center">
                            <span className="steps__round">{<FaCircle className="w-2.5 h-2.5" />}</span>
                            <div className="steper_text border-0">
                                <div className="font-semibold text-sm">{name}</div>
                                <div className="font-normal text-sm">{description}</div>
                            </div>
                        </div>
                        {step !== steps?.length && <div className="border_start"></div>}
                    </>
                )}
            </li>
        ))}
    </ol>
);

export default Steper;
