import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GoDotFill } from 'react-icons/go';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { ArrowUp, ArrowDown, Share05, Box, SearchLg, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import NoDataMessage from 'src/components/NoDataMessage';

const StaffServices = ({ staff }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [services, setServices] = useState([]);
    const [totalServices, setTotalServices] = useState<number>(0);
    const [expandedRows, setExpandedRows] = useState({});
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_admin_id: { value: id },
        },
    });
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        shop_admin_id: { value: id },
    });

    useEffect(() => {
        listServices();
    }, [lazyState, shop]);

    const listServices = async () => {
        setIsLoading(true);
        await axiosGet(API.SERVICE.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setServices(response.data.data.data);
                setTotalServices(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const onRowToggle = (event: any) => {
        setExpandedRows(event.data);
    };

    const allowExpansion = (row: any) => row.variables.length > 0;

    const getType = (row: any) => {
        const color = row.type === 'variable' ? '#17B26A' : '#2E90FA';
        return (
            <p className="px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max">
                <GoDotFill size={12} color={color} />
                {row.type}
            </p>
        );
    };

    const getLocation = (row: any) => row.locations[0].name;
    const getCategories = (row: any) => row.category.name;
    const getDuration = (row: any) => (row.price !== null ? `${moment.duration(row.price.duration).asMinutes()} min` : null);
    const getPrice = (row: any) => (row.price !== null ? `$${row.price.price}` : null);

    const rowExpansionTemplate = (data: any) => (
        <DataTable value={data.variables} showHeaders={false}>
            <Column
                expander={false}
                style={{ width: '40px', minWidth: '40px', maxWidth: '40px' }}
                className=" min-w-[50px] max-w-[50px] w-[50px]  xlm:min-w-[60px] xlm:w-[60px] xlm:max-w-[60px] truncate"
            />
            <Column
                field="name"
                header={t('Service name')}
                style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                className="text-xs font-medium text-mainTextColor min-w-[130px] w-[130px] max-w-[130px] xlm:min-w-[200px] xlm:w-[200px] xlm:max-w-[200px] truncate"
                colSpan={3}
            ></Column>
            <Column expander={false} style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }} />
            <Column expander={false} style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }} />
            {/* <Column field="description" header={t('Service description')} className="text-xs font-medium" style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}></Column> */}
            <Column
                field="location"
                header={t('Location')}
                className="text-xs font-semibold text-mainTextColor min-w-[130px] w-[130px] max-w-[130px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate"
                style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                body={data.locations[0].name}
            ></Column>
            <Column
                field="data,price.duration"
                header={t('Duration (minutes)')}
                className="text-xs leading-[18px] font-normal text-gray-600 min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[100px] xlm:w-[100px] xlm:max-w-[100px] truncate"
                body={(row: any) => (row.price.duration ? `${moment.duration(row.price.duration).asMinutes()} min` : null)}
                style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
            ></Column>
            <Column
                field="data,price.price"
                header={t('Price')}
                className="text-xs leading-[18px] font-normal text-gray-600 min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[100px] xlm:w-[100px] xlm:max-w-[100px] truncate"
                body={(row: any) => (row.price.price ? `$${row.price.price}` : null)}
                style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
            ></Column>
        </DataTable>
    );

    const handleBookingDownload = async () => {
        setIsDownloading(true);
        const payload = {
            filters: {
                shop_admin_id: { value: lazyState.filters.shop_admin_id.value },
            },
        };

        await axiosGet(API.STAFF.SERVICE_HISTORY, { shop_id: shop.id }, payload)
            .then(async (response) => {
                const exportResponse = response.data.data;
                const byteCharacters = atob(exportResponse);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                    type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
                });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `service_details_${staff.full_name}_${moment().format('YYYY-MM-DD')}.xlsx`;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .finally(() => setIsDownloading(false));
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                type: 'loading',
                name: 'loading',
                staff_count: 'loading',
                // description: 'loading',
                shop_categories: { name: 'loading' },
                shop_locations: { name: 'loading' },
                shop_staff_services: { price: 'loading', duration: 'loading' },
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow  staff_service_table datatable-custom-service">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div className="flex flex-col">
                    <div className="flex justify-start items-center">
                        <h2 className="view-table-title capitalize-first ">
                            {t('name Services', { name: staff.first_name })}
                            <p className="userCounter ml-1.5">
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                {totalServices} {t('Services')}
                            </p>
                        </h2>
                    </div>
                    <p className="view-table-subtitle">{t('View name offered services and details', { name: staff.first_name })}</p>
                </div>
                <div className="flex flex-row gap-4">
                    {/* <SearchBar placeholder={t('Search')} className="form-control-md " /> */}
                    <div className="location-dropdown">
                        <CustomButton
                            isLoading={isDownloading}
                            disabled={isDownloading || !totalServices}
                            secondary
                            outlineSecondary
                            icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                            onClick={handleBookingDownload}
                            className="flex items-center"
                        >
                            {t('Export')}
                        </CustomButton>
                    </div>
                    <div className="location-dropdown">
                        <SelectBox
                            name="page"
                            options={perPageOptions}
                            isClearable={false}
                            isSearchable={false}
                            onChangeFunc={onPageHandle}
                            value={perPageOptions.find((option: any) => option.value === lazyState.rows)}
                            classComp="w-[70px]"
                        />
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={customPaginatorTemplate}
                value={isLoading ? skeletons : services}
                totalRecords={totalServices}
                paginator={!isLoading && totalServices > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                rowExpansionTemplate={rowExpansionTemplate}
                expandedRows={expandedRows}
                onRowToggle={onRowToggle}
                dataKey="id"
                className="rounded-b-xl overflow-hidden "
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage
                            title={`${filters.global.value ? 'No Services found.' : 'No Services added.'}`}
                            description={`${filters.global.value ? 'Try adjusting your filters or add new Services.' : 'Add a Services, to start using the Services collection.'}`}
                            iconComponent={filters.global.value ? <SearchLg className="text-gray-700" /> : <Box className="text-gray-700" />}
                        />
                    </div>
                }
            >
                <Column
                    expander={isLoading ? false : allowExpansion}
                    body={isLoading && <Skeleton />}
                    style={{ width: '40px', minWidth: '40px', maxWidth: '40px', paddingRight: '0px' }}
                    className="table-arrow-btn"
                />
                <Column
                    field="name"
                    header={renderHeader(t('Service Name'), 'name')}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs leading-[18px] font-medium text-mainTextColor min-w-[130px] w-[130px] max-w-[130px] xlm:min-w-[200px] xlm:w-[200px] xlm:max-w-[200px] truncate"
                    style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                    sortable
                ></Column>
                <Column
                    field="type"
                    header={renderHeader(t('Type'), 'type')}
                    className="font-medium text-mainTextColor min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[120px] xlm:w-[120px] xlm:max-w-[120px] truncate"
                    body={isLoading ? <Skeleton /> : getType}
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    sortable
                ></Column>
                <Column
                    field="shop_categories.name"
                    header={renderHeader(t('Category'), 'shop_categories.name')}
                    body={isLoading ? <Skeleton /> : getCategories}
                    className="text-xs leading-[18px] font-medium text-mainTextColor min-w-[120px] w-[120px] max-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate"
                    style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                    sortable
                ></Column>
                {/* <Column
                    field="description"
                    header={renderHeader(t('Service description'), 'description')}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs leading-[18px] font-normal text-gray-600 "
                    style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                    sortable
                ></Column> */}
                <Column
                    field="shop_locations.name"
                    header={renderHeader(t('Location'), 'shop_locations.name')}
                    className="text-xs leading-[18px] font-semibold text-mainTextColor  min-w-[130px] w-[130px] max-w-[130px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate"
                    body={isLoading ? <Skeleton /> : getLocation}
                    style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    sortable
                ></Column>
                <Column
                    field="shop_staff_services.duration"
                    header={renderHeader(t('Duration'), 'shop_staff_services.duration')}
                    body={isLoading ? <Skeleton /> : getDuration}
                    className="text-xs text-gray-600 font-normal min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[100px] xlm:w-[100px] xlm:max-w-[100px] truncate"
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    sortable
                ></Column>
                <Column
                    field="shop_staff_services.price"
                    header={renderHeader(t('Price'), 'shop_staff_services.price')}
                    body={isLoading ? <Skeleton /> : getPrice}
                    className="text-xs text-gray-600 font-normal min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[100px] xlm:w-[100px] xlm:max-w-[100px] truncate"
                    sortable
                    style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default StaffServices;
