import React, { FC, useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreditCard02, Plus } from '@untitled-ui/icons-react/build/cjs';
import { selectCalendarData } from 'src/app/Calendar/Calendar.slice';
import { useAppSelector } from 'src/redux/hooks';
import InputWithLabel from 'src/components/InputWithLabel';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { toast } from 'react-toastify';
import Tip from './Tip';

interface PosPaymentProps {
    handlePaymentInput: (type: string, amount: number) => () => void;
    handleTipWithoutCalculation: (value: any, isFixed?: boolean) => () => void;
    handleBlurInput: (event: any) => void;
    errors: any;
    control: Control<FieldValues, any>;
    cardList: any;
    paymentArray: any;
}

const CardPayment: FC<PosPaymentProps> = ({ control, handlePaymentInput, handleTipWithoutCalculation, handleBlurInput, errors, cardList, paymentArray }) => {
    const { t } = useTranslation();
    const calendarData: any = useAppSelector(selectCalendarData);
    const [defaultCard, setDefaultCard] = useState<any>(null);
    const shop = useAppSelector(currentShop);

    const handleDefaultCard = async (cardItem: any) => {
        if (defaultCard?.id === cardItem.id) {
            setDefaultCard(cardItem);
            return;
        }
        const payload = {
            id: calendarData.selectedClientInfo.id,
            shop_id: shop.id,
        };
        axiosPost(API.CLIENT.CARD_DEFAULT, { payment_method_id: cardItem.id }, payload)
            .then(async () => {
                setDefaultCard(cardItem);
            })
            .catch((error: any) => toast.error(error?.message))
            .finally(() => setDefaultCard(cardItem));
    };
    return (
        <div className="flex flex-col border border-gray-200  w-full rounded-xl mt-3">
            <div className={`text-center flex items-center justify-between  gap-3 px-2.5 py-2 w-full`}>
                <div className="flex flex-row gap-3 items-center">
                    <div className="border border-[#7191EF] bg-[#EDF1FD] rounded-lg h-9 w-9 flex items-center justify-center">
                        <CreditCard02 className="text-[#143CB3] w-5" />
                    </div>
                    <p className="text-[#143CB3] font-semibold text-xs leading-[18px]">Card on file</p>
                </div>
            </div>
            <hr />
            <div className={` flex flex-col items-center gap-1    px-2.5 py-2  justify-between  w-full`}>
                <div className="flex justify-between items-center w-full">
                    <p className="text-[#344054] font-medium text-xs leading-[18px] ">Card on file</p>
                    <CustomButton
                        type="button"
                        // onClick={()}
                        className="!text-xs text-btnBg font-semibold flex items-center gap-1.5 h-[15px] shadow-none pr-0.5 "
                        icon={<Plus width="16" />}
                    >
                        Add payment method
                    </CustomButton>
                </div>
                <Controller
                    name="stripe"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="w-full">
                            <SelectBox
                                isSearchable
                                name="stripe"
                                id="stripe"
                                options={cardList}
                                value={defaultCard}
                                onChangeFunc={handleDefaultCard}
                                className=""
                                placeholder="⭑⭑⭑⭑ - ⭑⭑⭑⭑ - ⭑⭑⭑⭑ - 4242"
                            />

                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
            </div>
            <hr />
            <div className={` flex items-center gap-3 px-2.5 py-2.5 flex-row justify-between  w-full`}>
                <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Enter payment amount</p>
                <Controller
                    name={`stripe`}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div>
                            <InputWithLabel
                                type="number"
                                name={`stripe`}
                                placeholder={t('$00.00')}
                                onChange={(e: any) => {
                                    let amount = e.target.value;
                                    if (amount.length > 1) {
                                        amount = parseFloat(amount).toString();
                                    }
                                    onChange(amount);
                                    handlePaymentInput('stripe', amount)();
                                }}
                                value={value}
                                onBlur={handleBlurInput}
                                inputControlClassName="!h-[28px] !px-0 !text-center !bg-transparent !border-0 "
                                inputTextWrapper="!h-[30px] !w-[70px] !p-0 !bg-transparent !text-center"
                            />
                        </div>
                    )}
                />
            </div>
            <hr />
            {paymentArray.payments.length === 1 && (
                <>
                    <Tip control={control} handleTipWithoutCalculation={handleTipWithoutCalculation} errors={errors.tip} />
                </>
            )}
        </div>
    );
};

export default CardPayment;
