import React, { useEffect, useMemo, useState } from 'react';
import General from './General/General';
import Logo from './Logo/Logo';
import Template from './Template/Template';
import PageHeader from 'src/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Share03 } from '@untitled-ui/icons-react/build/cjs';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';

const Appearance = () => {
    const { t } = useTranslation();
    useEffect(() => {}, []);
    const shop: any = useAppSelector(currentShop);

    const tabs = useMemo(
        () => [
            {
                name: 'General',
                type: 'general',
            },
            {
                name: 'Logo Display',
                type: 'logo',
            },
            {
                name: 'Marketing Template',
                type: 'template',
            },
        ],
        [],
    );
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const handleTabClick = (tab: any) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        switch (tab.type) {
            case 'general':
                document.title = t('Titles.Appearance General');
                break;
            case 'logo':
                document.title = t('Titles.Appearance Logo Display');
                break;
            case 'template':
                document.title = t('Titles.Appearance Marketing Template');
                break;
        }
        setActiveTab(tab);
    };

    return (
        <div className="inner-page-wrape ">
            <PageHeader title={t('Appearance')} subtitle="Configure the appearance, branding and template settings of your live website.">
                <div className="flex justify-end text-gray-600 font-medium text-sm gap-1 items-center cursor-pointer">
                    <a
                        href={`https://${shop.slug}.${process.env.REACT_APP_DOMAIN}`}
                        className="self-stretch text-[#475467] hover:text-primary text-sm font-medium leading-normal flex items-center flex-row"
                        target="_blank"
                    >
                        {shop.slug}.{process.env.REACT_APP_DOMAIN} <Share03 className="w-4 h-4 ml-1" />
                    </a>
                </div>
            </PageHeader>
            <div className="flex flex-col side-spaching">
                <div className="fl-tab-btn-view w-full">
                    {tabs.map((tab, index) => (
                        <button key={index} type="button" className={`fl-tab-link !max-w-[182px] ${activeTab.name === tab.name ? 'active' : ''}`} onClick={handleTabClick(tab)}>
                            {tab.name}
                        </button>
                    ))}
                </div>
                {activeTab.type === 'general' && <General />}
                {activeTab.type === 'logo' && <Logo />}
                {activeTab.type === 'template' && <Template />}
            </div>
        </div>
    );
};

export default Appearance;
