import React, { useState, useEffect, useMemo } from 'react';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from '../Layout/SideBarLayout/Sidebar';
import Welcome from './Welcome/Welcome';
import { ROUTES } from 'src/constants/routes';
import { Edit, List, UserProfile } from 'src/theme/Images';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Brand from './Brand/Brand';
import AccountType from './AccountType/AccountType';

interface IMultiBrandStep {
    complete_account: boolean;
    type: boolean;
    brand: boolean;
}
interface IMultiBrandProps {
    country_name: string;
    language_name: string;
    type: 'owner' | 'individual';
    logo_image_name: string;
    business_name: string;
    slug: string;
    google_review?: string;
    facebook_page?: string;
    instagram_page?: string;
    admin_emails?: any;
}
const MultiBrand = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { t } = useTranslation();
    const multiBrandStep: IMultiBrandStep = {
        complete_account: false,
        type: false,
        brand: false,
    };
    const defaultCountry = 'CA';
    const defaultLanguage = 'english';
    const navigate = useNavigate();
    const defaultValues: Partial<IMultiBrandProps> = {
        country_name: defaultCountry,
        language_name: defaultLanguage,
        type: 'owner',
        logo_image_name: '',
        business_name: '',
        slug: '',
        google_review: '',
        facebook_page: '',
        instagram_page: '',
        admin_emails: [],
    };

    const [step, setStep] = useState<ICountStep>(0);

    const handleStep = (direction: 'next' | 'prev') => () => setStep((currentStep) => (direction === 'next' ? currentStep + 1 : currentStep - 1) as ICountStep);

    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: t('Select account type'),
                subTitle: t('Tell us a bit about yourself'),
                image: List,
                step: 1,
            },
            {
                name: t('Configure your brand'),
                subTitle: t('Tell us a bit about your business'),
                image: Edit,
                step: 2,
            },
            {
                name: t('Welcome to', { shop_name: process.env.REACT_APP_FULL_NAME }),
                subTitle: t('Finish your account setup'),
                image: UserProfile,
                step: 3,
            },
        ],
        [],
    );

    useEffect(() => {
        if (!multiBrandStep.type) {
            setStep(1);
        } else if (!multiBrandStep.brand) {
            setStep(2);
        } else {
            navigate(ROUTES.DASHBOARD);
        }
    }, []);

    const renderStepComponent = () => {
        const stepToRender = STEPS[step - 1];
        switch (stepToRender.step) {
            case 1:
                document.title = 'Account Type';
                return <AccountType setStep={setStep} stepLenght={STEPS.length} currentStep={step} />;
            case 2:
                document.title = 'Brand';
                return <Brand setStep={setStep} stepLenght={STEPS.length} currentStep={step} />;
            case 3:
                document.title = 'Welcome';
                return <Welcome stepLenght={STEPS.length} currentStep={step} />;
            default:
                return null;
        }
    };

    const validationSchema = [
        Yup.object({
            type: Yup.string<'owner' | 'individual'>().required(t('Account type required')),
        }).required(),

        Yup.object({
            logo_image_name: Yup.string().required(t('This field is required')),
            business_name: Yup.string().required(t('This field is required')),
            slug: Yup.string()
                .required(t('This field is required'))
                .matches(/^[a-zA-Z0-9-_]+$/, t('Special characters or space not allowed')),
            google_review: Yup.string(),
            facebook_page: Yup.string(),
            instagram_page: Yup.string(),
            admin_emails: Yup.array()
                .of(
                    Yup.object().shape({
                        value: Yup.string().matches(emailRegex, t('Invalid email format')).required(t('Email is required')),
                    }),
                )
                .notRequired(),
        }).required(),
    ];

    const schema = validationSchema[step - 1] as Yup.ObjectSchema<any>;

    const methods = useForm<IMultiBrandProps>({
        resolver: yupResolver(schema) as Resolver<IMultiBrandProps>,
        defaultValues: defaultValues,
    });
    return (
        <>
            {step !== 0 && (
                <>
                    <Sidebar activeStep={step} steps={STEPS} handleStep={handleStep} />
                    <FormProvider {...methods}>
                        <div className="flex-grow min-h-screen">
                            {renderStepComponent()}
                            {/* <main className="px-6 h-[calc(100vh-84px)] overflow-y-scroll flex-1 flex">{renderStepComponent()}</main>
                            <div className="bottom-0 right-0 start-[480px] fixed">
                                <div className="w-full bg-white">
                                    <StepProgressBar steps={STEPS.length} currentStep={step} className="w-full mx-auto justify-center" />
                                </div>
                                <Footer />
                            </div> */}
                        </div>
                    </FormProvider>
                </>
            )}
        </>
    );
};

export default MultiBrand;
