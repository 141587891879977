import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ChevronRight, ChevronUp, ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import { ROUTES } from 'src/constants/routes';
import { image1Location } from 'src/theme/Images';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import SStaffBooking from './SStaffBooking';

const STeamDetail = () => {
    const { t } = useTranslation();
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return (
        <div className="flex-1 flex flex-col mb-4">
            <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                <div className="flex items-center justify-between w-full">
                    <Link to={ROUTES.STAFF.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5">
                        <ArrowLeft className="w-4 cursor-pointer text-gray-600" />
                        Back to Team
                    </Link>
                </div>
            </div>
            <div className="flex flex-col flex-1">
                <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                    <div className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px] custom-loading">
                        <img src={image1Location} alt="" />
                    </div>
                    <div className="flex justify-between items-center w-full">
                        <div className="flex gap-2">
                            <div className="flex flex-col">
                                <div className="flex flex-row items-center">
                                    <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] custom-loading">loadinging</h1>
                                    <span
                                        className={`border xlm:h-[22px] sm:h-[18px] h-[16px] sm:text-[10px] flex justify-center items-center  rounded-full xlm:text-xs font-medium px-2 custom-loading`}
                                    >
                                        loadinging
                                    </span>
                                </div>
                                <p className="custom-loading flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center custom-loading">Children Hair Specialist</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-t border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 xlm:px-8 px-5">
                    <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_342px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8">
                        <div className="fl-tab-btn-view w-full">
                            <div className="w-[785px] h-[25px]">
                                {[...Array(4)].map((_$, index) => (
                                    <button key={index} type="button" className={`w-full`}></button>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="mb-8">
                                <div className="flex justify-between items-center gap-2 mb-3 mt-6">
                                    <h2 className="text-lg font-semibold">{t('Analytics')}</h2>
                                    <CustomButton
                                        className="!text-sm text-btnBg font-semibold flex items-center gap-1.5 h-[20px] shadow-none pr-0.5"
                                        rightIcon={<ChevronUp className="w-4 text-btnBg" />}
                                    >
                                        {t('View more')}
                                    </CustomButton>
                                </div>
                                <div className="grid grid-cols-4 gap-5">
                                    {[...Array(12)].map((_$, index) => (
                                        <div key={index} className="border flex flex-col rounded-lg p-3">
                                            <div>
                                                <span className="text-gray-600 text-xs font-normal mb-1.5 custom-loading">loadingloadinging</span>
                                                <p className="text-gray-900 text-xs font-medium custom-loading">loading</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-8 datatable-custom">
                                <SStaffBooking />
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-[306px] xls:w-[306px]  xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
                        <div className="flex flex-col">
                            <div className="grid grid-cols-1 gap-4 mb-5">
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Phone Number')}</p>
                                    <h3 className="sideBarSubHeading custom-loading">+1 (204) 555-0241</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Email')}</p>
                                    <h3 className="text-primary text-sm font-medium flex items-center custom-loading">loadingloading@</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Last active')}</p>
                                    <h3 className="sideBarSubHeading custom-loading">August 6th, 2024</h3>
                                </div>
                                <div className="title-block">
                                    <p className="sideBarHeading">{t('Account created')}</p>
                                    <h3 className="sideBarSubHeading custom-loading">July 2nd, 2024</h3>
                                </div>
                            </div>
                            <div className="border rounded-xl px-3 mb-5">
                                <div className="flex py-3 gap-1">
                                    <div className="w-1/2">
                                        <div className="flex justify-start items-center">
                                            {/* <div className="bg-gray-100 rounded-md p-2 w-10 h-10">
                                                        <Percent03 className="text-gray-600 " />
                                                    </div> */}
                                            <div className="title-block ">
                                                <p className="text-xs font-normal leading-[18px] text-gray-600">{t('Payment Structure')}</p>
                                                <h3 className="text-sm font-medium text-blue-600 leading-[20px] capitalize custom-loading">Commission</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title-block w-1/2 flex justify-between items-center">
                                        <div>
                                            <p className="text-xs font-normal leading-[18px] text-gray-600 ">{t('Next payout in')}</p>
                                            <h3 className="text-sm font-medium text-blue-600 leading-[20px] custom-loading">0 Days</h3>
                                        </div>
                                        {/* <div>
                                                    <ChevronDown className="text-gray-700 w-5 cursor-pointer" />
                                                </div> */}
                                    </div>
                                </div>
                                <div className="flex py-3 border-t gap-1">
                                    <div className="title-block w-1/2">
                                        <p className="text-xs font-normal leading-[18px] text-gray-600 max-w">Amount</p>
                                        <h3 className="text-sm font-medium text-blue-600 leading-[20px] custom-loading">30%</h3>
                                    </div>
                                    <div className="title-block w-1/2">
                                        <p className="text-xs font-normal leading-[18px] text-gray-600">{t('Current Payment Cycle')}</p>
                                        <h3 className="text-sm font-medium text-blue-600 leading-[20px] custom-loading">{'-'}</h3>
                                    </div>
                                </div>
                            </div>
                            <h6 className="text-sm font-semibold mb-4 text-gray-900">{t('Working Locations')}</h6>
                            <div className="flex flex-col gap-5">
                                {[...Array(8)].map((_$, index) => (
                                    <div key={index} className="rounded-xl border border-gray-200 p-3 flex flex-col relative">
                                        <div className="flex items-center">
                                            <div className="xlm:w-10 xlm:h-10 sm:w-9 w-7 sm:h-9 h-7 sm::mr-3 mr-2.5 flex-none ">
                                                <div className=" h-full w-full object-cover rounded-full custom-loading">
                                                    <img src={image1Location} alt="" />
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-1 truncate gap-[4px] ">
                                                <div>
                                                    <span className="text-gray-900 font-semibold text-sm truncate max-w-[230px] custom-loading">Bloor Street East</span>
                                                    <p className="text-gray-600 text-xs font-normal truncate  max-w-56 custom-loading">Old Toronto, Toronto, Canada</p>
                                                </div>
                                            </div>
                                            <button type="button" className="text-3xl">
                                                {index === 0 ? <ChevronDown className="text-gray-700 w-5 cursor-pointer" /> : <ChevronRight className="text-gray-700 w-5 cursor-pointer" />}
                                            </button>
                                        </div>
                                        <div className={`flex flex-col gap-4 border-t pt-4 mt-4 ${index !== 0 ? 'd-none' : ''}`}>
                                            <span className="text-sm font-semibold text-gray-900">Working Hours</span>
                                            <>
                                                <div className="grid grid-cols-2 gap-4">
                                                    {daysOfWeek.map((day, index2) => (
                                                        <div key={index2} className="w-full">
                                                            <p className="text-xs font-normal text-gray-600 mb-1 leading-[18px] capitalize">{day}</p>
                                                            <p className="text-xs font-semibold text-gray-600 custom-loading">09:00AM - 09:00PM</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="flex justify-between">
                                                    <span className="text-sm font-semibold text-gray-900">Frequency</span>
                                                    <span className="custom-loading">15 Minutes</span>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default STeamDetail;
