/* eslint-disable */
export const GLOBALVARIABLE = {
    accountImageSize: 4096,
    locationImageSize: 800,
    appearanceImageSize: 800,
    forNoValidation: 800000000,
    aboutImageSize: 800,
    heroImageSize: 800,
    productImageSize: 2000,
    locationBannerImageSize: 800,
    galleryImageSize: 2000,
};

export const ColorKeysToCheck = ['accent_color', 'button_color', 'cards_color', 'content_background', 'main_text_color', 'secondary_text_color'];

export const LightThemeCustomColorId = 6;

export const DarkThemeCustomColorId = 12;

export const perPageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '150', value: 150 },
    { label: '200', value: 200 },
];

export const AllLocationsOptions = { label: 'All Locations', value: null };
export const AllRolesOptions = { label: 'All Roles', value: null };
export const AllTeamMembersOptions = { label: 'All Team Members', value: null };
