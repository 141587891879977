import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate, formatName, getShortName } from 'src/utils/global-functions';
import NoDataMessage from 'src/components/NoDataMessage';
import { Box, ArrowUp, ArrowDown } from '@untitled-ui/icons-react/build/cjs';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import { overviewPerPageOptions } from '../Dashboard.Interface';
import SearchBar from 'src/components/SearchBar/SearchBar';

export const bookingAction = {
    view: false,
    data: null as any,
};

const UpcomingAppointment = ({ client, type }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [appointmentList, setAppointmentList] = useState([]);
    const [totalAppointment, setTotalAppointment] = useState<number>(0);
    const [action, setAction] = useState(bookingAction);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });

    useEffect(() => {
        getAppointmentList();
    }, [lazyState, shop]);

    const getAppointmentList = async () => {
        setIsLoading(true);
        await axiosPost(API.BOOKING.LIST, { type: 'upcoming', ...lazyState }, { shop_id: shop.id })
            .then((response) => {
                setAppointmentList(response.data.data.data);
                setTotalAppointment(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-mainTextColor flex items-center text-base mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'confirmed':
                return ' text-blueLight-700';
            case 'completed':
                return 'text-greenText';
            case 'pending':
                return ' text-[#F37744]';
            case 'declined':
                return ' text-blueLight-700';
            case 'canceled':
                return ' text-blueLight-700';
            case 'request_canceled':
                return ' text-blueLight-700';
            case 'expired':
                return ' text-blueLight-700';
            case 'no_show':
                return ' text-[#EE46BC]';
            default:
                return ' text-blueLight-700';
        }
    };

    const GetClient = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]">
                {row.user.profile_image_url ? (
                    <img src={row.user.profile_image_url} alt="client" className="min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] object-cover" />
                ) : (
                    getShortName(row.user.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] w-[120px] min-w-[120px]  max-w-[120px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.user.full_name}
                </p>
                <p className="text-xs font-normal text-gray-600 leading-[18px] w-[120px] min-w-[120px]  max-w-[120px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {formatDate(`${row.booking_date} ${row.booking_start_time}`)}
                </p>
            </div>
        </div>
    );
    const GetTeam = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.staff.profile_image_url ? <img src={row.staff.profile_image_url} alt="client" className=" w-full h-full object-cover" /> : getShortName(row.staff.full_name)}
            </figure>
            <div>
                <p className="text-xs font-normal text-secondaryTxtColor leading-[18px] w-[120px] min-w-[120px]  max-w-[120px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {formatName(row.staff.full_name)}
                </p>
                {/* <p className="text-xs font-normal text-gray-600 leading-[18px] w-[120px] min-w-[120px]  max-w-[120px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {formatDate(`${row.booking_date} ${row.booking_start_time}`)}
                </p> */}
            </div>
        </div>
    );

    const GetService = (row: any) => {
        const servicesArray = row.booking_services;

        return (
            <p className="text-xs font-normal text-secondaryTxtColor w-[100px] min-w-[100px]  max-w-[100px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[120px] xlm:w-[120px] xlm:max-w-[120px] truncate">{`${
                servicesArray[0].staff_service.service.name
            } ${servicesArray.length > 1 ? `+${servicesArray.length - 1}` : ''}`}</p>
        );
    };

    const GetProduct = (row: any) => {
        const productArray = row.booking_products;

        return (
            <p className="text-xs font-normal text-secondaryTxtColor w-[100px] min-w-[100px]  max-w-[100px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[120px] xlm:w-[120px] xlm:max-w-[120px] truncate">{`${
                productArray.length > 0 ? productArray[0].product.name : '-'
            } ${productArray.length > 1 ? `+${productArray.length - 1}` : ''}`}</p>
        );
    };

    const GetTotal = (row: any) => `$${row.total}`;
    const GetLocation = (row: any) => `${row.location.name}`;

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                booking_date: 'loading',
                total_duration: 'loading',
                shop_services: { name: 'loading' },
                shop_admins: { first_name: 'loading' },
                total: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div className="w-full border rounded-xl flex-col mb-5 flex shadow datatable-custom">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div className={`${type === 'appointments' ? '' : ''}`}>
                    <h2 className="view-table-title">{t('Upcoming Appointments ')}</h2>
                    <p className="view-table-subtitle">Upcoming appointment activity</p>
                </div>
                <div className="flex gap-4 ">
                    <div className="table-searchInput location-dropdown">
                        <SearchBar labelIconClassName="text-gray-500" placeholder={t('Search')} onChange={() => {}} className="form-control-md " />
                    </div>

                    <div className="location-dropdown">
                        <SelectBox
                            name="page"
                            className={`min-w-[70px]`}
                            options={type === 'overview' ? overviewPerPageOptions : perPageOptions}
                            isClearable={false}
                            isSearchable={false}
                            onChangeFunc={onPageHandle}
                            value={type === 'overview' ? overviewPerPageOptions.find((option) => option.value === lazyState.rows) : perPageOptions.find((option) => option.value === lazyState.rows)}
                            classComp={`w-[70px] max-w-[70px] ${type === 'appointments' && ''}`}
                        />
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={type === 'appointments' ? customPaginatorTemplate : undefined}
                value={isLoading ? skeletons : appointmentList}
                totalRecords={totalAppointment}
                paginator={!isLoading && type === 'appointments' ? totalAppointment > lazyState.rows : undefined}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className="rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage
                            title={`${lazyState.filters.global.value ? 'No bookings found.' : 'No bookings added.'}`}
                            description={`${lazyState.filters.global.value ? 'Try adjusting your filters or add new bookings.' : 'Add a bookings, to start using the bookings collection.'}`}
                            iconComponent={<Box className="text-gray-700" />}
                        />
                    </div>
                }
            >
                <Column
                    field="user_name"
                    header={renderHeader(t('Client'), 'user_name')}
                    body={isLoading ? <Skeleton /> : GetClient}
                    className="font-normal text-mainTextColor truncate"
                    sortable
                    style={{ width: '190px', minWidth: '190px', maxWidth: '190px' }}
                ></Column>
                <Column
                    field="location_name"
                    header={renderHeader(t('Location'), 'location_name')}
                    body={isLoading ? <Skeleton /> : GetLocation}
                    className="text-xs text-secondaryTxtColor font-normal truncate"
                    // style={{ width: '15%' }}
                    style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    sortable
                ></Column>
                <Column
                    field="staff_name"
                    header={renderHeader(t('Team member'), 'staff_name')}
                    body={isLoading ? <Skeleton /> : GetTeam}
                    className="font-normal  text-mainTextColor truncate"
                    sortable
                    style={{ width: '190px', minWidth: '190px', maxWidth: '190px' }}
                ></Column>

                <Column
                    field="service_name"
                    header={renderHeader(t('Service'), 'service_name')}
                    body={isLoading ? <Skeleton /> : GetService}
                    className="text-xs text-secondaryTxtColor font-normal truncate"
                    // style={{ width: '20%' }}
                    style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                    sortable
                ></Column>
                <Column
                    field="product_name"
                    header={renderHeader(t('Product'), 'product_name')}
                    body={isLoading ? <Skeleton /> : GetProduct}
                    className="text-xs text-secondaryTxtColor font-normal truncate"
                    // style={{ width: '20%' }}
                    style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                    sortable
                ></Column>
                <Column
                    field="total"
                    header={renderHeader(t('Total'), 'total')}
                    body={isLoading ? <Skeleton /> : GetTotal}
                    // style={{ width: '12.50%' }}
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    className="text-xs text-secondaryTxtColor font-normal truncate"
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default UpcomingAppointment;
