import React, { useLayoutEffect, useMemo, useState } from 'react';
import AccountSettings from './AccountSettings/AccountSettings';
import BusinessDetails from './BusinessDetails/BusinessDetails';
import Hyperlinks from './Hyperlinks/Hyperlinks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROLES, SETTING_TABS } from 'src/constants/common';
import PageHeader from 'src/components/PageHeader';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import { PATH } from 'src/constants/path';
import LoyaltyReferral from './LoyaltyReferral/LoyaltyReferral';
import Calendar from './Calendar/Calendar';
import Payment from './Payment/Payment';
import ClientNotifications from './ClientNotifications/ClientNotifications';
import Notifications from './Notifications/Notifications';
import Product from './Product/Product';
import { useAppSelector } from 'src/redux/hooks';
import { currentRole } from 'src/redux/services/common/Common.slice';

const Setting = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { tab: currentTab } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<any>();
    const role = useAppSelector(currentRole);
    const tabs = useMemo(
        () => [
            [
                {
                    name: t('Brand Overview'),
                    slug: SETTING_TABS.BUSINESS,
                    except: [],
                },

                {
                    name: t('Loyalty & Referral'),
                    slug: SETTING_TABS.LOYALTY,
                    except: [],
                },

                {
                    name: t('Client Notifications'),
                    slug: SETTING_TABS.CLIENT_NOTIFICATION,
                    except: [],
                },
                {
                    name: t('Products'),
                    slug: SETTING_TABS.PRODUCT,
                    except: [],
                },
                {
                    name: t('Payments'),
                    slug: SETTING_TABS.PAYMENT,
                    except: [],
                },

                {
                    name: t('Team Management'),
                    slug: SETTING_TABS.TEAM,
                    except: [ROLES.STAFF_COMMISSION, ROLES.STAFF_RENT, ROLES.STAFF_PARTNER],
                },
            ],
            [
                {
                    name: t('My Account'),
                    slug: SETTING_TABS.ACCOUNT,
                    except: [],
                },
                {
                    name: t('Calendar'),
                    slug: SETTING_TABS.CALENDAR,
                    except: [],
                },
                {
                    name: t('Notifications'),
                    slug: SETTING_TABS.NOTIFICATION,
                    except: [],
                },
            ],
        ],
        [],
    );

    useLayoutEffect(() => {
        const test = tabs[0].find((item: any) => item.slug === currentTab)?.except.includes(role.name);
        const test1 = tabs[1].find((item: any) => item.slug === currentTab)?.except.includes(role.name);

        if (currentTab && (test || test1)) {
            navigate(`${PATH.SETTINGS}${SETTING_TABS.BUSINESS}`);
            return;
        }
        switch (currentTab) {
            case SETTING_TABS.BUSINESS:
                document.title = t('Titles.Setting Business Details');
                setActiveTab(<BusinessDetails />);
                break;
            case SETTING_TABS.ACCOUNT:
                document.title = t('Titles.Setting Account Settings');
                setActiveTab(<AccountSettings />);
                break;
            case SETTING_TABS.LOYALTY:
                document.title = t('Titles.Setting Loyalty Referral');
                setActiveTab(<LoyaltyReferral />);
                break;
            case SETTING_TABS.CALENDAR:
                document.title = t('Titles.Setting Calendar Settings');
                setActiveTab(<Calendar />);
                break;
            case SETTING_TABS.CLIENT_NOTIFICATION:
                document.title = t('Titles.Setting Client Notifications');
                setActiveTab(<ClientNotifications />);
                break;
            case SETTING_TABS.PAYMENT:
                document.title = t('Titles.Setting Team Management');
                setActiveTab(<Payment />);
                break;
            case SETTING_TABS.TEAM:
                document.title = t('Titles.Setting Team Management');
                setActiveTab(<PaymentSettings />);
                break;
            case SETTING_TABS.HYPERLINK:
                document.title = t('Titles.Setting Hyperlinks');
                setActiveTab(<Hyperlinks />);
                break;
            case SETTING_TABS.NOTIFICATION:
                document.title = t('Titles.Setting Notifications');
                setActiveTab(<Notifications />);
                break;
            case SETTING_TABS.PRODUCT:
                document.title = t('Titles.Setting Product');
                setActiveTab(<Product />);
                break;
            default:
                navigate(`${PATH.SETTINGS}${SETTING_TABS.BUSINESS}`);
                break;
        }
    }, [currentTab, navigate, tabs, role.name]);

    const handleTabClick = (slug: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        navigate(`${PATH.SETTINGS}${slug}`);
    };

    return (
        <div className="inner-page-wrape ">
            <div className="flex flex-col flex-1">
                <PageHeader
                    title={currentTab === SETTING_TABS.ACCOUNT || currentTab === SETTING_TABS.CALENDAR || currentTab === SETTING_TABS.NOTIFICATION ? t('Account Settings') : t('Brand Settings')}
                    subtitle={t('Manage your brand settings', {
                        settingName: currentTab === SETTING_TABS.ACCOUNT || currentTab === SETTING_TABS.CALENDAR || currentTab === SETTING_TABS.NOTIFICATION ? 'account' : 'brand',
                    })}
                />
                <div className="side-spaching flex flex-1 flex-col w-full">
                    <div className="fl-tab-btn-view w-full">
                        {currentTab === SETTING_TABS.ACCOUNT || currentTab === SETTING_TABS.CALENDAR || currentTab === SETTING_TABS.NOTIFICATION
                            ? tabs[1].map((tab, index) =>
                                  tab.except.includes(role.name) ? null : (
                                      <button
                                          key={index}
                                          type="button"
                                          className={`fl-tab-link !max-w-[182px] ${
                                              currentTab === tab.slug ||
                                              (pathname === '/settings' && tab.slug === SETTING_TABS.BUSINESS) ||
                                              (pathname === '/settings/' && tab.slug === SETTING_TABS.BUSINESS)
                                                  ? 'active'
                                                  : ''
                                          }`}
                                          onClick={handleTabClick(tab.slug)}
                                      >
                                          {tab.name}
                                      </button>
                                  ),
                              )
                            : tabs[0].map((tab, index) =>
                                  tab.except.includes(role.name) ? null : (
                                      <button
                                          key={index}
                                          type="button"
                                          className={`fl-tab-link !max-w-[182px] ${
                                              currentTab === tab.slug ||
                                              (pathname === '/settings' && tab.slug === SETTING_TABS.BUSINESS) ||
                                              (pathname === '/settings/' && tab.slug === SETTING_TABS.BUSINESS)
                                                  ? 'active'
                                                  : ''
                                          }`}
                                          onClick={handleTabClick(tab.slug)}
                                      >
                                          {tab.name}
                                      </button>
                                  ),
                              )}
                    </div>
                    {activeTab}
                </div>
            </div>
        </div>
    );
};

export default Setting;
