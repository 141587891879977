import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import Switch from 'src/components/Switch/Switch';
import { AppearanceHero, InstagramColor } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { HeroProps, IFormData } from './Hero.interface';
import TemplateLayout from '../Layout/Layout';
import { allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import CropperModal from 'src/components/CropperModal/CropperModal';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import ImageUpload from 'src/components/ImageUpload';
import { GLOBALVARIABLE } from 'src/utils/global-variables';

const Hero: FC<HeroProps> = ({ template }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template_preview')?.value?.hero;
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [image, setImage] = useState<any>('');
    const [fieldName, setFieldName] = useState<keyof IFormData | null>(null);

    const shopId = shop.id;
    const schema = Yup.object({
        status: Yup.bool().required('This field is required.'),
        is_title: Yup.bool().required('This field is required.'),
        title: Yup.string()
            .nullable()
            .when('is_title', ([is_title], customSchema) => (is_title ? customSchema.required('This field is required.') : customSchema.nullable())),
        is_subtitle: Yup.bool().required('This field is required.'),
        subtitle: Yup.string()
            .nullable()
            .when('is_subtitle', ([is_subtitle], customSchema) => (is_subtitle ? customSchema.required('This field is required.') : customSchema.nullable())),
        is_button: Yup.bool().required('This field is required.'),
        button: Yup.string()
            .nullable()
            .when('is_button', ([is_button], customSchema) => (is_button ? customSchema.required('This field is required.') : customSchema.nullable())),
        is_instgram_button: Yup.bool().required('This field is required.'),
        instgram_button: Yup.string()
            .nullable()
            .when('is_instgram_button', ([is_instgram_button], customSchema) => (is_instgram_button ? customSchema.required('This field is required.') : customSchema.nullable())),
        is_reviews: Yup.bool().required('This field is required.'),
        reviews: Yup.string()
            .nullable()
            .when('is_reviews', ([is_reviews], customSchema) => (is_reviews ? customSchema.required('This field is required.') : customSchema.nullable())),
        reviews_description: Yup.string().nullable(),
        is_background: Yup.bool().required(t('This field is required')),
        background: Yup.object()
            .nullable()
            .shape({
                name: Yup.string().when('is_background', ([is_background], customSchema) => (is_background ? customSchema.required(t('This field is required')) : customSchema.nullable())),
                file: Yup.string().nullable(),
                url: Yup.string().when('is_background', ([is_background], customSchema) => (is_background ? customSchema.required(t('This field is required')) : customSchema.nullable())),
            })
            .test('background-validation', t('This field is required'), function (value) {
                const { is_background: isImage } = this.parent;
                if (!isImage) return true; // If is_image is false, no need to validate
                if (!value) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                const { name, url } = value;
                if (!name || !url) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                return true;
            }),
    });
    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            status: shopSettingInfo?.status || false,
            is_title: shopSettingInfo?.is_title || false,
            title: shopSettingInfo?.title || '',
            is_subtitle: shopSettingInfo?.is_subtitle || false,
            subtitle: shopSettingInfo?.subtitle || '',
            is_button: shopSettingInfo?.is_button || false,
            button: shopSettingInfo?.button || '',
            is_instgram_button: shopSettingInfo?.is_instgram_button || false,
            instgram_button: shopSettingInfo?.instgram_button || '',
            is_reviews: shopSettingInfo?.is_reviews || false,
            reviews: shopSettingInfo?.reviews || '',
            reviews_description: shopSettingInfo?.reviews_description || '',
            is_background: template === 'template_2' && shopSettingInfo?.is_background ? shopSettingInfo?.is_background : false,
            background:
                template === 'template_2' && shopSettingInfo?.background
                    ? {
                          name: shopSettingInfo?.background,
                          url: shopSettingInfo?.background && shopSettingInfo?.background_url,
                      }
                    : null,
        },
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        clearErrors,
        formState: { errors },
    } = methods;
    const isReviews = watch('is_reviews');
    const isStatus = watch('status');
    const isBackground = watch('is_background');
    const backgroundData = watch('background');

    const handleSwitchOnChange = (name: keyof IFormData) => (event: any) => {
        setValue(name, event.value);
    };
    // const handleReviewsChange = (event: any) => {
    //     setValue('reviews', event ? event.value : null);
    // };
    const handleChange = async (data: any) => {
        setIsLoading(true);
        let imageStatus = errorCode.updateSuccess;
        if (backgroundData?.file && backgroundData?.name) {
            const logoImageResponse: any = await s3Upload(backgroundData?.file, `${s3Path.SHOP_HERO_BACKGROUND}${backgroundData?.name}`);
            imageStatus = logoImageResponse?.status;
        }

        if (imageStatus === errorCode.updateSuccess) {
            submitForm(data);
        }
    };
    const submitForm = async (data: IFormData) => {
        setIsLoading(true);

        const payload = {
            hero: { ...data, background: data?.background?.name },
            section: 'hero',
        };

        await axiosPost(API.THEME.TEMPLATE.SECTION, payload, {
            shop_id: shopId,
        })
            .then(async (response) => {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
                return;
            })
            .catch((error) => {
                if (error.response.data.status === errorCode.unprocessable) {
                    if (error.response.data.data) {
                        Object.keys(error.response.data.data).forEach((field) => {
                            const fieldKey = field.replace('hero.', '');
                            setError(fieldKey as keyof IFormData, {
                                type: 'manual',
                                message: error.response.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    const onChangeCrop = (type: keyof IFormData) => (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.heroImageSize);
        if (!result) {
            setError(type, {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setIsUpload(false);
            };
            clearErrors(type);

            reader.onloadend = () => {
                setImage(reader.result as any);
                setIsUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            setFieldName(type);
        }
    };
    const handleCropData = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fieldName) {
            setValue(fieldName, {
                file: convertedFile.convertedFile as File,
                url: data,
                name: convertedFile.filename,
            });
        }
    };
    const imageUpload = async (data: any) => {};

    // const handleRemoveImage = () => {
    //     setValue('background', null);
    // };

    useFormErrorFocus<IFormData>({ errors, formSectionName: 'home' });

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange)}>
                <Collapse
                    title={t('Hero')}
                    description={t('The Hero section is the prominent top section of the website, featuring a key image or video and headline to engage visitors immediately.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange)}
                >
                    <TemplateLayout ImgProp={AppearanceHero}>
                        <>
                            <div className="flex justify-between mb-4 gap-4 items-start">
                                <Controller
                                    name={`title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Title')}
                                                placeholder={t('Enter hero title')}
                                                onChange={onChange}
                                                value={value}
                                                required
                                                id="home-title"
                                                name={'title'}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_title"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="subtitle"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Subtitle')}
                                                placeholder={t('Enter hero subtitle')}
                                                onChange={onChange}
                                                required
                                                value={value}
                                                id="home-subtitle"
                                                name="subtitle"
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_subtitle"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex gap-4 mb-4">
                                <div className="w-1/3">
                                    <Controller
                                        name="button"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label={t('Book Button Title')}
                                                    placeholder={t('Enter Button Title')}
                                                    id="home-button"
                                                    onChange={onChange}
                                                    value={value}
                                                    required
                                                    error={!!error}
                                                    name="button"
                                                    toggle={
                                                        <Controller
                                                            name="is_button"
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="w-2/3">
                                    <Controller
                                        name="instgram_button"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label={t('Instagram Profile URL')}
                                                    placeholder={t('Enter Instagram Username')}
                                                    id="home-instgram_button"
                                                    onChange={onChange}
                                                    labelIcon={
                                                        <div className="flex flex-row gap-1.5 items-center">
                                                            <InstagramColor />
                                                            <p className="text-xs font-medium text-mainTextColor">instagram.com/</p>
                                                        </div>
                                                    }
                                                    value={value}
                                                    required
                                                    error={!!error}
                                                    name="instgram_button"
                                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                                    inputControlClassName="pl-3"
                                                    toggle={
                                                        <Controller
                                                            name="is_instgram_button"
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="flex gap-4 mb-4">
                                    <div className="w-1/3">
                                        <div className="flex items-center gap-[10px] mb-[6px]">
                                            <label htmlFor="" className="fl-field-title-label mb-0">
                                                {' '}
                                                Reviews
                                            </label>
                                            <Switch className="" onChange={handleSwitchOnChange('is_reviews')} isChecked={isReviews} />
                                        </div>
                                        <Controller
                                            name="reviews"
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel type="number" min={0} max={5} placeholder={t('Enter Stars')} onChange={onChange} value={value} name="reviews" id="home-reviews" />
                                                    {/* <SelectBox
                                                        name="reviews"
                                                        id="home-reviews"
                                                        required
                                                        isSearchable={false}
                                                        value={REVIEW_STARS.filter((item: any) => item.value === value)}
                                                        options={REVIEW_STARS}
                                                        onChangeFunc={handleReviewsChange}
                                                        error={!!fieldState.error}
                                                        placeholder="Select Stars"
                                                    /> */}
                                                    {/* {error && <p className="text-error">{error?.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="w-2/3">
                                        <Controller
                                            name="reviews_description"
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                                <div className="w-full items-bottom">
                                                    <InputWithLabel
                                                        id="home-reviews_description"
                                                        placeholder={t('Total Google reviews')}
                                                        label={t('Total Google reviews')}
                                                        onChange={onChange}
                                                        value={value}
                                                        name="reviews_description"
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            {template === 'template_2' && (
                                <div className="flex gap-4 mb-4">
                                    <Controller
                                        name={`background`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div id="home-background" className="w-full">
                                                <div className="flex items-center gap-[10px] mb-[6px]" color="gray">
                                                    <label className="fl-field-title-label mb-0">
                                                        {t('Background content')}
                                                        <span className="asterisk">*</span>
                                                    </label>
                                                    <Switch className="" onChange={handleSwitchOnChange('is_background')} isChecked={isBackground} />
                                                </div>
                                                <ImageUpload cropData={value?.url} onChangeCrop={onChangeCrop('background')} id="background" shape="square" error={error?.message} />

                                                {/* <div className="relative cursor-pointer h-9 flex">
                                                    <input type="file" id="image" className="w-full absolute opacity-0" onChange={onChangeCrop('background')} />
                                                    <div
                                                        className={`rounded-l-lg border !rounded-r-none border-borderPrimary px-3 flex-1 flex items-center text-xs custom-hover-effect ${
                                                            error ? 'is-invalid rounded-l-lg rounded-r-0' : ''
                                                        }`}
                                                    >
                                                        <span className="flex-auto text-gray-500">Upload your background image</span>
                                                        <XCircle className="text-gray-400" width="16" />
                                                    </div>
                                                    <label
                                                        htmlFor="image"
                                                        className="px-4 py-2 font-medium gap-1 flex items-center justify-between rounded-r-lg border border-primary text-primary text-xs"
                                                    >
                                                        <UploadCloud01 width="16" />
                                                        {t('Upload')}
                                                    </label>
                                                </div>
                                                {value && value.url && (
                                                    <ImageInfo className="w-[350px] h-[72px] mt-[6px] relative" name={value.name} url={value?.url} onRemove={handleRemoveImage} isApperance />
                                                )} */}

                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            )}
                        </>
                    </TemplateLayout>
                    {isUpload && fieldName && (
                        <CropperModal
                            imageUpload={imageUpload}
                            imageUrl={image}
                            setUpload={setIsUpload}
                            setCropData={handleCropData}
                            defaultCropType="Square"
                            title={t('Background Image')}
                            description={t('Upload a  max 800Kb image for best results.')}
                            isCropType={false}
                            btnTitle={t('Confirm')}
                        />
                    )}
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Hero;
