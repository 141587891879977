export const NOTIFICATION_TABS = {
    GENERAL: 'general',
    APPOINTMENT: 'appointment',
};

export interface INotificationSettings {
    push: boolean;
    email: boolean;
    sms: boolean;
}
export interface IFormData {
    daily_checkout: INotificationSettings;
    new_user: INotificationSettings;
    monthly_statement: INotificationSettings;
    commission_statement: INotificationSettings;
    rent_statement: INotificationSettings;
    tip_statement: INotificationSettings;
    product_commission_statement: INotificationSettings;
    low_stock: INotificationSettings;
    staff_completed_registration: INotificationSettings;
    cancellations: INotificationSettings;
    cancellation_past_limit: INotificationSettings;
    reschedule: INotificationSettings;
    user_update_booking: INotificationSettings;
    staff_update_booking: INotificationSettings;
    update_booking: INotificationSettings;
    new_booking: INotificationSettings;
    new_user_booking: INotificationSettings;
    new_user_booking_referral: INotificationSettings;
    payment_structure_update: INotificationSettings;
}
