import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from '@amcharts/amcharts5/percent';

const AdvAnalyticsChartPie = ({ expenseGraphData }: any) => {
    useLayoutEffect(() => {
        let root = am5.Root.new('advance-expense-chartpie-div');
        if (root._logo) {
            root._logo.dispose();
        }
        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout,
            }),
        );

        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: 'Series',
                valueField: 'value',
                categoryField: 'name',
            }),
        );

        series.slices.template.set(
            'tooltip',
            am5.Tooltip.new(root, {
                getFillFromSprite: false,
                autoTextColor: false,
                background: am5.RoundedRectangle.new(root, {
                    fill: am5.color('#ffffff'),
                    fillOpacity: 1,
                }),
                pointerOrientation: 'horizontal',
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
            }),
        );

        series.slices.template.adapters.add('tooltipText', (text, target) => {
            const dataItem = target.dataItem;
            if (dataItem) {
                return `[#5279F0][fontSize:12px]●[/] [#475467][medium fontSize:12px]{name}[/]: [#475467][medium fontSize:12px]{valuePercentTotal.formatNumber('#.0')}%[/]`;
            }
            return text;
        });
        series.data.setAll(expenseGraphData);

        series.labels.template.set('visible', false);
        series.labels.template.set('disabled', true);
        series.ticks.template.set('disabled', false);
        series.slices.template.set('toggleKey', 'none');
        series.ticks.template.set('visible', false);
        series.labels.template.set('forceHidden', true);
        series.ticks.template.set('forceHidden', true);
        series.slices.template.set('hoverOnFocus', false);

        var legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                layout: root.horizontalLayout,
            }),
        );
        legend.itemContainers.template.set('setStateOnChildren', false);

        return () => {
            root.dispose();
        };
    }, [expenseGraphData]);

    return (
        <div
            id="advance-expense-chartpie-div"
            className="w-[160px] h-[140px] 2xl:w-[100px] 2xl:h-[100px] xxl:w-[140px] xxl:max-w-[140px] xxl:min-w-[140px] xxl:min-h-[140px] xxl:max-h-[140px] xxl:h-[140px]"
        ></div>
    );
};

export default AdvAnalyticsChartPie;
