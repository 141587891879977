import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Checkbox, Label } from 'flowbite-react';
import CustomButton from 'src/components/CustomButton';
import { ArrowLeft, BankNote02, CreditCard02, Gift01, Grid02, Monitor05 } from '@untitled-ui/icons-react/build/cjs';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { GoDotFill } from 'react-icons/go';
import { useFormContext } from 'react-hook-form';
import { IInitAction } from '../CheckoutSidebar.Interface';
import { IPaymentState } from 'src/app/Calendar/Calendar.Interface';

type PaymentMethod = 'cash' | 'pos' | 'stripe' | 'loyalty' | 'other';
interface IProps {
    handleAction: (type: keyof IInitAction) => () => void;
    className?: string;
    setPaymentArray: React.Dispatch<React.SetStateAction<any>>;
    appointmentCalulation: any;
    setCardList: React.Dispatch<React.SetStateAction<any>>;
    setLoyalPointAmount: React.Dispatch<React.SetStateAction<any>>;
    loyalPointAmount: any;
}
const Checkout: FC<IProps> = ({ className, handleAction, setPaymentArray, appointmentCalulation, setCardList, setLoyalPointAmount, loyalPointAmount }) => {
    const { getValues } = useFormContext();
    const bookedSlotInfo = getValues();
    const shop = useAppSelector(currentShop);
    const { t } = useTranslation();
    const [isSplitPayment, setIsSplitPayment] = useState<boolean>(false);
    const [paymentType, setPaymentType] = useState<PaymentMethod[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [defaultCard, setDefaultCard] = useState<any>(null);

    const formattedDuration = (duration: any) => moment.utc(duration.asMilliseconds()).format(duration.hours() > 0 ? (duration.minutes() > 0 ? 'H [Hours] m [Minutes]' : 'H [Hours]') : 'm [Minutes]');
    const isPaymentMethodAvailable = (type: PaymentMethod) => paymentType.includes(type);

    const handlePaymenttype = (event: any) => {
        const value = event.target.value;
        let filteredPayments = paymentType;
        if (!isSplitPayment) {
            const updatePayment = paymentType.includes(value) ? [] : [value];
            let payment = {
                type: value,
                amount: 0,
            };
            if (value === 'loyalty') {
                payment.amount = appointmentCalulation.total;
            }
            setPaymentArray((prevState: IPaymentState) => ({
                ...prevState,
                payments: [payment],
            }));

            // setPaymentArray(...paymentArray, { payments: [payment] });
            setPaymentType(updatePayment);
            return;
        }
        if (paymentType.includes(value)) {
            filteredPayments = filteredPayments.filter((item) => item !== value);
        } else {
            if (value !== 'cash') {
                filteredPayments = filteredPayments.filter((item) => item === 'cash');
            }
            filteredPayments = [...filteredPayments, value];
        }
        const payment = filteredPayments.map((filteredPayment) => ({
            type: filteredPayment,
            amount: 0,
        }));
        // setPaymentArray({ payments: payment });
        setPaymentArray((prevState: IPaymentState) => ({
            ...prevState,
            payments: payment,
        }));
        setPaymentType(filteredPayments);
    };

    const handleContinue = () => {
        handleAction('complate_payment')();
    };

    const handleSplitPayment = () => {
        setPaymentType([]);
        setIsSplitPayment(!isSplitPayment);
    };
    const handleBack = () => handleAction('checkout')();

    const getCardList = () => {
        axiosGet(API.CLIENT.CARD_LIST, { shop_id: shop.id, id: bookedSlotInfo.user_id })
            .then((response) => {
                const data = response.data.data;
                let filterData = response.data.data.cards.find((items: any) => items.id === response.data.data.default_card_id);
                const cardListwithLable = data.cards.map((card: any) => ({
                    ...card,
                    value: card.id,
                    label: `⭑⭑⭑⭑ - ⭑⭑⭑⭑ - ⭑⭑⭑⭑ - ${card.card.last4}`,
                }));
                setCardList(cardListwithLable);
                // setCardList(data.cards);
                setDefaultCard(filterData);
            })
            .finally(() => setIsLoading(false));
    };
    const getLoyaltyCard = () => {
        axiosGet(API.BOOKING.LOYALTY, { shop_id: shop.id }, { user_id: bookedSlotInfo.user_id, shop_admin_id: bookedSlotInfo.staff_id })
            .then((response) => {
                const data = response.data.data;
                setLoyalPointAmount(data.total_loyalty_amount);
            })
            .finally(() => setIsLoading(false));
        axiosGet(API.CLIENT.CARD_LIST, { shop_id: shop.id, id: bookedSlotInfo.user_id });
    };
    useEffect(() => {
        getCardList();
        getLoyaltyCard();
    }, []);

    return (
        <>
            <div className="p-4 flex items-center border-b gap-3">
                <Link className="close-btn" to="" onClick={handleBack}>
                    <ArrowLeft className="w-[18px] text-gray-700" />
                </Link>

                <div className="flex flex-col">
                    <span className="text-base font-semibold leading-6 text-gray-900">New Checkout</span>
                    {/* <span className="text-gray-600 text-xs font-medium leading-[18px]"> Appointment #{bookedSlotInfo.receipt_code}</span> */}
                </div>
            </div>

            <div className={`p-4 flex-1 overflow-y-auto ${className}`}>
                {bookedSlotInfo.services.length > 0 && (
                    <div className="mb-[10px] pb-[10px] border-b border-gray-200">
                        <h1 className="text-gray-600 text-xs font-semibold leading-[18px] mb-[6px]">Service(s)</h1>
                        {bookedSlotInfo.services.map((service: any, index: number) => (
                            <div className="flex justify-between mb-[4px] last:mb-0 " key={`${service.id}-${index}`}>
                                <p className="text-gray-900 font-normal text-xs leading-[18px] gap-1 flex flex-row">
                                    <span className="truncate max-w-[145px]">{`${service.name}`}</span>
                                    <span className="text-gray-600 font-normal text-xs leading-[18px]">({formattedDuration(moment.duration(service.duration))})</span>
                                </p>
                                <p className="ml-auto text-gray-600 font-normal text-xs leading-[18px]">${service.price}</p>
                            </div>
                        ))}
                    </div>
                )}

                {bookedSlotInfo.products.length > 0 && (
                    <div className="border-b border-gray-200  pb-[10px] mb-[10px]">
                        <h1 className="text-gray-600 text-xs font-semibold leading-[18px] mb-[6px]">Product(s)</h1>
                        {bookedSlotInfo.products.map((product: any, index: number) => (
                            <div className="flex justify-between mb-[4px] last:mb-0" key={`${product.id}-${index}`}>
                                <p className="text-gray-900 font-normal text-xs leading-[18px] gap-1 flex flex-row">
                                    <span className="truncate max-w-[145px]">{`${product.name}`}</span>
                                    <span className="text-gray-600 font-normal text-xs leading-[18px]">{`(${product.variantInfo.size} ${product.unit_of_measure})`}</span>
                                </p>
                                <p className="ml-auto text-gray-600 font-normal text-xs leading-[18px]">${product.variantInfo.price}</p>
                            </div>
                        ))}
                    </div>
                )}

                <div className="flex justify-between mb-[10px] pb-[10px] border-b border-gray-200">
                    {/* <p className="text-xs font-medium leading-[18px] text-gray-900">Subtotal</p> */}
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Subtotal</p>
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${appointmentCalulation.subtotal}</p>
                </div>
                <div className="flex justify-between mb-[12px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Taxes</p>
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${appointmentCalulation.taxes_and_fees}</p>
                </div>

                <div className="flex justify-between mb-3 pb-3 border-b border-gray-200">
                    <p className="text-gray-900 font-bold text-sm leading-5">Total</p>
                    <p className="ml-auto text-gray-900 font-bold text-sm leading-5">${appointmentCalulation.total}</p>
                </div>
            </div>
            <div className="flex flex-col ">
                <div className="flex flex-col px-4">
                    <div className="mb-4 flex justify-between pt-3">
                        <span className="text-gray-900 text-sm font-semibold leading-5"> Select a Payment Method </span>
                        <span className="text-gray-900 text-xs font-medium leading-[18px] flex">
                            <Checkbox id="isSplitPayment" checked={isSplitPayment} onChange={handleSplitPayment} />
                            <p className="ml-2">Split Payment</p>
                        </span>
                    </div>

                    <Label htmlFor="cashPayment" className={`payment-radio-block   ${isPaymentMethodAvailable('cash') && 'active'}`}>
                        <input type="button" id="cashPayment" name="paymentMethod" className="payment-radio" value={'cash'} onClick={handlePaymenttype} />
                        <div className="payment-radio-content  ">
                            <div className={`text-center flex items-center gap-3`}>
                                <div className="payment-icon-container ">
                                    <BankNote02 className="payment-icon " />
                                </div>
                                <p className="payment-radio-text ">Cash</p>
                            </div>
                        </div>
                    </Label>
                    <Label htmlFor="frontDeskPayment" className={`payment-radio-block ${isPaymentMethodAvailable('pos') && 'active'}`}>
                        <input type="button" id="frontDeskPayment" name="paymentMethod" className="payment-radio" value={'pos'} onClick={handlePaymenttype} />
                        <div className="payment-radio-content">
                            <div className="text-center flex items-center gap-3 w-full">
                                <div className="payment-icon-container">
                                    <Monitor05 className="payment-icon" />
                                </div>
                                <p className="payment-radio-text">Terminal</p>

                                <p className="flex flex-row gap-1 ml-auto ">
                                    <GoDotFill size={12} className="h-4 w-4 rounded-full flex mr-[2px] text-[#D92D20]" />
                                    {/* resd mate text-[#D92D20] */}
                                    <span className="text-mainTextColor font-medium text-xs leading-[18px]">Disconnected</span>
                                </p>
                            </div>
                        </div>
                    </Label>

                    {defaultCard && (
                        <Label htmlFor="creditCardPayment" className={`payment-radio-block ${isPaymentMethodAvailable('stripe') && 'active'}`}>
                            <input type="button" id="creditCardPayment" name="paymentMethod" className="payment-radio" value={'stripe'} onClick={handlePaymenttype} />
                            <div className="payment-radio-content">
                                <div className={`text-center flex items-center gap-3 w-full`}>
                                    <div className="payment-icon-container">
                                        <CreditCard02 className="payment-icon" />
                                    </div>
                                    <p className="payment-radio-text">Credit Card</p>
                                    <p className="ml-auto text-gray-600 font-normal text-xs leading-[18px]">⭑⭑⭑⭑ - ⭑⭑⭑⭑ - ⭑⭑⭑⭑ -{defaultCard?.card?.last4}</p>
                                </div>
                            </div>
                        </Label>
                    )}

                    <Label htmlFor="otherPayment" className={`payment-radio-block group hover:border-[#7191EF] ${isPaymentMethodAvailable('other') && 'active'}`}>
                        <input type="button" id="otherPayment" name="paymentMethod" className="payment-radio" value={'other'} onClick={handlePaymenttype} />
                        <div className="payment-radio-content">
                            <div className={`text-center flex items-center gap-3 w-full`}>
                                <div className="payment-icon-container">
                                    <Grid02 className="payment-icon" />
                                </div>
                                <p className="payment-radio-text">Other</p>
                            </div>
                        </div>
                    </Label>
                    {loyalPointAmount >= appointmentCalulation.total && (
                        <Label htmlFor="loyalPointPayment" className={`payment-radio-block ${isPaymentMethodAvailable('loyalty') && 'active'}`}>
                            <input
                                type="button"
                                id="loyalPointPayment"
                                disabled={isSplitPayment || !loyalPointAmount >= appointmentCalulation.total}
                                name="paymentMethod"
                                className="payment-radio"
                                value={'loyalty'}
                                onClick={handlePaymenttype}
                            />
                            <div className="payment-radio-content">
                                <div
                                    className={`text-center flex items-center gap-3 w-full ${
                                        isSplitPayment || !(loyalPointAmount && loyalPointAmount >= appointmentCalulation.total) ? 'opacity-50' : ''
                                    }`}
                                >
                                    <div className="payment-icon-container">
                                        <Gift01 className="payment-icon" />
                                    </div>
                                    <p className="payment-radio-text">Loyalty points</p>
                                    <p className="ml-auto text-gray-600 font-normal text-xs leading-[18px]">{bookedSlotInfo.user_info.total_loyalty_points} points</p>
                                </div>
                            </div>
                        </Label>
                    )}
                </div>

                <div className="border-t px-4 py-4 w-full">
                    <div className="flex gap-4 w-full">
                        <CustomButton className="w-1/2" secondary onClick={handleBack}>
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton type="button" disabled={paymentType.length < (isSplitPayment ? 2 : 1)} className="w-1/2" primary onClick={handleContinue}>
                            {t('Continue')}
                        </CustomButton>
                    </div>
                </div>
            </div>
            {/* <h1>checkout</h1> */}
        </>
    );
};
export default Checkout;
