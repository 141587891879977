import React, { memo, useEffect, useState } from 'react';
import { ICalulationDetails, ISidebarSchema } from './Sidebar.Interface';
import { FormProvider, useForm } from 'react-hook-form';

import { getShortName } from 'src/utils/global-functions';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectCalendarData, setData } from '../Calendar.slice';

import AppointmentHeader from './AppointmentHeader';
import CalulationDetails from './CalulationDetails';

import Loader from 'src/components/Loader/Loader';
import ManageSubmit from './Appointment/ManageSubmit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Client from './Client/Client';
import Service from './Service/Service';
import Product from './Product/Product';
import { format } from 'date-fns';
import { Calendar } from '@untitled-ui/icons-react/build/cjs';
import Recurring from './Recurring/Recurring';
import { useTranslation } from 'react-i18next';
import DateManageInfo from './Appointment/DateManageInfo';
import CancelAppointment from './CancelAppointment/CancelAppointment';

const AppointmentDetails = () => {
    const dispatch = useAppDispatch();
    const calendarData = useAppSelector(selectCalendarData);
    const [appointmentCalulation, setAppointmentCalulation] = useState<ICalulationDetails | null>(null);
    const { t } = useTranslation();
    const schema = Yup.object().shape({
        staff_id: Yup.number().required('Staff is required'),
        user_id: Yup.number().required('Client is required'),
        payment_method: Yup.string().required('Payment method is required'),
        services: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().required('Service is required'),
                    quantity: Yup.number().required('Quantity is required'),
                }),
            )
            .min(1, 'At least one service must be selected'),
        booking_date: Yup.date().required('Booking date is required'),
        booking_time: Yup.string().required('Booking time is required'),
        is_recurring: Yup.boolean(),
        recurring_frequency: Yup.string()
            .nullable()
            .when('is_recurring', ([is_title], customSchema) => (is_title ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        recurring_number: Yup.number()
            .nullable()
            .when('is_recurring', ([is_title], customSchema) => (is_title ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        recurring_end_date: Yup.string()
            .nullable()
            .when('is_recurring', ([is_title], customSchema) => (is_title ? customSchema.required(t('This field is required')) : customSchema.nullable())),
    });

    const methods = useForm<ISidebarSchema>({
        resolver: yupResolver(schema) as any,
        defaultValues: {
            services: null,
            payment_method: 'pay_online',
            user_id: null,
            staff_id: null,
            is_recurring: false,
            recurring_frequency: null,
            recurring_end_date: moment().add(15, 'days').toDate(),
            recurring_number: null,
        },
    });

    useEffect(() => {
        if (calendarData.bookedSlotInfo) {
            handleBookedSlot();
        }
    }, [calendarData.bookedSlotInfo]);
    useEffect(() => {
        if (calendarData.selectedSlotStaff) {
            setValue('staff_id', calendarData.selectedSlotStaff.id);
        }
    }, [calendarData.selectedSlotStaff]);

    useEffect(() => {
        const isValidDate = moment(calendarData.selectedTime).isValid();
        if (isValidDate && calendarData.selectedDate) {
            setValue('booking_date', format(calendarData.selectedDate, 'yyyy-MM-dd'));
        }
    }, [calendarData.selectedDate]);

    useEffect(() => {
        const isValidDate = moment(calendarData.selectedTime).isValid();
        if (isValidDate && calendarData.selectedTime) {
            setValue('booking_time', format(calendarData.selectedTime, 'HH:mm:ss'));
        }
    }, [calendarData.selectedTime]);

    const handleBookedSlot = async () => {
        if (calendarData.bookedSlotInfo) {
            const selectedServices: any = [];
            calendarData.bookedSlotInfo?.booking_services.forEach((booking_service: any) => {
                const { service, quantity, duration } = booking_service;

                for (let index = 0; index < quantity; index++) {
                    const momentDuration = moment.duration(duration);
                    const formattedDuration = moment.utc(momentDuration.asMilliseconds()).format(momentDuration.hours() > 0 ? 'H [hours] m [minutes]' : 'm [minutes]');
                    selectedServices.push({ ...service, quantity: booking_service.quantity, price: booking_service.price, formattedDuration: formattedDuration, duration });
                }
            });
            // const restructuredData = await calendarData.bookedSlotInfo?.booking_services.map((item: any) => ({
            //     name: item.service.name,
            //     price: parseFloat(item.price),
            //     staff_name: `${calendarData.bookedSlotInfo.staff.first_name} ${calendarData.bookedSlotInfo.staff.last_name}`, // Assuming staff_service_id is the staff name or ID
            //     time_slot: moment(`${calendarData.bookedSlotInfo.booking_date} ${calendarData.bookedSlotInfo.booking_start_time}`).format('hh:mm A'),
            //     duration: item.duration,
            //     qty: item.quantity,
            //     is_custom_price: item.is_custom_price,
            //     id: item.service.id,
            // }));
            // const idArray = await restructuredData.map((item: { id: any }) => item.id);
            /* const newCalculationDetails: ICalulationDetails = {
                appointmentCalulation: {
                    product: Number(calendarData.bookedSlotInfo.booking_products.map((product: any) => product.price * product.quantity).reduce((a: any, b: any) => a + b, 0)),
                    service: Number(calendarData.bookedSlotInfo.booking_services.map((service: any) => service.price * service.quantity).reduce((a: any, b: any) => a + b, 0)),
                    subtotal: Number(calendarData.bookedSlotInfo.subtotal),
                    taxes_and_fees: Number(calendarData.bookedSlotInfo.taxes_and_fees),
                    total: Number(calendarData.bookedSlotInfo.total),
                    tips: calendarData.bookedSlotInfo.is_tip_paid
                        ? Number(calendarData.bookedSlotInfo.tip_transaction.map((tip_transaction: any) => tip_transaction.total_amount).reduce((a: any, b: any) => a + b, 0))
                        : 0,
                },
            }; */
            const tips = calendarData.bookedSlotInfo.is_tip_paid
                ? Number(calendarData.bookedSlotInfo.tip_transaction.map((tip_transaction: any) => tip_transaction.total_amount).reduce((a: any, b: any) => a + b, 0))
                : 0;
            const completedCalculation: ICalulationDetails = {
                appointmentCalulation: {
                    product: Number(calendarData.bookedSlotInfo.booking_products.map((product: any) => product.price * product.quantity).reduce((a: any, b: any) => a + b, 0)),
                    service: Number(calendarData.bookedSlotInfo.booking_services.map((service: any) => service.price * service.quantity).reduce((a: any, b: any) => a + b, 0)),
                    subtotal: Number(calendarData.bookedSlotInfo.subtotal),
                    taxes_and_fees: calendarData.bookedSlotInfo.is_taxable ? Number(calendarData.bookedSlotInfo.taxes_and_fees) : 0,
                    total: +calendarData.bookedSlotInfo.total + +tips - +(!calendarData.bookedSlotInfo.is_taxable ? +calendarData.bookedSlotInfo.taxes_and_fees : 0),
                    tips: tips,
                },
            };

            const selectedProducts: any = [];
            await calendarData.bookedSlotInfo?.booking_products?.forEach((booking_product: any) => {
                const { product, quantity, product_variant: selectedVariant, product_inventory: inventory } = booking_product;
                for (let index = 0; index < quantity; index++) {
                    selectedProducts.push({ ...product, value: inventory.id, variantInfo: { ...selectedVariant, inventory, value: inventory.id } });
                }
            });

            setAppointmentCalulation(completedCalculation);
            setValue('services', selectedServices);
            setValue('products', selectedProducts);
            setValue('user_id', calendarData.bookedSlotInfo.user.id);
            setValue('is_recurring', calendarData.bookedSlotInfo.is_recurring);
            setValue('recurring_frequency', calendarData.bookedSlotInfo.recurring_frequency);
            setValue('recurring_end_date', calendarData.bookedSlotInfo.recurring_end_date);
            setValue('recurring_number', Number(calendarData.bookedSlotInfo.recurring_number));

            // const selectedDate = calendarData.timeZone && moment(`${calendarData.bookedSlotInfo.booking_date}`, 'YYYY-MM-DD').toDate().toLocaleString('en-US', { timeZone: calendarData.timeZone });
            const bookingDateTime = moment(`${calendarData.bookedSlotInfo.booking_date} ${calendarData.bookedSlotInfo.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').toDate();
            const formattedDate = bookingDateTime && calendarData.timeZone && bookingDateTime.toLocaleString('en-US', { timeZone: calendarData.timeZone });

            dispatch(
                setData({
                    selectedDate: moment(`${calendarData.bookedSlotInfo.booking_date} ${calendarData.bookedSlotInfo.booking_start_time}`).toDate(),
                    selectedTime: moment(formattedDate).toDate(),
                    selectedClientInfo: calendarData.bookedSlotInfo.user,
                }),
            );
        }
    };

    // const PaymentMethodJoined =
    //     calendarData.bookedSlotInfo &&
    //     [...calendarData.bookedSlotInfo.booking_transaction, ...calendarData.bookedSlotInfo.tip_transaction]
    //         .map((transaction: { payment_method: any }) => capitalizeFirstLetter(transaction.payment_method))
    //         .reduce((unique: string[], item: string) => (unique.includes(item) ? unique : [...unique, item]), [] as string[])
    //         .join(', ');

    const { setValue } = methods;

    return (
        <>
            {!calendarData.isLoading ? (
                <>
                    <FormProvider {...methods}>
                        <form className="h-full flex flex-col">
                            <AppointmentHeader />

                            <div className="p-4 flex-1 overflow-y-auto">
                                <Client />
                                <>
                                    {calendarData.bookedSlotInfo &&
                                        !calendarData.calendarStep.updateAppointment &&
                                        !calendarData.calendarStep.rescheduleAppointment &&
                                        !calendarData.calendarStep.newAppointment && (
                                            <>
                                                <div className="mb-3 border-b border-borderSecondary pb-3">
                                                    {calendarData.selectedSlotStaff && (
                                                        <div className="flex flex-col">
                                                            <label htmlFor="newclient" className="text-gray-700 text-xs font-semibold leading-[18px] mb-1.5">
                                                                Team Member
                                                            </label>
                                                            <div className="flex flex-row items-center">
                                                                <figure className="NoImgName w-[20px] h-[20px] !mr-2">
                                                                    {calendarData.selectedSlotStaff ? (
                                                                        calendarData.selectedSlotStaff.profile_image_url ? (
                                                                            <img src={calendarData.selectedSlotStaff.profile_image_url} alt="client" className="w-full h-full object-cover" />
                                                                        ) : (
                                                                            getShortName(calendarData.selectedSlotStaff.full_name)
                                                                        )
                                                                    ) : (
                                                                        '+'
                                                                    )}
                                                                </figure>
                                                                <span className="text-gray-900 font-medium text-xs leading-[18px] mr-2">{calendarData.selectedSlotStaff.full_name}</span>
                                                                <span className="text-gray-600 font-normal text-xs leading-[18px]">{calendarData.selectedSlotStaff.staff_role.name}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-3 border-b border-borderSecondary pb-3">
                                                    <div className="flex items-center">
                                                        <p className="flex flex-col">
                                                            <span className="text-gray-700 text-xs font-semibold leading-[18px] mb-1.5">Date and Time </span>
                                                            <span className="font-normal text-xs leading-[18px] text-gray-700 items-center flex flex-row gap-1.5">
                                                                <Calendar className="w-4 text-gray-500" />
                                                                {` ${moment(
                                                                    `${calendarData.bookedSlotInfo.booking_date} ${calendarData.bookedSlotInfo.booking_start_time}`,
                                                                    'YYYY-MM-DD HH:mm:ss',
                                                                ).format('ddd, MMM D [at] h:mm A')}`}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </>
                                <Service />
                                <Product />
                                <Recurring />
                                {(calendarData.calendarStep.cancelAppointment || calendarData.calendarStep.noShowAppointment) && <CancelAppointment />}

                                {calendarData.bookedSlotInfo && !calendarData.calendarStep.rescheduleAppointment && <DateManageInfo />}
                            </div>

                            {appointmentCalulation && calendarData.calendarStep.bookedAppointment && <CalulationDetails appointmentCalulation={appointmentCalulation.appointmentCalulation} />}

                            <ManageSubmit appointmentInfo={calendarData.bookedSlotInfo} />
                        </form>
                    </FormProvider>{' '}
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default memo(AppointmentDetails);
