import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from 'src/components/Switch/Switch';

const StaffAppointment = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="flex flex-col flex-1">
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Client Cancellations')}</p>
                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Be notified of canceled appointments.')}</p>
                </div>
                <Controller
                    name="cancellations"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Late Cancellations')}</p>
                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Get notified about late cancellations and fee options.')}</p>
                </div>
                <Controller
                    name="cancellation_past_limit"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Appointment Reschedules')}</p>
                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Stay informed of client rescheduling.')}</p>
                </div>
                <Controller
                    name="reschedule"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Appointment Modifications')}</p>
                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Know when clients modify their appointments.')}</p>
                </div>
                <Controller
                    name="update_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('New Client Appointments')}</p>
                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Receive alerts for new bookings.')}</p>
                </div>
                <Controller
                    name="new_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('New First-Time Clients')}</p>
                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Be alerted when a first-time client books with you.')}</p>
                </div>
                <Controller
                    name="new_user_booking"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Referred First-Time Clients')}</p>
                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Get notified about first-time clients from referrals.')}</p>
                </div>
                <Controller
                    name="new_user_booking_referral"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default StaffAppointment;
