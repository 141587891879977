import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch } from 'src/redux/hooks';
import { getAllSalesTaxes } from 'src/redux/services/common/Common.slice';
import { ISalesTaxResponse, ISelectBox } from './Location.interface';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { useTranslation } from 'react-i18next';

const SalesTaxesForm = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, watch } = useFormContext();
    const salesTaxId = watch('sales_tax_id');
    const [salesTaxes, setSalesTaxes] = useState([]);
    const [salesTaxOptions, setSalesTaxOptions] = useState<ISelectBox[]>([]);
    const [initSelectedSalesTaxes] = useState({ gst: '0.00', pst: '0.00', hst: '0.00' });
    const [selectedSalesTaxes, setSelectedSalesTaxes] = useState(initSelectedSalesTaxes);

    useEffect(() => {
        salesTaxList();
    }, []);

    useEffect(() => {
        if (salesTaxes.length) {
            const data: any = salesTaxes.find((salesTax: ISalesTaxResponse) => salesTax.id === salesTaxId);
            if (data) {
                setSelectedSalesTaxes({
                    gst: data.gst,
                    pst: data.pst,
                    hst: data.hst,
                });
            }
        } else {
            setSelectedSalesTaxes(initSelectedSalesTaxes);
        }
    }, [salesTaxId]);

    const salesTaxList = async () => {
        const result = await dispatch(getAllSalesTaxes());
        if (result.type === getAllSalesTaxes.fulfilled.toString()) {
            const data = result.payload.data;
            const options: ISelectBox[] | [] = getSelectBoxOptions(data, 'id', 'territory');
            setSalesTaxOptions(options);
            setSalesTaxes(data);
        }
    };
    return (
        <div className="flex flex-wrap gap-4 w-[400px]">
            <div className="flex gap-4 ">
                <div className="w-[290px]">
                    <Controller
                        name="sales_tax_id"
                        control={control}
                        render={({ field: { onChange, value }, fieldState }: any) => (
                            <>
                                <SelectBox
                                    name="sales_tax_id"
                                    id="sales_tax_id"
                                    placeholder={t('Select sales tax')}
                                    options={salesTaxOptions}
                                    onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                    value={salesTaxOptions.find((option) => option.value === value)}
                                    error={!!fieldState.error}
                                />
                                {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                            </>
                        )}
                    />
                </div>
                <div className="w-full">
                    <Controller
                        name="tax_id"
                        control={control}
                        render={({ field: { onChange, value }, fieldState }: any) => (
                            <>
                                <InputWithLabel id="tax_id" placeholder={t('Enter business tax id')} onChange={onChange} value={value} name="tax_id" error={!!fieldState.error} />
                                {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="flex gap-4 ">
                <div>
                    <div className="w-full max-w-[123px]">
                        <InputWithLabel
                            readOnly
                            name="gst"
                            value={`%${selectedSalesTaxes.gst}`}
                            onChange={() => {}}
                            label={
                                <>
                                    GST <span className="text-primary">Read-only</span>
                                </>
                            }
                        />
                    </div>
                </div>
                <div>
                    <div className="w-full max-w-[123px]">
                        <InputWithLabel
                            readOnly
                            name="pst"
                            value={`%${selectedSalesTaxes.pst}`}
                            onChange={() => {}}
                            label={
                                <>
                                    PST <span className="text-primary">Read-only</span>
                                </>
                            }
                        />
                    </div>
                </div>
                <div>
                    <div className="w-full max-w-[123px]">
                        <InputWithLabel
                            readOnly
                            name="hst"
                            value={`%${selectedSalesTaxes.hst}`}
                            onChange={() => {}}
                            label={
                                <>
                                    HST <span className="text-primary">Read-only</span>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesTaxesForm;
