import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceProduct } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IFormData, ProductProps } from './Product.interface';
import Form from './Form';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import TemplateLayout from '../Layout/Layout';
import { allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

const Product: FC<ProductProps> = ({ template }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template_preview')?.value?.product;
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const schema = Yup.object({
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        title: Yup.string()
            .nullable()
            .when('is_title', ([is_title], customSchema) => (is_title ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        is_subtitle: Yup.bool().required(t('This field is required')),
        subtitle: Yup.string()
            .nullable()
            .when('is_subtitle', ([is_subtitle], customSchema) => (is_subtitle ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        products: Yup.array()
            .test({
                name: 'products',
                message: 'Please add at least one product',
                test: function (value) {
                    const { status } = this.parent;
                    if (status && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object().shape({
                    name: Yup.string().required(t('This field is required')),
                    price: Yup.string().required(t('This field is required')),
                    button: Yup.string().required(t('This field is required')),
                    category: Yup.string().required(t('This field is required')),
                    is_description: Yup.bool().required(t('This field is required')),
                    description: Yup.string()
                        .nullable()
                        .when('is_description', ([is_description], customSchema) => (is_description ? customSchema.required(t('This field is required')) : customSchema.nullable())),

                    images: Yup.array()
                        .test({
                            name: 'image',
                            message: 'Please add at least one image',
                            test: function (value) {
                                const { status } = this.parent;
                                if (status && (!value || value.length === 0)) {
                                    return false;
                                }
                                return true;
                            },
                        })
                        .of(
                            Yup.object()
                                .shape({
                                    name: Yup.string().required(t('This field is required')),
                                    file: Yup.mixed().nullable(),
                                    url: Yup.string().nullable(),
                                })
                                .test('image-validation', t('This field is required'), function (value) {
                                    if (!value) {
                                        return this.createError({
                                            path: `${this.path}`,
                                            message: t('This field is required'),
                                        });
                                    }
                                    const { name, url } = value;
                                    if (!name || !url) {
                                        return this.createError({
                                            path: `${this.path}`,
                                            message: t('This field is required'),
                                        });
                                    }
                                    return true;
                                }),
                        )
                        .required(),
                }),
            )
            .when('status', ([status], customSchema) => (status ? customSchema.required() : customSchema.nullable()))
            .nullable(),
    });

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormData>,
        defaultValues: {
            status: false,
            is_title: false,
            title: '',
            is_subtitle: false,
            subtitle: '',
            products: [],
        },
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');

    useEffect(() => {
        if (shopSettingInfo) {
            const updatedProducts = shopSettingInfo?.products?.map((product: any, index: number) => ({
                name: product.name,
                category: product.category,
                price: product.price,
                button: product.button,
                is_description: product.is_description,
                description: product.description,
                images: product?.images?.map((image: any, indexImage: number) => {
                    if (image) {
                        return {
                            name: image,
                            url: (image && product.urls[indexImage]) ?? null,
                        };
                    }
                    return null;
                }),
            }));
            setValue('status', shopSettingInfo.status);
            setValue('is_title', shopSettingInfo.is_title);
            setValue('title', shopSettingInfo.title);
            setValue('is_subtitle', shopSettingInfo.is_subtitle);
            setValue('subtitle', shopSettingInfo.subtitle);
            setValue('products', updatedProducts);
            // setValue('image', updatedImages);
        }
    }, [shopSettingInfo]);

    const handleChange = async (data: any) => {
        setIsLoading(true);
        let imageStatus = errorCode.updateSuccess;

        const imagePromises = data.products.map((product: any) =>
            product?.images?.map((image: any) => (image?.file ? s3Upload(image.file, `${s3Path.SHOP_PRODUCT}${image.name}`) : Promise.resolve(null))),
        );

        if (imagePromises) {
            const imageResponses = await Promise.allSettled(imagePromises);
            const isSuccess = imageResponses.every((response: any) => response.status === 'fulfilled' && (response.value?.status === errorCode.updateSuccess || response?.value === null));
            if (isSuccess) {
                imageStatus = errorCode.updateSuccess;
            }
        }

        if (imageStatus === errorCode.updateSuccess) {
            const payload = {
                product: { ...data, products: data?.products?.map((product: any) => ({ ...product, images: product?.images?.map((image: any) => image?.name) })) },
                section: 'product',
            };

            try {
                const response = await axiosPost(API.THEME.TEMPLATE.SECTION, payload, {
                    shop_id: shop.id,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(getAllShopSettings({ shop_id: shop.id }));
                    return;
                }
                if (response.data.status === errorCode.unprocessable) {
                    if (response.data.data) {
                        Object.keys(response.data.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data.data[field][0],
                            });
                        });
                    }
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    useFormErrorFocus<IFormData>({ errors, formSectionName: 'product' });

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(handleChange)}
                className={`h-full ${errors?.products && errors?.products?.message ? 'is-invalid rounded-xl border' : 'rounded-xl border border-borderPrimary'}`}
            >
                <Collapse
                    title={t('Products')}
                    description={t('The Products section displays and describes grooming products available for purchase at your brand.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange)}
                >
                    <TemplateLayout ImgProp={AppearanceProduct}>
                        <div>
                            <div className="flex justify-between items-start mb-4 gap-4">
                                <Controller
                                    name={`title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Title')}
                                                id="product-title"
                                                required
                                                placeholder={t('Enter product title')}
                                                onChange={onChange}
                                                value={value}
                                                name={'title'}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={'is_title'}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="subtitle"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Subtitle')}
                                                id="product-subtitle"
                                                required
                                                placeholder={t('Enter product subtitle')}
                                                onChange={onChange}
                                                value={value}
                                                name="subtitle"
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={'is_subtitle'}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                            <Form />
                        </div>
                        {errors.products && errors?.products?.message && <p className="text-error text-center">{errors?.products?.message}</p>}
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Product;
