import React, { FC, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch } from 'src/redux/hooks';
import { IOtp, IVerifyOtpProps } from './VerifyOtp.interface';
import { verifyOtp, verifyPhoneEmailOtp } from './VerifyOtp.slice';
import OTPField from './OTPField/OTPField';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_KEYS } from 'src/constants/common';
import { errorCode } from 'src/constants/errorCode';
import { userLogin, userPhoneEmailLogin } from '../../Login/Login.slice';
import { me } from 'src/redux/services/common/Common.slice';
import Button from 'src/components/Button';
import { FaArrowLeft } from 'react-icons/fa6';
import CustomButton from 'src/components/CustomButton';
type FieldKey = 'otp';

const VerifyOtp: FC<IVerifyOtpProps> = ({ authData, handleAuthAction, setAuthData, resetAuthAction }) => {
    const [activeOTPIndex, setactiveOTPIndex] = useState<number>(0);
    const [inputValue, setInputValue] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handlerLoginOtpAgainSend = async () => {
        reset();
        let cread;
        if (authData.phone && authData.email) {
            cread = {
                email: authData.email,
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
            };
            const result: any = await dispatch(userPhoneEmailLogin(cread));
            if (result.type === userLogin.fulfilled.toString()) {
            }
        } else {
            if (authData.isEmail) {
                cread = {
                    email: authData.email,
                };
            } else {
                cread = {
                    phone: `+${authData.phone.code}${authData.phone.number}`,
                    phone_country_code: authData.phone.country,
                };
            }
            const result: any = await dispatch(userLogin(cread));
            if (result.type === userLogin.fulfilled.toString()) {
            }
        }
    };

    const handleChangePhoneEmail = async () => {
        if (authData.isEmail) {
            setAuthData({
                ...authData,
                email: null,
                isEmail: false,
            });
        } else {
            setAuthData({
                ...authData,
                phone: null,
                isEmail: true,
            });
        }
        handleAuthAction('phoneEmailVerification');
    };

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        reset,
    } = useForm<IOtp>({
        defaultValues: {
            otp: new Array(4).fill(''),
        },
    });
    const onInputChange = async (data: any) => {
        const Values = data.filter((value: string) => value.trim() !== '');
        if (Values.length === 4) {
            setInputValue(true);
        } else {
            setInputValue(false);
        }
    };

    const handleChange = async (data: any) => {
        setLoading(true);
        let cread;
        if (authData.isEmail) {
            cread = {
                email: authData.email,
                otp: data.otp.join(''),
            };
        } else {
            cread = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
                otp: data.otp.join(''),
            };
        }
        if (authData.email && authData.phone) {
            const result = await dispatch(verifyPhoneEmailOtp(cread));
            if (result.type === verifyPhoneEmailOtp.fulfilled.toString()) {
                if (result?.payload?.data?.token) {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.payload.data.token);
                    await dispatch(me());
                }
                setLoading(false);
            }
            if (result.type === verifyPhoneEmailOtp.rejected.toString()) {
                const response = result.payload;

                if (response.status === errorCode.unprocessable) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });

                    // setValue('number', new Array(4).fill(''));
                    // setactiveOTPIndex(0);
                }
                setLoading(false);
            }
        } else {
            const result = await dispatch(verifyOtp(cread));
            if (result.type === verifyOtp.fulfilled.toString()) {
                const enhancedAuthData = {
                    ...authData,
                    otp: data.otp.join(''),
                };
                if (result?.payload?.data?.is_new_user) {
                    setAuthData(enhancedAuthData);
                    handleAuthAction('phoneEmailVerification');
                } else if (result?.payload?.data?.is_password_set) {
                    const nameAuthData = {
                        ...enhancedAuthData,
                        name: result?.payload?.data?.first_name,
                    };
                    setAuthData(nameAuthData);
                    handleAuthAction('passwordVerification');
                } else if (result?.payload?.data?.token) {
                    setAuthData(enhancedAuthData);

                    localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.payload.data.token);
                    await dispatch(me());
                }
                setLoading(false);
            }
            if (result.type === verifyOtp.rejected.toString()) {
                const response = result.payload;

                if (response.status === errorCode.unprocessable) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                    // setValue('number', new Array(4).fill(''));
                    // setactiveOTPIndex(0);
                }
                setLoading(false);
            }
        }
    };
    return (
        <div className="content mt-[84px] flex justify-center">
            <div className="w-[380px]">
                <h2 className="text-3xl leading-[]38px font-semibold m-0 text-mainTextColor leading-[124%] -tracking-[0.384px]">{t('Enter the verification code')}</h2>
                <div className="mt-1 mb-6 text-secondaryTxtColor text-sm leading-[140%] -tracking-[0.14px]">
                    <span>
                        {t('We sent a code to ')} <span className="font-bold">{authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}</span>
                    </span>
                </div>
                <div className="flex justify-center">
                    <form className="w-full" onSubmit={handleSubmit(handleChange)}>
                        <div className="mx-12">
                            <Controller
                                name="otp"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="flex flex-col mt-3">
                                        <OTPField
                                            otp={value}
                                            setOtp={(data: any) => {
                                                onChange(data); // Call the original onChange from react-hook-form
                                                onInputChange(data); // Then handle the input change for button styling
                                            }}
                                            activeOTPIndex={activeOTPIndex}
                                            setactiveOTPIndex={setactiveOTPIndex}
                                            errors={errors.otp}
                                        />
                                        {errors.otp && <p className="text-error text-center">{errors.otp.message}</p>}
                                    </div>
                                )}
                            />
                            {authData.isEmail && (
                                <div className="flex mt-2 text-mainTextColor text-xs leading-[140%] -tracking-[0.14px]">
                                    <span className="opacity-70"> {t('Tip: Be sure to check your inbox and spam folders')}</span>
                                </div>
                            )}
                        </div>
                        <CustomButton primary type="submit" isLoading={loading} disabled={loading || !inputValue} size="w-full" className="!text-base mt-[20px]">
                            {t('Continue')}
                        </CustomButton>
                        <div className="mt-6 flex flex-col items-center">
                            <div className="flex">
                                <p className="text-secondaryTxtColor me-1 font-normal leading-[19.6px] -tracking-[0.384px]">{t('Didn’t receive code')}?</p>
                                <Button type="button" onClick={handlerLoginOtpAgainSend} className="cursor-pointer flex flex-col leading-[19.6px] text-sm font-bold text-primary">
                                    {t('Resend')}
                                </Button>
                            </div>
                            {authData.email && authData.phone && (
                                <Button type="button" onClick={handleChangePhoneEmail} className="cursor-pointer flex flex-col leading-[19.6px] text-sm font-semibold mt-2 text-secondaryTxtColor ">
                                    {authData.isEmail ? t('Changed your email?') : t('Changed your mobile number?')}
                                </Button>
                            )}

                            <div className="text-center mt-4">
                                <Button type="button" className="text-sm font-semibold" onClick={() => resetAuthAction()}>
                                    <FaArrowLeft size={14} className="me-1" /> {t('Back to Log in')}
                                </Button>
                            </div>
                        </div>
                        {/* isLoading={loading} disabled={isButtonDisabled} */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default VerifyOtp;
