export const FONT_FAMILY = [
    { value: 'helvetica', label: 'Helvetica' },
    { value: 'plus_jakarta_sans', label: 'Plus Jakarta Sans' },
    { value: 'roboto', label: 'Roboto' },
    { value: 'inter', label: 'Inter' },
    { value: 'montserrat', label: 'Montserrat' },
    { value: 'poppins', label: 'Poppins' },
    { value: 'mulish', label: 'Mulish' },
    // Add more options as needed
];
export const LANGUAGES = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
    // Add more options as needed
];
export interface IFormData {
    // slug: string;
    brand_color: string;
    font_family: string;
    base_theme: string;
    menu_placement: string;
    element_style: string;
    primary_language: string;
    secondary_language?: string;
    brand_text_color: string;
}

export interface IColor {
    name: string;
    hex: string;
}
export const COLORS: IColor[] = [
    { name: 'Teal', hex: '#57908B' },
    { name: 'PurpleBlue', hex: '#5F5790' },
    { name: 'Blue', hex: '#4589FF' },
    { name: 'Gray', hex: '#F4F4F4' },
    { name: 'Black', hex: '#000000' },
    { name: 'Purple', hex: '#800080' },
    { name: 'Pink', hex: '#FF7EB6' },
    { name: 'Red', hex: '#B8001F' },
];
