import React, { memo, useEffect, useRef, useState } from 'react';
import { IDropDownWithIcon } from './IDropDownWithIcon.interface';

const DropdownWithIcon: React.FC<IDropDownWithIcon> = ({
    label,
    data,
    setselected,
    className,
    subSwitch,
    handleItemClick,
    setSubSwitch,
    dropDownMenuClassName,
    dropDownMenuItemClassName,
    dropDownMenuLinkClassName,
    labelActiveIcon,
    labelIcon,
    dropwDownContainerClass,
}) => {
    const [Switch, setSwitch] = useState(false);

    useEffect(() => {
        const handler = () => {
            setSwitch(false);
            setSubSwitch?.(false);
        };
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);

    const handlerInputClick = (e: any) => {
        e.stopPropagation();
        setSwitch(!Switch);
        setSubSwitch?.(!subSwitch || false);
    };

    const onItemClick = (e: any, itemValue: any) => {
        setselected(itemValue);
        handleItemClick && handleItemClick(e);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const closeHandler = () => {
        setSwitch(false);
        setSubSwitch?.(false);
    };
    useEffect(() => {
        // Function to handle click outside the div
        const handleClickOutside = (event: MouseEvent) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                closeHandler();
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef]);
    return (
        <div className={`relative ${dropwDownContainerClass} cursor-pointer`} ref={divRef}>
            <div className="flex items-center" onClick={handlerInputClick}>
                <div className={className}>
                    {label ? (
                        <>
                            <span className="text">{label}</span>
                            {/* <i className="icon ml-2 text-xl">
                                {Switch || subSwitch ? (
                                    <HiChevronUp />
                                ) : (
                                    <HiChevronDown />
                                )}
                            </i> */}
                        </>
                    ) : labelActiveIcon && labelIcon ? (
                        <span className="text">{!Switch ? labelActiveIcon : labelIcon}</span>
                    ) : labelIcon ? (
                        <span className="text">{labelIcon}</span>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {Switch && (
                <div id="dropdownDelay" className={`z-10 absolute border border-gray-200 py-0.5 bg-white shadow-xl rounded-lg right-0 ${dropDownMenuClassName}`}>
                    <ul className="text-sm flex flex-col " aria-labelledby="dropdownDelayButton">
                        {data.map((items: any, index: number) => (
                            <li className={`flex h-[38px] px-[10px] cursor-pointer hover:bg-gray-50 items-center first:pt-0 last:pb-0 ${dropDownMenuItemClassName}`} key={index} value={items.label}>
                                {items.icon1 && items.icon1Position === 'left' && items.icon1}
                                {items.icon2 && items.icon2Position === 'left' && items.icon2}
                                <div className={`cursor-pointer flex-1  text-gray-900 leading-[18px] font-medium text-xs ${dropDownMenuLinkClassName}`} onClick={(e) => onItemClick(e, items)}>
                                    {items.label}
                                </div>
                                {items.icon1Position === 'right' && items.icon1}
                                {items.icon2 && items.icon2Position === 'right' && items.icon2}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default memo(DropdownWithIcon);
