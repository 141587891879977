import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import ClientBooking from './ClientBooking';
import CommonKpiCard from 'src/components/CommonKpiCard.tsx/CommonKpiCard';
import moment from 'moment';
import { getDateRange } from 'src/utils/global-functions';

const Overview = ({ client }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [booking, setBooking] = useState<any>({});
    const [viewAll, setViewAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [boxLabel, setBoxLabel] = useState('Yesterday');

    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [currentTab, setCurrentTab] = useState('last_30_days');
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('last_30_days');
    const timePeriodTabs = useMemo(
        () => [
            {
                name: t('30 Days'),
                value: 'last_30_days',
            },
            {
                name: t('90 Days'),
                value: 'last_90_days',
            },
            {
                name: t('180 Days'),
                value: 'last_180_days',
            },
            {
                name: t('360 Days'),
                value: 'last_year',
            },
            {
                name: t('All'),
                value: 'all_time',
            },
        ],
        [],
    );
    useEffect(() => {
        if (id) {
            let days = moment(endDate).diff(moment(startDate), 'days');
            let payload = {};
            if (currentTab !== 'all_time') {
                payload = {
                    days: days === 0 ? 30 : days + 1,
                };
            }

            axiosGet(API.CLIENT.COUNT, { shop_id: shop.id, id }, payload)
                .then((response) => {
                    const data = response.data.data;
                    setBooking(data.total);
                })
                .finally(() => setIsLoading(false));
        }
    }, [id, shop, startDate, endDate]);
    useEffect(() => {
        if (startDate && endDate) {
            if (selectedTimeRange === 'all_time') {
                setBoxLabel('Previous period');
            } else if (selectedTimeRange === 'quarterly') {
                setBoxLabel('last quarter');
            } else if (currentTab === '') {
                let days = moment(endDate).diff(moment(startDate), 'days');
                days = selectedTimeRange === 'last_30_days' || selectedTimeRange === 'last_90_days' || selectedTimeRange === 'last_7_days' || selectedTimeRange === 'custom' ? days + 1 : days;

                setBoxLabel(`Previous ${days} days`);
            }
        }
    }, [startDate, endDate]);
    useEffect(() => {
        if (currentTab !== '') {
            const { start, end } = getDateRange(currentTab);
            setStartDate(start);
            setEndDate(end);
        }
        if (currentTab === 'last_30_days') {
            setBoxLabel('last 30 days');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'last_90_days') {
            setBoxLabel('last 90 days');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'last_180_days') {
            setBoxLabel('last 180 days');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'last_year') {
            setBoxLabel('Last year');
            setSelectedTimeRange('last_year');
        } else if (currentTab === 'all_time') {
            setBoxLabel('All Time');
            setSelectedTimeRange('all_time');
        }
    }, [currentTab]);
    const handleTab = (val: string) => () => {
        setCurrentTab(val);
    };

    const KPIS = useMemo(() => {
        const kpis = [
            {
                name: t('Total Sales'),
                number: booking.amount || 0,
                growth: booking.amount_growth || 0,
                signIcon: '$',
            },
            {
                name: t('Service Sales'),
                number: booking.service_amount || 0,
                growth: booking.service_amount_growth || 0,
                signIcon: '$',
            },

            {
                name: t('Total Product'),
                number: booking.products_quantity || 0,
                growth: booking.products_quantity_growth || 0,
            },
            {
                name: t('Total Product Sales'),
                number: booking.product_amount || 0,
                growth: booking.product_amount_growth || 0,
                signIcon: '$',
            },
        ];
        return viewAll ? kpis : kpis.slice(0, 8);
    }, [booking, viewAll]);

    return (
        <div>
            <div className="mb-0">
                <div className="flex justify-between items-center gap-2 mb-3 mt-8">
                    <h2 className="text-gray-900 text-lg font-semibold sm:mb-0 mb-1">{t('Overview')}</h2>

                    <div className="fl-tab-btn-view4">
                        {timePeriodTabs.map((tab: any, index) => (
                            <div className={`fl-tab-link4   ${tab.value === currentTab ? 'active' : ''}`} key={index} onClick={handleTab(tab.value)}>
                                {tab.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="grid max-2xl:grid-cols-2  grid-cols-4 gap-4">
                    {KPIS.map((kpi: any, index) => (
                        <CommonKpiCard key={index} title={kpi.name} description={kpi.number} isLoading={isLoading} signIcon={kpi?.signIcon} growth={kpi?.growth || null} fromDate={boxLabel} />
                    ))}
                </div>
            </div>
            <div className="mb-8">
                <ClientBooking client={client} type="overview" />
            </div>
        </div>
    );
};

export default memo(Overview);
